import { useLocale } from 'shared/util-intl'
import DirectionsRoundedIcon from '@mui/icons-material/Directions'
import { NavigationLinkProps } from './types'
import { Button } from 'shared/ui-components'
import { WEB_APP_PATH_ROUTE_PLANNER } from 'shared/util-navigation'
import { useInternalLink } from '../use-internal-link'

export const RoutePlannerLink = ({ primary, compact }: NavigationLinkProps) => {
  const getInternalLinkProps = useInternalLink()
  const { intl } = useLocale()

  return (
    <Button
      block
      variant={primary ? 'primary' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      icon={<DirectionsRoundedIcon />}
      {...getInternalLinkProps(WEB_APP_PATH_ROUTE_PLANNER)}
      disabled={window.location.href.includes(WEB_APP_PATH_ROUTE_PLANNER)}
    >
      {intl.formatMessage({
        id: 'route_planner_link_label',
        defaultMessage: 'Plan a route',
      })}
    </Button>
  )
}
