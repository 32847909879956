import { Feature, Position } from 'geojson'
import { colors } from 'shared/ui-design-system'
import { LineLayer } from 'react-map-gl/maplibre'

type RouteLineVariant = 'default' | 'inactive' | 'highlight'

type LineLayerBaseProps = Omit<LineLayer, 'id' | 'source'>

const ROUTE_LINE_COMMON_PROPS: LineLayerBaseProps = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
}

export const getRouteLineLayerProps = (variant: RouteLineVariant = 'default'): LineLayerBaseProps => ({
  ...ROUTE_LINE_COMMON_PROPS,
  paint: {
    'line-width': [
      'interpolate', ['linear'], ['zoom'],
      // zoom is <=10 -> 3px
      10, 3,
      // zoom is >=22 -> 6px
      22, 6,
    ],
    'line-color': variant === 'inactive'
      ? colors.polylineComp.inactive.foreground
      : variant === 'highlight'
        ? colors.polylineComp.alternate.foreground
        : colors.polylineComp.foreground,
  },
})

export const getRouteOutlineLayerProps = (variant: RouteLineVariant = 'default'): LineLayerBaseProps => ({
  ...ROUTE_LINE_COMMON_PROPS,
  paint: {
    'line-width': [
      'interpolate', ['linear'], ['zoom'],
      // zoom is <=10 -> 6px
      10, 6,
      // zoom is >=22 -> 12px
      22, 12,
    ],
    'line-color': variant === 'inactive'
      ? colors.polylineComp.inactive.background
      : variant === 'highlight'
        ? colors.polylineComp.alternate.background
        : colors.polylineComp.background,
  },
})

export const getRouteLineFeatures = (identifier: string, segments: Position[][]): Feature[] => segments.map(
  (coordinates, segmentIndex) => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates,
    },
    properties: {
      type: identifier,
      segmentIndex,
    },
  })
)
