import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showNotification } from 'web-app/feature-notifications'
import { AvailableLocale, useLocale } from 'shared/util-intl'
import { geolocationDenied, geolocationFailure, geolocationRequest, geolocationSuccess, UserGeolocation } from './state'
import { useUserState } from './hooks'
import { reverseGeocode } from 'shared/data-access-geocoding'

type UserGeolocationReturn = {
  geolocation: UserGeolocation | null
  isGeolocationLoading: boolean
  isGeolocationDenied: boolean
  geolocate: () => Promise<[number, number] | null>
}

export const useUserGeolocation = (): UserGeolocationReturn => {
  const dispatch = useDispatch()
  const { intl, language } = useLocale()

  const { isGeolocationLoading, isGeolocationDenied, geolocation } = useUserState()

  const showGeocodingErrorNotification = useCallback(() => {
    showNotification(
      {
        title: intl.formatMessage({
          id: 'notification_user_geolocation_geocoding_failed_title',
          defaultMessage: "We couldn't find you",
        }),
        description: intl.formatMessage({
          id: 'notification_user_geolocation_geocoding_failed_description',
          defaultMessage: 'Sorry, something went wrong while determining your current location.',
        }),
        variant: 'danger',
      },
      'error_user_geolocation_geocoding',
    )
  }, [intl])

  const showGeolocationDeniedNotification = useCallback(() => {
    showNotification(
      {
        title: intl.formatMessage({
          id: 'notification_user_geolocation_disabled_title',
          defaultMessage: 'Location services not available',
        }),
        description: intl.formatMessage({
          id: 'notification_user_geolocation_disabled_description',
          defaultMessage: 'Please enable location services for Bikemap in your browser or device settings.',
        }),
        variant: 'info',
      },
      'info_user_geolocation_disabled',
    )
  }, [intl])

  const geocode = useCallback(
    async (clientLocation: [number, number]) => {
      const geolocation = await geocodeUserGeolocation(clientLocation, language)
      if (geolocation) {
        dispatch(geolocationSuccess(geolocation))
      } else {
        dispatch(geolocationFailure())
        showGeocodingErrorNotification()
      }
    },
    [dispatch, language, showGeocodingErrorNotification],
  )

  const geolocate = useCallback(async () => {
    dispatch(geolocationRequest())
    const clientLocation = await askForClientLocation()
    if (clientLocation) {
      geocode(clientLocation)
      return clientLocation
    } else {
      dispatch(geolocationDenied())
      showGeolocationDeniedNotification()
      return null
    }
  }, [dispatch, geocode, showGeolocationDeniedNotification])

  return { geolocation, isGeolocationLoading, isGeolocationDenied, geolocate }
}

/**
 * Ask the user for location permission if necessary and get the current location if available.
 */
export async function askForClientLocation(): Promise<[number, number] | null> {
  try {
    const position = (await getClientLocation()) as {
      coords: {
        latitude: number
        longitude: number
      }
    }
    const { longitude, latitude } = position.coords
    return [longitude, latitude]
  } catch (error) {
    return null
  }
}

/**
 * Retrieve geocoded address info for a given client location and return a full user geolocation.
 */
export async function geocodeUserGeolocation(
  clientLocation: [number, number],
  language: AvailableLocale,
): Promise<UserGeolocation | null> {
  const [lng, lat] = clientLocation
  const res = await reverseGeocode({ lng, lat }, language)
  if (res.success) {
    return {
      lng,
      lat,
      geocoded: res.data,
    }
  }
  return null
}

/**
 * Try to get user location from geolocation API.
 * @throws Error If user does not allow location within 10 seconds
 */
function getClientLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: 10000 })
  })
}
