import { Skeleton, Typography } from '@mui/material'
import { CollapsedDescription } from './collapsed-description'
import { useLocale } from 'shared/util-intl'
import { Input } from 'shared/ui-components'
import { ChangeEvent } from 'react'
import { EditableContent } from '../editable-content'
import { RouteEntity } from 'shared/data-access-core'

interface RouteDescriptionProps {
  description: string
}

const RouteDescription = ({ description }: RouteDescriptionProps) => {
  const { intl } = useLocale()

  return description ? (
    <CollapsedDescription>{description}</CollapsedDescription>
  ) : (
    <Typography paragraph color="textSecondary">
      {intl.formatMessage({
        id: 'route_description_empty_placeholder',
        defaultMessage: 'No description yet.',
      })}
    </Typography>
  )
}

const RouteDescriptionSkeleton = () => (
  <Typography paragraph color="textSecondary">
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton width="50%" />
  </Typography>
)

interface RouteDescriptionFormProps {
  name: string
  value: string
  onChange: (value: string) => void
}

const RouteDescriptionForm = ({ name, value, onChange }: RouteDescriptionFormProps) => {
  const { intl } = useLocale()

  return (
    <Input
      name={name}
      value={value}
      textarea
      resize
      hideLabel
      label={intl.formatMessage({
        id: 'route_description_form_label',
        defaultMessage: 'Description',
      })}
      placeholder={intl.formatMessage({
        id: 'route_description_form_placeholder',
        defaultMessage: 'Add a description',
      })}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
      data-testid="save-route-description"
    />
  )
}

interface EditableRouteDescriptionProps {
  heading?: string
  route?: RouteEntity
}

export const EditableRouteDescription = ({ heading, route }: EditableRouteDescriptionProps) => (
  <EditableContent
    name="description"
    currentValue={route ? route.description || '' : undefined}
    renderContent={({ value }) => <RouteDescription description={value} />}
    renderForm={(props) => <RouteDescriptionForm {...props} />}
    renderLoading={() => <RouteDescriptionSkeleton />}
    heading={heading}
  />
)
