import { useDispatch } from 'react-redux'
import { ErrorBoundary, ErrorBoundaryFallback } from 'web-app/utils-error-handling'
import { RoutePlannerInitializationState, reset } from '../state'
import { RoutePlannerCreate } from './create'
import { RoutePlannerEdit } from './edit'

interface RoutePlannerProps {
  routeId?: number
  waypoints?: string
  initializationState?: RoutePlannerInitializationState
}

export const RoutePlanner = ({
  routeId,
  waypoints,
  initializationState,
}: RoutePlannerProps) => {
  const dispatch = useDispatch()

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallback
          beforeReload={async () => {
            dispatch(reset())
          }}
        />
      }
    >
      {routeId ? (
        <RoutePlannerEdit routeId={routeId} />
      ) : (
        <RoutePlannerCreate
          waypoints={waypoints}
          initializationState={initializationState}
        />
      )}
    </ErrorBoundary>
  )
}
