import { useState } from 'react'
import { RoutePoiForm, RoutePoiFormData } from './route-poi-form'
import { useRoutePois } from '../route-pois-context'
import { useLocale } from 'shared/util-intl'
import { LngLat } from 'shared/ui-map'
import { RoutePoiEntity, updateRoutePoi } from 'shared/data-access-core'

interface RoutePoiEditingProps {
  routePoi: RoutePoiEntity
  position: LngLat
  onError: (message: string) => void
}

export const RoutePoiEditing = ({ routePoi, position, onError }: RoutePoiEditingProps) => {
  const { intl } = useLocale()
  const { onRoutePoiUpdated } = useRoutePois()

  const [formData, setFormData] = useState<RoutePoiFormData>({
    type: routePoi.type,
    description: routePoi.description || '',
    image: undefined,
    currentImage: routePoi.image,
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const res = await updateRoutePoi(routePoi.id, {
      type: formData.type !== routePoi?.type ? formData.type : undefined,
      description:
        !!routePoi?.description && formData.description !== routePoi?.description ? formData.description : undefined,
      position,
      image: !formData.currentImage && !formData.image ? null : formData.image,
    })
    if (res.success) {
      onRoutePoiUpdated(res.data)
    } else {
      onError(
        intl.formatMessage({
          id: 'route_poi_editing_save_error',
          defaultMessage: 'Your changes to the route highlight could not be saved.',
        }),
      )
    }
    setIsSubmitting(false)
  }

  return (
    <RoutePoiForm
      formData={formData}
      onChange={setFormData}
      onSubmit={handleSubmit}
      onImageFileError={onError}
      loading={isSubmitting}
    />
  )
}
