import { LineString, MultiLineString } from 'geojson'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { StateWithHistory } from './undo-redo'
import { StateWithIntlSlice } from 'shared/util-intl'
import { StateWithMapSlice } from 'web-app/feature-map'
import { StateWithRouteSlice } from 'web-app/feature-route'
import { LngLat, Waypoints } from 'shared/util-geo'
import { AlongTheRouteAttribute, BikeNetworkCategory, GetRouteErrors, RoutingProfile, RoutingProfileCyclingPath, SurfaceCategory, WayTypeCategory } from 'shared/data-access-core'
import { StateWithUserSlice } from 'web-app/feature-user'

export const PLANNER_SLICE_KEY = 'planner'

export type Waypoint = LngLat & {
  /** Index of the control point that represents the snapped version of this waypoint */
  controlPointIndex?: number

  /** Location strings from reverse geocoding */
  address?: string
  poiName?: string
  localName?: string

  /** Only derived from control point, can disappear again */
  isImplicit?: boolean
}

export type RoutedWaypoint = Waypoint & {
  controlPointIndex: number
}

export type GeocodedWaypoint = Waypoint & {
  address: string
}

export type IncompleteWaypoints = [null, null] | [Waypoint, null] | [null, Waypoint]

export type CompleteWaypoints = [RoutedWaypoint, RoutedWaypoint, ...RoutedWaypoint[]]

export type RoutingMode = 'routing' | 'freehand'

export type RoutePlannerRouteData = {
  /** Distance of the calculated route in meters */
  distance: number | null

  /** Estimated duration of the calculated route in seconds */
  durationInS: number | null

  /** Geometry of the calculated route in segments */
  geometry: MultiLineString | null

  /** Along the route attributes (only available if the entire route was calculated) */
  surfacesAlongTheRoute: AlongTheRouteAttribute<SurfaceCategory> | null
  wayTypesAlongTheRoute: AlongTheRouteAttribute<WayTypeCategory> | null
  bikeNetworkAlongTheRoute: AlongTheRouteAttribute<BikeNetworkCategory> | null
}

export type RoutePlannerState = RoutePlannerRouteData & {
  isInitialized: boolean

  /** ID of a route that's being edited or copied */
  basedOnRouteId: number | null

  /** List of all waypoints (origin, via, destination) in order */
  waypoints: IncompleteWaypoints | CompleteWaypoints

  /** How changes to the route are handled */
  routingMode: RoutingMode

  /** Currently selected routing profile */
  routingProfile: RoutingProfile

  /** Level of the cycling path routing profile */
  routingLevel: RoutingProfileCyclingPath

  /** Whether the routing profile should be applied to the whole route */
  isWholeRouteRouted: boolean

  /** Location currently selected on the map to perform actions on */
  selectedLocation: Waypoint | null

  /** Waypoint currently selected on the map to perform actions on */
  selectedWaypoint: number | null

  /** While set, the map is trying to fit the viewport to the route geometry */
  isFittingGeometryBounds: boolean

  /** Whether there is an ongoing routing request */
  isFetchingRoute: boolean

  /** Most recent errors when calculating a route failed */
  routingErrors: GetRouteErrors | null
}

export interface StateWithRoutePlannerSlice {
  [PLANNER_SLICE_KEY]: StateWithHistory<RoutePlannerState>
}

export type RoutePlannerInitializationState = {
  origin?: Waypoint,
  destination?: Waypoint,
}

/**
 * Derived state of waypoints with explicit typing and decomposed into start, via and end.
 */
export type WaypointsState =
  | {
      isFullRoute: false
      waypoints: IncompleteWaypoints
      start: Waypoint | null
      via: []
      end: Waypoint | null
    }
  | {
      isFullRoute: true
      waypoints: CompleteWaypoints
      start: RoutedWaypoint
      via: RoutedWaypoint[]
      end: RoutedWaypoint
    }

export type IndexWaypointState = {
  isStart: boolean
  isEnd: boolean
  isVia: boolean
  viaIndex: number
}

export type SelectedWaypointState = {
  index: number
  waypoint: Waypoint
}

export type RoutePlannerSliceDispatch = ThunkDispatch<
  StateWithRoutePlannerSlice & StateWithIntlSlice & StateWithMapSlice & StateWithRouteSlice & StateWithUserSlice,
  undefined,
  UnknownAction
>

export type SavableRouteState = {
  geometry: LineString
  distanceInM: number
  durationInS: number
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]]
  start: Waypoint
  via: Waypoint[]
  end: Waypoint
}
