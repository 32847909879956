import { useMemo } from 'react'
import { Feature, FeatureCollection, LineString } from 'geojson'
import { Layer, Source, useMap } from 'react-map-gl/maplibre'
import along from '@turf/along'
import { colors } from 'shared/ui-design-system'
import {
  ROUTE_DESTINATION_MARKER_LAYER_ID,
  ROUTE_DISTANCE_MARKERS_CIRCLE_LAYER_ID,
  ROUTE_DISTANCE_MARKERS_LABEL_LAYER_ID,
  ROUTE_DISTANCE_MARKERS_SOURCE_ID,
} from '../settings'
import { findBeforeIdOnTop } from '../helpers'

interface MapRouteDistanceMarkersProps {
  id: string
  geometry: LineString
  distance: number
  unitPreference: 'metric' | 'imperial'
  visible?: boolean
}

/**
 * Draws markers representing each full kilometer or mile along a route track.
 */
export const MapRouteDistanceMarkers = ({
  id,
  geometry,
  distance,
  unitPreference,
  visible = true,
}: MapRouteDistanceMarkersProps) => {
  const map = useMap()

  const zoom = map.current?.getZoom()

  const sourceData = useMemo(() => {
    const step = getDistanceMarkerStep(zoom || 0, unitPreference)
    return getDistanceMarkerFeatureCollection(geometry, distance, step, unitPreference)
  }, [distance, geometry, unitPreference, zoom])

  const beforeId = map.current && findBeforeIdOnTop(map.current, ROUTE_DESTINATION_MARKER_LAYER_ID.replace('{baseId}', id))

  return (
    <Source
      id={ROUTE_DISTANCE_MARKERS_SOURCE_ID.replace('{baseId}', id)}
      type='geojson'
      data={sourceData}
    >
      <Layer
        id={ROUTE_DISTANCE_MARKERS_CIRCLE_LAYER_ID.replace('{baseId}', id)}
        beforeId={beforeId}
        type='circle'
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{
          'circle-color': colors.actionColor.onNeutral.tertiary,
          'circle-radius': 10,
        }}
      />
      <Layer
        id={ROUTE_DISTANCE_MARKERS_LABEL_LAYER_ID.replace('{baseId}', id)}
        beforeId={beforeId}
        type='symbol'
        layout={{
          'text-allow-overlap': true,
          'text-field': '{distance}',
          'text-size': 10,
          'text-font': ['Roboto Medium'],
          'text-offset': [0, 0.1],
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{
          'text-color': colors.onColor.primary,
        }}
      />
    </Source>
  )
}

/**
 * Get a GeoJSON FeatureCollection containing all distance marker positions.
 * @param distance Total route distance in meters
 * @param step Distance between two distance markers in large unit (km / mi)
 */
export function getDistanceMarkerFeatureCollection(
  geometry: LineString,
  distance: number,
  step: number,
  unit: 'metric' | 'imperial'
): FeatureCollection {
  const features: Feature[] = []
  const distanceInLargeUnit = distance / (unit === 'imperial' ? 1609.34 : 1000)

  for (let i = step; i < distanceInLargeUnit; i = i + step) {
    const featureAlongGeometry = along(geometry, i, {
      units: unit === 'imperial' ? 'miles' : 'kilometers',
    })
    features.push({
      type: 'Feature',
      geometry: featureAlongGeometry.geometry,
      properties: {
        distance: i,
      },
    })
  }

  return {
    type: 'FeatureCollection',
    features,
  }
}

/**
 * Determine the distance step between distance markers, based on zoom level and unit preference.
 */
export function getDistanceMarkerStep(zoom: number, unitPreference: 'metric' | 'imperial'): number {
  return zoom >= (unitPreference === 'imperial' ? 11 : 12)
    ? 1
    : zoom >= 9
      ? 5
      : zoom >= 7.5
        ? 10
        : 50
}
