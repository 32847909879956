import { useDispatch } from 'react-redux'
import { MapMarkerLocation, useMapClick, useMapContextMenu, useMapLongPress } from 'shared/ui-map'
import { ElevationCurveMapFeatures } from 'shared/feature-elevation-curve'
import { RoutePoisMapFeatures, useRoutePoiPopupProps } from 'shared/feature-route-pois'
import { locationSelected, mapPopupClosed, useRouteDetailsState } from '../state'
import { WebAppMap, MAP_ID } from 'web-app/feature-map'
import { RouteDetailsMapPopup } from './route-details-map-popup'
import { MainMapRoute, useFitRouteBounds, useIsOwnRoute, useRoute } from 'web-app/feature-route'
import { MapLayerTouchEvent } from 'maplibre-gl'
import { MapLayerMouseEvent, useMap } from 'react-map-gl/maplibre'
import { debounce } from 'lodash'
import { useMemo } from 'react'

const MAP_ROUTE_ID = 'route-details-route'

const RouteDetailsMapSelection = () => {
  const dispatch = useDispatch()
  const { [MAP_ID]: map } = useMap()
  const isRoutePoiPopupOpen = !!useRoutePoiPopupProps()
  const isOwnRoute = useIsOwnRoute()
  const { selectedLocation } = useRouteDetailsState()

  const handleMapSelection = debounce((e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    if (selectedLocation) {
      dispatch(mapPopupClosed())
    } else if (isOwnRoute && !isRoutePoiPopupOpen) {
      dispatch(locationSelected({ ...e.lngLat }))
    }
  }, 300)

  const handleMapClick = useMemo(
    () => debounce((event: MapLayerMouseEvent) => {
      const currentMap = map?.getMap()
      if (
        currentMap &&
        !currentMap.isZooming() &&
        !currentMap.isMoving()
      ) {
        handleMapSelection(event)
      }
    }, 300),
    [handleMapSelection, map]
  )

  useMapClick(MAP_ID, handleMapClick)
  useMapContextMenu(MAP_ID, handleMapSelection)
  useMapLongPress(MAP_ID, handleMapSelection)

  return selectedLocation && <MapMarkerLocation longitude={selectedLocation.lng} latitude={selectedLocation.lat} />
}

export const RouteDetailsMap = () => {
  const dispatch = useDispatch()
  const route = useRoute()
  const { boundsToFit, onFitBounds } = useFitRouteBounds()

  return (
    <WebAppMap boundsToFit={boundsToFit} onFitBounds={onFitBounds}>
      {route && (
        <>
          <MainMapRoute />
          <ElevationCurveMapFeatures mapRouteId={MAP_ROUTE_ID} geometry={route.geometry} distance={route.distance} />
          <RoutePoisMapFeatures mapId={MAP_ID} onSelect={() => dispatch(mapPopupClosed())} />
          <RouteDetailsMapSelection />
        </>
      )}
      <RouteDetailsMapPopup />
    </WebAppMap>
  )
}
