export const API_BASE_URL = process.env['NX_PUBLIC_API_URL']
export const API_BASE_URL_CACHED = process.env['NX_PUBLIC_API_URL_CACHED']
export const ROUTING_API_BASE_URL = process.env['NX_PUBLIC_ROUTING_API_URL']

export const API_PATH_AVAILABLE_CURRENCIES = '/v5/payments/plans/currencies/'
export const API_PATH_AVAILABLE_SUBSCRIPTION_PLANS = '/v5/payments/plans/:currencyCode/'
export const API_PATH_DEEPLINK = '/v5/deeplink/'
export const API_PATH_DEFAULT_MAP_STYLE = '/v5/map_styles/default/style/?is_inhoused=true'
export const API_PATH_GARMIN = '/v5/users/:userId/garmin_token/'
export const API_PATH_GEO_FILE_UPLOAD = '/v5/geo_file_upload/'
export const API_PATH_GEO_IP = '/v4/geoip_lookup/'
export const API_PATH_GEO_ROUTE_SIMPLIFICATION = '/v5/georoutesimplification/'
export const API_PATH_MAP_STYLES = '/v4/map_styles/?is_inhoused=true'
export const API_PATH_POPULAR_DESTINATIONS = '/v5/regions/popular-destinations/'
export const API_PATH_RECAP = '/v5/statistics/year-activity/:token/'
export const API_PATH_ROUTE = '/v5/routes/:routeId/'
export const API_PATH_ROUTE_COLLECTION_ROUTES = '/v5/routecollections/:routeCollectionId/routes/'
export const API_PATH_ROUTE_COLLECTIONS = '/v5/routecollections/'
export const API_PATH_ROUTE_FAVOR = '/v5/routes/:routeId/favor/'
export const API_PATH_ROUTE_IMAGE = '/v5/routes/:routeId/image/:routeImageId/'
export const API_PATH_ROUTE_IMAGES = '/v5/routes/:routeId/image/'
export const API_PATH_ROUTE_POI = '/v5/routepois/:routePoiId/'
export const API_PATH_ROUTE_POIS = '/v5/routepois/'
export const API_PATH_ROUTE_ROUTE_POIS = '/v5/routes/:routeId/pois/'
export const API_PATH_ROUTE_SEARCH = '/v5/search/'
export const API_PATH_ROUTE_UNFAVOR = '/v5/routes/:routeId/unfavor/'
export const API_PATH_ROUTE_UPLOAD_IMAGE = '/v5/routes/:routeId/upload_image/'
export const API_PATH_ROUTES = '/v5/routes/'
export const API_PATH_USER = '/v5/users/:userId/'
export const API_PATH_USER_ROUTE_COLLECTIONS = '/v5/users/:userId/collections/'

export const API_URL_GET_DEFAULT_MAP_STYLE = API_BASE_URL_CACHED + API_PATH_DEFAULT_MAP_STYLE

export const API_URL_ROUTING = ROUTING_API_BASE_URL + '/route'
export const API_URL_MATCHING = ROUTING_API_BASE_URL + '/match'
export const API_URL_NEAREST = ROUTING_API_BASE_URL + '/nearest'
