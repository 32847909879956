import { API_PATH_ROUTE } from '../config'
import { ExtensiveRouteEntity, RouteBikeTypes, RouteSurfaces } from '../entities'
import { addApiHeaders, patchToCoreApi } from '../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import {
  RouteResponse,
  RouteResponseIncludedControlPointIndexes,
  RouteResponseIncludedWaypoints,
  convertToExtensiveRouteEntity,
} from '../responses'
import { LineString, MultiPoint } from 'geojson'
import { Waypoints } from 'shared/util-geo'

export type RouteChangeForm = {
  title?: string
  description?: string
  bikeTypes?: RouteBikeTypes
  surfaces?: RouteSurfaces
  isPrivate?: boolean
}

export type RouteGeometryChangeForm = {
  geometry: LineString
  distanceInM: number
  durationInS?: number
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]]
}

type RoutePatchBody = {
  title?: string
  description?: string
  category?: RouteBikeTypes | [0]
  ground?: RouteSurfaces | [0]
  is_private?: boolean
  /** Route endpoint seems to expect a LineString with type="MultiLineString". */
  points?: {
    type: 'MultiLineString',
    coordinates: LineString['coordinates']
  }
  distance?: number
  duration?: number
  waypoints?: MultiPoint
  control_point_indexes?: number[]
}

/**
 * Set one or more attributes of a route.
 */
export async function changeRoute(
  routeId: number,
  form: RouteChangeForm,
  geometryForm?: RouteGeometryChangeForm
): ApiResult<ExtensiveRouteEntity> {
  const { bikeTypes, surfaces, isPrivate, ...changes } = form
  try {
    const body: RoutePatchBody = { ...changes }
    if (bikeTypes) {
      body.category = bikeTypes.length ? bikeTypes : [0]
    }
    if (surfaces) {
      body.ground = surfaces.length ? surfaces : [0]
    }
    if (typeof isPrivate === 'boolean') {
      body.is_private = isPrivate
    }

    if (geometryForm) {
      const { geometry, distanceInM, durationInS, waypoints, controlPointIndexes, ...geometryChanges } = geometryForm
      Object.assign(body, {
        ...geometryChanges,
        points: {
          ...geometry,
          type: 'MultiLineString',
        },
        distance: distanceInM,
        duration: durationInS,
        waypoints: {
          type: 'MultiPoint',
          coordinates: waypoints.map(({ lng, lat, controlPointIndex }) => [lng, lat, controlPointIndex]),
        },
        control_point_indexes: controlPointIndexes,
      } as RoutePatchBody)
    }

    const res: (
      RouteResponse &
      RouteResponseIncludedWaypoints &
      RouteResponseIncludedControlPointIndexes
    ) = await patchToCoreApi(API_PATH_ROUTE, {
      headers: await addApiHeaders(),
      params: { routeId },
      body,
      type: 'json',
    })
    return createSuccessResult(convertToExtensiveRouteEntity({
      ...res,
      // Along the route data wouldn't be ready yet anyway
      bm_surface: null,
      bm_way_type: null,
      bike_network: null,
    }))
  } catch (error) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}
