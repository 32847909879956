import clsx from 'clsx'
import React from 'react'
import styles from './LocationListList.module.scss'

export interface LocationListListProps {
  title?: string
  secondary?: boolean
  children: React.ReactNode
}

export function LocationListList(props: LocationListListProps) {
  return (
    <div className={styles['wrapper']}>
      {props.title && (
        <div className={clsx(styles['heading'], {
          [styles['heading-secondary']]: props.secondary,
        })}>
          {props.title}
        </div>
      )}
      <ul className={styles['list']}>
        {props.children}
      </ul>
    </div>
  )
}
