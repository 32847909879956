import { BikemapLogo } from '../BikemapLogo'
import { Link } from '../Link'

import styles from './BikemapHeader.module.scss'

export interface BikemapHeaderProps {
  logoHref: string
  logoLinkAriaLabel: string
}

export function BikemapHeader({
  logoHref,
  logoLinkAriaLabel,
}: BikemapHeaderProps) {
  return (
    <div className={styles['root']}>
      <Link href={logoHref} aria-label={logoLinkAriaLabel}>
        <BikemapLogo />
      </Link>
    </div>
  )
}

export default BikemapHeader
