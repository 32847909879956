import clsx from 'clsx'
import React from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Input, InputProps } from '../Input'
import { ToolButton } from '../ToolButton'
import { useMessages } from '../MessagesProvider'
import styles from './LocationInput.module.scss'

export interface LocationInputProps extends InputProps {
  icon: React.ReactNode
  onRemove?: () => void
  disabledRemove?: boolean
  inputIcon?: React.ReactNode
}

export const LocationInput: React.FC<LocationInputProps & React.HTMLProps<HTMLInputElement>> = ({
  icon,
  onRemove,
  disabledRemove,
  inputIcon,
  ...inputProps
}: LocationInputProps) => {
  const { deleteLabel } = useMessages()

  return (
    <div className={clsx(styles['root'], { [styles['with-remove']]: !!onRemove || disabledRemove })}>
      <div className={styles['icon-before']}>{icon}</div>
      <div className={styles['input']}>
        <Input {...inputProps} icon={inputIcon} />
      </div>
      {(onRemove || disabledRemove) && (
        <div className={styles['remove-button-holder']}>
          <ToolButton
            variant='ghost-primary'
            onClick={onRemove ?? onRemove}
            icon={<RemoveCircleOutlineIcon />}
            ariaLabel={deleteLabel}
            disabled={disabledRemove}
          />
        </div>
      )}
    </div>
  )
}
