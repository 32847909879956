import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { Box } from '@mui/material'
import { RoutePoiDetails } from '../details/route-poi-details'
import { Button, ButtonGroup, useMessages } from 'shared/ui-components'
import { RoutePoiEntity } from 'shared/data-access-core'
import { useRoutePois } from '../route-pois-context'
import { useState } from 'react'
import { RoutePoiDeleting } from './route-poi-deleting'

interface RoutePoiDetailsEditableProps {
  routePoi: RoutePoiEntity
  onError: (message: string) => void
}

export const RoutePoiDetailsEditable = ({
  routePoi,
  onError,
}: RoutePoiDetailsEditableProps) => {
  const { editLabel, deleteLabel } = useMessages()
  const { onEditRoutePoi } = useRoutePois()

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  return isDeleting ? (
    <RoutePoiDeleting routePoi={routePoi} onCancel={() => setIsDeleting(false)} onError={onError} />
  ) : (
    <>
      <Box marginBottom='1rem'>
        <RoutePoiDetails routePoi={routePoi} />
      </Box>
      <ButtonGroup>
        <Button variant='secondary' icon={<EditRoundedIcon />} onClick={() => onEditRoutePoi(routePoi)}>
          {editLabel}
        </Button>
        <Button variant='danger-secondary' icon={<DeleteForeverRoundedIcon />} onClick={() => setIsDeleting(true)}>
          {deleteLabel}
        </Button>
      </ButtonGroup>
    </>
  )
}
