import { logError } from 'shared/util-error-handling'
import { addApiHeaders, getFromCoreApi } from '../../network'
import { API_PATH_USER, API_PATH_GEO_IP } from '../../config'
import { ApiServiceResult } from '../shared/types'
import { ApiService } from '../shared/api-service'
import { getFailureResult, getSuccessResult } from '../shared/helpers'
import { PREMIUM_PLAN_PERIODS, PremiumPlanPeriod, UserEntity } from '../../entities'
import { UserGetResponse } from '../../responses'
import { LngLat } from 'shared/util-geo'

type ReadUserErrors = {
  unexpectedError?: true
}

type GeoIpErrors = {
  unexpectedResponse?: true
  unexpectedError?: true
}

export class UserApiService extends ApiService {

  /**
   * Get detailed information about a user.
   */
  async read(userId?: number): ApiServiceResult<UserEntity, ReadUserErrors> {
    try {
      const res = await getFromCoreApi(API_PATH_USER, {
        headers: await addApiHeaders(),
        params: {
          userId: userId || 'self',
        },
      })

      return getSuccessResult(this.getFromResponse(res))
    } catch (e) {
      return getFailureResult({ unexpectedError: true })
    }
  }

  /**
   * Period name of the premium plan if subscribed.
   */
  private getPremiumPlanPeriod = (premiumPlan: string | undefined): PremiumPlanPeriod | undefined => {
    if (premiumPlan) {
      for (const period of PREMIUM_PLAN_PERIODS) {
        if (premiumPlan.includes(period)) {
          return period
        }
      }
      logError('Subscription period could not be detected.')
    }
    return undefined
  }

  /**
   * Format user from API response.
   */
  private getFromResponse = (res: UserGetResponse): UserEntity => {
    const premiumPlan = (res.subscription_info && res.subscription_info.plan) || undefined
    return {
      id: res.id,
      slug: res.slug,
      name: res.displayname,
      avatar: res.avatar_image || undefined,
      coverImage: res.cover_image || undefined,
      isPremium: res.is_subscribed,
      externalId: res.external_id,
      planPeriod: (res.is_subscribed && this.getPremiumPlanPeriod(premiumPlan)) || 'free',
      favoriteRoutesCount: res.favorited_count,
      isStaff: !!res.is_staff,
      email: res.email,
      avatarRaw: res.avatar_image_raw || undefined,
      coverImageRaw: res.cover_image_raw || undefined,
      hadTrial: !!res.subscription_info?.trial_ends_at,
    }
  }

  /**
   * Get user's approximate geolocation based on request's IP address.
   * @link https://development.bikemap.net/api/docs/#/geoip_lookup/geoip_lookup_retrieve
   */
  async getIpLocation(): ApiServiceResult<LngLat, GeoIpErrors> {
    try {
      const res = await getFromCoreApi(API_PATH_GEO_IP, {
        headers: await addApiHeaders(),
      })

      if (res.longitude && res.latitude) {
        return getSuccessResult({
          lng: res.longitude,
          lat: res.latitude,
        })
      }

      this.logError('Invalid geo ip response', null, { res })
      return getFailureResult({ unexpectedResponse: true })
    } catch (e) {
      this.logError('Could not get geo ip location', e)
      return getFailureResult({ unexpectedError: true })
    }
  }

}
