import { useMemo } from 'react'
import { WebAppContent, WebAppMainContent, useLayoutRefs } from 'web-app/ui-layout'
import { ElevationCurveWithSkeleton } from 'shared/feature-elevation-curve'
import { colors } from 'shared/ui-design-system'
import { RoutePlannerMainNavigation } from './plan-main-navigation'
import { RoutePlannerContentFooter } from './route-planner-content-footer'
import { RoutePlannerStatistics } from './route-planner-statistics'
import { useRoutePlannerState, useWaypoints } from '../state'
import LocationsList from './locations-list'
import { useWaypointLocationFocus } from './use-waypoint-location-focus'
import { RoutingOptions } from '../components/routing-options'
import { RoutePlannerDiagrams } from './route-planner-diagrams'

export const RoutePlannerMainContent = () => {
  const { isFullRoute, start, end } = useWaypoints()
  const { basedOnRouteId } = useRoutePlannerState()
  const { contentFooterRef } = useLayoutRefs()
  const { onWaypointItemFocus } = useWaypointLocationFocus(contentFooterRef)

  const locations = useMemo(() => {
    const locationListBackground = colors.neutral.secondary
    return (
      <WebAppContent backgroundColor={locationListBackground} noPadding>
        <LocationsList backgroundColor={locationListBackground} onWaypointItemFocus={onWaypointItemFocus} />
      </WebAppContent>
    )
  }, [onWaypointItemFocus])

  return (
    <WebAppMainContent
      secondary
      Header={RoutePlannerMainNavigation}
      footer={isFullRoute && <RoutePlannerContentFooter />}
    >
      {locations}
      {(start || end || basedOnRouteId) && <RoutingOptions />}
      {isFullRoute && (
        <WebAppContent scrollHint>
          <RoutePlannerStatistics />
          <ElevationCurveWithSkeleton height="8rem" />
        </WebAppContent>
      )}
      {isFullRoute && (
        <WebAppContent>
          <RoutePlannerDiagrams />
        </WebAppContent>
      )}
    </WebAppMainContent>
  )
}
