import { MessageFormatElement } from 'react-intl'
import { DEFAULT_LOCALE } from './config'
import { AvailableLocale, IntlNamespace } from './types'

export const getMessages = async (
  language: AvailableLocale,
  namespace: IntlNamespace
): Promise<Record<string, MessageFormatElement[]>> => {
  if (language === DEFAULT_LOCALE) {
    // No need to import since default messages are already in message definitions
    return {}
  }

  const sharedMessages = await (await import(/* webpackChunkName: "lang-shared" */ 'lang/compiled/de/shared.json')).default
  switch (namespace) {
    case 'web-app':
      return {
        ...sharedMessages,
        ...(await import(/* webpackChunkName: "lang-web-app" */ 'lang/compiled/de/web-app.json')).default,
      }
    case 'widgets':
      return {
        ...sharedMessages,
        ...(await import(/* webpackChunkName: "lang-widgets" */ 'lang/compiled/de/widgets.json')).default,
      }
    case 'website':
      return {
        ...sharedMessages,
        ...(await import(/* webpackChunkName: "lang-website" */ 'lang/compiled/de/website.json')).default,
      }
  }
}
