import { useParams } from 'react-router-dom'
import { RouteDetails } from 'web-app/view-route-details'
import { ViewNotFound } from 'web-app/utils-error-handling'

const RouteDetailsView = () => {
  const params = useParams()
  const routeId = params['routeId'] ? Number.parseInt(params['routeId']) : undefined

  return routeId ? <RouteDetails routeId={routeId} /> : <ViewNotFound />
}

export default RouteDetailsView
