import { GlobalStyles } from '@mui/material'
import { StylesMode } from './helpers/get-as-custom-properties'
import { GlobalStylesOptions, getGlobalStyles } from './helpers/get-global-styles'

interface GlobalThemeStylesProps extends GlobalStylesOptions {
  mode?: StylesMode
  customProperties?: Record<string, string>
}

export const GlobalThemeStyles = ({
  mode,
  customProperties,
  ...options
}: GlobalThemeStylesProps) => {
  const globalStyles = getGlobalStyles(mode, options)
  return (
    <GlobalStyles
      styles={{
        ...globalStyles,
        html: {
          ...globalStyles.html,
          ...customProperties,
        },
      }}
    />
  )
}
