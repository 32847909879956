import calculateDistance from '@turf/distance'
import { LineString } from 'geojson'

/**
 * Calculate the distance of a given line in meters.
 */
export function calculateLineDistance(line: LineString): number {
  let distance = 0
  for (let i = 1; i < line.coordinates.length; i++) {
    distance += calculateDistance(line.coordinates[i - 1], line.coordinates[i], { units: 'meters' })
  }
  return distance
}
