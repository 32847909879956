import { useIntl } from 'react-intl'
import { AvailableLocale, IntlShape } from './types'

export interface UseLocale {
  intl: IntlShape
  language: AvailableLocale
}

/**
 * This is preferred source of truth for getting current language.
 * Because translated texts requre useIntl() -> formatMessage() - provided by react-intl wrapper,
 * so it's more consistent if we read language from the same hook.
 */
export function useLocale(): UseLocale {
  const intl = useIntl()
  return {
    intl: intl as IntlShape,
    language: intl.locale as AvailableLocale,
  }
}
