import React from 'react'
import { useNavigate } from 'react-router-dom'

type GetExternalLinkProps = (path: string) => {
  href: string
  onClick: React.MouseEventHandler<HTMLButtonElement|HTMLAnchorElement>
}

export const useInternalLink = (): GetExternalLinkProps => {
  const navigate = useNavigate()
  return (path: string) => ({
    href: path,
    onClick: (e) => {
      e.preventDefault()
      navigate(path)
    },
  })
}
