import AddLocationRoundedIcon from '@mui/icons-material/AddLocationRounded'
import { Button, ButtonGroup, CopyIcon } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useRoutePoiHandlers } from 'shared/feature-route-pois'
import { LngLat } from 'shared/ui-map'
import { useDispatch } from 'react-redux'
import { mapPopupClosed } from '../state'
import { RouteEditLink } from 'web-app/feature-navigation'
import { useRoute } from 'web-app/feature-route'
import { useCopyRoute } from '../hooks/use-copy-route'

interface MapLocationActionsProps {
  location: LngLat
}

export const MapLocationActions = ({ location }: MapLocationActionsProps) => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const route = useRoute()
  const { handleCopy } = useCopyRoute()
  const { onCreateRoutePoi } = useRoutePoiHandlers()

  return (
    <ButtonGroup>
      <Button
        variant='secondary'
        icon={<AddLocationRoundedIcon />}
        onClick={() => {
          onCreateRoutePoi(location)
          dispatch(mapPopupClosed())
        }}
      >
        {intl.formatMessage({
          id: 'route_details_map_action_add_route_poi',
          defaultMessage: 'Add highlight',
        })}
      </Button>
      <Button
        variant='secondary'
        icon={<CopyIcon />}
        onClick={() => {
          handleCopy()
          dispatch(mapPopupClosed())
        }}
      >
        {intl.formatMessage({
          id: 'route_details_map_actionn_copy',
          defaultMessage: 'Copy route',
        })}
      </Button>
      <RouteEditLink
        primary
        routeId={route?.id}
        onClick={() => {
          dispatch(mapPopupClosed())
        }}
      />
    </ButtonGroup>
  )
}
