import { useLocale, useTitle } from 'shared/util-intl'
import { ViewNotFound } from 'web-app/utils-error-handling'

const FallbackView = () => {
  const { intl } = useLocale()
  useTitle(
    intl.formatMessage({
      id: 'fallback_document_title',
      defaultMessage: 'Bikemap - The essential app for cyclists, by cyclists',
    }),
  )

  return <ViewNotFound />
}

export default FallbackView
