import { SelectRow } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { UnitPreference, unitPreferenceUpdated, useUserState } from 'web-app/feature-user'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

export const UnitSelect = () => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const { unitPreference } = useUserState()

  const options: { value: UnitPreference; label: string }[] = useMemo(
    () => [
      {
        value: 'metric',
        label: intl.formatMessage({
          id: 'unit_select_option_metric',
          defaultMessage: 'Metric (m)',
        }),
      },
      {
        value: 'imperial',
        label: intl.formatMessage({
          id: 'unit_select_option_imperial',
          defaultMessage: 'Imperial (ft)',
        }),
      },
    ],
    [intl],
  )

  return (
    <SelectRow
      options={options}
      value={unitPreference}
      onSelect={(unitPreference) => dispatch(unitPreferenceUpdated(unitPreference))}
    />
  )
}
