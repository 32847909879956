import { LngLatBoundsArray } from 'shared/util-geo'
import { API_PATH_ROUTE_SEARCH } from '../config'
import { RoutePreviewEntity, UserPreviewEntity } from '../entities'
import { GetRequestOptionsType, addApiHeaders, getFromCoreApi } from '../network'
import { RouteSearchResponse, convertToRoutePreviewEntityResults } from '../responses'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import { Position } from 'geojson'

type ListParams = {
  page?: number
  pageSize?: number
}

type RouteSearchOptions = {
  bounds?: Position | LngLatBoundsArray
  lang?: 'en' | 'de'
}

type RouteSearchQueryParams = {
  page?: number
  page_size?: number
  sort?: string
  sort_order?: 'ASC' | 'DESC'
  bounds?: string
  no_location_filters?: true
  score_nearby?: 'false'
  route_type: 'user'
}

export type SearchRoutesData = {
  results: number[]
  routes: { [routeId: number]: RoutePreviewEntity }
  creators: { [userId: number]: UserPreviewEntity }
}

export async function searchRoutes(
  options: RouteSearchOptions = {},
  listParams: ListParams = {
    page: 1,
    pageSize: 20,
  }
): ApiResult<SearchRoutesData> {
  try {
    const requestOptions: GetRequestOptionsType = {
      headers: await addApiHeaders(),
      queryParams: {
        page: listParams.page,
        page_size: listParams.pageSize,
        sort: 'relevance',
        sort_order: 'DESC',
        route_type: 'user',
      } as RouteSearchQueryParams,
    }

    if (options.bounds) {
      if (requestOptions.queryParams) {
        requestOptions.queryParams['bounds'] = options.bounds.join(',')
        requestOptions.queryParams['score_nearby'] = 'false'
      }
    }

    if (options.lang && requestOptions.headers) {
      requestOptions.headers['Accept-Language'] = options.lang
    }

    const res: RouteSearchResponse = await getFromCoreApi(API_PATH_ROUTE_SEARCH, requestOptions)
    return createSuccessResult(convertToRoutePreviewEntityResults(res))
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
