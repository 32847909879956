import { ForwardedRef, ReactNode, forwardRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useLayoutRefs } from './layout-hooks'

import styles from './web-app-top-content.module.css'

const TOP_CONTENT_CONTAINER_ID = 'web-app-top-content-container'

export const TopContentContainer = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => (
  <div ref={ref} id={TOP_CONTENT_CONTAINER_ID} />
))

interface WebAppTopContentProps {
  children: ReactNode
  hidden?: boolean
}

/**
 * Content shown on top of the bottom sheet on small viewports.
 */
export const WebAppTopContent = ({
  children,
  hidden,
}: WebAppTopContentProps) => {
  const { bottomSheetRef } = useLayoutRefs()
  const container = document.getElementById(TOP_CONTENT_CONTAINER_ID)

  useEffect(() => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.snapTo(0)
    }
  }, [bottomSheetRef])

  return container ? createPortal((
    <div
      className={styles['root']}
      style={{ visibility: hidden ? 'hidden' : 'visible' }}
    >
      {children}
    </div>
  ), container) : null
}
