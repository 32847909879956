import { createSelector } from 'reselect'
import { ExtensiveRouteEntity, UserPreviewEntity } from 'shared/data-access-core'
import { ROUTE_SLICE_KEY, RouteState, StateWithRouteSlice } from './types'
import { StateWithUserSlice, USER_SLICE_KEY } from 'web-app/feature-user'

export const routeSliceSelector = (state: StateWithRouteSlice): RouteState => state[ROUTE_SLICE_KEY]

export const routeSelector: (state: StateWithRouteSlice) => ExtensiveRouteEntity | undefined = createSelector(
  routeSliceSelector,
  slice => slice.route,
)

export const creatorSelector: (
  state: StateWithRouteSlice
) => UserPreviewEntity | undefined = createSelector(
  routeSliceSelector,
  slice => slice.creator,
)

export const assignedRouteCollectionIdsSelector: (
  state: StateWithRouteSlice
) => undefined | number[] = createSelector(
  routeSliceSelector,
  slice => slice.assignedRouteCollectionIds,
)

export const isOwnRouteSelector: (
  state: StateWithRouteSlice & StateWithUserSlice
) => boolean | undefined = createSelector(
  (state: StateWithRouteSlice) => state[ROUTE_SLICE_KEY].creator,
  (state: StateWithUserSlice) => state[USER_SLICE_KEY].user,
  (state: StateWithUserSlice) => state[USER_SLICE_KEY].isUserLoaded,
  (creator, user, isUserLoaded) => {
    if (user && isUserLoaded) {
      return !!(user.isStaff || creator?.id === user.id)
    }
    return undefined
  },
)
