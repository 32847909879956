import React, { useMemo } from 'react'
import clsx from 'clsx'
import _uniqueId from 'lodash/uniqueId'
import { Tag } from '../Tag'

import styles from './tag-select-field.module.scss'

type KeyType = number | string

export interface TagSelectFieldProps {
  name: string
  label: string
  options: Record<KeyType, string>
  value: KeyType[]
  onChange: (value: KeyType[]) => void
  labelHidden?: boolean
}

export function TagSelectField({
  name,
  label,
  options,
  value,
  onChange,
  labelHidden,
}: TagSelectFieldProps) {
  const id = useMemo(() => _uniqueId(name + '-'), [name])

  const toggleValue = (newValue: KeyType) => {
    const newValues: KeyType[] = []
    for (const key in options) {
      const optionValue = Number.parseInt(key)
      const wasSelected = value.includes(optionValue)
      if (
        (wasSelected && newValue !== optionValue) ||
        (!wasSelected && newValue === optionValue)
      ) {
        newValues.push(optionValue)
      }
    }

    onChange(newValues)
  }

  const optionElements: React.ReactNode[] = []
  const tags: React.ReactNode[] = []

  for (const key in options) {
    const optionValue = Number.parseInt(key)
    const optionLabel = options[key]
    const isSelected = value.includes(optionValue)
    optionElements.push(
      <option key={key} value={optionValue}>
        {optionLabel}
      </option>
    )
    tags.push(
      <Tag
        key={key}
        active={isSelected}
        onClick={() => toggleValue(optionValue)}
        data-testid='tag'
      >
        {optionLabel}
      </Tag>
    )
  }

  return (
    <>
      <label className={clsx(styles['label'], { [styles['invisible-label']]: labelHidden })} htmlFor={id}>
        {label}
      </label>
      <select className={styles['select']} id={id} name={name} multiple defaultValue={value.map(item => '' + item)} tabIndex={-1}>
        {optionElements}
      </select>
      <div className={styles['tags']}>
        {tags}
      </div>
    </>
  )
}
