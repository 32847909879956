import { WebAppLayout, WebAppMainContent } from 'web-app/ui-layout'
import { DefaultHeader, UserNavigation } from 'web-app/feature-navigation'
import { ErrorBoundary, ErrorBoundaryFallback } from 'web-app/utils-error-handling'
import { WebAppMap } from 'web-app/feature-map'
import { HomeMapFeatures } from './map/map-features'
import { QuickAccessSection } from './quick-access-section'
import { DiscoverRoutesSection } from './discover-routes-section'

export const Home = () => (
  <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
    <WebAppLayout UserNavigation={UserNavigation}>
      <WebAppMainContent Header={DefaultHeader}>
        <QuickAccessSection />
        <DiscoverRoutesSection />
      </WebAppMainContent>
      <WebAppMap>
        <HomeMapFeatures />
      </WebAppMap>
    </WebAppLayout>
  </ErrorBoundary>
)
