import { LineString } from 'geojson'
import { has, isEmpty } from 'lodash'
import { addApiHeaders, postToCoreApi } from '../../network'
import { API_PATH_GEO_ROUTE_SIMPLIFICATION } from '../../config'
import { SimplifiedRouteEntity } from './types'
import { ApiServiceResult } from '../shared/types'
import { ApiService } from '../shared/api-service'
import { getFailureResult, getSuccessResult } from '../shared/helpers'

export interface SimplifiedRouteResponse {
  points_simplified: LineString,

  /**
   * Seems wrong...
   * @see https://bikemap.slack.com/archives/C0113ULLRV3/p1710511694587419
   * @see https://bikemap.atlassian.net/browse/WEB-1420
   * @deprecated
   */
  distance: number,
}

type GetSimplifiedRouteErrors = {
  unexpectedError?: true
  unexpectedResponse?: true
}

export class RouteSimplificationApiService extends ApiService {

  /**
   * Send original route and get its simplified version.
   * @link https://development.bikemap.net/api/swagger-ui/#/georoutesimplification/georoutesimplification_create
   */
  getSimplified = async (
    geometry: LineString,
    tolerance = 2
  ): ApiServiceResult<SimplifiedRouteEntity, GetSimplifiedRouteErrors> => {
    try {
      const res: SimplifiedRouteResponse = await postToCoreApi(API_PATH_GEO_ROUTE_SIMPLIFICATION, {
        headers: await addApiHeaders(),
        body: {
          tolerance: tolerance.toString(),
          get_distance: true,
          points_unsimplified: geometry,
        },
        type: 'json',
      })

      // Return successful response
      if (has(res, 'points_simplified.coordinates') && !isEmpty(res.points_simplified.coordinates)) {
        return getSuccessResult({
          geometry: res.points_simplified,
          distance: res.distance || 0,
        })
      }
    } catch (e) {
      this.logError('Simplify route', e, { geometry })
      return getFailureResult({ unexpectedError: true })
    }

    this.logError('Could not get simplified route coordinates', null, { geometry })
    return getFailureResult({ unexpectedResponse: true })
  }

}
