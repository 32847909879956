import { ReactNode } from 'react'
import clsx from 'clsx'

import styles from './user-navigation-container.module.scss'
import additionalContentStyles from './web-app-additional-content.module.scss'

interface UserNavigationContainerProps {
  children: ReactNode
}

export const UserNavigationContainer = ({
  children,
}: UserNavigationContainerProps) => {
  return (
    <div className={clsx(styles['root'], additionalContentStyles['hidden-if-open'])}>
      {children}
    </div>
  )
}
