import { controlPointsSelector, routePlannerSliceSelector, waypointsSelector } from '../selectors'
import {
  fittingGeometryBoundsStarted,
  locationSelected,
  routingAppliedToWholeRoute,
  routingLevelChanged,
  routingProfileChanged,
  waypointsListSorted,
  routeReversed,
} from '../state'
import { CompleteWaypoints, RoutePlannerSliceDispatch, StateWithRoutePlannerSlice } from '../types'
import { reverseGeocode } from './geocoding'
import { calculateRoute, calculateSegments } from './route-calculation'
import { RoutingProfile, RoutingProfileCyclingPath } from 'shared/data-access-core'
import { LngLat, areLngLatsEqual } from 'shared/util-geo'

export function selectLocation(lngLat: LngLat, poiName?: string) {
  return (dispatch: RoutePlannerSliceDispatch) => {
    const location = { ...lngLat } // otherwise it might be non-serializable in redux state
    dispatch(locationSelected(location))
    dispatch(reverseGeocode(location, poiName))
  }
}

/**
 * Reverse the whole route by reversing waypoints and control points.
 */
export function reverseRoute() {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    dispatch(routeReversed())

    const controlPoints = controlPointsSelector(getState())
    if (controlPoints) {
      await dispatch(calculateSegments(controlPoints.reverse(), 0, controlPoints.length - 1))
    } else {
      await dispatch(calculateRoute())
    }
    dispatch(fittingGeometryBoundsStarted())
  }
}

/**
 * Update waypoints order and recalculate only the affected sections ignoring their control points.
 */
export function updateWaypointsOrder(waypoints: CompleteWaypoints) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const originalState = getState()
    const { isFullRoute: wasFullRoute, waypoints: originalWaypoints } = waypointsSelector(originalState)
    const { geometry: originalGeometry } = routePlannerSliceSelector(originalState)
    dispatch(waypointsListSorted(waypoints))

    const updatedState = getState()
    const { isFullRoute, waypoints: updatedWaypoints } = waypointsSelector(updatedState)
    const { geometry: updatedGeometry } = routePlannerSliceSelector(updatedState)
    if (wasFullRoute && isFullRoute && originalGeometry && updatedGeometry) {
      let segmentsDifference = originalGeometry.coordinates.length - updatedGeometry.coordinates.length
      let isAnySectionAffected = false
      let sectionStartWaypointIndex: number | null = null
      for (let i = 1; i < updatedWaypoints.length; i++) {
        const waypoint = updatedWaypoints[i]
        const previousWaypoint = updatedWaypoints[i - 1]
        const originalIndex = originalWaypoints.findIndex(w => areLngLatsEqual(w, waypoint))
        const originalWaypoint = originalIndex >= 0 ? originalWaypoints[originalIndex] : null
        const originalPreviousWaypoint = originalIndex > 0 ? originalWaypoints[originalIndex - 1] : null
        const isSectionAffected = !(
          originalPreviousWaypoint && areLngLatsEqual(originalPreviousWaypoint, previousWaypoint) &&
          originalWaypoint && areLngLatsEqual(originalWaypoint, waypoint)
        )
        if (isSectionAffected && sectionStartWaypointIndex === null) {
          isAnySectionAffected = true
          sectionStartWaypointIndex = i - 1
        }
        if (!isSectionAffected && sectionStartWaypointIndex !== null) {
          const requestWaypoints = updatedWaypoints.slice(sectionStartWaypointIndex, i)
          const startSegmentIndex = updatedWaypoints[sectionStartWaypointIndex].controlPointIndex
          const numSegments = originalPreviousWaypoint.controlPointIndex - startSegmentIndex - segmentsDifference
          await dispatch(calculateSegments(requestWaypoints, startSegmentIndex, numSegments > 0 ? numSegments : 0))
          segmentsDifference += numSegments - requestWaypoints.length + 1
          sectionStartWaypointIndex = null
        }
        if (i === updatedWaypoints.length - 1 && sectionStartWaypointIndex !== null) {
          const requestWaypoints = updatedWaypoints.slice(sectionStartWaypointIndex)
          const startSegmentIndex = updatedWaypoints[sectionStartWaypointIndex].controlPointIndex
          const numSegments = originalWaypoints[i].controlPointIndex - startSegmentIndex - segmentsDifference
          await dispatch(calculateSegments(requestWaypoints, startSegmentIndex, numSegments > 0 ? numSegments : 0))
        }
      }
      if (isAnySectionAffected) {
        dispatch(fittingGeometryBoundsStarted())
      }
    }
  }
}

export function changeRoutingProfile(routingProfile: RoutingProfile) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    dispatch(routingProfileChanged(routingProfile))
    const { isWholeRouteRouted } = routePlannerSliceSelector(getState())
    if (isWholeRouteRouted) {
      await dispatch(calculateRoute())
      dispatch(fittingGeometryBoundsStarted())
    }
  }
}

export function changeRoutingLevel(routingLevel: RoutingProfileCyclingPath) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    dispatch(routingLevelChanged(routingLevel))
    const { isWholeRouteRouted } = routePlannerSliceSelector(getState())
    if (isWholeRouteRouted) {
      await dispatch(calculateRoute())
      dispatch(fittingGeometryBoundsStarted())
    }
  }
}

export function applyRoutingProfileToWholeRoute() {
  return async (dispatch: RoutePlannerSliceDispatch) => {
    dispatch(routingAppliedToWholeRoute())
    await dispatch(calculateRoute())
    dispatch(fittingGeometryBoundsStarted())
  }
}
