import { useEffect, useState } from 'react'
import { Popover, PopoverProps, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ToolButton, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { CollectionAssignmentList } from './collection-assignment-list'
import { CreateCollectionForm } from './create-collection-form'

import styles from './collections-popover.module.css'

interface CollectionsPopoverProps extends PopoverProps {
  anchorEl: HTMLElement | null
  onClose: () => void
}

export const CollectionsPopover = (props: CollectionsPopoverProps) => {
  const { intl } = useLocale()
  const { closeLabel } = useMessages()

  const [isCreateContentShown, setIsCreateContentShown] = useState<boolean>(false)

  useEffect(() => {
    if (props.open) {
      // Reset state when opening again
      setIsCreateContentShown(false)
    }
  }, [props.open])

  return (
    <Popover
      {...props}
      PaperProps={{
        className: styles['container'],
        style: { width: props.anchorEl?.clientWidth },
      }}
    >
      <div className={styles['close-button-holder']}>
        <ToolButton variant="ghost-secondary" icon={<CloseIcon />} ariaLabel={closeLabel} onClick={props.onClose} />
      </div>
      <header className={styles['header']}>
        <Typography variant="h3" component="h2">
          {isCreateContentShown
            ? intl.formatMessage({
              id: 'collections_popover_create_heading',
              defaultMessage: 'New collection',
            })
            : intl.formatMessage({
              id: 'collections_popover_list_heading',
              defaultMessage: 'Choose a collection',
            })}
        </Typography>
      </header>
      <div className={styles['content']}>
        {isCreateContentShown ? (
          <CreateCollectionForm onCancel={() => setIsCreateContentShown(false)} onDone={props.onClose} />
        ) : (
          <CollectionAssignmentList onCreate={() => setIsCreateContentShown(true)} />
        )}
      </div>
    </Popover>
  )
}
