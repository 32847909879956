import { useMemo } from 'react'
import { calculateAscent, calculateDescent } from '../calculation'
import { useElevationCurveContext } from '../context'
import { ElevationData } from '../types'
import { roundToNearest } from 'shared/util-formatting'

export const useSelectionValues = (): { ascent: number, descent: number } => {
  const {
    elevation,
    selectionIndexes,
  } = useElevationCurveContext()

  const selectionElevation = useMemo<ElevationData[] | null>(() => {
    if (elevation?.length && selectionIndexes?.length === 2) {
      const [start, end] = selectionIndexes
      return elevation.slice(start, end + 1)
    }
    return null
  }, [elevation, selectionIndexes])

  const ascent = useMemo<number>(
    () => selectionElevation ? roundToNearest(calculateAscent(selectionElevation)) : 0,
    [selectionElevation]
  )

  const descent = useMemo<number>(
    () => selectionElevation ? roundToNearest(calculateDescent(selectionElevation)) : 0,
    [selectionElevation]
  )

  return {
    ascent,
    descent,
  }
}
