import { UNIT_PREFERENCE_URL_PARAM, addTrackingParameters } from './params'
import { BaseUrlArgs, TrackingParameters } from './types'

const WEBSITE_BASE = process.env['NX_PUBLIC_WEBSITE_URL']
const LEGACY_WEBSITE_BASE = process.env['NX_PUBLIC_LEGACY_WEBSITE_URL']

interface UserProfileUrlArgs extends BaseUrlArgs {
  slug: string
}

interface RouteDetailUrlArgs extends BaseUrlArgs {
  routeId: number
}

interface RouteShareUrlArgs {
  routeId: number
  trackingParameters: TrackingParameters
}

interface RouteCollectionUrlArgs extends BaseUrlArgs {
  routeCollectionId: number
}

type FormatWebsiteUrlOprions = {
  relative?: boolean
  legacy?: boolean
}

/**
 * Concatenates the full Website URL consisting of origin, path and parameters. Path must have a
 * trailing `/`. To append parameters, start with `&`.
 */
function formatWebsiteUrl(path: string, args: BaseUrlArgs, options: FormatWebsiteUrlOprions) {
  const { language, unitPreference, cookieConsentStatistics, trackingParameters } = args

  const params = new URLSearchParams({
    [UNIT_PREFERENCE_URL_PARAM]: unitPreference,
  })

  if (cookieConsentStatistics !== null) {
    params.set('cookieConsentStatistics', cookieConsentStatistics ? '1' : '0')
  }

  if (trackingParameters) {
    addTrackingParameters(trackingParameters, params)
  }

  const base = options.legacy ? LEGACY_WEBSITE_BASE : WEBSITE_BASE
  const paramsString = params.toString()
  return (options.relative ? '' : base) + `/${language}/${path}${paramsString && '?' + paramsString}`
}

export const statelessHomeUrl = WEBSITE_BASE + '/'

export const statelessImprintUrl = LEGACY_WEBSITE_BASE + '/imprint/'

export function getUserGarminUrl({ slug, ...baseArgs }: UserProfileUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl(`u/${slug}/settings/garmin/`, baseArgs, { relative, legacy: true })
}

export function getRouteDetailUrl({ routeId, ...baseArgs }: RouteDetailUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl(`r/${routeId}/`, baseArgs, { relative, legacy: true })
}

export function getRouteShareUrl({ routeId, trackingParameters }: RouteShareUrlArgs, relative?: boolean): string {
  const paramsString = addTrackingParameters(trackingParameters).toString()
  return (relative ? '' : LEGACY_WEBSITE_BASE) + `/r/${routeId}/?${paramsString}`
}

export function getHomeUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('', baseArgs, { relative })
}

export function getPremiumUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('premium/', baseArgs, { relative })
}

export function getPremiumGiftUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('premium-gift/', baseArgs, { relative })
}

interface LoginUrlArgs extends BaseUrlArgs {
  nextPath?: string
  webAppPath?: string
}

export function getLoginUrl({ nextPath, webAppPath, ...baseArgs }: LoginUrlArgs, relative?: boolean): string {
  const nextParam = nextPath ? `&next=${encodeURIComponent(nextPath)}` : webAppPath ? `&next=${encodeURIComponent('/web-app' + webAppPath)}` : ''
  return formatWebsiteUrl('login/', baseArgs, { relative, legacy: true }) + nextParam
}

export function getUserProfileUrl({ slug, ...baseArgs }: UserProfileUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl(`u/${slug}/`, baseArgs, { relative, legacy: true })
}

export function getProfileSettingsUrl({ slug, ...baseArgs }: UserProfileUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl(`u/${slug}/settings/`, baseArgs, { relative, legacy: true })
}

export function getFavoriteRoutesUrl({ slug, ...baseArgs }: UserProfileUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl(`u/${slug}/routes/favorites/`, baseArgs, { relative, legacy: true })
}

type SearchUrlArgs = BaseUrlArgs & {
  center?: [number, number]
  zoom?: number
}

export function getSearchUrl({ center, zoom, ...baseArgs }: SearchUrlArgs, relative?: boolean): string {
  const params = center && zoom ? `&center=${center.join(',')}&zoom=${zoom}` : ''
  return formatWebsiteUrl('search/', baseArgs, { relative, legacy: true }) + params
}

export function getTermsOfServiceUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('terms-of-service/', baseArgs, { relative, legacy: true })
}

export function getPrivacyPolicyUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('dataprivacy/', baseArgs, { relative, legacy: true })
}

export function getCookieDeclarationUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('cookies/', baseArgs, { relative, legacy: true })
}

export function getImprintUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('imprint/', baseArgs, { relative, legacy: true })
}

export interface CheckoutUrlArgs extends BaseUrlArgs {
  plan: string
  currency: string
  successUrl: string
  cancelUrl: string
  voucherCode?: string
}

export function getCheckoutUrl(
  { plan, currency, successUrl, cancelUrl, voucherCode, ...baseArgs }: CheckoutUrlArgs,
  relative?: boolean
): string {
  const params = new URLSearchParams({
    plan,
    currency,
    success_url: successUrl,
    cancel_url: cancelUrl,
  })
  if (voucherCode) {
    params.append('voucher_code', voucherCode)
  }
  const paramsString = params.toString()
  return formatWebsiteUrl('payment/checkout/', baseArgs, { relative, legacy: true }) + '&' + paramsString
}

export interface GiftCheckoutUrlArgs extends CheckoutUrlArgs {
  recipientEmail: string
  deliveryDate: string
  message?: string
  notifyPurchaser?: boolean
}

export function getGiftCheckoutUrl(
  {
    plan,
    currency,
    successUrl,
    cancelUrl,
    voucherCode,
    recipientEmail,
    deliveryDate,
    message,
    notifyPurchaser,
    ...baseArgs
  }: GiftCheckoutUrlArgs,
  relative?: boolean
): string {
  const params = new URLSearchParams({
    plan,
    currency,
    success_url: successUrl,
    cancel_url: cancelUrl,
    is_gift: '1',
    recipient_email: recipientEmail,
    delivery_date: deliveryDate,
    include_avatar: '1',
  })
  if (voucherCode) {
    params.append('voucher_code', voucherCode)
  }
  if (message) {
    params.append('message', message)
  }
  if (notifyPurchaser) {
    params.append('notify_purchaser', '1')
  }
  const paramsString = params.toString()
  return formatWebsiteUrl('payment/checkout/', baseArgs, { relative, legacy: true }) + '&' + paramsString
}

export function getRouteCollectionUrl(
  { routeCollectionId, ...baseArgs }: RouteCollectionUrlArgs,
  relative?: boolean
): string {
  return formatWebsiteUrl(`c/${routeCollectionId}/`, baseArgs, { relative, legacy: true })
}

interface LogoutUrlArgs extends BaseUrlArgs {
  path?: string
}

export function getLogoutUrl({ path, ...baseArgs }: LogoutUrlArgs, relative?: boolean): string {
  const nextPath = path ? `&next=${encodeURIComponent('/web-app' + path)}` : ''
  return formatWebsiteUrl('logout/', baseArgs, { relative, legacy: true }) + nextPath
}

export function getAboutUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('about-bikemap/', baseArgs, { relative, legacy: true })
}

export function getJobsUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('jobs/', baseArgs, { relative, legacy: true })
}

export function getCommunityReportsUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('community-reports/', baseArgs, { relative, legacy: true })
}

export function getAppsUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('apps/', baseArgs, { relative, legacy: true })
}

export function getAppleWatchUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('apple-watch/', baseArgs, { relative, legacy: true })
}

export function getInviteFriendsUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('invite-friends/', baseArgs, { relative, legacy: true })
}

export function getRegionsUrl(baseArgs: BaseUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl('l/', baseArgs, { relative, legacy: true })
}

interface RegionUrlArgs extends BaseUrlArgs {
  geonameId: number
}

export function getRegionUrl({ geonameId, ...baseArgs }: RegionUrlArgs, relative?: boolean): string {
  return formatWebsiteUrl(`l/${geonameId}/`, baseArgs, { relative, legacy: true })
}
