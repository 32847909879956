import { LocationListList } from './LocationListList'
import { LocationListHolder } from './LocationListHolder'
import { LocationListItem, LocationListLoadingItem } from './LocationListItem'
import { LocationListAction } from './LocationListAction'

export const LocationList = {
  Holder: LocationListHolder,
  List: LocationListList,
  Item: LocationListItem,
  LoadingItem: LocationListLoadingItem,
  Action: LocationListAction,
}
