import { RouteEntity } from 'shared/data-access-core'
import { AddToCollectionAction } from './add-to-collection-action'
import { AssignedCollectionsList } from './assigned-collections-list'

interface RouteDetailCollectionsProps {
  route: RouteEntity
  assignedRouteCollectionIds: number[]
}

export const RouteDetailCollections = ({
  route,
  assignedRouteCollectionIds,
}: RouteDetailCollectionsProps) => (
  <>
    {(route.isFavorite || !!assignedRouteCollectionIds?.length) && (
      <AssignedCollectionsList route={route} assignedRouteCollectionIds={assignedRouteCollectionIds} />
    )}
    <AddToCollectionAction />
  </>
)
