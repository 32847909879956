import { WebAppMainContent } from 'web-app/ui-layout'
import { RoutePrintMainNavigation } from './route-print-main-navigation'
import { RoutePrintMainActions } from './route-print-main-actions'
import { PrintTips } from './components/print-tips'
import { PrintingOptions } from './components/printing-options'
import { DetailedMapsOptions } from './components/detailed-maps-options'
import { useRoute } from 'web-app/feature-route'
import { ImagesOptions } from './components/images-options'

interface RoutePrintMainContentProps {
  routeId: number
}

export const RoutePrintMainContent = ({
  routeId,
}: RoutePrintMainContentProps) => {
  const route = useRoute()

  return (
    <WebAppMainContent
      Header={() => <RoutePrintMainNavigation routeId={routeId} />}
      footer={<RoutePrintMainActions />}
    >
      <PrintingOptions />
      {!!route?.images.length && <ImagesOptions />}
      <DetailedMapsOptions />
      <PrintTips />
    </WebAppMainContent>
  )
}
