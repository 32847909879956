import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'
import { addApiHeaders, getFromCoreApi } from '../network'
import { API_PATH_AVAILABLE_CURRENCIES, API_PATH_AVAILABLE_SUBSCRIPTION_PLANS } from '../config'
import { SubscriptionPlanEntity } from '../entities'
import { SubscriptionPlanResponse, convertToSubscriptionPlanEntity } from '../responses'

export type Currency = {
  code: string
  symbol: string
}

export async function getAvailableCurrencies(): ApiResult<Currency[]> {
  try {
    const res: Currency[] = await getFromCoreApi(API_PATH_AVAILABLE_CURRENCIES, {
      headers: await addApiHeaders(),
    })
    return createSuccessResult(res)
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

export type SubscriptionPlans = {
  monthly?: SubscriptionPlanEntity
  yearly?: SubscriptionPlanEntity
  threeYears?: SubscriptionPlanEntity
  lifetime?: SubscriptionPlanEntity
}

export async function getAvailableSubscriptionPlans(currencyCode: string): ApiResult<SubscriptionPlans> {
  try {
    const res: SubscriptionPlanResponse[] = await getFromCoreApi(API_PATH_AVAILABLE_SUBSCRIPTION_PLANS, {
      params: { currencyCode },
      headers: await addApiHeaders(),
    })
    return createSuccessResult(getSubscriptionPlansFromResponse(res))
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

export async function getAvailableGiftPlans(currencyCode: string): ApiResult<SubscriptionPlans> {
  try {
    const res: SubscriptionPlanResponse[] = await getFromCoreApi(API_PATH_AVAILABLE_SUBSCRIPTION_PLANS, {
      params: { currencyCode },
      queryParams: { giftable: 1 },
      headers: await addApiHeaders(),
    })
    return createSuccessResult(getSubscriptionPlansFromResponse(res))
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}

function getSubscriptionPlansFromResponse(res: SubscriptionPlanResponse[]): SubscriptionPlans {
  const subscriptionPlans: SubscriptionPlans = {}
  for (const planRes of res) {
    if (planRes.code.includes('monthly')) {
      subscriptionPlans.monthly = convertToSubscriptionPlanEntity(planRes)
    } else if (planRes.code.includes('yearly')) {
      subscriptionPlans.yearly = convertToSubscriptionPlanEntity(planRes)
    } else if (planRes.code.includes('3-years')) {
      subscriptionPlans.threeYears = convertToSubscriptionPlanEntity(planRes)
    } else if (planRes.code.includes('lifetime')) {
      subscriptionPlans.lifetime = convertToSubscriptionPlanEntity(planRes)
    }
  }
  return subscriptionPlans
}
