import { useMemo } from 'react'
import { LineString, Feature, FeatureCollection, Position } from 'geojson'
import { Layer, Source } from 'react-map-gl/maplibre'
import { colors } from 'shared/ui-design-system'
import { getLetterFromWaypointIndex } from '../helpers'
import { useMapImage } from '../use-map-image'
import {
  ROUTE_DESTINATION_MARKER_LAYER_ID,
  ROUTE_GEOMETRY_SOURCE_ID,
  ROUTE_ORIGIN_MARKER_LAYER_ID, ROUTE_VIA_MARKER_LAYER_ID,
} from '../settings'
import { useRouteLayerBeforeId } from './use-route-layer-before-id'
import { LngLat, Waypoints, isWaypoint } from 'shared/util-geo'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { useControlPointRelations } from './use-control-point-relations'
import { useRouteLines } from './use-route-lines'
import { useInactiveRouteLines } from './use-inactive-route-lines'

import originMarkerImg from '../img/route-marker-origin.png'
import locationMarkerImg from '../img/location-marker.png'
import destinationMarkerImg from '../img/route-marker-destination.png'

interface MapRouteProps {
  id: string
  geometry: LineString
  inactiveGeometry?: LineString
  waypoints: Waypoints | [LngLat, LngLat, ...LngLat[]]
  controlPointIndexes?: [number, number, ...number[]]
}

/**
 * Default representation of a route on the map.
 */
export const MapRoute = ({
  id,
  geometry,
  inactiveGeometry,
  waypoints,
  controlPointIndexes,
}: MapRouteProps) => {
  useMapImage(originMarkerImg, 'route-marker-origin')
  useMapImage(locationMarkerImg, 'location-marker')
  useMapImage(destinationMarkerImg, 'route-marker-destination')

  const controlPointRelationLines = useMemo<[Position, Position][]>(() => waypoints.reduce((relations, waypoint, i) => {
    if (isWaypoint(waypoint) && geometry?.coordinates.length) {
      const { lng, lat } = waypoint
      const controlPoint = i === 0 ? ( // origin
        geometry.coordinates[0]
      ) : i === waypoints.length - 1 ? ( // destination
        geometry.coordinates[geometry.coordinates.length - 1]
      ) : ( // via
        controlPointIndexes
          ? geometry.coordinates[controlPointIndexes[waypoint.controlPointIndex]]
          : nearestPointOnLine(geometry, [lng, lat]).geometry.coordinates
      )

      if (controlPoint && !(controlPoint[0] === lng && controlPoint[1] === lat)) {
        relations.push([[lng, lat], controlPoint])
      }
    }
    return relations
  }, [] as [Position, Position][]), [controlPointIndexes, geometry, waypoints])

  const routeLayerBeforeId = useRouteLayerBeforeId()
  const routeLines = useRouteLines(id, [geometry.coordinates])
  const inactiveRouteLines = useInactiveRouteLines(id, inactiveGeometry ? [inactiveGeometry.coordinates] : [])
  const controlPointRelations = useControlPointRelations(id, controlPointRelationLines)

  const sourceData = useMemo<FeatureCollection>(() => {
    const features: Feature[] = [
      ...routeLines.features,
      ...inactiveRouteLines.features,
      ...controlPointRelations.features,
    ]

    waypoints.forEach((waypoint, i) => {
      const { lng, lat } = waypoint

      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
        properties: i === 0 ? {
          type: 'origin',
        } : i === waypoints.length - 1 ? {
          type: 'destination',
        } : {
          type: 'via',
          label: getLetterFromWaypointIndex(i),
        },
      })
    })

    return {
      type: 'FeatureCollection',
      features,
    }
  }, [controlPointRelations.features, inactiveRouteLines.features, routeLines.features, waypoints])

  const sourceId = useMemo<string>(() => ROUTE_GEOMETRY_SOURCE_ID.replace('{baseId}', id), [id])

  return (
    <Source
      id={sourceId}
      type='geojson'
      data={sourceData}
    >
      <Layer {...controlPointRelations.layerProps} beforeId={routeLayerBeforeId} />
      <Layer {...inactiveRouteLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...inactiveRouteLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeLines.outlineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer {...routeLines.lineLayerProps} beforeId={routeLayerBeforeId} />
      <Layer
        id={ROUTE_DESTINATION_MARKER_LAYER_ID.replace('{baseId}', id)}
        type='symbol'
        layout={{
          'icon-image': 'route-marker-destination',
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 15],
          'icon-size': 0.44,
        }}
        filter={['==', ['get', 'type'], 'destination']}
      />
      <Layer
        id={ROUTE_VIA_MARKER_LAYER_ID.replace('{baseId}', id)}
        type='symbol'
        layout={{
          'icon-image': 'location-marker',
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 13],
          'icon-size': 0.196,
          'text-field': ['get', 'label'],
          'text-font': ['Roboto Medium'],
          'text-size': 11.5,
          'text-offset': [0, -1.95],
        }}
        paint={{
          'text-color': colors.onNeutral.primary,
        }}
        filter={['==', ['get', 'type'], 'via']}
      />
      <Layer
        id={ROUTE_ORIGIN_MARKER_LAYER_ID.replace('{baseId}', id)}
        type='symbol'
        layout={{
          'icon-image': 'route-marker-origin',
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 10],
          'icon-size': 0.47,
        }}
        filter={['==', ['get', 'type'], 'origin']}
      />
    </Source>
  )
}

export default MapRoute
