import { MapRoute } from 'shared/ui-map'
import { useRoute } from './state'

/**
 * Shows the current main route from global state on the map.
 */
export const MainMapRoute = () => {
  const route = useRoute()

  return route ? (
    <MapRoute
      id='main-route'
      geometry={route.geometry}
      waypoints={route.waypoints}
      controlPointIndexes={route.controlPointIndexes || undefined}
    />
  ) : null
}
