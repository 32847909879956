import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HOME_SLICE_KEY, HomeState } from './types'
import { RoutePreviewEntity, UserPreviewEntity } from 'shared/data-access-core'

export const initialState: HomeState = {
  isSearching: true,
  results: [],
  selectedRoute: null,
  routes: {},
  creators: {},
}

const slice = createSlice({
  name: HOME_SLICE_KEY,
  initialState,
  reducers: {
    routesSearched(state) {
      state.isSearching = true
    },
    routesFound(state, action: PayloadAction<{
      results: number[]
      routes: { [routeId: number]: RoutePreviewEntity }
      creators: { [userId: number]: UserPreviewEntity }
    }>) {
      Object.assign(state, action.payload)
      state.isSearching = false
    },
    routeSelected(state, action: PayloadAction<{ routeId: number }>) {
      const route = state.routes[action.payload.routeId]
      if (route) {
        state.selectedRoute = {
          ...route.start,
          id: route.id,
        }
      }
    },
    routeSelectionCleared(state) {
      state.selectedRoute = null
    },
    routeAddedToFavorites(state, action: PayloadAction<{ routeId: number }>) {
      const route = state.routes[action.payload.routeId]
      if (route) {
        route.isFavorite = true
        route.favoriteCount++
      }
    },
    routeRemovedFromFavorites(state, action: PayloadAction<{ routeId: number }>) {
      const route = state.routes[action.payload.routeId]
      if (route) {
        route.isFavorite = false
        route.favoriteCount--
      }
    },
  },
})

export const {
  routesSearched,
  routesFound,
  routeSelected,
  routeSelectionCleared,
  routeAddedToFavorites,
  routeRemovedFromFavorites,
} = slice.actions

export const homeReducer = slice.reducer
