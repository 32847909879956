import { useCallback, ReactNode, MouseEvent, useMemo } from 'react'
import { useDropzone, DropzoneOptions, FileRejection } from 'react-dropzone'
import { isEmpty, uniqueId } from 'lodash'
import clsx from 'clsx'
import { CircularProgress } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Button, ButtonProps } from '../Button'
import { useMessages } from '../MessagesProvider'

import styles from './Uploader.module.scss'

type RemoteFile = {
  type: 'image'
  url: string
}

export interface UploaderMessages {
  dropFilesHere: string
  button: string
  cancel: string
}

export interface UploaderProps {
  variant?: 'default' | 'primary'
  className?: string
  label: string
  hideLabel?: boolean
  onDrop?: (files: File[]) => void
  previewFiles?: File[]
  onPreviewFileClear?: (fileIndex: number) => void
  remoteFiles?: RemoteFile[]
  onRemoteFileClear?: (url: string) => void
  dropzoneOptions: DropzoneOptions
  loading?: boolean
  onCancel?: () => void
  children?: ReactNode
  messages: UploaderMessages
  buttonIcon?: ReactNode
  inputDataTestId?: string
  extraActions?: ReactNode
}

export type UploaderFileRejectionsType = FileRejection[]

/**
 * Universal file uploader with drag & drop zone.
 */
export const Uploader = ({
  variant = 'default',
  className,
  label,
  hideLabel,
  onDrop,
  previewFiles,
  onPreviewFileClear,
  remoteFiles,
  onRemoteFileClear,
  dropzoneOptions,
  loading,
  onCancel,
  children,
  messages,
  buttonIcon,
  inputDataTestId,
  extraActions,
}: UploaderProps) => {
  const onDropCallback = useCallback((files: File[]) => {
    if (typeof onDrop === 'function') {
      onDrop(files)
    }
  }, [onDrop])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop: onDropCallback,
    disabled: loading,
    ...dropzoneOptions,
  })

  const clearRemoteFile = (e: MouseEvent, url: string) => {
    e.preventDefault()
    e.stopPropagation()

    if (typeof onRemoteFileClear === 'function') {
      onRemoteFileClear(url)
    }
  }

  const clearFile = (e: MouseEvent, fileIndex: number) => {
    e.preventDefault()
    e.stopPropagation()

    if (typeof onPreviewFileClear === 'function') {
      onPreviewFileClear(fileIndex)
    }
  }

  const { deleteLabel } = useMessages()

  const renderRemoteFile = (file: RemoteFile, fileIndex: number) => {
    return (
      <div key={fileIndex} className={styles['preview-file']}>
        <Button
          className={styles['delete-button']}
          onClick={(e) => clearRemoteFile(e, file.url)}
          icon={<DeleteForeverIcon />}
          variant='danger-secondary'
          size='small'
          ariaLabel={deleteLabel}
        />
        {file.type === 'image'
          ? (
            <img
              className={styles['preview-image']}
              src={file.url}
              alt={'preview-file-' + fileIndex}
            />
          )
          : (
            <div className={styles['preview-text']}>
              {file.type}
            </div>
          )}
      </div>
    )
  }

  const renderPreviewFile = (file: File, fileIndex: number) => {
    const fileNameParts = file.name.split('.')
    const fileEnding = fileNameParts[fileNameParts.length - 1]
    return (
      <div key={fileIndex} className={styles['preview-file']}>
        <Button
          className={styles['delete-button']}
          onClick={(e) => clearFile(e, fileIndex)}
          icon={<DeleteForeverIcon />}
          variant='danger-secondary'
          size='small'
          ariaLabel={deleteLabel}
        />
        {file.type && file.type.includes('image')
          ? (
            <img
              className={styles['preview-image']}
              src={URL.createObjectURL(file)}
              alt={'preview-file-' + fileIndex}
            />
          )
          : (
            <div className={styles['preview-text']}>
              {fileEnding}
            </div>
          )}
      </div>
    )
  }

  const buttonProps: ButtonProps = {
    variant: variant === 'primary'
      ? (loading ? 'secondary-contrast' : 'primary')
      : 'secondary',
    icon: !loading ? buttonIcon : undefined,
    block: true,
    onClick: loading && onCancel ? () => { onCancel() } : undefined,
    ariaLabel: loading ? messages.cancel : messages.button,
    children: loading ? messages.cancel : messages.button,
  }

  const inputId = useMemo(() => uniqueId('Uploader-'), [])
  const hasPreviewFiles = previewFiles && !isEmpty(previewFiles)
  const hasRemoteFiles = remoteFiles && !isEmpty(remoteFiles)

  return (
    <div className={className || ''}>
      <label htmlFor={inputId} className={hideLabel ? styles['invisible-label'] : styles['label']}>{label}</label>
      <div className={clsx(styles['container'], styles[variant])} {...getRootProps()} tabIndex={-1}>
        <input id={inputId} {...getInputProps()} data-testid={inputDataTestId || 'uploader'} />
        <div className={clsx(styles['content'], { [styles['content-with-preview-files']]: hasPreviewFiles || hasRemoteFiles })}>
          <div className={clsx({ [styles['invisible']]: isDragActive || loading })}>
            {hasRemoteFiles || hasPreviewFiles
              ? (
                <div className={styles['preview-holder']}>
                  {remoteFiles?.map((file, i) => renderRemoteFile(file, i))}
                  {previewFiles?.map((file, i) => renderPreviewFile(file, i))}
                </div>
              )
              : children
            }
          </div>
          {loading && (
            <div className={styles['overlay']}>
              <CircularProgress size={'1.5rem'} />
            </div>
          )}
        </div>
        <div className={clsx(styles['actions'], { [styles['invisible']]: isDragActive })}>
          <Button {...buttonProps}/>
          {extraActions && (
            <div onClick={e => e.stopPropagation()}>
              {extraActions}
            </div>
          )}
        </div>
        {isDragActive && (
          <div className={styles['overlay']}>{messages.dropFilesHere}</div>
        )}
      </div>
    </div>
  )
}

export default Uploader
