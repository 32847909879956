import { LineString, MultiLineString, Position } from 'geojson'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { CompleteWaypoints, IncompleteWaypoints, RoutedWaypoint, Waypoint } from './types'
import calculateDistance from '@turf/distance'
import { LngLat, LngLatElevation, Position3d, isPosition3d, positionToLngLat } from 'shared/util-geo'

/**
 * Split a line at given indexes. Indexes must be unique, ascending and should contain the first and last index.
 */
export function splitLineAtIndexes(line: LineString, indexes: [number, number, ...number[]]): MultiLineString {
  const coordinates: Position[][] = [line.coordinates.slice(0, indexes[1] + 1)]
  for (let i = 2; i < indexes.length; i++) {
    coordinates.push(line.coordinates.slice(indexes[i - 1], indexes[i] + 1))
  }
  return {
    type: 'MultiLineString',
    coordinates,
  }
}

/**
 * Split a line according to split points, which can be part of the line coordinates, on the line or near the line.
 */
export function splitLineAtPoints(line: LineString, splitPoints: LngLat[]): MultiLineString {
  return {
    type: 'MultiLineString',
    coordinates: splitPoints.length > 2
      ? splitLineNearMultipleLocations(line.coordinates, splitPoints.slice(1, -1))
      : [[...line.coordinates]],
  }
}

/**
 * Split line coordinates near the given locations.
 */
export function splitLineNearMultipleLocations(coordinates: Position[], splitPoints: LngLat[]): Position[][] {
  /** Coordinates for the final MultiLineString */
  const splitCoordinates: Position[][] = []
  /** Line without the parts that have already been sliced (to minimize conflicts) */
  let remainingCoordinates: Position[] = coordinates

  for (const splitPoint of splitPoints) {
    const splitLine = splitLineNearLocation(remainingCoordinates, splitPoint)
    splitCoordinates.push(splitLine[0])
    remainingCoordinates = splitLine[1] || []
  }
  // Add the last segment (until the end) to coordinates
  splitCoordinates.push(remainingCoordinates)

  return splitCoordinates
}

/**
 * Split line coordinates at the point that is nearest to a given location.
 */
export function splitLineNearLocation(
  coordinates: Position[],
  location: LngLat
): [Position[]] | [Position[], Position[]] {
  const { lng, lat } = location

  // First check if exact point is already in coordinates
  const pointIndex = coordinates.findIndex(
    ([pointLng, pointLat]) => lng === pointLng && lat === pointLat
  )
  if (pointIndex === 0 || pointIndex === coordinates.length - 1) {
    return [coordinates]
  } else if (pointIndex > 0) {
    return [
      coordinates.slice(0, pointIndex + 1),
      coordinates.slice(pointIndex),
    ]
  }

  // Find nearest point on the line and split into segments at that point
  const { geometry, properties } = nearestPointOnLine({ type: 'LineString', coordinates }, [lng, lat])
  const linePartIndex = properties.index ? properties.index : 0
  const elevation = (
    coordinates[linePartIndex][2] + coordinates[linePartIndex + 1][2]
  ) / 2
  const splitPoint = [...geometry.coordinates.map(roundPositionValue), roundPositionValue(elevation)]
  return [[
    ...coordinates.slice(0, linePartIndex + 1), // points up until the split part
    splitPoint, // exact split point
  ], [
    splitPoint,
    ...coordinates.slice(linePartIndex + 1),
  ]]
}

/**
 * Combine line segments to one line without duplicate split points.
 */
export function combineSegmentsToLine(segments: MultiLineString): LineString {
  const line: LineString = {
    type: 'LineString',
    coordinates: [...segments.coordinates[0]],
  }
  for (let i = 1; i < segments.coordinates.length; i++) {
    // Add all coordinates of the next segment except the first one (duplicate)
    for (let j = 1; j < segments.coordinates[i].length; j++) {
      line.coordinates.push(segments.coordinates[i][j])
    }
  }
  return line
}

/**
 * Get the start point, split points and end point of the segmented route line.
 */
export function deriveControlPointsFromSegments(segments: MultiLineString): LngLat[] {
  if (!segments.coordinates.length) return []
  const start = positionToLngLat(segments.coordinates[0][0])
  const segmentEndPoints = segments.coordinates.map(points => positionToLngLat(points[points.length - 1]))
  return [start, ...segmentEndPoints]
}

/**
 * Get indexes of the positions in a combined geometry that would represent control points.
 */
export function deriveControlPointIndexesFromSegments(segments: MultiLineString): [number, number, ...number[]] | null {
  if (!segments.coordinates.length) return null
  const indexes: [number, number, ...number[]] = [0, segments.coordinates[0].length - 1]
  for (let i = 1; i < segments.coordinates.length; i++) {
    indexes.push(indexes[indexes.length - 1] + segments.coordinates[i].length - 1)
  }
  return indexes
}

/**
 * Create geometry coordinates with straight segments and estimated stats from a list of control points.
 * @param controlPoints Points that define the freehand sections
 */
export function createFreehandSegments(controlPoints: LngLatElevation[]): {
  geometry: MultiLineString
  distanceInM: number
  durationInS: number
} {
  const coordinates: Position3d[][] = []
  let distanceInM = 0
  let durationInS = 0
  for (let i = 1; i < controlPoints.length; i++) {
    const previous = controlPoints[i - 1]
    const current = controlPoints[i]

    const from: Position3d = [previous.lng, previous.lat, previous.elevation]
    const to: Position3d = [current.lng, current.lat, current.elevation]
    const segmentEstimates = getStatsEstimation(from, to)

    coordinates.push([from, to])
    distanceInM += segmentEstimates.distanceInM
    durationInS += segmentEstimates.durationInS
  }
  return {
    geometry: { type: 'MultiLineString', coordinates },
    distanceInM,
    durationInS,
  }
}

/**
 * Calculate estimated stats for route geometry.
 */
export function estimateRouteStats(geometry: MultiLineString | null): { distanceInM: number, durationInS: number } {
  let distanceInM = 0
  let durationInS = 0
  if (geometry) {
    for (let segmentIndex = 0; segmentIndex < geometry.coordinates.length; segmentIndex++) {
      const segment = geometry.coordinates[segmentIndex]
      for (let i = 1; i < segment.length; i++) {
        const previous = segment[i - 1]
        const current = segment[i]
        if (isPosition3d(previous) && isPosition3d(current)) {
          const statsEstimation = getStatsEstimation(previous, current)
          distanceInM += statsEstimation.distanceInM
          durationInS += statsEstimation.durationInS
        }
      }
    }
  }
  return { distanceInM, durationInS }
}

// These are experimental values based on comparison with Graphhopper responses (WEB-1412)
const ESTIMATED_AVERAGE_SPEED_IN_KMH = 17
const ESTIMATED_AVERAGE_SPEED_IN_MS = ESTIMATED_AVERAGE_SPEED_IN_KMH / 3.6
const GRADIENT_FACTOR_UP = 12
const GRADIENT_FACTOR_DOWN = 3

/**
 * Get a rough estimation of the route stats based on 3d geometry.
 */
export function getStatsEstimation(from: Position3d, to: Position3d): { distanceInM: number, durationInS: number } {
  const distanceInM = calculateDistance(from, to, { units: 'meters' })
  if (!distanceInM) {
    return { distanceInM: 0, durationInS: 0 }
  }
  const elevationDifference = to[2] - from[2]
  const gradientFactor = elevationDifference > 0 ? GRADIENT_FACTOR_UP : GRADIENT_FACTOR_DOWN
  const equivalentFlatDistance = distanceInM + elevationDifference * gradientFactor
  const durationInS = equivalentFlatDistance / ESTIMATED_AVERAGE_SPEED_IN_MS
  return { distanceInM, durationInS }
}

/**
 * Takes initial, complete waypoints assuming there is the same number of control points and returns a correctly
 * typed list of waypoints with control point relations.
 */
export function getInitialCompleteWaypoints(waypoints: [Waypoint, Waypoint, ...Waypoint[]]): CompleteWaypoints {
  return [
    { ...waypoints[0], controlPointIndex: 0 },
    { ...waypoints[1], controlPointIndex: 1 },
    ...waypoints.slice(2).map((waypoint, i) => ({ ...waypoint, controlPointIndex: i + 2 })),
  ]
}

export function updateControlPointRelations(
  waypoints: (Waypoint | null)[],
  firstAffectedControlPointIndex: number,
  delta = 1
) {
  waypoints.forEach((waypoint) => {
    if (typeof waypoint?.controlPointIndex === 'number' && waypoint.controlPointIndex >= firstAffectedControlPointIndex) {
      waypoint.controlPointIndex += delta
    }
  })
}

export function areWaypointsComplete(
  waypoints: IncompleteWaypoints | CompleteWaypoints
): waypoints is CompleteWaypoints {
  return !!(waypoints.length > 2 || (waypoints[0] && waypoints[1]))
}

export function areMoreWaypoints(
  waypoints: IncompleteWaypoints | CompleteWaypoints
): waypoints is [RoutedWaypoint, RoutedWaypoint, RoutedWaypoint, ...RoutedWaypoint[]] {
  return waypoints.length > 2
}

/**
 * Round a value to the number of decimals commonly used in positions.
 */
function roundPositionValue(value: number): number {
  return Math.round(value * 100000) / 100000
}
