import { useCallback, useContext, useEffect } from 'react'
import { useMap } from 'react-map-gl/maplibre'
import { debounce } from 'lodash'
import { Map, MapRoute, MapViewport } from 'shared/ui-map'
import { LngLatBoundsArray } from 'shared/util-geo'
import { ElevationCurveMapFeatures } from 'shared/feature-elevation-curve'
import { useMapState } from 'web-app/feature-map'
import { useRoute } from 'web-app/feature-route'
import { useRoutePrintState } from '../state'
import { PrintContext } from '../print-context'

import styles from './print-map.module.scss'

const MAP_ROUTE_ID = 'print-route'

interface PrintMapProps {
  id: string
  onRender: (image: string) => void
  mapImage: string | null
  viewport: MapViewport
  onViewportChanged: (viewport: MapViewport) => void
  interactive?: boolean
  boundsToFit?: LngLatBoundsArray | null
  onFitBounds?: () => void
}

export const PrintMap = ({
  id,
  onRender,
  mapImage,
  viewport,
  onViewportChanged,
  interactive,
  boundsToFit,
  onFitBounds,
}: PrintMapProps) => {
  const { [id]: map } = useMap()
  const { mapStyle, isGlobalHeatmapEnabled } = useMapState()
  const route = useRoute()
  const { mapAttributions, areDistanceMarkersShown } = useRoutePrintState()

  const { isInteractivePreview } = useContext(PrintContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRender = useCallback(debounce(() => {
    if (isInteractivePreview) {
      const mapInstance = map?.getMap()
      const image = mapInstance?.loaded() && map?.getMap().getCanvas().toDataURL('image/png')
      if (image && image !== mapImage) {
        onRender(image)
      }
    }
  }, 100), [isInteractivePreview, map, onRender])

  useEffect(() => {
    return () => handleRender.cancel()
  }, [handleRender])

  useEffect(() => {
    const interval = !mapImage && setInterval(handleRender, 3000)
    return () => {
      interval && clearInterval(interval)
    }
  }, [handleRender, mapImage])

  return (
    <div className={styles['root']}>
      <div className={styles['map-container']}>
        {isInteractivePreview ? (
          <div className={styles['preview-map']}>
            <Map
              id={id}
              viewport={viewport}
              onViewportChanged={onViewportChanged}
              mapStyle={mapStyle}
              preserveDrawingBuffer
              onRender={handleRender}
              boundsToFit={boundsToFit}
              onFitBounds={onFitBounds}
              padding={{ top: 48, right: 32, bottom: 32, left: 32 }}
              interactive={interactive}
              reuseMaps={false}
              isGlobalHeatmapEnabled={isGlobalHeatmapEnabled}
            >
              {route && (
                <>
                  <MapRoute id={MAP_ROUTE_ID} geometry={route.geometry} waypoints={route.waypoints} />
                  <ElevationCurveMapFeatures
                    mapRouteId={MAP_ROUTE_ID}
                    geometry={route.geometry}
                    distance={route.distance}
                    areDistanceMarkersPermanent={areDistanceMarkersShown}
                  />
                </>
              )}
            </Map>
          </div>
        ) : mapImage && (
          <img src={mapImage} alt='' className={styles['print-image']} />
        )}
      </div>
      <div className={styles['attributions']}>
        {mapAttributions.map((html, i) => <span key={i} dangerouslySetInnerHTML={{ __html: html }} />)}
      </div>
    </div>
  )
}
