import { ButtonGroup } from 'shared/ui-components'
import { useBaseRoute } from '../state'
import { useIsOwnRoute } from 'web-app/feature-route'
import { CopyRouteAction, CreateRouteAction, UpdateRouteAction } from '../components/actions'

export const RoutePlannerContentFooter = () => {
  const isOwnRoute = useIsOwnRoute()
  const baseRoute = useBaseRoute()

  return (
    <ButtonGroup>
      {!baseRoute && <CreateRouteAction primary />}
      {baseRoute && isOwnRoute && <UpdateRouteAction primary />}
      {baseRoute && <CopyRouteAction primary={!isOwnRoute} />}
    </ButtonGroup>
  )
}
