import { fuzzySearch, reverseGeocode as tomtomReverseGeocode } from '@tomtom-international/web-sdk-services/esm'
import { FuzzySearchResult } from '@tomtom-international/web-sdk-services'
import { ApiResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from 'shared/util-network'
import { LngLat } from 'shared/util-geo'
import { logError } from 'shared/util-error-handling'

const TOMTOM_API_KEY = process.env['NX_PUBLIC_TOMTOM_API_KEY']

export type GeocoderLocation = {
  position: LngLat
  address: string
  poiName?: string
  localName?: string
}

type CommonGeocodingErrors = MinimalEndpointErrors & {
  missingConfigError?: true
  unexpectedResponse?: true
}

/**
 * Get address details from provided point.
 */
export async function reverseGeocode(position: LngLat, language: 'en' | 'de'): ApiResult<GeocoderLocation, CommonGeocodingErrors> {
  if (!TOMTOM_API_KEY) {
    return createFailureResult({ missingConfigError: true })
  }

  try {
    const res = await tomtomReverseGeocode({
      key: TOMTOM_API_KEY,
      language: language === 'de' ? 'de-DE' : 'en-US',
      position,
    })

    if (res['addresses']?.length) {
      const { position, address } = res['addresses'][0]
      return createSuccessResult({
        position,
        address: address.freeformAddress,
        localName: address.localName,
      })
    }

    return createFailureResult({ unexpectedResponse: true }, { position, res })
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { position })
  }
}

/**
 * Search for places (addresses, places, etc.).
 */
export async function searchForPlaces(
  query: string,
  language: string,
  center?: LngLat,
): ApiResult<GeocoderLocation[], CommonGeocodingErrors> {
  if (!TOMTOM_API_KEY) {
    return createFailureResult({ missingConfigError: true })
  }

  try {
    const res = await fuzzySearch({
      key: TOMTOM_API_KEY,
      query,
      center,
      language,
      limit: 4,
      typeahead: true,
    })

    if (Array.isArray(res.results)) {
      const locations = []
      for (const result of res.results) {
        const location = formatLocation(result)
        if (location) {
          locations.push(location)
        }
      }
      return createSuccessResult(locations)
    }

    return createFailureResult({ unexpectedResponse: true }, { query, res })
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { query })
  }
}

export function formatLocation(result: FuzzySearchResult): GeocoderLocation | null {
  const { position, address, poi } = result
  if (!position) {
    logError('Fuzzy search result does not contain position.', null, result)
    return null
  }

  const { lng, lat } = position
  if (lng === undefined || lat === undefined) {
    logError('Fuzzy search result position coordinates are invalid.', null, result)
    return null
  }

  if (!address) {
    logError('Fuzzy search result does not contain address object.', null, result)
    return null
  }

  const { freeformAddress, municipality, country } = address
  if (!freeformAddress && !municipality && !country) {
    logError('Fuzzy search result does not contain address info.', null, result)
    return null
  }

  const location: GeocoderLocation = {
    position: { lng, lat },
    address: (freeformAddress || municipality || country) as string,
    poiName: poi?.name,
    localName: address.localName,
  }
  return location
}
