import { useLocale } from 'shared/util-intl'
import { NavigationLinkProps } from './types'
import { Button } from 'shared/ui-components'
import { getLoginUrl } from 'shared/util-navigation'
import { useSessionInfo } from '../hooks'

export const SignupLoginLink = ({ primary, compact }: NavigationLinkProps) => {
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()

  return (
    <Button
      block
      variant={primary ? 'primary' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      href={getLoginUrl({
        ...sessionInfo,
        webAppPath: window.location.pathname,
      })}
    >
      {intl.formatMessage({
        id: 'signup_login_link_label',
        defaultMessage: 'Sign up or log in',
      })}
    </Button>
  )
}
