import { Button, Select } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { getCheckoutUrl } from 'shared/util-navigation'
import { useUserState } from '../hooks'
import { useCookieConsentStatistics } from 'web-app/feature-cookie-consent'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Popover, Skeleton, Typography } from '@mui/material'
import { Currency, SubscriptionPlans, getAvailableCurrencies, getAvailableSubscriptionPlans } from 'shared/data-access-core'
import {
  SubscriptionPlanItemLifetime,
  SubscriptionPlanItemMonthly,
  SubscriptionPlanItemSkeleton,
  SubscriptionPlanItemThreeYears,
  SubscriptionPlanItemYearly,
} from 'shared/feature-subscription'
import { useDispatch } from 'react-redux'
import { currencyPreferenceUpdated } from '../state'

import styles from './premium-actions.module.scss'

type PlanPeriod = 'monthly' | 'yearly' | 'threeYears' | 'lifetime'

interface PremiumActionsProps {
  checkoutSuccessUrl?: string
  checkoutCancelUrl?: string
}

export const PremiumActions = ({
  checkoutSuccessUrl,
  checkoutCancelUrl,
}: PremiumActionsProps) => {
  const dispatch = useDispatch()
  const { intl, language } = useLocale()
  const { unitPreference, currencyPreference, user } = useUserState()
  const cookieConsentStatistics = useCookieConsentStatistics()

  const ref = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)

  const [isPlanSelectionOpen, setIsPlanSelectionOpen] = useState<boolean>(false)
  const [currencies, setCurrencies] = useState<Currency[] | null>(null)
  const [plans, setPlans] = useState<SubscriptionPlans | null>(null)
  const [planPeriod, setPlanPeriod] = useState<PlanPeriod>('yearly')
  const [skeletonHeight, setSkeletonHeight] = useState<number | undefined>()

  const currency = useMemo(() => currencyPreference || { code: 'EUR', symbol: '€' }, [currencyPreference])

  useEffect(() => {
    getAvailableCurrencies().then(result => {
      if (result.success) {
        setCurrencies(result.data)
      }
    })
  }, [])

  useEffect(() => {
    const listHeight = listRef.current?.getBoundingClientRect().height
    setSkeletonHeight(listHeight ? (listHeight - 16) / 3 : undefined)
    setPlans(null)
    getAvailableSubscriptionPlans(currency.code).then(result => {
      if (result.success) {
        setPlans(result.data)
      }
    })
  }, [currency])

  const handlePlanSelect = (planPeriod: PlanPeriod) => () => {
    setPlanPeriod(planPeriod)
    setIsPlanSelectionOpen(false)
  }

  const selectedPlan = plans && plans[planPeriod]

  return (
    <div ref={ref} className={styles['container']}>
      <div className={styles['plan-item']}>
        {planPeriod === 'monthly' && plans?.monthly ? (
          <SubscriptionPlanItemMonthly plan={plans.monthly} currency={currency} />
        ) : planPeriod === 'yearly' && plans?.yearly ? (
          <SubscriptionPlanItemYearly plan={plans.yearly} currency={currency} monthly={plans.monthly} />
        ) : planPeriod === 'threeYears' && plans?.threeYears ? (
          <SubscriptionPlanItemThreeYears plan={plans.threeYears} currency={currency} />
        ) : planPeriod === 'lifetime' && plans?.lifetime ? (
          <SubscriptionPlanItemLifetime plan={plans.lifetime} currency={currency} />
        ) : <SubscriptionPlanItemSkeleton />}
      </div>
      <Button
        variant='secondary'
        onClick={() => setIsPlanSelectionOpen(true)}
      >
        {intl.formatMessage({
          id: 'premium_modal_change_button',
          defaultMessage: 'Change',
        })}
      </Button>
      <Button
        variant='accent'
        href={selectedPlan ? getCheckoutUrl({
          language,
          unitPreference,
          cookieConsentStatistics,
          currency: currency.code,
          plan: selectedPlan.code,
          successUrl: checkoutSuccessUrl || window.location.href,
          cancelUrl: checkoutCancelUrl || window.location.href,
        }) : undefined}
        disabled={!selectedPlan}
      >
        {user && !user.hadTrial ? intl.formatMessage({
          id: 'premium_modal_checkout_button_trial',
          defaultMessage: 'Start your free trial',
        }) : intl.formatMessage({
          id: 'premium_modal_checkout_button',
          defaultMessage: 'Get Premium',
        })}
      </Button>
      <Popover
        open={isPlanSelectionOpen}
        onClose={() => setIsPlanSelectionOpen(false)}
        anchorEl={ref.current}
        slotProps={{
          paper: {
            className: styles['plan-selection-popover'],
            style: { width: ref.current?.clientWidth },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography variant='h3' margin={0}>Select a plan</Typography>
        {currencies ? (
          <Select
            options={currencies.map(({ code }) => ({ value: code, label: code }))}
            value={currency.code}
            onSelect={code => dispatch(currencyPreferenceUpdated(currencies.find(c => c.code === code) || currency))}
          />
        ) : <Skeleton variant='rectangular' />}
        <ul ref={listRef} className={styles['plan-selection-list']}>
          {plans ? (
            <>
              {plans.monthly && (
                <li>
                  <SubscriptionPlanItemMonthly
                    plan={plans.monthly}
                    currency={currency}
                    selected={planPeriod === 'monthly'}
                    onClick={handlePlanSelect('monthly')}
                  />
                </li>
              )}
              {plans.yearly && (
                <li>
                  <SubscriptionPlanItemYearly
                    plan={plans.yearly}
                    currency={currency}
                    monthly={plans.monthly}
                    selected={planPeriod === 'yearly'}
                    onClick={handlePlanSelect('yearly')}
                  />
                </li>
              )}
              {plans.threeYears && (
                <li>
                  <SubscriptionPlanItemThreeYears
                    plan={plans.threeYears}
                    currency={currency}
                    selected={planPeriod === 'threeYears'}
                    onClick={handlePlanSelect('threeYears')}
                  />
                </li>
              )}
              {plans.lifetime && (
                <li>
                  <SubscriptionPlanItemLifetime
                    plan={plans.lifetime}
                    currency={currency}
                    selected={planPeriod === 'lifetime'}
                    onClick={handlePlanSelect('lifetime')}
                  />
                </li>
              )}
            </>
          ) : (
            <>
              <li><SubscriptionPlanItemSkeleton height={skeletonHeight} /></li>
              <li><SubscriptionPlanItemSkeleton height={skeletonHeight} /></li>
              <li><SubscriptionPlanItemSkeleton height={skeletonHeight} /></li>
            </>
          )}
        </ul>
      </Popover>
    </div>
  )
}
