import { DataLayerObject } from '../types'

/**
 * Prepend all object keys with our standard prefix (`data-analytics-`).
 */
export function formatAnalyticsData(analytics: DataLayerObject): DataLayerObject {
  const prefixed = Object.keys(analytics).map((key) => ({
    ['data-analytics-' + key]: analytics[key],
  }))
  return Object.assign({}, ...prefixed)
}
