import { Popover } from '@mui/material'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { Button, ButtonGroup, Dialog, ToolButton, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useMemo, useRef, useState } from 'react'
import { useCreator, useRoute } from 'web-app/feature-route'
import { useUser } from 'web-app/feature-user'
import { getRouteDetailsUrl } from 'shared/util-navigation'

import styles from './creator-flag-actions.module.css'

const REPORT_EMAIL_ADDRESS = 'support@bikemap.net'

export const CreatorFlagActions = () => {
  const { intl } = useLocale()
  const { cancelLabel } = useMessages()
  const route = useRoute()
  const creator = useCreator()
  const [user, isUserLoaded] = useUser()

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [entityTypeToBeReported, setEntityTypeToBeReported] = useState<'route' | 'user' | null>(null)

  const anchorEl = useRef<HTMLDivElement>(null)

  const emailHref = useMemo<string | null>(() => {
    if (!entityTypeToBeReported || !isUserLoaded) return null
    const subject = `Report for ${entityTypeToBeReported}`

    let entityId: number, entityUrl: string
    if (entityTypeToBeReported === 'user') {
      if (!creator) return null
      entityId = creator.id
      entityUrl = `${process.env['NX_PUBLIC_LEGACY_WEBSITE_URL']}/u/${creator.slug}/`
    } else {
      if (!route) return null
      entityId = route.id
      entityUrl = getRouteDetailsUrl(route.id)
    }

    const text = `
Dear Bikemap team,%0D%0A
%0D%0A
I would like to report ${entityTypeToBeReported} ${entityId} (${entityUrl}) as I think it violates the platform's Terms
 of Service.%0D%0A
%0D%0A
Sincerely yours,%0D%0A
${user?.name || '<name>'}%0D%0A
___________%0D%0A
User: ${user?.id || 'Anonymous'}
    `

    return `mailto:${REPORT_EMAIL_ADDRESS}?subject=${subject}&body=${text}`
  }, [creator, entityTypeToBeReported, isUserLoaded, route, user?.id, user?.name])

  return (
    <div ref={anchorEl}>
      <ToolButton
        variant="ghost-primary"
        icon={<MoreHorizRoundedIcon />}
        ariaLabel={intl.formatMessage({
          id: 'route_details_creator_additional_actions',
          defaultMessage: 'Additional actions',
        })}
        onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
      />
      <Popover
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{ classes: { root: styles['popover'] } }}
      >
        <Button
          size="medium"
          variant="danger-secondary"
          icon={<FlagRoundedIcon />}
          onClick={() => {
            setIsPopoverOpen(false)
            setEntityTypeToBeReported('route')
          }}
        >
          {intl.formatMessage({
            id: 'route_details_report_route',
            defaultMessage: 'Report route',
          })}
        </Button>
        {creator && (
          <Button
            size="medium"
            variant="danger-secondary"
            icon={<FlagRoundedIcon />}
            onClick={() => {
              setIsPopoverOpen(false)
              setEntityTypeToBeReported('user')
            }}
          >
            {intl.formatMessage({
              id: 'route_details_report_user',
              defaultMessage: 'Report user',
            })}
          </Button>
        )}
      </Popover>
      <Dialog
        open={!!entityTypeToBeReported}
        title={intl.formatMessage({
          id: 'route_details_report_content_dialog_title',
          defaultMessage: 'Are you sure you want to report this content?',
        })}
        text={intl.formatMessage({
          id: 'route_details_report_content_dialog_text',
          defaultMessage:
            'Your report will be reviewed by our team to ensure it complies with our community guidelines. Please make sure to include some context to your report to help us better understand the situation.',
        })}
        buttons={
          <ButtonGroup>
            <Button
              variant="danger-primary"
              href={emailHref || undefined}
              disabled={!isUserLoaded}
              onClick={() => setEntityTypeToBeReported(null)}
            >
              {intl.formatMessage({
                id: 'route_details_report_content_dialog_action',
                defaultMessage: 'Write a report',
              })}
            </Button>
            <Button variant="secondary" onClick={() => setEntityTypeToBeReported(null)}>
              {cancelLabel}
            </Button>
          </ButtonGroup>
        }
      />
    </div>
  )
}
