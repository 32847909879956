import { useLocale } from 'shared/util-intl'
import { NavigationLinkProps } from './types'
import { Button } from 'shared/ui-components'
import { getPremiumUrl } from 'shared/util-navigation'
import { useSessionInfo } from '../hooks'

export const PremiumLink = ({ primary, compact }: NavigationLinkProps) => {
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()

  return (
    <Button
      block
      variant={primary ? 'accent' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      href={getPremiumUrl(sessionInfo)}
    >
      {intl.formatMessage({
        id: 'premium_link_label',
        defaultMessage: 'Get Premium',
      })}
    </Button>
  )
}
