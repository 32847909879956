import { useSelector } from 'react-redux'
import {
  editingFieldSelector,
  openImageIndexSelector,
  routeCollectionsSelector,
  routeDetailsSliceSelector,
} from './selectors'
import { EditingField } from './types'

export const useRouteDetailsState = () => useSelector(routeDetailsSliceSelector)

export const useRouteCollections = () => useSelector(routeCollectionsSelector)

export const useOpenImageIndex = () => useSelector(openImageIndexSelector)

export const useIsEditingFlags = (name: EditingField) => {
  const editingField = useSelector(editingFieldSelector)
  const isEditing = editingField === name
  return {
    isEditing,
    isEditingAnotherField: !isEditing && editingField !== null,
  }
}
