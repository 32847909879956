import { useEffect, useState } from 'react'
import { useUserState } from './hooks'
import { PremiumModalGeneral } from './premium-modal'
import { useDispatch } from 'react-redux'
import { premiumTriggerShown } from './state'
import campaigns from './campaign'
import { Campaign } from './campaign/types'

const GENERAL_MODAL_INTERVAL_DAYS = 7
const GENERAL_MODAL_INTERVAL_MILLISECONDS = GENERAL_MODAL_INTERVAL_DAYS * 86400000

const CAMPAIGN_MODAL_INTERVAL_DAYS = 2
const CAMPAIGN_MODAL_INTERVAL_MILLISECONDS = CAMPAIGN_MODAL_INTERVAL_DAYS * 86400000

/**
 * Integrate this globally to enable user-specific premium triggers such as modals.
 */
export const PremiumUpsellTriggers = () => {
  const dispatch = useDispatch()
  const { premiumTriggerLastShown, user, isUserLoaded } = useUserState()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const campaign: Campaign | null = (
    isUserLoaded && campaigns.find(c => c.isUserTarget(!!user, !!user?.isPremium))
  ) || null
  const PremiumModalCampaign = campaign && campaign?.popup

  useEffect(() => {
    if (
      (campaign && (
        !premiumTriggerLastShown ||
        new Date().getTime() - premiumTriggerLastShown > CAMPAIGN_MODAL_INTERVAL_MILLISECONDS
      )) ||
      (isUserLoaded && !!user && !user.isPremium && (
        !premiumTriggerLastShown ||
        new Date().getTime() - premiumTriggerLastShown > GENERAL_MODAL_INTERVAL_MILLISECONDS
      ))
    ) {
      setIsOpen(true)
    }
  }, [campaign, isUserLoaded, premiumTriggerLastShown, user])

  const handleClose = () => {
    setIsOpen(false)
    dispatch(premiumTriggerShown())
  }

  return PremiumModalCampaign ? (
    <PremiumModalCampaign isOpen={isOpen} onClose={handleClose} />
  ) : (
    <PremiumModalGeneral isOpen={isOpen} onClose={handleClose} />
  )
}
