import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { IntlStateType, AvailableLocale, INTL_SLICE_KEY } from './types'
import { getPreferredLanguage } from './helpers'

const initialState: IntlStateType = {
  language: getPreferredLanguage(),
}

const slice = createSlice({
  name: INTL_SLICE_KEY,
  initialState,
  reducers: {
    languageUpdated(state, action: PayloadAction<AvailableLocale>) {
      return {
        ...state,
        language: action.payload,
      }
    },
  },
})

export const { languageUpdated } = slice.actions

const persistConfig: PersistConfig<IntlStateType> = {
  key: INTL_SLICE_KEY,
  storage,
}

export const intlReducer = persistReducer(persistConfig, slice.reducer)
