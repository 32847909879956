import { BaseRadioButton, BaseRadioButtonProps } from '../../base/BaseRadioButton'

import styles from './RadioButton.module.scss'

export interface RadioButtonProps extends BaseRadioButtonProps {
  label: string
  description?: string
}

export function RadioButton(props: RadioButtonProps) {
  const {
    name,
    label,
    description,
    ...rest
  } = props

  return (
    <BaseRadioButton name={name} {...rest}>
      <span className={styles['label']}>{label}</span>
      {description && <span className={styles['description']}>{description}</span>}
    </BaseRadioButton>
  )
}

export default RadioButton
