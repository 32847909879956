import { calculateAscent, calculateDescent, useElevationCurveContext } from 'shared/feature-elevation-curve'
import { useMemo } from 'react'
import { RouteStatisticsChangeIndicator } from 'shared/ui-components'
import { useBaseRoute, useFinalGeometry, useRoutePlannerState } from '../state'
import { MainRouteStats, RouteStats, RouteStatsOverrides } from 'web-app/feature-route'
import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { useUserState } from 'web-app/feature-user'

import styles from './route-planner-statistics.module.css'

const METER_FOOT_FACTOR = 3.28084

export const RoutePlannerStatistics = () => {
  const { intl } = useLocale()
  const { elevation } = useElevationCurveContext() // elevation can be imperial!
  const { unitPreference } = useUserState()
  const baseRoute = useBaseRoute()
  const { durationInS, distance } = useRoutePlannerState()
  const finalGeometry = useFinalGeometry()

  const ascentInM = useMemo(() => {
    if (!elevation) return undefined
    const ascent = calculateAscent(elevation)
    return unitPreference === 'imperial' ? ascent / METER_FOOT_FACTOR : ascent
  }, [elevation, unitPreference])
  const descentInM = useMemo(() => {
    if (!elevation) return undefined
    const descent = calculateDescent(elevation)
    return unitPreference === 'imperial' ? descent / METER_FOOT_FACTOR : descent
  }, [elevation, unitPreference])

  const maxAltitude = useMemo<number | undefined>(() => {
    if (!finalGeometry || !elevation) return undefined
    let maxAltitude = 0
    for (const [, , elevation] of finalGeometry.coordinates) {
      if (elevation > maxAltitude) {
        maxAltitude = elevation
      }
    }
    return maxAltitude
  }, [elevation, finalGeometry])

  const durationOverride = useMemo<number | undefined>(
    () => (baseRoute && !areApproximatelyEqual(durationInS, baseRoute.durationInS, 1) && durationInS) || undefined,
    [baseRoute, durationInS],
  )

  const distanceOverride = useMemo<number | undefined>(
    () => (baseRoute && !areApproximatelyEqual(distance, baseRoute.distance, 1) && distance) || undefined,
    [baseRoute, distance],
  )

  const ascentOverride = useMemo<number | undefined>(
    () => (baseRoute && !areApproximatelyEqual(ascentInM, baseRoute.ascent, 1) && ascentInM) || undefined,
    [baseRoute, ascentInM],
  )

  const descentOverride = useMemo<number | undefined>(
    () => (baseRoute && !areApproximatelyEqual(descentInM, baseRoute.descent, 1) && descentInM) || undefined,
    [baseRoute, descentInM],
  )

  const averageSpeedOverride = useMemo<number | undefined>(() => {
    if (!baseRoute) return undefined
    const original = baseRoute.averageSpeedInMs || (baseRoute.durationInS && baseRoute.distance / baseRoute.durationInS)
    const current = distance && durationInS ? distance / durationInS : undefined
    return areApproximatelyEqual(current, original, 0.005) ? undefined : current
  }, [baseRoute, distance, durationInS])

  const maxAltitudeOverride = useMemo<number | undefined>(() => {
    if (!baseRoute) return undefined
    let original = 0
    for (const [, , elevation] of baseRoute.geometry.coordinates) {
      if (elevation > original) {
        original = elevation
      }
    }
    return areApproximatelyEqual(maxAltitude, original, 1) ? undefined : maxAltitude
  }, [baseRoute, maxAltitude])

  const overrides = useMemo<RouteStatsOverrides | undefined>(() => {
    if (
      durationOverride ||
      distanceOverride ||
      ascentOverride ||
      descentOverride ||
      averageSpeedOverride ||
      maxAltitudeOverride
    ) {
      return {
        durationInS: durationOverride,
        distanceInM: distanceOverride,
        ascentInM: ascentOverride,
        descentInM: descentOverride,
        averageSpeedInMs: averageSpeedOverride,
        maxAltitudeInM: maxAltitudeOverride,
      }
    }
    return undefined
  }, [ascentOverride, averageSpeedOverride, descentOverride, distanceOverride, durationOverride, maxAltitudeOverride])

  return (
    <div className={styles['container']}>
      {overrides && (
        <span className={styles['changed-label']}>
          <RouteStatisticsChangeIndicator />
          {intl.formatMessage({
            id: 'route_planner_route_statistics_changed_label',
            defaultMessage: 'changed',
          })}
        </span>
      )}
      <Typography variant="h4" component="h2">
        {intl.formatMessage({
          id: 'route_planner_route_statistics_heading',
          defaultMessage: 'Route statistics',
        })}
      </Typography>
      {baseRoute ? (
        <MainRouteStats extended overrides={overrides} loading={!finalGeometry} />
      ) : (
        <RouteStats
          extended
          durationInS={durationInS !== null ? durationInS : undefined}
          distanceInM={distance !== null ? distance : undefined}
          ascentInM={ascentInM}
          descentInM={descentInM}
          averageSpeedInMs={
            distance !== null && durationInS ? distance / durationInS : durationInS === 0 ? null : undefined
          }
          maxAltitudeInM={maxAltitude}
        />
      )}
    </div>
  )
}

function areApproximatelyEqual(a: number | null | undefined, b: number | null | undefined, tolerance: number) {
  const comparableA = a || 0
  const comparableB = b || 0
  return Math.abs(comparableA - comparableB) < tolerance
}
