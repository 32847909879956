import React, { useState } from 'react'
import clsx from 'clsx'
import { FormControl, MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material'
import { PremiumLockIcon } from '../icons'
import { colors } from 'shared/ui-design-system'

import styles from './select.module.scss'

export interface SelectOption<ValueType> {
  value: ValueType
  label: React.ReactNode
  icon?: React.ReactNode
  hasPremiumIndicator?: boolean
}

export interface SelectProps<ValueType> {
  variant?: 'default' | 'onColor'
  options: SelectOption<ValueType>[]
  name?: string
  value: ValueType
  onSelect: (value: ValueType, name?: string) => void
}

export function Select<ValueType extends string>({
  variant = 'default',
  options,
  name,
  value,
  onSelect,
}: SelectProps<ValueType>) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value as ValueType, name)
  }

  return (
    <FormControl fullWidth>
      <MuiSelect
        open={isOpen}
        onOpen={() => { setIsOpen(true) }}
        onClose={() => { setIsOpen(false) }}
        className={styles['select']}
        value={value}
        onChange={handleChange}
        MenuProps={{
          className: styles['menu'],
        }}
        SelectDisplayProps={{
          className: clsx(styles['select-trigger'], {
            [styles['onColor']]: variant === 'onColor',
            [styles['open']]: isOpen,
          }),
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            className={styles['select-item']}
            value={option.value}
            style={option.value === value ? { display: 'none' } : {}}
          >
            {option.icon}
            <span className={styles['select-item-label']}>
              {option.label}
            </span>
            {option.hasPremiumIndicator && (
              <PremiumLockIcon style={{
                color: colors.premiumComp.premiumIndicator,
                fontSize: '0.8rem',
              }} />
            )}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
