export const ROUTE_GEOMETRY_SOURCE_ID = '{baseId}-geometry'

export const ROUTE_DISTANCE_MARKERS_SOURCE_ID = '{baseId}-distance-markers'

export const ROUTE_LINE_LAYER_ID = '{baseId}-line'

export const ROUTE_OUTLINE_LAYER_ID = '{baseId}-outline'

export const ROUTE_DISTANCE_MARKERS_CIRCLE_LAYER_ID = '{baseId}-distance-marker-circles'

export const ROUTE_DISTANCE_MARKERS_LABEL_LAYER_ID = '{baseId}-distance-marker-labels'

export const ROUTE_ORIGIN_MARKER_LAYER_ID = '{baseId}-marker-origin'

export const ROUTE_DESTINATION_MARKER_LAYER_ID = '{baseId}-marker-destination'

export const ROUTE_VIA_MARKER_LAYER_ID = '{baseId}-marker-via'

export const ROUTE_CONTROL_POINTS_LAYER_ID = '{baseId}-control-points'
