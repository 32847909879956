import { useMemo, useState } from 'react'
import PrintRoundedIcon from '@mui/icons-material/PrintRounded'
import { Button } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { PremiumModalExport, useUser } from 'web-app/feature-user'
import { useRoutePrintState } from './state'
import { CircularProgress } from '@mui/material'

export const RoutePrintMainActions = () => {
  const { intl } = useLocale()
  const [user, isUserLoaded] = useUser()
  const { overviewMapImage, detailedMapViewports, detailedMapImages } = useRoutePrintState()

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState<boolean>(false)

  const handlePrintClick = () => {
    if (!isUserLoaded) return

    if (user?.isPremium) {
      window.print()
    } else {
      setIsPremiumModalOpen(true)
    }
  }

  const isReadyForPrinting = useMemo(() => {
    for (const mapKey in detailedMapViewports) {
      if (detailedMapViewports[mapKey] && !detailedMapImages[mapKey]) return false
    }
    return overviewMapImage !== null
  }, [detailedMapImages, detailedMapViewports, overviewMapImage])

  return (
    <>
      <Button
        block
        icon={isReadyForPrinting ? <PrintRoundedIcon /> : <CircularProgress size="1.5rem" color="inherit" />}
        onClick={handlePrintClick}
        withPremiumIndicator={isUserLoaded && !user?.isPremium}
        disabled={!isReadyForPrinting}
      >
        {intl.formatMessage({
          id: 'route_print_open_print_dialog',
          defaultMessage: 'Print or save',
        })}
      </Button>
      <PremiumModalExport isOpen={isPremiumModalOpen} onClose={() => setIsPremiumModalOpen(false)} />
    </>
  )
}
