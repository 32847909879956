import clsx from 'clsx'
import { ReactNode } from 'react'
import { colors } from 'shared/ui-design-system'
import { CircularProgress } from '@mui/material'
import { useLayoutMediaQueries, useLayoutRefs } from './layout-hooks'

import styles from './web-app-content.module.scss'

interface WebAppContentProps {
  children?: ReactNode
  backgroundColor?: string
  noPadding?: boolean
  scrollHint?: boolean
  renderLarge?: () => ReactNode
  renderSmall?: () => ReactNode
}

/**
 * Padded content wrapper that provides some common layout utilities.
 */
export const WebAppContent = ({
  children,
  backgroundColor = colors.neutral.primary,
  noPadding,
  scrollHint,
  renderLarge,
  renderSmall,
}: WebAppContentProps) => {
  const { contentScrollToRef } = useLayoutRefs()
  const { isLargeViewport } = useLayoutMediaQueries()

  return (
    <div
      ref={scrollHint ? contentScrollToRef : undefined}
      className={clsx(styles['root'], {
        [styles['root-padding']]: !noPadding,
      })}
      style={{ backgroundColor }}
    >
      <div className={styles['wrapper']}>
        {(renderLarge && isLargeViewport) ? (
          renderLarge()
        ) : renderSmall && !isLargeViewport ? (
          renderSmall()
        ) : children}
      </div>
    </div>
  )
}

export const WebAppContentLoading = () => (
  <div className={styles['loading-wrapper']}>
    <CircularProgress />
  </div>
)
