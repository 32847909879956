import { Popover, PopoverProps } from '@mui/material'

import styles from './dropdown-menu.module.scss'
import { ReactNode } from 'react'

export const DropdownMenu = (popoverProps: PopoverProps) => {
  return (
    <Popover
      marginThreshold={16}
      {...popoverProps}
      slotProps={{
        paper: {
          className: styles['popover'],
        },
      }}
      data-testid="user-menu"
    />
  )
}

interface DropdownMenuSettingsProps {
  children: ReactNode
}

export const DropdownMenuSettings = ({ children }: DropdownMenuSettingsProps) => (
  <div className={styles['settings-container']}>
    {children}
  </div>
)
