import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { Currency, UserEntity } from 'shared/data-access-core'
import { GeocoderLocation } from 'shared/data-access-geocoding'

export const USER_SLICE_KEY = 'user'

export type UnitPreference = 'metric'|'imperial'

export type UserGeolocation = {
  lng: number
  lat: number
  geocoded: GeocoderLocation
}

export type UserState = {
  user: null|UserEntity
  isLoggedIn: boolean
  isUserLoaded: boolean
  unitPreference: UnitPreference
  currencyPreference: Currency | null

  /** Flag set during all the calls necessary to get an updated `geolocation` */
  isGeolocationLoading: boolean

  /** Flag set when client location has been denied by the user */
  isGeolocationDenied: boolean

  /** Accurate and geocoded geolocation of the user, based on browser client location */
  geolocation: UserGeolocation | null

  /** Timestamp when the last premium upsell trigger was shown */
  premiumTriggerLastShown: number | null
}

export interface StateWithUserSlice {
  [USER_SLICE_KEY]: UserState
}

export const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  isUserLoaded: false,
  unitPreference: 'metric',
  currencyPreference: null,
  isGeolocationLoading: false,
  isGeolocationDenied: false,
  geolocation: null,
  premiumTriggerLastShown: null,
}

const slice = createSlice({
  name: USER_SLICE_KEY,
  initialState,
  reducers: {
    getUserRequest(state) {
      return {
        ...state,
        isUserLoaded: false,
      }
    },
    getUserSuccess(state, action: PayloadAction<UserEntity>) {
      const user = action.payload
      return {
        ...state,
        user,
        isLoggedIn: true,
        isUserLoaded: true,
      }
    },
    getUserFailure(state) {
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        isUserLoaded: true,
      }
    },
    unitPreferenceUpdated(state, action: PayloadAction<UnitPreference>) {
      return {
        ...state,
        unitPreference: action.payload,
      }
    },
    currencyPreferenceUpdated(state, action: PayloadAction<Currency>) {
      state.currencyPreference = action.payload
    },
    geolocationRequest(state) {
      return {
        ...state,
        isGeolocationLoading: true,
      }
    },
    geolocationSuccess(state, action: PayloadAction<UserGeolocation>) {
      return {
        ...state,
        isGeolocationLoading: false,
        isGeolocationDenied: false,
        geolocation: action.payload,
      }
    },
    geolocationFailure(state) {
      return {
        ...state,
        isGeolocationLoading: false,
        geolocation: null,
      }
    },
    geolocationDenied(state) {
      return {
        ...state,
        isGeolocationLoading: false,
        isGeolocationDenied: true,
        geolocation: null,
      }
    },
    favoriteRoutesCountUpdated(state, action: PayloadAction<number>) {
      if (state.user) {
        state.user.favoriteRoutesCount = action.payload
      }
    },
    premiumTriggerShown(state) {
      state.premiumTriggerLastShown = new Date().getTime()
    },
  },
})

export const {
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  unitPreferenceUpdated,
  currencyPreferenceUpdated,
  geolocationRequest,
  geolocationSuccess,
  geolocationFailure,
  geolocationDenied,
  favoriteRoutesCountUpdated,
  premiumTriggerShown,
} = slice.actions

const persistConfig = {
  key: USER_SLICE_KEY,
  storage,
  whitelist: [
    'user',
    'isLoggedIn',
    'unitPreference',
    'currencyPreference',
    'isGeolocationDenied',
    'premiumTriggerLastShown',
  ],
}

export const userReducer = persistReducer(persistConfig, slice.reducer)
