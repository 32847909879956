import { ReactNode } from 'react'
import { Currency, SubscriptionPlanEntity } from 'shared/data-access-core'
import { useGiftYearlyLabels, useLifetimeLabels, useMonthlyLabels, useThreeYearsLabels, useYearlyLabels } from './common-labels'
import { SubscriptionPlanBadgeBestValue, SubscriptionPlanBadgePopular, SubscriptionPlanBadgeSave } from './subscription-plan-badge'
import { DiscountedPrice } from './discounted-price'
import { Skeleton } from '@mui/material'
import { CurrencySymbol } from './currency-symbol'

import styles from './subscription-plan-item.module.scss'
import clsx from 'clsx'

interface BaseSubscriptionPlanItemProps {
  name: string
  price: ReactNode
  interval: string
  badge?: ReactNode
  selected?: boolean
  onClick?: () => void
}

const BaseSubscriptionPlanItem = ({
  name,
  price,
  interval,
  badge,
  selected,
  onClick,
}: BaseSubscriptionPlanItemProps) => {
  const content = (
    <>
      <div className={styles['content']}>
        <strong className={styles['name']}>{name}</strong>
        <span className={styles['description']}>
          <span className={styles['price']}>
            {price}
          </span> <span className={styles['interval']}>
            {interval}
          </span>
        </span>
      </div>
      {badge}
    </>
  )

  const className = clsx(styles['container'], { [styles['selected']]: selected })

  return onClick ? (
    <button className={className} onClick={onClick}>
      {content}
    </button>
  ) : (
    <div className={className}>
      {content}
    </div>
  )
}

interface SubscriptionPlanItemProps {
  plan: SubscriptionPlanEntity
  currency: Currency
  selected?: boolean
  onClick?: () => void
}

export const SubscriptionPlanItemMonthly = ({
  plan,
  currency,
  ...props
}: SubscriptionPlanItemProps) => {
  const labels = useMonthlyLabels(plan)

  return (
    <BaseSubscriptionPlanItem
      {...props}
      name={labels.name}
      price={
        <strong>
          {currency.symbol && (
            <CurrencySymbol>
              {currency.symbol}
            </CurrencySymbol>
          )}
          {plan.price}
        </strong>
      }
      interval={labels.interval}
    />
  )
}

export const SubscriptionPlanItemYearly = ({
  plan,
  currency,
  monthly,
  ...props
}: SubscriptionPlanItemProps & { monthly?: SubscriptionPlanEntity }) => {
  const labels = useYearlyLabels(plan)

  return (
    <BaseSubscriptionPlanItem
      {...props}
      name={labels.name}
      price={
        <DiscountedPrice
          price={plan.price}
          higherPrice={monthly && monthly.price * 12}
          currencySymbol={currency.symbol}
        />
      }
      interval={labels.interval}
      badge={monthly && <SubscriptionPlanBadgeSave primary price={plan.price} higherPrice={monthly.price * 12} />}
    />
  )
}

export const SubscriptionPlanItemThreeYears = ({
  plan,
  currency,
  ...props
}: SubscriptionPlanItemProps) => {
  const labels = useThreeYearsLabels()

  return (
    <BaseSubscriptionPlanItem
      {...props}
      name={labels.name}
      price={
        <strong>
          {currency.symbol && (
            <CurrencySymbol>
              {currency.symbol}
            </CurrencySymbol>
          )}
          {plan.price}
        </strong>
      }
      interval={labels.interval}
      badge={<SubscriptionPlanBadgeBestValue />}
    />
  )
}

export const SubscriptionPlanItemLifetime = ({
  plan,
  currency,
  ...props
}: SubscriptionPlanItemProps) => {
  const labels = useLifetimeLabels()

  return (
    <BaseSubscriptionPlanItem
      {...props}
      name={labels.name}
      price={
        <strong>
          {currency.symbol && (
            <CurrencySymbol>
              {currency.symbol}
            </CurrencySymbol>
          )}
          {plan.price}
        </strong>
      }
      interval={labels.interval}
      badge={<SubscriptionPlanBadgeBestValue />}
    />
  )
}

export const SubscriptionPlanItemGiftYearly = ({
  plan,
  currency,
  ...props
}: SubscriptionPlanItemProps) => {
  const labels = useGiftYearlyLabels()

  return (
    <BaseSubscriptionPlanItem
      {...props}
      name={labels.name}
      price={
        <strong>
          {currency.symbol && (
            <CurrencySymbol>
              {currency.symbol}
            </CurrencySymbol>
          )}
          {plan.price}
        </strong>
      }
      interval={labels.interval}
      badge={<SubscriptionPlanBadgePopular primary />}
    />
  )
}

export const SubscriptionPlanItemSkeleton = ({ height }: { height?: number }) => (
  <Skeleton variant='rectangular' className={styles['skeleton']} height={height} />
)
