import { Action, Middleware, PayloadAction, isAction } from '@reduxjs/toolkit'
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { pushToDataLayer } from './helpers'
import { has } from 'lodash'

const EXCLUDED_ACTION_TYPES: string[] = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
const PAYLOAD_TRACKING_ACTION_TYPES: string[] = ['import/mapMatchingChanged', 'import/routeSimplifyChanged']

type ActionTrackingData = {
  type: string
  payload?: unknown
}

/**
 * This redux middleware adds dispatched actions to the GTM data layer for analytics.
 * */
export const analyticsMiddleware: Middleware = () => (next) => (action) => {
  if (!isAction(action) || EXCLUDED_ACTION_TYPES.includes(action.type)) {
    return next(action)
  }

  const actionTrackingData: ActionTrackingData = { type: action.type }

  if (isPayloadAction(action) && PAYLOAD_TRACKING_ACTION_TYPES.includes(action.type)) {
    actionTrackingData.payload = action.payload
  }

  pushToDataLayer({
    event: 'action',
    action: actionTrackingData,
  })

  return next(action)
}

function isPayloadAction(action: Action): action is PayloadAction {
  return has(action, 'payload')
}
