import calculateDistance from '@turf/distance'
import { ElevationData, UnitPreference } from '../types'
import { LineString, Position } from 'geojson'
import { ELEVATION_ZERO_SPIKE_TOLERANCE } from '../settings'
import { logError } from 'shared/util-error-handling'

const DISTANCE_INCONSISTENCY_ERROR_TOLERANCE_FACTOR = 1.01

/**
 * Calculates a graph of absolute distance and elevation per position from given 3-dimensional route geometry.
 */
export function calculateElevationCurve(
  geometry: LineString,
  distance: number,
  unitPreference: UnitPreference,
): ElevationData[] {
  const { coordinates } = geometry

  if (coordinates.length < 2) {
    throw Error('Invalid route geometry for calculating elevation curve.')
  }

  const unitAwareDistanceLimit = unitPreference === 'imperial' ? distance * 3.28084 : distance

  const elevation: ElevationData[] = [
    {
      distance: 0,
      elevation: getElevationFromPosition(coordinates, 0, unitPreference),
    },
  ]

  for (let i = 1; i < coordinates.length; i++) {
    const absoluteDistance =
      elevation[i - 1].distance +
      calculateDistance(coordinates[i - 1], coordinates[i], {
        // Elevation data should already be in desired unit to get round tick values
        units: unitPreference === 'imperial' ? 'feet' : 'meters',
      })

    if (
      i < coordinates.length - 1 &&
      absoluteDistance > unitAwareDistanceLimit * DISTANCE_INCONSISTENCY_ERROR_TOLERANCE_FACTOR
    ) {
      logError('Route distance does not match calculated absolute distance.')
    }

    elevation.push({
      distance:
        absoluteDistance > unitAwareDistanceLimit || i === coordinates.length - 1
          ? unitAwareDistanceLimit
          : absoluteDistance,
      elevation: getElevationFromPosition(coordinates, i, unitPreference),
    })
  }

  return elevation
}

/**
 * Cheap calculation of the elevation curve, which assumes all geometry coordinates to have the same distance between
 * each other.
 */
export function calculateCheapElevationCurve(
  geometry: LineString,
  distance: number,
  unitPreference: UnitPreference,
): ElevationData[] {
  const { coordinates } = geometry
  const pointsLength = coordinates.length

  if (pointsLength < 2) {
    throw Error('Invalid route geometry for calculating elevation curve.')
  }

  const unitAwareDistance = unitPreference === 'imperial' ? distance * 3.28084 : distance
  const step = unitAwareDistance / (pointsLength - 1)
  const elevation: ElevationData[] = [
    {
      distance: 0,
      elevation: getElevationFromPosition(coordinates, 0, unitPreference),
    },
  ]
  for (let i = 1; i < pointsLength; i++) {
    elevation.push({
      distance: i === pointsLength - 1 ? unitAwareDistance : elevation[i - 1].distance + step,
      elevation: getElevationFromPosition(coordinates, i, unitPreference),
    })
  }
  return elevation
}

/**
 * Get unit-aware elevation value from a 3 dimensional position, flattening spikes to 0.
 */
function getElevationFromPosition(coordinates: Position[], i: number, unitPreference: UnitPreference): number {
  if (coordinates[i].length < 3) {
    throw Error('Missing elevation when calculating elevation curve. Expected 3-dimensional position.')
  }

  const elevation = coordinates[i][2]
  let flattenedElevation = elevation
  if (elevation === 0) {
    const isFirst = i === 0
    const isLast = i === coordinates.length - 1

    if (
      (isFirst || coordinates[i - 1][2] > ELEVATION_ZERO_SPIKE_TOLERANCE) &&
      (isLast || coordinates[i + 1][2] > ELEVATION_ZERO_SPIKE_TOLERANCE)
    ) {
      if (isFirst && !isLast) flattenedElevation = coordinates[i + 1][2]
      if (!isFirst && isLast) flattenedElevation = coordinates[i - 1][2]
      if (!isFirst && !isLast) flattenedElevation = (coordinates[i - 1][2] + coordinates[i + 1][2]) / 2
    }
  }

  return unitPreference === 'imperial' ? flattenedElevation * 3.28084 : flattenedElevation
}
