import { Typography } from '@mui/material'
import MapIcon from '@mui/icons-material/Map'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useLocale } from 'shared/util-intl'
import { WebAppContent } from 'web-app/ui-layout'
import { useDispatch } from 'react-redux'
import { detailedMapAdded, detailedMapRemoved, useRoutePrintState } from '../state'
import { Button, ToolButton, useMessages } from 'shared/ui-components'

import styles from './detailed-maps-options.module.css'

export const DetailedMapsOptions = () => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const { removeLabel } = useMessages()
  const { detailedMapViewports, selectedImages } = useRoutePrintState()

  const mapKeys = Object.keys(detailedMapViewports)
  const mapPagesOffset = 2 + Math.ceil(selectedImages.length / 5)

  return (
    <WebAppContent>
      <Typography variant="h4" component="h2">
        {intl.formatMessage({
          id: 'route_print_detailed_maps_options_heading',
          defaultMessage: 'Spotlighted route segments',
        })}
      </Typography>
      <Typography paragraph variant="body2">
        {intl.formatMessage({
          id: 'route_print_detailed_maps_options_introduction',
          defaultMessage: 'Add pages and zoom in to highlight specific segments of your route.',
        })}
      </Typography>
      {!!mapKeys.length && (
        <ol className={styles['list']}>
          {mapKeys.map((mapKey: string, i) => (
            <li key={mapKey} className={styles['item']}>
              <Typography color="textSecondary">
                <Typography component="span" fontWeight={500}>
                  {intl.formatMessage(
                    {
                      id: 'route_print_detailed_map_item_page',
                      defaultMessage: 'Page {number}',
                    },
                    { number: i + mapPagesOffset },
                  )}
                </Typography>
                <MapIcon className={styles['map-icon']} />
                {intl.formatMessage({
                  id: 'route_print_detailed_map_item_label',
                  defaultMessage: 'Detailed map',
                })}
              </Typography>
              <ToolButton
                variant="danger-secondary"
                onClick={() => dispatch(detailedMapRemoved({ mapKey }))}
                icon={<DeleteForeverRoundedIcon />}
                ariaLabel={removeLabel}
              />
            </li>
          ))}
        </ol>
      )}
      <Button block variant="secondary" icon={<AddRoundedIcon />} onClick={() => dispatch(detailedMapAdded())}>
        {intl.formatMessage({
          id: 'route_print_add_detailed_map',
          defaultMessage: 'Detailed map',
        })}
      </Button>
    </WebAppContent>
  )
}
