import { useEffect, useState } from 'react'
import { useRouteState } from './state'
import { LngLatBoundsArray } from 'shared/util-geo'

/**
 * Provides map props needed to fit the bounds of the main route once it's loaded.
 */
export const useFitRouteBounds = () => {
  const { isRouteLoaded, route } = useRouteState()

  const [boundsToFit, setBoundsToFit] = useState<LngLatBoundsArray | null>(null)

  useEffect(() => {
    if (isRouteLoaded && route?.bounds) {
      setBoundsToFit(route.bounds)
    } else {
      setBoundsToFit(null)
    }
  }, [isRouteLoaded, route?.bounds])

  return {
    boundsToFit,
    onFitBounds: () => setBoundsToFit(null),
    setBoundsToFit,
  }
}
