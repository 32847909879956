import { Modal, ModalProps } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { pushModalEvent } from 'shared/util-analytics'
import { useOfflineState } from './use-offline-state'
import ViewNotFoundIllustration from './svgs/connection-lost'
import { Typography } from '@mui/material'

import styles from './ConnectionLostModal.module.scss'

/**
 * Shown when going "offline" and hidden when coming back "online".
 */
export function ConnectionLostModal() {
  const { intl } = useLocale()

  const [isOffline] = useOfflineState()

  const headline = intl.formatMessage({
    id: 'connection_lost_modal_headline',
    defaultMessage: 'Lost connection!',
  })

  const modalProps: Omit<ModalProps, 'children'> = {
    open: isOffline,
    onOpen: () => pushModalEvent('error_connection_lost'),
    'aria-label': headline,
  }

  return (
    <Modal {...modalProps}>
      <div className={styles['content']}>
        <div className={styles['illustration']}>
          <ViewNotFoundIllustration />
        </div>
        <Typography variant="h3">{headline}</Typography>
        <Typography paragraph color="textSecondary" marginBottom="1rem">
          {intl.formatMessage({
            id: 'connection_lost_modal_message',
            defaultMessage:
              'It looks like you have lost your internet connection. Make sure you are connected to the internet before you continue.',
          })}
        </Typography>
      </div>
    </Modal>
  )
}
