import { useMemo } from 'react'
import { useLocale } from 'shared/util-intl'

type RouteMessages = {
  durationLabel: string
  distanceLabel: string
  averageSpeedLabel: string
  ascentLabel: string
  descentLabel: string
  maxAltitudeLabel: string
  locationLabel: string
}

export const useRouteMessages = (): RouteMessages => {
  const { intl } = useLocale()

  return useMemo(() => ({
    durationLabel: intl.formatMessage({
      id: 'routes_duration_label',
      defaultMessage: 'Duration',
    }),
    distanceLabel: intl.formatMessage({
      id: 'routes_distance_label',
      defaultMessage: 'Distance',
    }),
    averageSpeedLabel: intl.formatMessage({
      id: 'routes_average_speed_label',
      defaultMessage: 'Avg. speed',
    }),
    ascentLabel: intl.formatMessage({
      id: 'routes_ascent_label',
      defaultMessage: 'Ascent',
    }),
    descentLabel: intl.formatMessage({
      id: 'routes_descent_label',
      defaultMessage: 'Descent',
    }),
    maxAltitudeLabel: intl.formatMessage({
      id: 'routes_max_altitude_label',
      defaultMessage: 'Max. altitude',
    }),
    locationLabel: intl.formatMessage({
      id: 'routes_location_label',
      defaultMessage: 'Location',
    }),
  }), [intl])
}
