import clsx from 'clsx'
import React from 'react'

import styles from './LocationListButton.module.scss'

export interface LocationListButtonProps {
  title: string
  subtitle?: string
  icon?: React.ReactNode
  highlighted?: boolean
  onClick?: () => void
  analyticsId?: string
}

export function LocationListButton({
  title,
  subtitle,
  icon,
  highlighted,
  onClick,
  analyticsId,
}: LocationListButtonProps) {
  return (
    <button
      className={clsx(styles['button'], {
        [styles['highlighted']]: highlighted,
      })}
      aria-label={title}
      onClick={onClick}
      data-aid={analyticsId}
    >
      {icon && (
        <span className={styles['icon']} data-testid='location-list-item-icon'>{icon}</span>
      )}
      <span>
        <span className={styles['title']}>{title}</span>
        {subtitle && (
          <span className={styles['subtitle']}>{subtitle}</span>
        )}
      </span>
    </button>
  )
}
