import { Tag, TagSkeleton } from 'shared/ui-components'
import { logError } from 'shared/util-error-handling'

import styles from './tags-row.module.css'

interface TagsRowProps {
  tags: number[]
  labels: Record<number, string>
}

export const TagsRow = ({ tags, labels }: TagsRowProps) => (
  <div className={styles['container']} data-testid="tags-row-container">
    {tags.map((tag) => {
      const label = labels[tag]
      if (label) {
        return <Tag key={tag}>{labels[tag]}</Tag>
      }
      logError('No label available for this tag: ' + tag)
      return null
    })}
  </div>
)

export const TagsRowSkeleton = () => (
  <div className={styles['container']}>
    <TagSkeleton />
    <TagSkeleton />
  </div>
)
