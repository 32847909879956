import { useNavigate } from 'react-router-dom'

export const useCancel = (): (() => void) => {
  const navigate = useNavigate()
  return () => cancel(() => navigate('/'))
}

export function cancel(fallback: () => void) {
  if (canGoBackToDifferentBikemapPage()) {
    window.history.back()
  } else {
    fallback()
  }
}

function canGoBackToDifferentBikemapPage(): boolean {
  return (
    window.history.length > 1 &&
    !!document.referrer &&
    document.referrer.includes('bikemap.net') &&
    referrerIsDifferentPage() &&
    !referrerWillRedirectBack()
  )
}

function referrerIsDifferentPage(): boolean {
  return !!document.referrer && !window.location.href.startsWith(document.referrer)
}

function referrerWillRedirectBack(): boolean {
  const referrerNextMatch = document.referrer?.match('[?&]next=([^&]+)')
  return !!referrerNextMatch && window.location.href.includes(decodeURIComponent(referrerNextMatch[1]))
}
