import { useDispatch } from 'react-redux'
import { PrintMap } from './print-map'
import { mapAttributionsChanged, mapRendered, useRoutePrintState } from '../state'
import { useAttributions } from 'web-app/feature-map'
import { useContext, useEffect, useState } from 'react'
import { PrintContext } from '../print-context'
import { MapViewport } from 'shared/ui-map'
import { useFitRouteBounds, useRoute } from 'web-app/feature-route'
import { useMap } from 'react-map-gl/maplibre'

const OVERVIEW_MAP_ID = 'overview-map'

export const OverviewMap = () => {
  const { [OVERVIEW_MAP_ID]: map } = useMap()
  const dispatch = useDispatch()
  const currentAttributions = useAttributions(OVERVIEW_MAP_ID)
  const { overviewMapViewport, overviewMapImage } = useRoutePrintState()
  const { boundsToFit, onFitBounds, setBoundsToFit } = useFitRouteBounds()
  const route = useRoute()

  const { isInteractivePreview } = useContext(PrintContext)

  const [viewport, setViewport] = useState<MapViewport>(overviewMapViewport)

  useEffect(() => {
    if (isInteractivePreview) {
      dispatch(mapAttributionsChanged(currentAttributions))
    }
  }, [currentAttributions, dispatch, isInteractivePreview])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setBoundsToFit(route?.bounds || null)
    })
    const canvas = map?.getCanvas()
    if (canvas) {
      resizeObserver.observe(canvas)
    }

    return () => resizeObserver.disconnect()
  }, [map, route?.bounds, setBoundsToFit])

  return (
    <PrintMap
      id={OVERVIEW_MAP_ID}
      onRender={image => dispatch(mapRendered({ image, viewport }))}
      mapImage={overviewMapImage}
      viewport={viewport}
      onViewportChanged={setViewport}
      interactive={false}
      boundsToFit={boundsToFit}
      onFitBounds={onFitBounds}
    />
  )
}
