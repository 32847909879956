import clsx from 'clsx'
import React, { Ref } from 'react'
import {
  BottomSheet as ReactSpringBottomSheet,
  BottomSheetProps as ReactSpringBottomSheetProps,
  BottomSheetRef as ReactSpringBottomSheetRef,
} from 'react-spring-bottom-sheet'

import 'react-spring-bottom-sheet/dist/style.css'
import styles from './bottom-sheet.module.scss'

// Reassign to prevent build warning "export 'BottomSheetRef' was not found in 'react-spring-bottom-sheet'"
export type BottomSheetRef = ReactSpringBottomSheetRef

export type BottomSheetSnapPointProps = {
  headerHeight: number;
  footerHeight: number;
  height: number;
  minHeight: number;
  maxHeight: number;
}

export interface BottomSheetProps extends ReactSpringBottomSheetProps {
  ref?: Ref<BottomSheetRef>
  snapPoints?: (props: BottomSheetSnapPointProps) => number | number[]
  secondary?: boolean
}

/**
 * Customized react-spring-bottom-sheet.
 * @link https://github.com/stipsan/react-spring-bottom-sheet
 */
export const BottomSheet = React.forwardRef((props: BottomSheetProps, ref: Ref<BottomSheetRef>) => {
  const { secondary, ...bottomSheetProps } = props
  return (
    <ReactSpringBottomSheet
      ref={ref}
      className={clsx(styles['bottom-sheet'], styles['bottom-sheet-global-overwrite'], {
        [styles['bottom-sheet-secondary']]: secondary,
      })}
      {...bottomSheetProps}
    />
  )
})
