import clsx from 'clsx'
import { MouseEvent, ReactNode } from 'react'
import { Avatar } from '../Avatar'
import { HeartButton } from '../HeartButton'
import { Tag } from '../Tag'
import { ArrowAscent, ArrowDescent, ArrowDistance, BikemapIcon } from '../icons'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { Skeleton } from '@mui/material'

import styles from './route-tile.module.scss'

type RenderImageProps = {
  className: string
  src: string
  width: number
  height: number
  alt: string
  loading: 'lazy' | 'eager'
}

export interface RouteTileProps {
  title: string
  distance: string
  ascent: string
  descent: string
  location?: string
  favoriteCount: number
  tags: string[]
  image?: string
  staticMap?: string
  isFavorite: boolean
  avatarProps?: {
    name: string
    image?: string
    isPremium: boolean
  }
  href: string
  eagerLoading?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  onHeartButtonClick?: () => void
  messages: {
    distanceLabel: string
    ascentLabel: string
    descentLabel: string
    locationLabel: string
  }
  renderImage?: (props: RenderImageProps) => ReactNode
  analyticsId?: string
}

export const RouteTile = ({
  title,
  distance,
  ascent,
  descent,
  location,
  favoriteCount,
  tags,
  image,
  staticMap,
  isFavorite,
  avatarProps,
  href,
  eagerLoading,
  onClick,
  onHeartButtonClick,
  messages,
  renderImage,
  analyticsId,
}: RouteTileProps) => {
  const baseImageProps: Pick<RenderImageProps, 'alt' | 'loading'> = {
    alt: title,
    loading: eagerLoading ? 'eager' : 'lazy',
  }

  const getMainImage = (): ReactNode => {
    const src = image || staticMap
    if (!src) return null
    const mainImageProps: RenderImageProps = {
      ...baseImageProps,
      className: styles['main-image'],
      src,
      width: 368,
      height: 288,
    }
    return renderImage
      ? renderImage(mainImageProps)
      : <img {...mainImageProps} alt={mainImageProps.alt} />
  }

  const getAdditionalImage = (): ReactNode => {
    if (!image || !staticMap) return null
    const additionalImageProps: RenderImageProps = {
      ...baseImageProps,
      className: styles['additional-image'],
      src: staticMap,
      width: 116,
      height: 68,
    }
    return image ? (
      renderImage
        ? renderImage(additionalImageProps)
        : <img {...additionalImageProps} alt={additionalImageProps.alt} />
    ) : null
  }

  return (
    <div className={styles['container']}>
      <div className={styles['media']}>
        {getMainImage() || (
          <BikemapIcon className={styles['placeholder-icon']} />
        )}
        {!!tags.length && (
          <div className={styles['tags']}>
            {tags.map((tag, i) => <Tag key={i}>{tag}</Tag>)}
          </div>
        )}
        {avatarProps && <Avatar className={styles['avatar']} {...avatarProps} eagerLoading={eagerLoading} />}
        {getAdditionalImage()}
      </div>
      <div className={clsx(styles['heart-button'], { [styles['heart-button-interactive']]: !!onHeartButtonClick })}>
        <HeartButton active={isFavorite} amount={favoriteCount} onClick={onHeartButtonClick} />
      </div>
      <a className={styles['title']} href={href} onClick={onClick} data-aid={analyticsId}>
        {title}
      </a>
      <dl className={styles['attributes']}>
        <div className={styles['attribute-item']}>
          <dt>
            <span>{messages.distanceLabel}</span>
            <ArrowDistance fontSize='small' />
          </dt>
          <dd>{distance}</dd>
        </div>
        <div className={styles['attribute-item']}>
          <dt>
            <span>{messages.ascentLabel}</span>
            <ArrowAscent fontSize='small' />
          </dt>
          <dd>{ascent}</dd>
        </div>
        <div className={styles['attribute-item']}>
          <dt>
            <span>{messages.descentLabel}</span>
            <ArrowDescent fontSize='small' />
          </dt>
          <dd>{descent}</dd>
        </div>
        {location && (
          <div className={clsx(styles['attribute-item'], styles['location-attribute'])}>
            <dt>
              <span>{messages.locationLabel}</span>
              <LocationOnRoundedIcon fontSize='small' />
            </dt>
            <dd>{location}</dd>
          </div>
        )}
      </dl>
    </div>
  )
}

export const RouteTileSkeleton = () => (
  <div className={styles['container']}>
    <div className={styles['media']}>
      <Skeleton variant='rectangular' height='100%' />
    </div>
    <div className={styles['title']}>
      <Skeleton width='70%' />
    </div>
    <dl className={styles['attributes']}>
      <div className={styles['attribute-item']}>
        <Skeleton width='5rem' />
      </div>
      <div className={styles['attribute-item']}>
        <Skeleton width='5rem' />
      </div>
      <div className={styles['attribute-item']}>
        <Skeleton width='5rem' />
      </div>
    </dl>
  </div>
)
