import { MAX_ROUTE_TITLE_LENGTH, MIN_ROUTE_TITLE_LENGTH } from './config'

/**
 * Copy the given text to the clipboard.
 */
export async function copyToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

export function isRouteTitleTooShort(title: string): boolean {
  const letters = title.match(/\p{L}/gu)
  const countedCharacters = letters ? letters.length : 0
  return countedCharacters < MIN_ROUTE_TITLE_LENGTH
}

export function isRouteTitleTooLong(title: string): boolean {
  return title.length > MAX_ROUTE_TITLE_LENGTH
}

const YEAR_IN_MILLISECONDS = 3.154e10
const MONTH_IN_MILLISECONDS = 2.628e9
const DAY_IN_MILLISECONDS = 8.64e7
const HOUR_IN_MILLISECONDS = 3.6e6
const MIN_IN_MILLISECONDS = 6e4

export function formatCreatedTimeAgo(
  routeCreatedTimestamp: number,
  formatRelativeTime: (value: number, unit?: Intl.RelativeTimeFormatUnit) => string,
  shortTimeString: string,
): string {
  const diff = new Date().getTime() - routeCreatedTimestamp

  if (diff < MIN_IN_MILLISECONDS) {
    return shortTimeString
  }

  if (diff < HOUR_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / MIN_IN_MILLISECONDS), 'minute')
  }
  if (diff < DAY_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / HOUR_IN_MILLISECONDS), 'hour')
  }
  if (diff < MONTH_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / DAY_IN_MILLISECONDS), 'day')
  }
  if (diff < YEAR_IN_MILLISECONDS) {
    return formatRelativeTime(-Math.trunc(diff / MONTH_IN_MILLISECONDS), 'month')
  }
  return formatRelativeTime(-Math.trunc(diff / YEAR_IN_MILLISECONDS), 'year')
}
