import { LngLat, lngLatToPosition2d } from 'shared/util-geo'
import { API_PATH_ROUTE_POI, API_PATH_ROUTE_POIS, API_PATH_ROUTE_ROUTE_POIS } from '../config'
import { RoutePoiEntity, RoutePoiType } from '../entities'
import { addApiHeaders, deleteFromCoreApi, getFromCoreApi, patchToCoreApi, postToCoreApi } from '../network'
import { RoutePoiResponse, convertToRoutePoiEntity } from '../responses'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export type RoutePoiForm = {
  routeId: number
  position: LngLat
  type: RoutePoiType
  description: string
  image?: File
}

type CreateRoutePoiRequestBody = {
  route: number
  lng_lat: string
  text: string
  poi_class: string
  image: File | ''
}

export async function createRoutePoi(form: RoutePoiForm): ApiResult<RoutePoiEntity> {
  try {
    const { routeId, position, type, description, image } = form

    const body: CreateRoutePoiRequestBody = {
      route: routeId,
      lng_lat: JSON.stringify({
        type: 'Point',
        coordinates: lngLatToPosition2d(position),
      }),
      text: description,
      poi_class: 'poi-' + type,
      image: image || '',
    }

    const res: RoutePoiResponse = await postToCoreApi(API_PATH_ROUTE_POIS, {
      headers: await addApiHeaders(),
      body,
    })
    return createSuccessResult(convertToRoutePoiEntity(res))
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { form })
  }
}

export async function getRoutePois(routeId: number): ApiResult<RoutePoiEntity[]> {
  try {
    const res: RoutePoiResponse[] = await getFromCoreApi(API_PATH_ROUTE_ROUTE_POIS, {
      params: { routeId },
      headers: await addApiHeaders(),
    })
    return createSuccessResult(res.map(convertToRoutePoiEntity))
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}

export type RoutePoiUpdateForm = {
  position?: LngLat
  type?: RoutePoiType
  description?: string
  image?: File | null
}

type UpdateRoutePoiRequestBody = {
  lng_lat?: string
  text?: string
  poi_class?: string
  image?: File | ''
}

export async function updateRoutePoi(routePoiId: number, form: RoutePoiUpdateForm): ApiResult<RoutePoiEntity> {
  try {
    const body: UpdateRoutePoiRequestBody = {}
    if (form.position) {
      body.lng_lat = JSON.stringify({
        type: 'Point',
        coordinates: lngLatToPosition2d(form.position),
      })
    }
    if (form.type) {
      body.poi_class = 'poi-' + form.type
    }
    if (form.description !== undefined) {
      body.text = form.description
    }
    if (form.image !== undefined) {
      body.image = form.image || ''
    }

    const res: RoutePoiResponse = await patchToCoreApi(API_PATH_ROUTE_POI, {
      params: { routePoiId },
      headers: await addApiHeaders(),
      body,
    })
    return createSuccessResult(convertToRoutePoiEntity(res))
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routePoiId, form })
  }
}

export async function deleteRoutePoi(routePoiId: number): ApiResult<null> {
  try {
    await deleteFromCoreApi(API_PATH_ROUTE_POI, {
      params: { routePoiId },
      headers: await addApiHeaders(),
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routePoiId })
  }
}
