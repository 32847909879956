import clsx from 'clsx'
import MapMarkerEmptySvg from './svgs/map-marker-empty.svg'
import { CommonMapMarker, CommonMapMarkerProps } from './shared/common-map-marker'

import styles from './map-marker-start.module.scss'

interface MapMarkerStartProps extends Omit<CommonMapMarkerProps, 'svg'> {
  selected?: boolean
}

/**
 * Map marker for the route starting point.
 */
export const MapMarkerStart = ({
  selected,
  ...props
}: MapMarkerStartProps) => (
  <CommonMapMarker
    {...props}
    svg={MapMarkerEmptySvg}
    size={selected ? 'large' : 'small'}
  >
    <div className={clsx(styles['marker-dot'], { [styles['marker-dot-large']]: selected })} />
  </CommonMapMarker>
)

export default MapMarkerStart
