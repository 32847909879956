import { toast } from 'sonner'
import { Notification, NotificationProps } from 'shared/ui-components'
import { pushNotificationEvent } from 'shared/util-analytics'

export function showNotification(props: NotificationProps, analyticsId: string): Promise<string | number> {
  // 15 s is default, use Infinity to keep open
  const duration = props.duration || 15000

  return new Promise((resolve) => {
    toast.custom(
      (toastId) => {
        resolve(toastId)
        return (
          <Notification
            {...props}
            duration={duration}
            onDismiss={() => {
              if (props.onDismiss) {
                props.onDismiss()
              }
              toast.dismiss(toastId)
            }}
          />
        )
      },
      {
        duration,
      },
    )

    pushNotificationEvent(analyticsId)
  })
}

export function clearNotification(id: string | number) {
  toast.dismiss(id)
}
