import { LineString } from 'geojson'
import { ApiResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from 'shared/util-network'
import { addAuthHeader, postRequest } from '../network'
import { API_URL_MATCHING } from '../config'
import { decodePath3d } from 'shared/util-geo'

type MapMatchingResultData = {
  geometry: LineString
  distanceInM: number
  durationInMs: number
}

type MapMatchingErrors = MinimalEndpointErrors & {
  unexpectedResponse?: true
}

interface MapMatchingResponse {
  paths: {
    distance: number
    points: string // encoded
    time: number
  }[]
}

// TODO WEB-1017: Consider moving non-core api calls and api utils out of here
export async function mapMatchRouteGeometry(geometry: LineString): ApiResult<MapMatchingResultData, MapMatchingErrors> {
  try {
    const response: MapMatchingResponse = await postRequest(API_URL_MATCHING, {
      headers: await addAuthHeader(),
      body: {
        points: geometry,
      },
      type: 'json',
    })

    const path = response.paths[0]
    if (!path) {
      return createFailureResult({ unexpectedResponse: true })
    }

    return createSuccessResult({
      geometry: {
        type: 'LineString',
        coordinates: decodePath3d(path.points),
      },
      distanceInM: path.distance,
      durationInMs: path.time,
    })
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
