import { ReactNode } from 'react'
import { useLayoutState } from './layout-hooks'
import { useTopHeaderMargin } from './use-top-header-margin'

import styles from './web-app-media.module.css'

export interface WebAppMediaProps {
  children: ReactNode
}

/**
 * Contains the main media of a Web App view, usually the map. This is the most prominent,
 * full screen section, rendered behind other content.
 */
export const WebAppMedia = ({
  children,
}: WebAppMediaProps) => {
  const marginTop = useTopHeaderMargin()
  const { isFullScreenContent } = useLayoutState()

  return isFullScreenContent ? null : (
    <div className={styles['root']} style={{ marginTop }}>
      {children}
    </div>
  )
}
