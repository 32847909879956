import { useContext, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ElevationCurve } from 'shared/feature-elevation-curve'
import { PrintContext } from '../print-context'
import { elevationCurveRendered, useRoutePrintState } from '../state'

import styles from './print-elevation-curve.module.scss'

export const PrintElevationCurve = () => {
  const dispatch = useDispatch()
  const { isInteractivePreview } = useContext(PrintContext)
  const { elevationCurveSvg } = useRoutePrintState()

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current && !elevationCurveSvg) {
      const svgNode = containerRef.current.querySelector('svg')
      const html = svgNode?.parentElement?.innerHTML
      if (html) {
        dispatch(elevationCurveRendered(html))
      }
    }
  })

  return isInteractivePreview ? (
    <div ref={containerRef}>
      <ElevationCurve height='3em' interactive={false} />
    </div>
  ) : elevationCurveSvg ? (
    <div className={styles['image-container']} dangerouslySetInnerHTML={{ __html: elevationCurveSvg }} />
  ) : null
}
