import svg from './svgs/map-marker-dest.svg'
import { CommonMapMarker, CommonMapMarkerProps } from './shared/common-map-marker'

interface MapMarkerEndProps extends Omit<CommonMapMarkerProps, 'svg'> {
  selected?: boolean
}

/**
 * Map marker for the route end point.
 */
export const MapMarkerEnd = ({
  selected,
  ...props
}: MapMarkerEndProps) => (
  <CommonMapMarker
    {...props}
    svg={svg}
    size={selected ? 'large' : 'default'}
  />
)

export default MapMarkerEnd
