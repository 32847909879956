import React from 'react'
import { Collapse, useTheme } from '@mui/material'
import styles from './results-large-screen.module.scss'

interface ResultsLargeScreenProps {
  open: boolean
  backgroundColor: string
  children: React.ReactElement
}

export function ResultsLargeScreen({ open, backgroundColor, children }: ResultsLargeScreenProps) {
  const { transitions } = useTheme()
  return (
    <Collapse in={open} timeout={transitions.duration.enteringScreen}>
      <div className={styles['results']}>
        <div className={styles['results-separator']} style={{ background: backgroundColor }} />
        {children}
      </div>
    </Collapse>
  )
}
