import { API_PATH_ROUTE } from '../config'
import { ExtensiveRouteEntity, RouteEntity, UserPreviewEntity } from '../entities'
import { AuthError, GoneError, addApiHeaders, getFromCoreApi } from '../network'
import { ApiFailureResult, ApiResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from 'shared/util-network'
import {
  RouteResponse,
  RouteResponseIncludedBikeNetworkAlongTheRoute,
  RouteResponseIncludedCollections,
  RouteResponseIncludedControlPointIndexes,
  RouteResponseIncludedSurfaceAlongTheRoute,
  RouteResponseIncludedWayTypeAlongTheRoute,
  RouteResponseIncludedWaypoints,
  convertToExtensiveRouteEntity,
  convertToRouteEntity,
  convertToUserEntity,
} from '../responses'
import { isValidGeometry } from 'shared/util-geo'

type GetRouteDetailsErrors = MinimalEndpointErrors & {
  invalidGeometry?: true
  unauthorized?: true
  gone?: true
}

export type GetRouteDetailsData = {
  route: RouteEntity
  creator?: UserPreviewEntity
}

/**
 * Fetch detailed info about an existing route and a preview of its creator (user).
 */
export async function getRouteDetails(
  routeId: number
): ApiResult<GetRouteDetailsData, GetRouteDetailsErrors> {
  try {
    const res: (
      RouteResponse &
      RouteResponseIncludedWaypoints &
      RouteResponseIncludedControlPointIndexes
    ) = await getFromCoreApi(API_PATH_ROUTE, {
      headers: await addApiHeaders(),
      params: { routeId },
      queryParams: {
        include: 'waypoints,control_point_indexes',
      },
    })

    if (!isValidGeometry(res.points)) {
      return createFailureResult({ invalidGeometry: true }, { routeId, res })
    }

    return createSuccessResult({
      route: convertToRouteEntity(res),
      creator: res.user && res.user.slug ? convertToUserEntity(res.user) : undefined,
    })
  } catch (error) {
    return handleRequestError(error, routeId)
  }
}

export type GetExtensiveRouteDetailsData = {
  route: ExtensiveRouteEntity
  creator?: UserPreviewEntity
  collectionIds: number[]
}

/**
 * Fetch detailed info about an existing route, a preview of its creator (user) and a list of collections (IDs)
 * of the current user which the route is assigned to.
 */
export async function getExtensiveRouteDetails(
  routeId: number
): ApiResult<GetExtensiveRouteDetailsData, GetRouteDetailsErrors> {
  try {
    const res: (
      RouteResponse &
      RouteResponseIncludedWaypoints &
      RouteResponseIncludedControlPointIndexes &
      RouteResponseIncludedCollections &
      RouteResponseIncludedSurfaceAlongTheRoute &
      RouteResponseIncludedWayTypeAlongTheRoute &
      RouteResponseIncludedBikeNetworkAlongTheRoute
    ) = await getFromCoreApi(API_PATH_ROUTE, {
      headers: await addApiHeaders(),
      params: { routeId },
      queryParams: {
        include: 'waypoints,control_point_indexes,routecollections,bm_surface,bm_way_type,bike_network',
      },
    })

    if (!isValidGeometry(res.points)) {
      return createFailureResult({ invalidGeometry: true }, { routeId, res })
    }

    return createSuccessResult({
      route: convertToExtensiveRouteEntity(res),
      creator: res.user && res.user.slug ? convertToUserEntity(res.user) : undefined,
      collectionIds: res.routecollections,
    })
  } catch (error) {
    return handleRequestError(error, routeId)
  }
}

function handleRequestError(error: unknown, routeId: number): ApiFailureResult<GetRouteDetailsErrors> {
  if (error instanceof AuthError) {
    return createFailureResult({ unauthorized: true }, { routeId })
  }
  if (error instanceof GoneError) {
    return createFailureResult({ gone: true }, { routeId })
  }
  return createFailureResult({ unexpectedError: true }, { routeId })
}
