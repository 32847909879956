import { isEmpty } from 'lodash'
import { LANGUAGE_URL_PARAM, extractLanguageFromUrl, getFromUrl } from 'shared/util-navigation'
import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from './config'
import { AvailableLocale } from './types'

/**
 * Get language only if it's one of our available languages.
 */
function filterLanguage(language: string): AvailableLocale | undefined {
  const lang = language.slice(0, 2).toLowerCase() as AvailableLocale
  return AVAILABLE_LOCALES.includes(lang) ? lang : undefined
}

/**
 * Try to find language in URL and also remove it if found.
 */
export function extractUrlLanguage(): AvailableLocale | undefined {
  const fromUrl = extractLanguageFromUrl()
  return fromUrl ? filterLanguage(fromUrl) : undefined
}

/**
 * Get preferred language from browser.
 */
export function getBrowserLanguage(): AvailableLocale | undefined {
  try {
    const language = !isEmpty(window.navigator.languages) ? window.navigator.languages[0] : window.navigator.language
    return filterLanguage(language)
  } catch (e) {
    return undefined
  }
}

/**
 * Get preferred language in prioritized order.
 */
export function getPreferredLanguage(): AvailableLocale {
  return getBrowserLanguage() || DEFAULT_LOCALE
}

/**
 * Read language from URL param. If not available, take browser default.
 */
export function getUrlOrPreferredLanguage(): AvailableLocale {
  const fromUrl = getFromUrl(LANGUAGE_URL_PARAM)
  return (fromUrl && filterLanguage(fromUrl)) || getPreferredLanguage()
}
