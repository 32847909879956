import { MarkerProps } from 'react-map-gl/maplibre'
import MapMarkerRouteSvg from './svgs/map-marker-route.svg'
import SvgMapMarker from './shared/svg-map-marker'

/**
 * Map marker for the route point and for the elevation curve.
 */
export const MapMarkerPoint = (props: MarkerProps) => (
  <SvgMapMarker
    {...props}
    svg={MapMarkerRouteSvg}
    anchor='center'
    style={{ width: 26, height: 42, ...props.style }}
    offset={[0, 0]}
  />
)

export default MapMarkerPoint
