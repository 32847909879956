import { useRef, useState } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { useLocale } from 'shared/util-intl'
import { getSearchUrl } from 'shared/util-navigation'
import { useSessionInfo } from './hooks'
import { BikemapLogo, Button, Link } from 'shared/ui-components'
import { useUser } from 'web-app/feature-user'
import { PremiumLink } from './links'
import { UserMenuToggle } from './user-menu-toggle'
import { UserMenu } from './user-menu'
import { useInternalLink } from './use-internal-link'

import styles from './default-header.module.scss'

interface DefaultHeaderProps {
  withUserNavigation?: boolean
}

export const DefaultHeader = ({ withUserNavigation }: DefaultHeaderProps) => {
  const getInternalLinkProps = useInternalLink()
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()
  const [user, isUserLoaded] = useUser()

  const buttonsContainerRef = useRef(null)

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const searchButtonLabel = intl.formatMessage({
    id: 'default_header_search_link_label',
    defaultMessage: 'Discover',
  })

  return (
    <div className={styles['root']}>
      <Link
        className={styles['logo']}
        {...getInternalLinkProps('/')}
        aria-label={intl.formatMessage({
          id: 'default_header_logo_link_label',
          defaultMessage: 'Bikemap home',
        })}
      >
        <BikemapLogo />
      </Link>
      <div className={styles['buttons-container']} ref={buttonsContainerRef}>
        {(!withUserNavigation || user?.isPremium) && (
          <Button variant="secondary" size="medium" icon={<SearchRoundedIcon />} href={getSearchUrl(sessionInfo)}>
            {searchButtonLabel}
          </Button>
        )}
        {withUserNavigation && (
          <>
            {isUserLoaded && !user?.isPremium && <PremiumLink primary compact />}
            <UserMenuToggle isOpen={isMenuOpen} onToggle={setIsMenuOpen} compact />
            <UserMenu
              anchorEl={buttonsContainerRef.current}
              open={isMenuOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => {
                setIsMenuOpen(false)
              }}
              user={(isUserLoaded && user) || undefined}
            />
          </>
        )}
      </div>
    </div>
  )
}
