import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { showNotification, useCommonErrorNotification } from 'web-app/feature-notifications'
import { RouteCollectionEntity } from 'shared/data-access-core'
import { RouteSliceDispatch, assignCollection, useRoute } from 'web-app/feature-route'
import { routeAddedToCollection } from '../state'

export function useRouteRemovedFromCollectionNotification() {
  const dispatch = useDispatch() as RouteSliceDispatch
  const { intl } = useLocale()
  const route = useRoute()
  const showAddRouteToCollectionErrorNotification = useAddRouteToCollectionErrorNotification()

  return (routeCollection: RouteCollectionEntity) => {
    if (!route) return
    showNotification(
      {
        title: intl.formatMessage(
          {
            id: 'route_removed_from_collection_notification_title',
            defaultMessage: 'Route removed from collection',
          },
          {
            collectionTitle: routeCollection.title,
          },
        ),
        description: intl.formatMessage(
          {
            id: 'route_removed_from_collection_notification_description',
            defaultMessage: '{routeTitle} has been removed from {collectionTitle}.',
          },
          {
            routeTitle: route.title,
            collectionTitle: routeCollection.title,
          },
        ),
        secondaryButton: {
          label: intl.formatMessage({
            id: 'route_removed_from_collection_notification_undo_button_label',
            defaultMessage: 'Undo',
          }),
          onClick: async () => {
            try {
              await dispatch(assignCollection(routeCollection.id)).unwrap()
              dispatch(routeAddedToCollection(routeCollection.id))
            } catch {
              showAddRouteToCollectionErrorNotification(routeCollection)
            }
          },
          closesNotification: true,
        },
      },
      'route_removed_from_collection',
    )
  }
}

export function useRemoveRouteFromCollectionErrorNotification() {
  const { intl } = useLocale()
  const route = useRoute()

  return (routeCollection: RouteCollectionEntity) => {
    if (!route) return
    showNotification(
      {
        title: intl.formatMessage({
          id: 'remove_route_from_collection_error_notification_title',
          defaultMessage: 'Error removing route from collection',
        }),
        description: intl.formatMessage(
          {
            id: 'remove_route_from_collection_error_notification_description',
            defaultMessage: '{routeTitle} could not be removed from {collectionTitle}.',
          },
          {
            routeTitle: route.title,
            collectionTitle: routeCollection.title,
          },
        ),
        variant: 'danger',
      },
      'remove_route_from_collection_error',
    )
  }
}

export function useAddRouteToCollectionErrorNotification() {
  const { intl } = useLocale()
  const route = useRoute()

  return (routeCollection: RouteCollectionEntity) => {
    if (!route) return
    showNotification(
      {
        title: intl.formatMessage({
          id: 'add_route_to_collection_error_notification_title',
          defaultMessage: 'Error adding route to collection',
        }),
        description: intl.formatMessage(
          {
            id: 'add_route_to_collection_error_notification_description',
            defaultMessage: '{routeTitle} could not be added to {collectionTitle}.',
          },
          {
            routeTitle: route.title,
            collectionTitle: routeCollection.title,
          },
        ),
        variant: 'danger',
      },
      'add_route_to_collection_error',
    )
  }
}

export function useSaveContentChangesErrorNotification() {
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()

  return () =>
    showCommonErrorNotification(
      intl.formatMessage({
        id: 'route_details_save_content_changes_error_details',
        defaultMessage: 'Your changes could not be saved.',
      }),
    )
}

export function useDeleteRouteErrorNotification() {
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()

  return () =>
    showCommonErrorNotification(
      intl.formatMessage({
        id: 'route_details_delete_route_error_details',
        defaultMessage: 'The route could not be deleted.',
      }),
    )
}

export function useUrlCopiedNotification() {
  const { intl } = useLocale()

  return (url: string) => {
    showNotification(
      {
        title: intl.formatMessage({
          id: 'route_details_sharing_copied_to_clipboard',
          defaultMessage: 'Link copied to clipboard',
        }),
        description: intl.formatMessage(
          {
            id: 'route_details_sharing_copied_to_clipboard_description',
            defaultMessage: `
          You can now share the route with your friends by pasting the link in a message or post: {url}.
          It will open the route directly in the app if they have it installed or open a web page otherwise.
        `,
          },
          {
            url: (
              <a href={url} style={{ lineBreak: 'anywhere' }}>
                {url}
              </a>
            ),
          },
        ),
        variant: 'success',
      },
      'success_route_url_copied',
    )
  }
}

export function useRouteImageUploadNotifications() {
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()

  return {
    showMaxNumberImagesErrorNotification: (maxNumber: number) => {
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_images_max_number_images_error_title',
            defaultMessage: 'Too many images',
          }),
          description: intl.formatMessage(
            {
              id: 'route_images_max_number_images_error_description',
              defaultMessage:
                'You have reached the limit of {maxNumber} images per route. Please delete an image before uploading another.',
            },
            {
              maxNumber,
            },
          ),
          variant: 'danger',
        },
        'route_images_max_number_images_error',
      )
    },

    showFileTypeErrorNotification: (fileName: string) => {
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_images_file_type_error_title',
            defaultMessage: 'Unsupported file type',
          }),
          description: intl.formatMessage(
            {
              id: 'route_images_file_type_error_description',
              defaultMessage:
                'The selected file "{fileName}" is not supported. Please upload a .jpg, .jpeg or .png file.',
            },
            {
              fileName,
            },
          ),
          variant: 'danger',
        },
        'route_images_file_type_error',
      )
    },

    showFileSizeErrorNotification: (fileName: string, maxFileSize: number) => {
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_images_file_size_error_title',
            defaultMessage: 'File too large',
          }),
          description: intl.formatMessage(
            {
              id: 'route_images_file_size_error_description',
              defaultMessage:
                'The selected file "{fileName}" exceeds the maximum allowed file size of {maxFileSize}MB.',
            },
            {
              fileName,
              maxFileSize,
            },
          ),
          variant: 'danger',
        },
        'route_images_file_size_error',
      )
    },

    showUploadErrorNotification: () => {
      showCommonErrorNotification(
        intl.formatMessage({
          id: 'route_images_upload_error_details',
          defaultMessage: 'Route image could not be uploaded. Please try again later or using different images.',
        }),
      )
    },
  }
}
