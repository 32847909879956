import { CircularProgress } from '@mui/material'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'

import styles from './deleting-indicator.module.css'

export interface DeletingIndicatorProps {
  message: string
}

export const DeletingIndicator = ({
  message,
}: DeletingIndicatorProps) => (
  <div className={styles['container']}>
    <span className={styles['icon-holder']}>
      <CircularProgress size='3rem' color='error' />
      <DeleteForeverRoundedIcon className={styles['icon']} />
    </span>
    <div>{message}</div>
  </div>
)
