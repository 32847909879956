import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded'
import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { Button, ButtonGroup } from 'shared/ui-components'
import {
  useIsRoundtrip,
  SelectedWaypointState,
  useWaypoints,
  RoutePlannerSliceDispatch,
  insertWaypoint,
  GeocodedWaypoint,
  removeSelectedWaypoint,
} from '../../state'
import { LocationMapPopup } from 'web-app/feature-map'

interface PopupEditActionsProps {
  waypointIndex: number
}

const PopupEditActions = ({ waypointIndex }: PopupEditActionsProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { waypoints, start, end } = useWaypoints()
  const isRoundtrip = useIsRoundtrip()

  const isStart = waypointIndex === 0
  const isEnd = waypointIndex === waypoints.length - 1
  const isRoundtripActionAvailable = start && ((isStart && end) || isEnd) && !isRoundtrip

  return (
    <ButtonGroup>
      {isRoundtripActionAvailable && (
        <Button
          variant="primary"
          icon={<SettingsBackupRestoreRoundedIcon />}
          onClick={() => dispatch(insertWaypoint(waypoints.length, { ...start }))}
        >
          {isStart
            ? intl.formatMessage({
              id: 'start_popup_create_roundtrip_label',
              defaultMessage: 'Create roundtrip',
            })
            : intl.formatMessage({
              id: 'destination_popup_back_to_start_label',
              defaultMessage: 'Back to starting point',
            })}
        </Button>
      )}
      <Button
        variant={isRoundtripActionAvailable ? 'danger-secondary' : 'danger-primary'}
        icon={<RemoveCircleIcon />}
        onClick={() => {
          dispatch(removeSelectedWaypoint())
        }}
      >
        {intl.formatMessage({
          id: 'remove_waypoint_label',
          defaultMessage: 'Remove waypoint',
        })}
      </Button>
    </ButtonGroup>
  )
}

interface PopupEditProps {
  selectedWaypointState: SelectedWaypointState
}

export const PopupEdit = ({ selectedWaypointState }: PopupEditProps) => {
  const { waypoint } = selectedWaypointState
  const { lng, lat } = waypoint

  return (
    <LocationMapPopup
      longitude={lng}
      latitude={lat}
      geocoded={waypoint.address ? (waypoint as GeocodedWaypoint) : undefined}
    >
      <PopupEditActions waypointIndex={selectedWaypointState.index} />
    </LocationMapPopup>
  )
}
