import React, { useRef, createContext, RefObject } from 'react'
import { useMediaQuery } from '@mui/material'
import { BottomSheetRef } from 'shared/ui-components'

export type LayoutMediaQueries = {
  isLargeViewport: boolean
  isUserMenuShown: boolean
  isFlatViewport: boolean
}

export type LayoutRefs = {
  contentHeaderRef: RefObject<HTMLDivElement>
  contentScrollToRef: RefObject<HTMLDivElement>
  contentFooterRef: RefObject<HTMLDivElement>
  bottomSheetRef: RefObject<BottomSheetRef>
}

export type LayoutProviderValues = {
  refs: LayoutRefs
  mediaQueries: LayoutMediaQueries
  isFullScreenContent: boolean
}

export const LayoutContext = createContext<LayoutProviderValues|undefined>(undefined)

interface LayoutProviderProps {
  children: React.ReactNode
  fullScreenContent: boolean
}

export const LayoutProvider = ({
  children,
  fullScreenContent,
} : LayoutProviderProps) => {

  const refs: LayoutRefs = {
    contentHeaderRef: useRef<HTMLDivElement>(null),
    contentScrollToRef: useRef<HTMLDivElement>(null),
    contentFooterRef: useRef<HTMLDivElement>(null),
    bottomSheetRef: useRef<BottomSheetRef>(null),
  }

  const mediaQueries = {
    isLargeViewport: useMediaQuery('(min-width:768px)', { noSsr: true }),
    isUserMenuShown: useMediaQuery('(min-width:65rem)', { noSsr: true }),
    isFlatViewport: useMediaQuery('(max-height:35rem)', { noSsr: true }),
  }

  return (
    <LayoutContext.Provider value={{
      refs,
      mediaQueries,
      isFullScreenContent: fullScreenContent && !mediaQueries.isLargeViewport,
    }}>
      {children}
    </LayoutContext.Provider>
  )
}
