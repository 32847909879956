import clsx from 'clsx'
import { ReactNode } from 'react'
import { useLayoutMediaQueries, useLayoutState } from './layout-hooks'
import { useTopHeaderMargin } from './use-top-header-margin'

import styles from './web-app-controls.module.scss'
import additionalContentStyles from './web-app-additional-content.module.scss'

export interface WebAppControlsProps {
  children: ReactNode
}

/**
 * Controls for the media, such as map controls for a map.
 */
export const WebAppControls = ({
  children,
}: WebAppControlsProps) => {
  const { isLargeViewport } = useLayoutMediaQueries()
  const { isFullScreenContent } = useLayoutState()
  const marginTop = useTopHeaderMargin()

  return isFullScreenContent ? null : (
    <div
      className={clsx(styles['root'], additionalContentStyles['hidden-if-open'], {
        [styles['large-viewport']]: isLargeViewport,
        [styles['small-viewport']]: !isLargeViewport,
      })}
      style={{ marginTop }}
    >
      {children}
    </div>
  )
}
