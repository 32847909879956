import clsx from 'clsx'
import { ReactNode, useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { Popover } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Button } from '../Button'
import { useMessages } from '../MessagesProvider'
import { Link } from '../Link'

import styles from './MinimalFooter.module.scss'

export interface MinimalFooterProps {
  homeHref: string
  items?: ReactNode[]
  secondaryItems?: ReactNode[]
  colored?: boolean
}

export const MinimalFooter = ({
  homeHref,
  items = [],
  secondaryItems,
  colored,
}: MinimalFooterProps) => {
  const { collapseLabel, expandLabel } = useMessages()

  const rootRef = useRef<HTMLDivElement>(null)
  const mainRef = useRef<HTMLDivElement>(null)
  const secondaryRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isCompact, setIsCompact] = useState<boolean>(true)

  const handleCompact = useCallback(() => {
    const rootWidth = rootRef.current?.offsetWidth
    const mainWidth = mainRef.current?.offsetWidth
    const secondaryWidth = secondaryRef.current?.offsetWidth

    if (rootWidth && mainWidth && secondaryWidth) {
      setIsCompact((mainWidth + secondaryWidth + 60 > rootWidth))
    }
  }, [rootRef, secondaryRef])

  useEffect(() => {
    handleCompact()
  }, [handleCompact, secondaryItems])

  const renderedSecondaryItems = useMemo(() => {
    return secondaryItems && secondaryItems.length > 0 ? (
      <span className={styles['text']}>
        {secondaryItems.reduce((nodes: ReactNode[], item: ReactNode, i: number) => {
          if (i > 0) {
            nodes.push(<span key={'divider' + i}>&nbsp;|&nbsp;</span>)
          }
          nodes.push(<span key={i}>{item}</span>)
          return nodes
        }, [])}
      </span>
    ) : null
  }, [secondaryItems])

  const classNames = clsx(styles['root'], {
    [styles['root-compact']]: renderedSecondaryItems && isCompact,
    [styles['colored']]: colored,
  })

  return (
    <footer ref={rootRef} className={classNames}>
      <div ref={mainRef}>
        <Link small secondary onColor={colored} href={homeHref}><strong>Bikemap</strong></Link>
        {items.reduce((nodes: ReactNode[], item: ReactNode, i: number) => {
          nodes.push(<span key={'divider' + i}>&nbsp;|&nbsp;</span>)
          nodes.push(<span key={i}>{item}</span>)
          return nodes
        }, [])}
      </div>
      {renderedSecondaryItems && (
        <>
          <div ref={secondaryRef} className={isCompact ? styles['hidden'] : ''}>
            {renderedSecondaryItems}
          </div>
          <div ref={popoverRef} className={!isCompact ? styles['hidden'] : ''}>
            <Button
              size='small'
              variant='secondary'
              ariaLabel={isOpen ? collapseLabel : expandLabel}
              onClick={() => { setIsOpen(!isOpen) }}
              icon={<InfoOutlined />}
            />
            <Popover
              anchorEl={popoverRef.current}
              open={isOpen}
              onClose={() => { setIsOpen(false) }}
              disablePortal={true}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              PaperProps={{ classes: { root: styles['popover'] } }}
            >
              {renderedSecondaryItems}
            </Popover>
          </div>
        </>
      )}
    </footer>
  )
}
