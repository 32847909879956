import { SvgIcon } from '@mui/material'
import React, { MouseEvent, ReactNode, TouchEvent, useEffect, useState } from 'react'
import { Marker, MarkerProps } from 'react-map-gl/maplibre'
import clsx from 'clsx'
import { LngLat } from '../../types'

import styles from './svg-map-marker.module.scss'

export interface SvgMapMarkerProps extends MarkerProps {
  svg: (props: React.SVGProps<SVGSVGElement>) => ReactNode
  children?: React.ReactNode
  onHover?: (e: MouseEvent | TouchEvent) => void
  onLeave?: (e: MouseEvent | TouchEvent) => void
}

/**
 * Shared base for map markers that just set an SVG with the same styles as the whole marker and optionally additional
 * children. This basically just adds a slot for an SVG component to the base Marker.
 */
export const SvgMapMarker = ({
  svg,
  children,
  onHover,
  onLeave,
  ...markerProps
}: SvgMapMarkerProps) => {
  const { longitude, latitude, onDrag, style } = markerProps
  const [lngLat, setLngLat] = useState<LngLat>({ lng: longitude, lat: latitude })

  useEffect(() => {
    setLngLat({ lng: longitude, lat: latitude })
  }, [longitude, latitude])

  const isClickable = !!(onDrag || markerProps.onClick)

  return (
    <Marker
      {...markerProps}
      latitude={lngLat.lat}
      longitude={lngLat.lng}
      draggable={onDrag !== undefined}
      onDrag={(e) => {
        setLngLat(e.lngLat)
        onDrag && onDrag(e)
      }}
    >
      <div
        className={clsx({ [styles['marker-content']]: isClickable })}
        onMouseMove={onHover}
        onTouchMove={onHover}
        onMouseLeave={onLeave}
        onTouchEnd={onLeave}
      >
        <SvgIcon component={svg} inheritViewBox sx={style} />
        {children}
      </div>
    </Marker>
  )
}

export default SvgMapMarker
