import { useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import AdjustIcon from '@mui/icons-material/Adjust'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { LocationInput, DestinationIcon, useInputValue, SortableHandleProps } from 'shared/ui-components'
import { getLetterFromWaypointIndex } from 'shared/ui-map'
import { RoutePlannerSliceDispatch, removeWaypoint, useWaypoints } from '../../state'
import { IconVia } from './icons/IconVia'
import styles from './waypoint-location-input.module.scss'

export interface WaypointLocationInputProps {
  waypointsListIndex: number
  waypointIndex: number | null
  isLast: boolean
  isSearchActive: boolean
  isSorting: boolean
  isRemoveVisible: boolean
  sortableHandleProps?: SortableHandleProps
  defaultValue: string
  onFocus: () => void
  onSearch: (val: string) => void
  onRemoveItem: (waypointsListIndex: number) => void
}

export function WaypointLocationInput({
  waypointsListIndex,
  waypointIndex,
  isLast,
  isSearchActive,
  isSorting,
  isRemoveVisible,
  sortableHandleProps,
  defaultValue,
  onFocus,
  onSearch,
  onRemoveItem,
}: WaypointLocationInputProps) {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { isFullRoute } = useWaypoints()

  const containerRef = useRef<HTMLDivElement>(null)

  const isStart = waypointsListIndex === 0

  const { inputProps, setValue } = useInputValue(defaultValue, onSearch)

  const inputLabel = isStart
    ? intl.formatMessage({
      id: 'route_planner_starting_point',
      defaultMessage: 'Starting point',
    })
    : intl.formatMessage({
      id: 'route_planner_destination',
      defaultMessage: 'Destination',
    })

  useEffect(() => {
    if (!isSearchActive && inputProps.value !== defaultValue) {
      setValue(defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchActive])

  const currentContainerRef = containerRef.current
  useEffect(() => {
    if (isSearchActive && currentContainerRef) {
      currentContainerRef.querySelector('input')?.focus()
    }
  }, [currentContainerRef, isSearchActive])

  const isRemovable = useMemo(() => isFullRoute || waypointIndex !== null, [isFullRoute, waypointIndex])

  return (
    <div className={styles['root']} ref={containerRef}>
      {!isLast && !isSorting && !isSearchActive && (
        <div className={styles['divider']}>
          <MoreVertIcon />
        </div>
      )}
      <LocationInput
        {...inputProps}
        hideLabel
        icon={
          isSorting ? (
            <IconVia label="" />
          ) : isStart ? (
            <AdjustIcon />
          ) : isLast ? (
            <DestinationIcon />
          ) : (
            <IconVia label={getLetterFromWaypointIndex(waypointsListIndex - 1)} />
          )
        }
        inputIcon={
          sortableHandleProps && (
            <div
              {...sortableHandleProps}
              className={clsx(styles['drag-icon'], {
                [styles['drag-icon-dragging']]: isSorting,
              })}
              aria-label={intl.formatMessage({
                id: 'route_planner_grab_to_drag_and_sort',
                defaultMessage: 'Grab to drag and sort',
              })}
              tabIndex={-1}
              onKeyDown={(e) => {
                e.stopPropagation()
                sortableHandleProps.onKeyDown && sortableHandleProps.onKeyDown(e)
              }}
              onPointerDown={(e) => {
                e.stopPropagation()
                sortableHandleProps.onPointerDown && sortableHandleProps.onPointerDown(e)
              }}
            >
              <DragHandleIcon />
            </div>
          )
        }
        name={`waypoint-${waypointsListIndex}`}
        label={inputLabel}
        placeholder={inputLabel}
        autoComplete="off"
        onRemove={
          isRemoveVisible && isRemovable
            ? () => {
              if (waypointIndex !== null) {
                dispatch(removeWaypoint(waypointIndex))
              } else {
                onRemoveItem(waypointsListIndex)
              }
              setValue(defaultValue)
            }
            : undefined
        }
        disabledRemove={isRemoveVisible && !isRemovable}
        onFocus={onFocus}
      />
    </div>
  )
}
