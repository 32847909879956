import { RoutePreviewEntity, UserPreviewEntity } from 'shared/data-access-core'
import React from 'react'
import { WEB_APP_PATH_ROUTE_DETAILS, getLoginUrl } from 'shared/util-navigation'
import { RouteEntityTile } from 'shared/feature-routes'
import { useUser } from 'web-app/feature-user'
import { useSessionInfo } from 'web-app/feature-navigation'
import { useNavigate } from 'react-router-dom'

interface RouteEntityTileProps {
  route: RoutePreviewEntity
  creator?: UserPreviewEntity
  imperial?: boolean
  onFavoriteToggle: () => void
  onClick?: (e: React.MouseEvent) => void
}

/**
 * Renders a route tile based on a given route entity (and its optional creator entity).
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const WebAppRouteEntityTile = ({
  route,
  creator,
  imperial,
  onFavoriteToggle,
  onClick,
} : RouteEntityTileProps) => {
  const navigate = useNavigate()
  const [user, isUserLoaded] = useUser()
  const sessionInfo = useSessionInfo()

  const handleHeartButtonClick = async () => {
    if (isUserLoaded && !user) {
      window.location.href = getLoginUrl({
        webAppPath: window.location.pathname,
        ...sessionInfo,
      })
    } else {
      onFavoriteToggle()
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e)
    }
    e.preventDefault()
    navigate(WEB_APP_PATH_ROUTE_DETAILS.replace(':routeId', route.id.toString()))
  }

  return (
    <RouteEntityTile
      route={route}
      creator={creator}
      imperial={imperial}
      onClick={handleClick}
      onHeartButtonClick={handleHeartButtonClick}
    />
  )
}
