import { has } from 'lodash'
import { addApiHeaders, getFromCoreApi } from '../../network'
import { API_PATH_GARMIN } from '../../config'
import { ApiServiceResult } from '../shared/types'
import { ApiService } from '../shared/api-service'
import { getFailureResult, getSuccessResult } from '../shared/helpers'

type IsConnectedErrors = {
  unexpectedError?: true
}

export class GarminApiService extends ApiService {

  /**
   * Check if user has garmin connection.
   */
  async isConnected(userId: number): ApiServiceResult<boolean, IsConnectedErrors> {
    try {
      const res = await getFromCoreApi(API_PATH_GARMIN, {
        headers: await addApiHeaders(),
        params: {
          userId,
        },
        isSilent: true,
      })

      return getSuccessResult(res && has(res, 'token'))
    } catch (e) {
      return getFailureResult({ unexpectedError: true })
    }
  }

}
