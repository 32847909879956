import { Typography } from '@mui/material'
import { Link, RouteTileSkeleton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { getSearchUrl } from 'shared/util-navigation'
import { useSessionInfo } from 'web-app/feature-navigation'
import { WebAppContent } from 'web-app/ui-layout'
import { useUserState } from 'web-app/feature-user'
import { HomeSliceDispatch, routeSelectionCleared, useHomeState, useResultsList } from './state'
import { WebAppRouteEntityTile } from 'web-app/feature-route'
import { useDispatch } from 'react-redux'
import { toggleFavoriteRoute } from './state/thunks'
import { useMemo } from 'react'
import { useMapViewport } from 'web-app/feature-map'

import styles from './discover-routes-section.module.css'

export const DiscoverRoutesSection = () => {
  const dispatch = useDispatch() as HomeSliceDispatch
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()
  const { unitPreference } = useUserState()
  const { center, zoom } = useMapViewport()
  const { isSearching } = useHomeState()
  const resultsList = useResultsList()

  const searchUrl = useMemo(() => getSearchUrl({ center, zoom, ...sessionInfo }), [center, sessionInfo, zoom])

  return (
    <WebAppContent>
      <div className={styles['header']}>
        <Typography variant='h3' component="h2" margin={0}>
          {intl.formatMessage({
            id: 'home_discover_routes_heading',
            defaultMessage: 'Popular routes',
          })}
        </Typography>
        <Link href={searchUrl}>
          {intl.formatMessage({
            id: 'home_discover_routes_cta',
            defaultMessage: 'See all',
          })}
        </Link>
      </div>
      <ul className={styles['routes-list']}>
        {isSearching
          ? (
            <>
              <li><RouteTileSkeleton /></li>
              <li><RouteTileSkeleton /></li>
              <li><RouteTileSkeleton /></li>
            </>
          )
          : resultsList.length
            ? resultsList.map(({ route, creator }) => (
              <li key={route.id}>
                <WebAppRouteEntityTile
                  route={route}
                  creator={creator}
                  onFavoriteToggle={() => dispatch(toggleFavoriteRoute(route.id))}
                  imperial={unitPreference === 'imperial'}
                  onClick={() => dispatch(routeSelectionCleared())}
                />
              </li>
            ))
            : (
              <Typography paragraph color='textSecondary'>
                {intl.formatMessage({
                  id: 'home_discover_routes_no_results',
                  defaultMessage: 'There are no routes in this region. Try zooming out or moving the map.',
                })}
              </Typography>
            )
        }
      </ul>
    </WebAppContent>
  )
}
