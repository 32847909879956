import React, { useMemo } from 'react'
import _uniqueId from 'lodash/uniqueId'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import clsx from 'clsx'

import styles from './Checkbox.module.scss'

export interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  children: React.ReactNode
  name: string
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  controlPosition?: 'start' | 'end'
}

export function Checkbox(props: CheckboxProps) {
  const {
    className,
    name,
    children,
    checked,
    disabled,
    controlPosition = 'start',
    ...inputProps
  } = props

  const id = useMemo(() => _uniqueId(name + '-'), [name])

  return (
    <div className={className}>
      <input
        className={styles['checkbox']}
        type='checkbox'
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        {...inputProps}
      />
      <label
        className={clsx(styles['container'], { [styles['disabled']]: disabled })}
        htmlFor={id}
        role='checkbox'
        aria-checked={checked}
      >
        {controlPosition === 'end' && <div className={styles['children']}>{children}</div>}
        <div className={styles['button']}>
          {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
        </div>
        {controlPosition === 'start' && <div className={styles['children']}>{children}</div>}
      </label>
    </div>
  )
}

export default Checkbox
