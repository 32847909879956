import { useLayoutMediaQueries, useLayoutRefs } from './layout-hooks'

/**
 * Provides `marginTop` to push down elements according to the height of the header that's fixed
 * on top on small viewports.
 */
export const useTopHeaderMargin = (): number | undefined => {
  const { contentHeaderRef } = useLayoutRefs()
  const { isLargeViewport } = useLayoutMediaQueries()

  return !(isLargeViewport && contentHeaderRef.current) ? contentHeaderRef.current?.clientHeight : undefined
}
