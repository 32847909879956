import { locationGeocoded, selectionCanceled } from '../state'
import { RoutePlannerSliceDispatch, StateWithRoutePlannerSlice } from '../types'
import { INTL_SLICE_KEY, StateWithIntlSlice } from 'shared/util-intl'
import { waypointsSelector } from '../selectors'
import { LngLat } from 'shared/util-geo'
import { uniqBy } from 'lodash'
import { reverseGeocode as apiReverseGeocode } from 'shared/data-access-geocoding'

/**
 * Make reverse geocoding request for a location and add display values to it,
 * if it is (still) the selected location or a waypoint at that time.
 */
export function reverseGeocode(location: LngLat, poiName?: string) {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithIntlSlice,
  ) => {
    const state = getState()
    const language = state[INTL_SLICE_KEY].language

    const res = await apiReverseGeocode(location, language)
    if (res.success) {
      dispatch(
        locationGeocoded({
          location: { ...location },
          geocoded: {
            ...res.data,
            poiName,
          },
        }),
      )
    } else {
      dispatch(selectionCanceled())
    }
  }
}

/**
 * Reverse geocode all waypoints without address while respecting Tomtom's request limit (5 req/s).
 */
export function reverseGeocodeWaypoints() {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    const { waypoints } = waypointsSelector(getState())
    const uniqueLngLats = uniqBy(waypoints, w => w && `${w.lng},${w.lat}`)
    for (const waypoint of uniqueLngLats) {
      if (waypoint && !waypoint.address) {
        dispatch(reverseGeocode(waypoint))
        await new Promise((resolve) => {
          setTimeout(resolve, 500)
        })
      }
    }
  }
}
