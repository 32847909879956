import { pushToDataLayer } from './helpers'

export type ModalEventIdType =
  'premium_map_style' |
  'premium_campaign' |
  'error_connection_lost'

/**
 * Push event for a triggered modal.
 */
export function pushModalEvent(id: ModalEventIdType) {
  pushToDataLayer({
    event: 'modal',
    id,
  })
}
