import { useSelector } from 'react-redux'
import {
  undoRedoSelector,
  routePlannerSliceSelector,
  isRoundTripSelector,
  waypointsSelector,
  hasRouteDistanceSelector,
  baseRouteSelector,
  geometryBoundsSelector,
  hasRouteBeenChangedSelector,
  savableRouteSelector,
  finalGeometrySelector,
  controlPointsSelector,
  selectedWaypointSelector,
} from './selectors'

export const useUndoRedo = () => useSelector(undoRedoSelector)

export const useRoutePlannerState = () => useSelector(routePlannerSliceSelector)

export const useWaypoints = () => useSelector(waypointsSelector)

export const useIsRoundtrip = () => useSelector(isRoundTripSelector)

export const useSelectedWaypoint = () => useSelector(selectedWaypointSelector)

export const useHasRouteDistance = () => useSelector(hasRouteDistanceSelector)

export const useBaseRoute = () => useSelector(baseRouteSelector)

export const useFinalGeometry = () => useSelector(finalGeometrySelector)

export const useControlPoints = () => useSelector(controlPointsSelector)

export const useGeometryBounds = () => useSelector(geometryBoundsSelector)

export const useHasRouteBeenChanged = () => useSelector(hasRouteBeenChangedSelector)

export const useSavableRoute = () => useSelector(savableRouteSelector)
