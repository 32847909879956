import { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import { WebAppMedia, useLayoutMediaQueries } from 'web-app/ui-layout'
import { useUser } from 'web-app/feature-user'
import { PrintContext } from './print-context'
import { Pages } from './pages'

import styles from './route-print-media.module.css'

interface PrintPagesProps {
  children: ReactNode
}

const PrintPages = ({
  children,
}: PrintPagesProps) => {
  const printContainer = document.getElementById('print')

  return printContainer ? createPortal((
    <PrintContext.Provider value={{ isInteractivePreview: false }}>
      <div className={styles['print-pages-container']}>{children}</div>
    </PrintContext.Provider>
  ), printContainer) : null
}

export const RoutePrintMedia = () => {
  const { isLargeViewport } = useLayoutMediaQueries()
  const [user, isUserLoaded] = useUser()

  return (
    <>
      {isUserLoaded && user?.isPremium && (
        <PrintPages>
          <Pages />
        </PrintPages>
      )}
      <WebAppMedia>
        <div className={clsx(styles['root'], { [styles['root-large']]: isLargeViewport })}>
          <div className={clsx(styles['pages-container'], {
            [styles['pages-container-large']]: isLargeViewport,
            [styles['pages-container-small']]: !isLargeViewport,
            [styles['pages-container-printable']]: isUserLoaded && user?.isPremium,
          })}>
            <PrintContext.Provider value={{ isInteractivePreview: true }}>
              <Pages />
            </PrintContext.Provider>
          </div>
        </div>
      </WebAppMedia>
    </>
  )
}
