import { RouteCollectionForm, createRouteCollection } from 'shared/data-access-core'
import { ROUTE_SLICE_KEY, RouteSliceDispatch, StateWithRouteSlice, assignCollection } from 'web-app/feature-route'
import { routeAddedToCollection, routeCollectionCreated } from './state'

export const createAndAssignCollection =
  (routeCollectionForm: RouteCollectionForm) =>
    async (dispatch: RouteSliceDispatch, getState: () => StateWithRouteSlice) => {
      const { route, assignedRouteCollectionIds } = getState()[ROUTE_SLICE_KEY]
      if (!route || !assignedRouteCollectionIds) return

      const res = await createRouteCollection(routeCollectionForm)
      if (res.success) {
        const routeCollection = res.data
        dispatch(routeCollectionCreated(routeCollection))
        dispatch(assignCollection(routeCollection.id))
        dispatch(routeAddedToCollection(routeCollection.id))
      }
    }
