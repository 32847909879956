import { AlongTheRouteAttribute, SurfaceCategory } from 'shared/data-access-core'
import { AlongTheRouteDiagram, CategoryGroups } from './along-the-route-diagram'
import { colors } from 'shared/ui-design-system'
import { useMemo } from 'react'
import { useLocale } from 'shared/util-intl'

interface SurfacesDiagramProps {
  surfacesAlongTheRoute: AlongTheRouteAttribute<SurfaceCategory>
  routeDistanceM: number
}

export const SurfacesDiagram = ({
  surfacesAlongTheRoute,
  routeDistanceM,
}: SurfacesDiagramProps) => {
  const { intl } = useLocale()

  const labels = useMemo<Record<SurfaceCategory, string>>(() => ({
    missing: intl.formatMessage({
      id: 'surface_missing_label',
      defaultMessage: 'Undefined',
    }),
    paving_stones: intl.formatMessage({
      id: 'surface_paving_stones_label',
      defaultMessage: 'Paving stones',
    }),
    concrete: intl.formatMessage({
      id: 'surface_concrete_label',
      defaultMessage: 'Concrete',
    }),
    asphalt: intl.formatMessage({
      id: 'surface_asphalt_label',
      defaultMessage: 'Asphalt',
    }),
    paved: intl.formatMessage({
      id: 'surface_paved_label',
      defaultMessage: 'Paved (undefined)',
    }),
    gravel: intl.formatMessage({
      id: 'surface_gravel_label',
      defaultMessage: 'Gravel',
    }),
    unpaved: intl.formatMessage({
      id: 'surface_unpaved_label',
      defaultMessage: 'Unpaved (undefined)',
    }),
    snow: intl.formatMessage({
      id: 'surface_snow_label',
      defaultMessage: 'Snow',
    }),
    metal: intl.formatMessage({
      id: 'surface_metal_label',
      defaultMessage: 'Metal',
    }),
    rock: intl.formatMessage({
      id: 'surface_rock_label',
      defaultMessage: 'Rock',
    }),
    metal_grid: intl.formatMessage({
      id: 'surface_metal_grid_label',
      defaultMessage: 'Metal grid',
    }),
    pebblestone: intl.formatMessage({
      id: 'surface_pebblestone_label',
      defaultMessage: 'Pebblestone',
    }),
    mud: intl.formatMessage({
      id: 'surface_mud_label',
      defaultMessage: 'Mud',
    }),
    wood: intl.formatMessage({
      id: 'surface_wood_label',
      defaultMessage: 'Wood',
    }),
    ground: intl.formatMessage({
      id: 'surface_ground_label',
      defaultMessage: 'Ground',
    }),
    woodchips: intl.formatMessage({
      id: 'surface_woodchips_label',
      defaultMessage: 'Woodchips',
    }),
    cobblestones: intl.formatMessage({
      id: 'surface_cobblestones_label',
      defaultMessage: 'Cobblestones',
    }),
    sand: intl.formatMessage({
      id: 'surface_sand_label',
      defaultMessage: 'Sand',
    }),
    loose_gravel: intl.formatMessage({
      id: 'surface_loose_gravel_label',
      defaultMessage: 'Loose gravel',
    }),
    shells: intl.formatMessage({
      id: 'surface_shells_label',
      defaultMessage: 'Shells',
    }),
  }), [intl])

  const groups = useMemo<CategoryGroups<SurfaceCategory>>(() => [
    {
      categories: [
        'paving_stones',
        'concrete',
        'asphalt',
        'paved',
        'metal',
        'wood',
        'cobblestones',
      ],
      label: intl.formatMessage({
        id: 'surface_group_paved_label',
        defaultMessage: 'Paved',
      }),
    },
    {
      categories: [
        'gravel',
        'unpaved',
        'snow',
        'rock',
        'metal_grid',
        'pebblestone',
        'mud',
        'ground',
        'woodchips',
        'sand',
        'loose_gravel',
        'shells',
      ],
      label: intl.formatMessage({
        id: 'surface_group_unpaved_label',
        defaultMessage: 'Unpaved',
      }),
    },
  ], [intl])

  return (
    <AlongTheRouteDiagram
      alongTheRouteAttribute={surfacesAlongTheRoute}
      colors={colors.surfacesComp}
      labels={labels}
      routeDistanceM={routeDistanceM}
      groups={groups}
    />
  )
}
