import { pushToDataLayer } from './helpers'
import { UserEntity, PremiumPlanPeriod } from 'shared/data-access-core'

interface PushUserDataEventType {
  userExternalId: UserEntity['externalId'] | 'anonymous'
  userPlanPeriod: PremiumPlanPeriod
}

/**
 * Push data about the active user to GTM data layer.
 */
export function pushUserDataEvent({ userExternalId, userPlanPeriod }: PushUserDataEventType) {
  pushToDataLayer({
    event: 'userData',
    user: userExternalId,
    plan: userPlanPeriod,
  })
}

/**
 * Push data about a route to GTM data layer, which app is currently based on.
 */
export function pushRouteDataEvent(routeExternalId: string) {
  pushToDataLayer({
    event: 'routeData',
    route: routeExternalId,
  })
}
