import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button, ButtonGroup, Uploader, UploaderFileRejectionsType, useMessages } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { logError } from 'web-app/utils-error-handling'
import { MAX_IMAGES, MAX_IMAGE_FILE_SIZE_MB } from '../../config'
import { useRouteImageUploadNotifications } from '../../hooks/notifications'
import { RouteImageEntity, addRouteImage, deleteRouteImage, getRouteImages } from 'shared/data-access-core'
import { editingDone } from '../../state'
import { useDispatch } from 'react-redux'
import { ImagesGridSkeleton } from './images-grid'
import { useRoute } from 'web-app/feature-route'

interface RouteImagesUploaderProps {
  /** Called when route images are outdated and need to be reloaded. */
  onImagesUpdated: () => void
}

export const RouteImagesUploader = ({ onImagesUpdated }: RouteImagesUploaderProps) => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const { cancelLabel, saveLabel } = useMessages()
  const route = useRoute()
  const notifications = useRouteImageUploadNotifications()

  const [currentImages, setCurrentImages] = useState<RouteImageEntity[] | undefined>()
  const [selectedImages, setSelectedImages] = useState<File[]>([])
  const [removedImages, setRemovedImages] = useState<RouteImageEntity[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const routeId = route?.id
  useEffect(() => {
    if (routeId !== undefined) {
      getRouteImages(routeId).then((res) => {
        if (res.success) {
          setCurrentImages(res.data)
        }
      })
    }
  }, [routeId])

  /**
   * Merges new images with existing ones and trims if necessary.
   */
  const getLimitedNumberOfImages = (currentImages: File[], newImages: File[]): [File[], boolean] => {
    const all = [...currentImages, ...newImages]
    const isLimitReached = all.length > MAX_IMAGES
    const images = isLimitReached ? all.slice(0, MAX_IMAGES) : all

    return [images, isLimitReached]
  }

  /**
   * Store selected images in state.
   * This will render their preview and only be submitted to backend when route is saved.
   */
  const onRouteImageDrop = (files: File[]) => {
    setSelectedImages((current: File[]) => {
      const [images, isMaxNumberOfImagesReached] = getLimitedNumberOfImages(current, files)
      if (isMaxNumberOfImagesReached) {
        notifications.showMaxNumberImagesErrorNotification(MAX_IMAGES)
      }
      return images
    })
  }

  /**
   * Show errors when invalid images are selected.
   */
  const onDropRejected = (rejectedImages: UploaderFileRejectionsType): void => {
    try {
      // Go through allowed number of images with errors
      rejectedImages.slice(0, MAX_IMAGES).map((rejection) => {
        const code = rejection.errors[0].code
        const fileName = rejection.file.name
        if (code === 'file-invalid-type') {
          notifications.showFileTypeErrorNotification(fileName)
        }
        if (code === 'file-too-large') {
          notifications.showFileSizeErrorNotification(fileName, MAX_IMAGE_FILE_SIZE_MB)
        }
        return rejection
      })
    } catch (e) {
      logError('Problem showing errors of rejected images', e, { rejectedImages })
    }
  }

  /**
   * Clear single image from currently selected images.
   */
  const onImagePreviewClear = (fileIndex: number) => {
    setSelectedImages((current) => current.filter((file, index) => index !== fileIndex))
  }

  /**
   * Clear single image from existing route images.
   */
  const onRemoteImageClear = (url: string) => {
    setCurrentImages(
      (current) =>
        current &&
        current.filter((img) => {
          if (img.url === url) {
            setRemovedImages((current) => [...current, img])
            return false
          }
          return true
        }),
    )
  }

  const handleCancel = () => {
    dispatch(editingDone())
    setSelectedImages([])
  }

  /**
   * Upload all added and remove all deleted images.
   */
  const handleSubmit = async () => {
    if (!route) return
    setIsUploading(true)
    try {
      // Upload images in parallel
      const requests: Promise<{ success: boolean }>[] = []
      for (const key in selectedImages) {
        requests.push(addRouteImage(route.id, selectedImages[key]))
      }
      for (const key in removedImages) {
        requests.push(deleteRouteImage(route.id, removedImages[key].id))
      }

      // Check if all uploads were successful
      const results = await Promise.all(requests)
      for (const result of results) {
        if (!result.success) throw Error
      }

      onImagesUpdated()
    } catch (e) {
      logError('Problem updating route images', e, { routeId: route.id })
      notifications.showUploadErrorNotification()
    }
    setIsUploading(false)
  }

  const imagesDropzoneProps = {
    onDrop: onRouteImageDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxSize: MAX_IMAGE_FILE_SIZE_MB * 1024 * 1024,
    multiple: true,
    onDropRejected,
  }

  return isUploading ? (
    <ImagesGridSkeleton />
  ) : (
    <Uploader
      label={intl.formatMessage({
        id: 'route_images_label',
        defaultMessage: 'Photos',
      })}
      hideLabel
      loading={!currentImages}
      dropzoneOptions={imagesDropzoneProps}
      previewFiles={selectedImages}
      onPreviewFileClear={onImagePreviewClear}
      remoteFiles={currentImages?.map((img) => ({ type: 'image', url: img.url }))}
      onRemoteFileClear={onRemoteImageClear}
      buttonIcon={<AddIcon />}
      inputDataTestId="save-route-image-file"
      messages={{
        button: intl.formatMessage({
          id: 'route_images_upload_button',
          defaultMessage: 'Add photos',
        }),
        cancel: intl.formatMessage({
          id: 'route_images_cancel_button',
          defaultMessage: 'Cancel',
        }),
        dropFilesHere: intl.formatMessage({
          id: 'route_images_drop_label',
          defaultMessage: 'Drop your pictures here...',
        }),
      }}
      extraActions={
        (!!selectedImages.length || !!currentImages?.length || !!removedImages.length) && (
          <ButtonGroup>
            <Button variant="secondary" onClick={handleCancel}>
              {cancelLabel}
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              {saveLabel}
            </Button>
          </ButtonGroup>
        )
      }
    />
  )
}
