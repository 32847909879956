import { useLocale } from 'shared/util-intl'
import { Waypoint, SavableRouteState, reset } from '../../state'
import { useCallback } from 'react'
import { RouteForm } from 'shared/data-access-core'
import { RouteSliceDispatch, saveRoute } from 'web-app/feature-route'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { WEB_APP_PATH_ROUTE_DETAILS } from 'shared/util-navigation'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { BaseRouteAction } from './base-route-action'

interface CreateRouteActionProps {
  primary?: boolean
}

export const CreateRouteAction = (props: CreateRouteActionProps) => {
  const navigate = useNavigate()
  const { intl } = useLocale()
  const showCommonErrorNotification = useCommonErrorNotification()
  const dispatch = useDispatch() as RouteSliceDispatch

  const showRouteSaveErrorNotification = useCallback(() => {
    showCommonErrorNotification(
      intl.formatMessage({
        id: 'route_planner_create_route_action_error',
        defaultMessage: 'The route could not be saved.',
      }),
    )
  }, [intl, showCommonErrorNotification])

  const formatDefaultTitle = useCallback(
    (start: Waypoint, end: Waypoint): string => {
      if (start.localName && end.localName && start.localName !== end.localName) {
        return intl.formatMessage(
          {
            id: 'route_planner_save_route_title',
            defaultMessage: '{start} to {end}',
          },
          {
            start: start.localName,
            end: end.localName,
          },
        )
      }
      if (end.address) {
        return intl.formatMessage(
          {
            id: 'route_planner_save_route_title_end_fallback',
            defaultMessage: 'Route to {end}',
          },
          {
            end: end.poiName || end.address,
          },
        )
      }
      return intl.formatMessage({
        id: 'route_planner_save_route_title_fallback',
        defaultMessage: 'Untitled route',
      })
    },
    [intl],
  )

  const handleRouteSubmit = useCallback(
    async ({ geometry, distanceInM, durationInS, waypoints, start, end, controlPointIndexes }: SavableRouteState) => {
      try {
        const routeForm: RouteForm = {
          geometry,
          title: formatDefaultTitle(start, end),
          appVersion: 'Create',
          distanceInM,
          durationInS,
          waypoints,
          controlPointIndexes,
        }
        const { route } = await dispatch(saveRoute(routeForm)).unwrap()
        if (route) {
          dispatch(reset())
          navigate(WEB_APP_PATH_ROUTE_DETAILS.replace(':routeId', route.id.toString()))
        }
      } catch {
        showRouteSaveErrorNotification()
      }
    },
    [dispatch, formatDefaultTitle, navigate, showRouteSaveErrorNotification],
  )

  return (
    <BaseRouteAction {...props} onRouteSubmit={handleRouteSubmit}>
      {intl.formatMessage({
        id: 'route_planner_create_route_action_label',
        defaultMessage: 'Save route',
      })}
    </BaseRouteAction>
  )
}
