import { SubscriptionPlanEntity } from '../entities'

export type SubscriptionPlanResponse = {
  code: string
  price: string
  price_higher: string | null
  discount_percentage: string | null
  trial_period: number
  interval_name: {
    en: string | null
    de: string | null
  }
  name: {
    en: string | null
    de: string | null
  }
}

export const dummySubscriptionPlansResponse: SubscriptionPlanResponse[] = [
  {
    price: '9',
    price_higher: null,
    discount_percentage: null,
    trial_period: 3,
    interval_name: {
      en: 'monthly',
      de: null,
    },
    name: {
      en: 'Bikemap Premium Membership Monthly',
      de: null,
    },
    code: 'pro-monthly-1',
  },
  {
    price: '39',
    price_higher: '108',
    discount_percentage: '64',
    trial_period: 3,
    interval_name: {
      en: 'yearly',
      de: null,
    },
    name: {
      en: 'Bikemap Premium Membership Yearly',
      de: null,
    },
    code: 'pro-yearly-1',
  },
  {
    price: '99',
    price_higher: null,
    discount_percentage: null,
    trial_period: 0,
    interval_name: {
      en: '3-years',
      de: '3-jahre',
    },
    name: {
      en: 'Bikemap Premium Membership for 3 Years',
      de: 'Bikemap Premium Mitgliedschaft für 3 Jahre',
    },
    code: 'pro-3-years',
  },
]

export function convertToSubscriptionPlanEntity(res: SubscriptionPlanResponse): SubscriptionPlanEntity {
  return {
    code: res.code,
    price: Number.parseInt(res.price),
    trialDays: res.trial_period,
  }
}
