import { ReactNode, useEffect, useRef } from 'react'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import { useLocale } from 'shared/util-intl'
import { useCreator, useRoute } from 'web-app/feature-route'
import { BikemapLogo } from 'shared/ui-components'
import { useRoutePrintState } from '../state'

import styles from './base-page.module.scss'

interface BasePageProps {
  children: ReactNode
  title: string
  number: number
}

export const BasePage = ({ children, title, number }: BasePageProps) => {
  const { intl } = useLocale()
  const route = useRoute()
  const creator = useCreator()
  const { activePage } = useRoutePrintState()

  const pageRootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (activePage === number) {
      pageRootRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [activePage, number])

  return (
    <div className={styles['root']} ref={pageRootRef}>
      <div className={styles['page-header']}>
        <h2 className={styles['title']}>{title}</h2>
        <p className={styles['location']}>
          <FmdGoodIcon
            className={styles['secondary-info-icon']}
            style={{ fontSize: '1.3em', verticalAlign: '-0.2em' }}
          />
          &nbsp;{route?.location}
        </p>
        <BikemapLogo size="0.5em" />
      </div>
      <div className={styles['page-content']}>{children}</div>
      <div className={styles['page-footer']}>
        <p className={styles['page-number']}>
          {intl.formatMessage(
            {
              id: 'route_print_page_number',
              defaultMessage: 'Page {number}',
            },
            { number },
          )}
        </p>
        {creator && (
          <p className={styles['creator']}>
            {intl.formatMessage(
              {
                id: 'route_print_page_created_by',
                defaultMessage: 'Route created by {creator}',
              },
              {
                creator: creator.name,
              },
            )}
          </p>
        )}
        {route && <p>web.bikemap.net/r/{route.id}</p>}
      </div>
    </div>
  )
}
