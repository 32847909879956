import { RefObject, useCallback, useEffect } from 'react'
import { useMap } from 'react-map-gl/maplibre'

/**
 * There is a Chrome issue where elements on the map are sometimes rendered blurry. This is a workaround to fix this.
 * @see https://github.com/mapbox/mapbox-gl-js/issues/4906#issuecomment-1620166374
 */
export const useFixBlurryRendering = (mapId: string, elementRef: RefObject<HTMLElement>) => {
  const maps = useMap()
  const map = maps[mapId]

  const fixBlurryRendering = useCallback(() => {
    if (elementRef.current) {
      elementRef.current.style.display = 'none'
      elementRef.current.style.display = 'block'
    }
  }, [elementRef])

  fixBlurryRendering()

  useEffect(() => {
    if (map) {
      map.on('zoomend', fixBlurryRendering)
    }
    return () => {
      if (map) {
        map.off('zoomend', fixBlurryRendering)
      }
    }
  }, [fixBlurryRendering, map])
}
