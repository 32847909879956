import { useEffect, useState, useCallback } from 'react'
import { MapLibreEvent, MapDataEvent } from 'maplibre-gl'
import { useMap } from 'react-map-gl/maplibre'
import { MAP_ID } from './settings'

// Sometimes contains multiple attributions in one item
type Attributions = string[]

const filterAttributions = (attributions: Attributions): Attributions => {
  // remove any entries that are whitespace
  const attrib = attributions.filter(e => String(e).trim())

  // remove any entries that are substrings of another entry.
  // first sort by length so that substrings come first
  attrib.sort((a, b) => a.length - b.length)

  return attrib.filter((a, i) => {
    for (let j = i + 1; j < attrib.length; j++) {
      if (attrib[j].indexOf(a) >= 0) {
        return false
      }
    }
    return true
  })
}

/**
 * Based on maplibre AttributionControl.
 * @link https://maplibre.org/maplibre-gl-js-docs/api/markers/#attributioncontrol
 * @link https://github.com/maplibre/maplibre-gl-js/blob/main/src/ui/control/attribution_control.ts
 */
export const useAttributions = (mapId: string = MAP_ID): Attributions => {
  const maps = useMap()
  const map = maps[mapId]
  const currentMap = map?.getMap()

  const [attributions, setAttributions] = useState<Attributions>([])

  const updateAttributions = useCallback((e: MapLibreEvent & MapDataEvent) => {
    const style = e?.target?.style
    if (!style) {
      return
    }

    const sourceAttributions: Attributions = []
    const sourceCaches = style.sourceCaches
    for (const id in sourceCaches) {
      const sourceCache = sourceCaches[id]
      if (sourceCache.used || sourceCache.usedForTerrain) {
        const source = sourceCache.getSource()
        if (source.attribution && sourceAttributions.indexOf(source.attribution) < 0) {
          sourceAttributions.push(source.attribution)
        }
      }
    }

    setAttributions(filterAttributions(sourceAttributions))
  }, [])

  const updateData = useCallback((e: MapLibreEvent & MapDataEvent) => {
    if (e && (
      e.sourceDataType === 'metadata' ||
      e.sourceDataType === 'visibility' ||
      e.dataType === 'style' ||
      e.type === 'terrain'
    )) {
      updateAttributions(e)
    }
  }, [updateAttributions])

  useEffect(() => {
    if (currentMap) {
      currentMap.on('styledata', updateData)
      currentMap.on('sourcedata', updateData)
      currentMap.on('terrain', updateData)
    }
    return () => {
      currentMap?.off('styledata', updateData)
      currentMap?.off('sourcedata', updateData)
      currentMap?.off('terrain', updateData)
    }
  }, [currentMap, updateData])

  return attributions
}
