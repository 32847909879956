import { WebAppFooter, WebAppLayout } from 'web-app/ui-layout'
import { MinimalFooter, UserNavigation } from 'web-app/feature-navigation'
import { useRouteById, useRouteTitle } from 'web-app/feature-route'
import { RoutePrintMedia } from './route-print-media'
import { RoutePrintControls } from './route-print-controls'
import { RoutePrintMainContent } from './route-print-main-content'
import { useEffect } from 'react'
import { initializedForRouteId, useRoutePrintState } from './state'
import { useDispatch } from 'react-redux'

interface RoutePrintProps {
  routeId: number
}

export const RoutePrint = ({
  routeId,
}: RoutePrintProps) => {
  const dispatch = useDispatch()
  useRouteById(routeId)
  useRouteTitle()
  const { forRouteId } = useRoutePrintState()

  useEffect(() => {
    dispatch(initializedForRouteId(routeId))
  }, [dispatch, forRouteId, routeId])

  return (
    <WebAppLayout UserNavigation={UserNavigation}>
      <RoutePrintMainContent routeId={routeId} />
      <RoutePrintMedia />
      <RoutePrintControls />
      <WebAppFooter>
        <MinimalFooter colored />
      </WebAppFooter>
    </WebAppLayout>
  )
}
