import { createSlice } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { assignCollection, fetchRoute, saveRoute, saveRouteChanges, unassignCollection } from './thunks'
import { ROUTE_SLICE_KEY, RouteState } from './types'

export const initialState: RouteState = {
  isRouteLoaded: false,
  isRouteUnavailable: false,
  route: undefined,
  creator: undefined,
  assignedRouteCollectionIds: undefined,
}

const slice = createSlice({
  name: ROUTE_SLICE_KEY,
  initialState,
  reducers: {
    routeAddedToFavorites(state) {
      if (state.isRouteLoaded && state.route) {
        state.route.isFavorite = true
        state.route.favoriteCount++
      }
    },
    routeRemovedFromFavorites(state) {
      if (state.isRouteLoaded && state.route) {
        state.route.isFavorite = false
        state.route.favoriteCount--
      }
    },
    reset() {
      return initialState
    },
  },
  extraReducers(builder) {
    builder.addCase(saveRoute.pending, (state) => {
      // Keep route to not break view state (eg. saving in planner)
      state.isRouteLoaded = false
      state.isRouteUnavailable = false
    })
    builder.addCase(saveRoute.fulfilled, (state, action) => {
      state.isRouteLoaded = true
      state.route = action.payload.route
      state.creator = action.payload.creator
    })
    builder.addCase(saveRoute.rejected, (state) => {
      state.isRouteLoaded = true
      state.route = undefined
      state.creator = undefined
    })

    builder.addCase(fetchRoute.pending, (state, action) => {
      state.isRouteLoaded = false
      if (state.route?.id !== action.meta.arg) {
        state.route = undefined
        state.creator = undefined
        state.isRouteUnavailable = false
      }
    })
    builder.addCase(fetchRoute.fulfilled, (state, action) => {
      state.isRouteLoaded = true
      state.route = action.payload.route
      state.creator = action.payload.creator
      state.assignedRouteCollectionIds = action.payload.collectionIds
    })
    builder.addCase(fetchRoute.rejected, (state) => {
      state.isRouteLoaded = true
      state.route = undefined
      state.creator = undefined
      state.isRouteUnavailable = true
    })

    builder.addCase(saveRouteChanges.pending, (state) => {
      state.isRouteLoaded = false
    })
    builder.addCase(saveRouteChanges.fulfilled, (state, action) => {
      state.isRouteLoaded = true
      state.route = action.payload
    })
    builder.addCase(saveRouteChanges.rejected, (state) => {
      state.isRouteLoaded = true
    })

    builder.addCase(assignCollection.pending, (state, action) => {
      if (state.assignedRouteCollectionIds) {
        state.assignedRouteCollectionIds.push(action.meta.arg)
      }
    })
    builder.addCase(assignCollection.rejected, (state, action) => {
      if (state.assignedRouteCollectionIds) {
        state.assignedRouteCollectionIds = state.assignedRouteCollectionIds.filter(
          (id) => id !== action.meta.arg
        )
      }
    })

    builder.addCase(unassignCollection.pending, (state, action) => {
      if (state.assignedRouteCollectionIds) {
        state.assignedRouteCollectionIds = state.assignedRouteCollectionIds.filter(
          (id) => id !== action.meta.arg
        )
      }
    })
    builder.addCase(unassignCollection.rejected, (state, action) => {
      if (state.assignedRouteCollectionIds) {
        state.assignedRouteCollectionIds.push(action.meta.arg)
      }
    })
  },
})

export const {
  routeAddedToFavorites,
  routeRemovedFromFavorites,
  reset,
} = slice.actions

const persistConfig: PersistConfig<RouteState> = {
  key: ROUTE_SLICE_KEY,
  storage,
  whitelist: [
    'route',
    'creator',
    'assignedRouteCollectionIds',
  ],
}

export const routeReducer = persistReducer(persistConfig, slice.reducer)
