import { LineString, Position } from 'geojson'
import { LngLat, MapRoute } from 'shared/ui-map'
import { useMemo } from 'react'
import { positionToLngLat } from 'shared/util-geo'

interface MapRoutesProps {
  activeGeometry?: LineString | null
  inactiveGeometry?: LineString | null
}

export const MapRoutes = ({ activeGeometry, inactiveGeometry }: MapRoutesProps) => {
  const waypoints: [LngLat, LngLat] | null = useMemo(() => {
    const coordinates = activeGeometry?.coordinates
    if (!(coordinates && coordinates.length > 1)) return null
    const start = positionToLngLat(coordinates[0] as Position)
    const end = positionToLngLat(coordinates[coordinates?.length - 1] as Position)
    return [start, end]
  }, [activeGeometry?.coordinates])

  return activeGeometry && waypoints && (
    <MapRoute
      id="route-import-route"
      geometry={activeGeometry}
      waypoints={waypoints}
      inactiveGeometry={inactiveGeometry || undefined}
    />
  )
}
