import { MapDiscoverRoutes } from './map-discover-routes'
import { MapLocationSelection } from './map-location-selection'

export const HomeMapFeatures = () => {
  return (
    <>
      <MapDiscoverRoutes />
      <MapLocationSelection />
    </>
  )
}
