import { Button, ButtonGroup, Dialog } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useBaseRoute } from '../state'
import { useRoutePlannerCancel } from './use-route-planner-cancel'
import { useIsOwnRoute } from 'web-app/feature-route'
import { CopyRouteAction, CreateRouteAction, UpdateRouteAction } from '../components/actions'

export interface RoutePlannerCancelDialogProps {
  open: boolean
  onClose: () => void
}

export const RoutePlannerCancelDialog = ({ open, onClose }: RoutePlannerCancelDialogProps) => {
  const { intl } = useLocale()
  const isOwnRoute = useIsOwnRoute()
  const baseRoute = useBaseRoute()
  const onCancel = useRoutePlannerCancel()

  return (
    <Dialog
      title={intl.formatMessage({
        id: 'route_planner_cancel_dialog_title',
        defaultMessage: 'Do you really want to stop planning?',
      })}
      text={
        baseRoute
          ? intl.formatMessage({
            id: 'route_planner_cancel_dialog_editing_text',
            defaultMessage: 'You will lose the changes you made so far. Would you like to save them?',
          })
          : intl.formatMessage({
            id: 'route_planner_cancel_dialog_text',
            defaultMessage:
                'You will lose the route you created so far. Would you like to save it instead, so you can continue later?',
          })
      }
      buttons={
        <ButtonGroup stack>
          {!baseRoute && <CreateRouteAction />}
          {baseRoute && isOwnRoute && <UpdateRouteAction />}
          {baseRoute && !isOwnRoute && <CopyRouteAction />}
          <Button variant="danger-primary" onClick={onCancel} data-testid="cancel">
            {baseRoute
              ? intl.formatMessage({
                id: 'route_planner_cancel_dialog_editing_cancel',
                defaultMessage: 'Dismiss changes',
              })
              : intl.formatMessage({
                id: 'route_planner_cancel_dialog_cancel',
                defaultMessage: 'Dismiss planned route',
              })}
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
      open={open}
    />
  )
}
