import { useLocale } from 'shared/util-intl'
import { NavigationLinkProps } from './types'
import { Button, RouteCollectionIcon } from 'shared/ui-components'
import { getUserProfileUrl } from 'shared/util-navigation'
import { useSessionInfo } from '../hooks'
import { UserEntity } from 'shared/data-access-core'

interface MyRoutesLinkProps extends NavigationLinkProps {
  user: UserEntity
}

export const MyRoutesLink = ({ primary, compact, user }: MyRoutesLinkProps) => {
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()

  return (
    <Button
      block
      variant={primary ? 'primary' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      href={getUserProfileUrl({
        ...sessionInfo,
        slug: user.slug,
      })}
      icon={<RouteCollectionIcon />}
    >
      {intl.formatMessage({
        id: 'my_routes_link_label',
        defaultMessage: 'My routes',
      })}
    </Button>
  )
}
