import { useLocale, useTitle } from 'shared/util-intl'
import { RoutePlanner } from 'web-app/view-route-planner'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

const WAYPOINTS_URL_PARAM = 'waypoints'

const RoutePlannerView = () => {
  const params = useParams()
  const location = useLocation()
  const [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const { intl } = useLocale()

  const routeId = params['routeId'] ? Number.parseInt(params['routeId']) : undefined

  const waypoints = URLSearchParams.get(WAYPOINTS_URL_PARAM) || undefined
  if (waypoints) {
    URLSearchParams.delete(WAYPOINTS_URL_PARAM)
    SetURLSearchParams(URLSearchParams)
  }

  useTitle(
    intl.formatMessage({
      id: 'route_planner_document_title',
      defaultMessage: 'Plan your cycling route - Bikemap',
    }),
  )

  const { origin, destination } = location.state || {}
  const initializationState = origin || destination
    ? { origin, destination }
    : undefined

  return <RoutePlanner routeId={routeId} waypoints={waypoints} initializationState={initializationState} />
}

export default RoutePlannerView
