import { RefObject, useCallback, useRef, useState } from 'react'
import { GeocodedWaypoint, Waypoint, useWaypoints } from '../../state'
import { ResultsLargeScreen } from './results-large-screen'
import { ResultsSmallScreen } from './results-small-screen'
import { SearchSuggestions } from './search-suggestions'
import { WaypointLocationInput } from './waypoint-location-input'
import { SortableHandleProps } from 'shared/ui-components'
import { useLayoutMediaQueries } from 'web-app/ui-layout'
import { useLocale } from 'shared/util-intl'
import { useMapState } from 'web-app/feature-map'
import { positionToLngLat } from 'shared/util-geo'
import { searchForPlaces } from 'shared/data-access-geocoding'

interface WaypointsListItemProps {
  waypointsListIndex: number
  waypointIndex: number | null
  beforeIndex: number | null
  isLast: boolean
  item: Waypoint | null
  isSearchActive: boolean
  isSorting: boolean
  sortableHandleProps?: SortableHandleProps
  backgroundColor: string
  onSearchClose: () => void
  onInsertedItemObsolete: (waypointsListIndex: number, willTurnIntoWaypoint: boolean) => void
  onFocus: (waypointsListIndex: number, ref: RefObject<HTMLDivElement>) => void
}

export const WaypointsListItem = ({
  waypointsListIndex,
  waypointIndex,
  beforeIndex,
  isLast,
  item,
  isSearchActive,
  isSorting,
  sortableHandleProps,
  backgroundColor,
  onSearchClose,
  onInsertedItemObsolete,
  onFocus,
}: WaypointsListItemProps) => {
  const { language } = useLocale()
  const { viewport } = useMapState()
  const { start, end } = useWaypoints()
  const { isLargeViewport, isFlatViewport } = useLayoutMediaQueries()
  const isFullScreenOverlay = !isLargeViewport || isFlatViewport

  /** Search results of the search waypoint waypoints list item */
  const [searchResults, setSearchResults] = useState<GeocodedWaypoint[]>([])

  const ref = useRef<HTMLDivElement>(null)

  const defaultValue = item
    ? item.address
      ? item.poiName || item.address
      : `${item.lng.toPrecision(7)}, ${item.lat.toPrecision(7)}`
    : ''

  const handleSearch = useCallback(
    (val: string) => {
      onFocus(waypointsListIndex, ref)
      if (val.length >= 3) {
        searchForPlaces(val, language, positionToLngLat(viewport.center)).then((res) => {
          if (res.success) {
            const suggestions: GeocodedWaypoint[] = res.data.map((result) => {
              const { position, ...strings } = result
              return { ...position, ...strings }
            })
            setSearchResults(suggestions)
          }
        })
      }
    },
    [language, onFocus, viewport.center, waypointsListIndex],
  )

  const suggestionsList = (
    <SearchSuggestions
      waypointsListIndex={waypointsListIndex}
      waypointIndex={waypointIndex}
      beforeIndex={beforeIndex}
      isLast={isLast}
      searchResults={searchResults}
      afterSelect={onSearchClose}
      onInsert={() => onInsertedItemObsolete(waypointsListIndex, true)}
    />
  )

  return (
    <div style={{ paddingTop: '0.5rem' }} ref={ref}>
      <WaypointLocationInput
        waypointsListIndex={waypointsListIndex}
        waypointIndex={waypointIndex}
        isLast={isLast}
        isSearchActive={isSearchActive}
        isSorting={isSorting}
        isRemoveVisible={!!(start || end)}
        sortableHandleProps={sortableHandleProps}
        defaultValue={defaultValue}
        onFocus={() => onFocus(waypointsListIndex, ref)}
        onSearch={handleSearch}
        onRemoveItem={() => onInsertedItemObsolete(waypointsListIndex, false)}
      />
      {isFullScreenOverlay ? (
        <ResultsSmallScreen
          open={isSearchActive}
          defaultValue={defaultValue}
          onSearch={handleSearch}
          onClose={onSearchClose}
        >
          {suggestionsList}
        </ResultsSmallScreen>
      ) : (
        <ResultsLargeScreen open={isSearchActive} backgroundColor={backgroundColor}>
          {suggestionsList}
        </ResultsLargeScreen>
      )}
    </div>
  )
}
