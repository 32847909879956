import React, { ForwardedRef, forwardRef } from 'react'
import styles from './LocationListHolder.module.scss'

export interface LocationListHolderProps {
  children: React.ReactNode
}

export const LocationListHolder = forwardRef((props: LocationListHolderProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div className={styles['holder']} ref={ref}>{props.children}</div>
))
