import { useCallback, useEffect } from 'react'
import { MapRef } from 'react-map-gl/maplibre'

const HEATMAP_LAYER_PREFIX = 'bm-heatmap-global-'

export const useGlobalHeatmapLayer = (
  map?: MapRef,
  isGlobalHeatmapEnabled?: boolean,
  onGlobalHeatmapSupportDetection?: (isSupported: boolean) => void
) => {

  const updateGlobalHeatmapLayer = useCallback(() => {
    if (map?.isStyleLoaded()) {
      let isGlobalHeatmapSupported = false
      const visibilityValue = isGlobalHeatmapEnabled ? 'visible' : 'none'
      for (const layer of map.getStyle().layers) {
        if (layer.id.startsWith(HEATMAP_LAYER_PREFIX) && layer.type === 'line') {
          isGlobalHeatmapSupported = true
          if (layer.layout?.visibility !== visibilityValue) {
            map.getMap().setLayoutProperty(layer.id, 'visibility', visibilityValue)
          }
        }
      }
      onGlobalHeatmapSupportDetection && onGlobalHeatmapSupportDetection(isGlobalHeatmapSupported)
    }
  }, [isGlobalHeatmapEnabled, map, onGlobalHeatmapSupportDetection])

  useEffect(updateGlobalHeatmapLayer, [updateGlobalHeatmapLayer])

  return updateGlobalHeatmapLayer

}
