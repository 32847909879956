import { useLocale } from 'shared/util-intl'

export const useCommonPrivacyLabels = () => {
  const { intl } = useLocale()

  return {
    privateLabel: intl.formatMessage({
      id: 'privacy_common_label_private',
      defaultMessage: 'Private',
    }),
    publicLabel: intl.formatMessage({
      id: 'privacy_common_label_public',
      defaultMessage: 'Public',
    }),
  }
}
