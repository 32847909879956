import { CurrencySymbol } from './currency-symbol'

interface DiscountedPriceProps {
  price: number
  higherPrice?: number
  currencySymbol?: string
}

export const DiscountedPrice = ({
  price,
  higherPrice,
  currencySymbol,
}: DiscountedPriceProps) => (
  <>
    {higherPrice && (
      <s>
        {currencySymbol && (
          <CurrencySymbol>
            {currencySymbol}
          </CurrencySymbol>
        )}
        {higherPrice}
      </s>
    )}
    {' '}
    <strong>
      {currencySymbol && (
        <CurrencySymbol>
          {currencySymbol}
        </CurrencySymbol>
      )}
      {price}
    </strong>
  </>
)
