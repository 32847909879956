import { useLocale, useTitle } from 'shared/util-intl'
import { useRouteState } from './state'

/**
 * Set the page title depending on loading and availability status of the main route.
 */
export const useRouteTitle = () => {
  const { intl } = useLocale()
  const { isRouteUnavailable, route, isRouteLoaded } = useRouteState()

  useTitle(
    isRouteUnavailable
      ? intl.formatMessage({
        id: 'route_details_document_title_unavailable',
        defaultMessage: 'Route not available - Bikemap',
      })
      : isRouteLoaded && route?.title
        ? intl.formatMessage(
          {
            id: 'route_details_document_title_route',
            defaultMessage: '{routeTitle} - Bikemap',
          },
          {
            routeTitle: route.title,
          },
        )
        : intl.formatMessage({
          id: 'route_details_document_title_loading',
          defaultMessage: 'Loading route - Bikemap',
        }),
  )
}
