import { Typography } from '@mui/material'
import { Button, ButtonGroup, DeletingIndicator, useMessages } from 'shared/ui-components'
import { RoutePoiEntity, deleteRoutePoi } from 'shared/data-access-core'
import { useRoutePois } from '../route-pois-context'
import { useLocale } from 'shared/util-intl'
import { useState } from 'react'

interface RoutePoiDeletingProps {
  routePoi: RoutePoiEntity
  onCancel: () => void
  onError: (message: string) => void
}

export const RoutePoiDeleting = ({ routePoi, onCancel, onError }: RoutePoiDeletingProps) => {
  const { intl } = useLocale()
  const { cancelLabel, deleteLabel } = useMessages()
  const { onRoutePoiRemoved } = useRoutePois()

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const handleDelete = async () => {
    setIsDeleting(true)
    const res = await deleteRoutePoi(routePoi.id)
    if (res.success) {
      onRoutePoiRemoved(routePoi.id)
    } else {
      onError(
        intl.formatMessage({
          id: 'route_poi_deleting_error',
          defaultMessage: 'The route highlight could not be deleted.',
        }),
      )
    }
    setIsDeleting(false)
  }

  return isDeleting ? (
    <DeletingIndicator
      message={intl.formatMessage({
        id: 'route_poi_deleting_progress',
        defaultMessage: 'Deleting route highlight...',
      })}
    />
  ) : (
    <>
      <Typography variant="h3" component="h2">
        {intl.formatMessage({
          id: 'route_poi_deleting_heading',
          defaultMessage: 'Are you sure?',
        })}
      </Typography>
      <Typography paragraph marginBottom="1rem">
        {intl.formatMessage({
          id: 'route_poi_deleting_info',
          defaultMessage: 'This route highlight would be permanently deleted.',
        })}
      </Typography>
      <ButtonGroup>
        <Button variant="secondary" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button variant="danger-primary" onClick={() => handleDelete()}>
          {deleteLabel}
        </Button>
      </ButtonGroup>
    </>
  )
}
