import { ElevationCurveProvider } from 'shared/feature-elevation-curve'
import { UserNavigation } from 'web-app/feature-navigation'
import { useUserState } from 'web-app/feature-user'
import { WebAppLayout } from 'web-app/ui-layout'
import { useFinalGeometry, useRoutePlannerState } from '../state'
import { useRoutingErrorNotifications } from './use-routing-error-notifications'
import { RoutePlannerMap } from '../map'
import { RoutePlannerMainContent } from '../content'

export const RoutePlannerBase = () => {
  const { unitPreference } = useUserState()
  const { distance } = useRoutePlannerState()
  const finalGeometry = useFinalGeometry()

  useRoutingErrorNotifications()

  return (
    <ElevationCurveProvider
      geometry={finalGeometry || undefined}
      distance={distance || undefined}
      unitPreference={unitPreference}
    >
      <WebAppLayout UserNavigation={UserNavigation}>
        <RoutePlannerMainContent />
        <RoutePlannerMap />
      </WebAppLayout>
    </ElevationCurveProvider>
  )
}
