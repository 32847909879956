import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import { RouteCollectionEntity, RouteEntity, UserEntity, getUserRouteCollections } from 'shared/data-access-core'
import { RouteCollectionItemSkeleton, ToolButton, useMessages } from 'shared/ui-components'
import { getFavoriteRoutesUrl, getRouteCollectionUrl } from 'shared/util-navigation'
import { useSessionInfo } from 'web-app/feature-navigation'
import { useUserState } from 'web-app/feature-user'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import { RouteSliceDispatch, toggleFavoriteRoute, unassignCollection } from 'web-app/feature-route'
import { RouteDetailsSliceDispatch, routeCollectionsFetched, routeRemovedFromCollection, useRouteCollections } from '../../state'
import { FavoritesItem } from './favorites-item'
import { CollectionItem } from './collection-item'
import {
  useRemoveRouteFromCollectionErrorNotification,
  useRouteRemovedFromCollectionNotification,
} from '../../hooks/notifications'

import styles from './assigned-collections-list.module.scss'

interface RemoveButtonProps {
  onClick: () => void
}

const RemoveButton = ({ onClick }: RemoveButtonProps) => {
  const { removeLabel } = useMessages()

  return (
    <ToolButton variant="ghost-danger" icon={<RemoveCircleRoundedIcon />} ariaLabel={removeLabel} onClick={onClick} />
  )
}

interface FavoritesListItemProps {
  user: UserEntity
}

const FavoritesListItem = ({ user }: FavoritesListItemProps) => {
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const sessionInfo = useSessionInfo()

  const favoritesUrl = getFavoriteRoutesUrl({
    slug: user?.slug,
    ...sessionInfo,
  })

  return (
    <li className={styles['item']}>
      <a href={favoritesUrl} className={styles['link']}>
        <FavoritesItem />
      </a>
      <RemoveButton onClick={() => dispatch(toggleFavoriteRoute())} />
    </li>
  )
}

interface CollectionListItemProps {
  routeCollection: RouteCollectionEntity
  route: RouteEntity
}

const CollectionListItem = ({ routeCollection, route }: CollectionListItemProps) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const sessionInfo = useSessionInfo()
  const showRouteRemovedFromCollectionNotification = useRouteRemovedFromCollectionNotification()
  const showRemoveRouteFromCollectionErrorNotification = useRemoveRouteFromCollectionErrorNotification()

  const handleRemove = async () => {
    try {
      await dispatch(unassignCollection(routeCollection.id)).unwrap()
      dispatch(routeRemovedFromCollection(routeCollection.id))
      showRouteRemovedFromCollectionNotification(routeCollection)
    } catch {
      showRemoveRouteFromCollectionErrorNotification(routeCollection)
    }
  }

  return (
    <li className={styles['item']}>
      <a
        href={getRouteCollectionUrl({
          routeCollectionId: routeCollection.id,
          ...sessionInfo,
        })}
        className={styles['link']}
      >
        <CollectionItem collection={routeCollection} />
      </a>
      <RemoveButton onClick={handleRemove} />
    </li>
  )
}

interface AssignedCollectionsListProps {
  route: RouteEntity
  assignedRouteCollectionIds: number[]
}

export const AssignedCollectionsList = ({ route, assignedRouteCollectionIds }: AssignedCollectionsListProps) => {
  const dispatch = useDispatch()
  const { user } = useUserState()
  const routeCollections = useRouteCollections()

  useEffect(() => {
    if (assignedRouteCollectionIds.length) {
      getUserRouteCollections('self', route.id).then((result) => {
        if (result.success) {
          dispatch(routeCollectionsFetched(result.data))
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ul className={styles['list']}>
      {route.isFavorite && user && <FavoritesListItem user={user} />}
      {!!assignedRouteCollectionIds?.length &&
        assignedRouteCollectionIds.map((id: number) => {
          const collection = routeCollections[id]
          return collection ? (
            <CollectionListItem key={id} routeCollection={collection} route={route} />
          ) : (
            <li className={clsx(styles['item'], styles['item-loading'])} key={id}>
              <RouteCollectionItemSkeleton />
            </li>
          )
        })}
    </ul>
  )
}
