import QRCode from 'react-qr-code'
import { useEffect, useState } from 'react'
import { getRouteDetailUrl } from 'shared/util-navigation'
import { useRoute } from 'web-app/feature-route'
import { useSessionInfo } from 'web-app/feature-navigation'
import { useLocale } from 'shared/util-intl'
import { getDynamicLink } from 'shared/data-access-core'

import styles from './print-app-section.module.css'

export const PrintAppSection = () => {
  const { intl } = useLocale()
  const route = useRoute()
  const sessionInfo = useSessionInfo()

  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null)

  useEffect(() => {
    if (route) {
      const routeUrl = getRouteDetailUrl({
        routeId: route.id,
        ...sessionInfo,
        trackingParameters: {
          utmMedium: 'route_print',
          utmCampaign: 'navigate_qr',
        },
      })
      getDynamicLink(routeUrl).then((result) => {
        setQrCodeUrl(result.success ? result.data : routeUrl)
      })
    }
  }, [route, sessionInfo])

  return (
    <div className={styles['container']}>
      <p>
        {intl.formatMessage({
          id: 'route_print_app_label',
          defaultMessage: 'Navigate with Bikemap',
        })}
      </p>
      {qrCodeUrl && <QRCode value={qrCodeUrl} style={{ width: '100%', height: 'auto', display: 'block' }} />}
    </div>
  )
}
