import { MultiLineString, Position } from 'geojson'
import { useCallback, useMemo, useState } from 'react'
import { splitLineAtPoints } from '../../state/helpers'
import { LngLat } from 'shared/ui-map'
import { useControlPoints, useRoutePlannerState } from '../../state'
import { getSegmentsAffectedByControlPoint, getSegmentsAffectedByWaypoint } from './helpers'
import { useLocale } from 'shared/util-intl'
import { RoutingRouteData, routingApi } from 'shared/data-access-core'
import { lngLatToPosition } from 'shared/util-geo'

export const useRoutePreview = () => {
  const { language } = useLocale()
  const { routingMode, routingProfile, routingLevel, waypoints, geometry } = useRoutePlannerState()
  const controlPoints = useControlPoints()

  /** Record of line coordinates by segment index */
  const [previewSegments, setPreviewSegments] = useState<Record<number, Position[]> | null>(null)

  const handleSegmentsPreview = useCallback((routingRouteData: RoutingRouteData, segmentIndexes: number[] | null) => {
    const segmentCoordinates = splitLineAtPoints(routingRouteData.geometry, routingRouteData.snappedWaypoints)
    if (segmentIndexes?.length === segmentCoordinates.coordinates.length) {
      const previewSegments: Record<number, Position[]> = {}
      segmentIndexes.forEach((segmentIndex, i) => {
        previewSegments[segmentIndex] = segmentCoordinates.coordinates[i]
      })
      setPreviewSegments(previewSegments)
    }
  }, [])

  const handleFreehandSegmentsPreview = useCallback((controlPoints: LngLat[], segmentIndexes: number[] | null) => {
    if (segmentIndexes?.length === controlPoints.length - 1) {
      const previewSegments: Record<number, [Position, Position]> = {}
      segmentIndexes.forEach((segmentIndex, i) => {
        previewSegments[segmentIndex] = [lngLatToPosition(controlPoints[i]), lngLatToPosition(controlPoints[i + 1])]
      })
      setPreviewSegments(previewSegments)
    }
  }, [])

  const calculateWaypointDragPreview = useCallback(
    async (waypointIndex: number, lngLat: LngLat) => {
      const controlPointIndex = waypoints[waypointIndex]?.controlPointIndex
      if (typeof controlPointIndex !== 'number' || !controlPoints) return
      const requestWaypoints: LngLat[] = [lngLat]
      if (controlPointIndex > 0) {
        requestWaypoints.unshift(controlPoints[controlPointIndex - 1])
      }
      if (controlPointIndex < controlPoints.length - 1) {
        requestWaypoints.push(controlPoints[controlPointIndex + 1])
      }

      if (requestWaypoints.length < 2) return

      const segmentIndexes = getSegmentsAffectedByWaypoint(waypoints, controlPoints, waypointIndex)
      if (routingMode === 'routing') {
        const res = await routingApi.getRoute(requestWaypoints, language, routingProfile, routingLevel)
        if (res.success) {
          handleSegmentsPreview(res.data, segmentIndexes)
        } else {
          setPreviewSegments(null)
        }
      } else if (routingMode === 'freehand') {
        handleFreehandSegmentsPreview(requestWaypoints, segmentIndexes)
      }
    },
    [
      waypoints,
      controlPoints,
      language,
      routingMode,
      routingProfile,
      routingLevel,
      handleSegmentsPreview,
      handleFreehandSegmentsPreview,
    ],
  )

  const calculateControlPointDragPreview = useCallback(
    async (controlPointIndex: number, lngLat: LngLat) => {
      if (!controlPoints) return
      const requestWaypoints: LngLat[] = [lngLat]
      if (controlPointIndex > 0) {
        requestWaypoints.unshift(controlPoints[controlPointIndex - 1])
      }
      if (controlPointIndex < controlPoints.length - 1) {
        requestWaypoints.push(controlPoints[controlPointIndex + 1])
      }
      if (requestWaypoints.length < 2) return

      const segmentIndexes = getSegmentsAffectedByControlPoint(controlPoints, controlPointIndex)
      if (routingMode === 'routing') {
        const res = await routingApi.getRoute(requestWaypoints, language, routingProfile, routingLevel)
        if (res.success) {
          handleSegmentsPreview(res.data, segmentIndexes)
        } else {
          setPreviewSegments(null)
        }
      } else if (routingMode === 'freehand') {
        handleFreehandSegmentsPreview(requestWaypoints, segmentIndexes)
      }
    },
    [
      controlPoints,
      routingMode,
      language,
      routingProfile,
      routingLevel,
      handleSegmentsPreview,
      handleFreehandSegmentsPreview,
    ],
  )

  const calculateSegmentDragPreview = useCallback(
    async (segmentIndex: number, lngLat: LngLat) => {
      if (!controlPoints || segmentIndex < 0 || segmentIndex > controlPoints.length - 2) return

      const requestWaypoints: LngLat[] = [controlPoints[segmentIndex], lngLat, controlPoints[segmentIndex + 1]]

      if (routingMode === 'routing') {
        const res = await routingApi.getRoute(requestWaypoints, language, routingProfile, routingLevel)
        if (res.success) {
          setPreviewSegments({ [segmentIndex]: res.data.geometry.coordinates })
        } else {
          setPreviewSegments(null)
        }
      } else if (routingMode === 'freehand') {
        setPreviewSegments({
          [segmentIndex]: requestWaypoints.map(lngLatToPosition),
        })
      }
    },
    [controlPoints, routingMode, language, routingProfile, routingLevel],
  )

  const resetPreview = () => {
    setPreviewSegments(null)
  }

  /** Geometry including preview segments */
  const mapRouteGeometry = useMemo<MultiLineString | undefined>(
    () =>
      previewSegments && geometry
        ? {
          type: 'MultiLineString',
          coordinates: geometry.coordinates.map((segment, i) => {
            if (previewSegments[i]) {
              // Make sure segments are connected
              const previewSegment = [...previewSegments[i]]
              if (i > 0 && !previewSegments[i - 1]) {
                previewSegment[0] = geometry.coordinates[i - 1][geometry.coordinates[i - 1].length - 1]
              }
              if (i < geometry.coordinates.length - 1 && !previewSegments[i + 1]) {
                previewSegment[previewSegment.length - 1] = geometry.coordinates[i + 1][0]
              }
              return previewSegment
            }
            return segment
          }),
        }
        : geometry || undefined,
    [geometry, previewSegments],
  )

  /** Preview segments or combination of potentially affected segments from hovering and selection */
  const highlightedPreviewSegments = useMemo<number[] | null>(
    () => previewSegments && Object.keys(previewSegments).map((i) => Number.parseInt(i)),
    [previewSegments],
  )

  return {
    calculateWaypointDragPreview,
    calculateControlPointDragPreview,
    calculateSegmentDragPreview,
    resetPreview,
    mapRouteGeometry,
    highlightedPreviewSegments,
  }
}
