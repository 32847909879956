import { ElevationCurveProvider } from 'shared/feature-elevation-curve'
import { ErrorBoundary, ErrorBoundaryFallback, ViewNotFound } from 'web-app/utils-error-handling'
import { WebAppLayout } from 'web-app/ui-layout'
import { useUserState } from 'web-app/feature-user'
import { RouteSliceDispatch, useRouteById, useRouteState, useRouteTitle } from 'web-app/feature-route'
import { UserNavigation } from 'web-app/feature-navigation'
import { reset } from '../state'
import { useDispatch } from 'react-redux'
import { RouteDetailsAdditionalContent } from './route-details-additional-content'
import { RouteDetailsMainContent } from './route-details-main-content'
import { RouteDetailsMap } from './route-details-map'
import { RoutePoisProvider } from 'shared/feature-route-pois'

interface RouteDetailsProps {
  routeId: number
}

export function RouteDetails({ routeId }: RouteDetailsProps) {
  const dispatch = useDispatch() as RouteSliceDispatch
  const { unitPreference } = useUserState()
  const route = useRouteById(routeId)
  const { isRouteUnavailable } = useRouteState()
  useRouteTitle()

  return isRouteUnavailable ? (
    <ViewNotFound />
  ) : (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallback
          beforeReload={async () => {
            dispatch(reset())
          }}
        />
      }
    >
      <ElevationCurveProvider geometry={route?.geometry} distance={route?.distance} unitPreference={unitPreference}>
        <RoutePoisProvider routeId={routeId}>
          <WebAppLayout UserNavigation={UserNavigation}>
            <RouteDetailsMainContent />
            <RouteDetailsAdditionalContent />
            <RouteDetailsMap />
          </WebAppLayout>
        </RoutePoisProvider>
      </ElevationCurveProvider>
    </ErrorBoundary>
  )
}
