import React, { useEffect, useState } from 'react'
import { BaseButton } from '../../base/BaseButton'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Checkbox } from '../Checkbox'
import { Typography } from '@mui/material'

import styles from './CookieBanner.module.scss'

type CookieBannerSettings = {
  statistics?: boolean
}

type CookiePreferences = {
  statistics: boolean
}

export interface CookieBannerMessages {
  explanation: Array<React.ReactNode>
  title: string,
  necessaryLabel: string,
  requiredAddition: string,
  statisticsLabel: string,
  learnMore: React.ReactNode,
  saveSelectionLabel: string,
  acceptAllLabel: string
}

export interface CookieBannerProps {
  locale: 'en' | 'de'
  onLanguageSelect: (locale: 'en' | 'de') => void
  onSave: (settings: CookiePreferences) => void
  onAcceptAll: () => void
  initialPreferences: CookieBannerSettings
  isHidden?: boolean
  messages: CookieBannerMessages
}

export function CookieBanner({
  locale,
  onLanguageSelect,
  onSave,
  onAcceptAll,
  initialPreferences,
  isHidden,
  messages,
}: CookieBannerProps) {
  const [statistics, setStatistics] = useState<boolean>(false)

  // To update also on later changes to the initialPreferences prop
  useEffect(() => {
    setStatistics(!!initialPreferences.statistics)
  }, [initialPreferences])

  const handleSaveSelection = () => {
    onSave({ statistics })
  }

  const LanguageButton = ({ language }: { language: CookieBannerProps['locale'] }) => {
    const label = language.toUpperCase()
    return (
      <BaseButton
        variant='ghost-primary'
        className={styles['language-button']}
        onClick={() => onLanguageSelect(language)}
        disabled={locale === language}
        tabIndex={isHidden ? -1 : 0}
        ariaLabel={label}
        children={label}
      />
    )
  }

  return (
    <div className={styles['root']} aria-hidden={isHidden}>
      <Typography variant='h4' marginBottom='1rem'>
        {messages.title}
      </Typography>

      <div className={styles['language']}>
        <LanguageButton language="en" />
        <span>{' | '}</span>
        <LanguageButton language="de" />
      </div>

      <div className={styles['explanation']}>
        {messages.explanation.map((paragraph, i) => <Typography paragraph key={i}>{paragraph}</Typography>)}
      </div>

      <div className={styles['checkboxes']}>
        <Checkbox className={styles['requiredCheckbox']} name='necessary' onChange={() => { '' }} disabled checked>
          {messages.necessaryLabel}
          &nbsp;
          <span className={styles['requiredAddition']}>
            {messages.requiredAddition}
          </span>
        </Checkbox>
        <Checkbox checked={statistics} name='statistics' onChange={() => { setStatistics(!statistics) }} tabIndex={isHidden ? -1 : 0}>
          {messages.statisticsLabel}
        </Checkbox>
      </div>

      <Typography paragraph color='textSecondary' lineHeight='1.25' marginBottom='1rem'>
        {messages.learnMore}
      </Typography>

      <ButtonGroup>
        <Button variant={statistics ? 'primary' : 'secondary'} onClick={handleSaveSelection} tabIndex={isHidden ? -1 : 0} ariaLabel={messages.saveSelectionLabel}>
          {messages.saveSelectionLabel}
        </Button>
        <Button variant={statistics ? 'secondary' : 'primary'} onClick={onAcceptAll} tabIndex={isHidden ? -1 : 0} ariaLabel={messages.acceptAllLabel}>
          {messages.acceptAllLabel}
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default CookieBanner
