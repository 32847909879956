import { LogErrorType, Logger } from 'shared/util-error-handling'

export class ApiService {

  // Custom logger with a prefix
  logError: LogErrorType

  constructor() {
    this.logError = new Logger().setMessagePrefix(this.constructor.name).logError
  }

}
