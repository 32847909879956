import { MinimalFooter } from 'web-app/feature-navigation'
import { useAttributions } from './use-attributions'

export const MapAttributionsFooter = () => {
  const attributions = useAttributions()

  return (
    <MinimalFooter
      secondaryItems={attributions.map((a) => <span dangerouslySetInnerHTML={{ __html: a }} />)}
    />
  )
}
