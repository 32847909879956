import { GeocoderLocation } from 'shared/data-access-geocoding'

export type LocationDisplayValues = {
  displayValue: string
  displayValueTitle: string
  displayValueSubtitle: string
  displayValueShort: string
}

/**
 * Separate location values in multiple parts for cases where we need it in multiple lines.
 * It just breaks the already formatted address in "title" and "subtitle".
 */
export function formatLocationDisplayValues({
  poiName,
  localName,
  address,
}: Omit<GeocoderLocation, 'position'>): LocationDisplayValues {
  if (poiName) {
    return {
      displayValue: poiName,
      displayValueTitle: poiName,
      displayValueSubtitle: address,
      displayValueShort: localName || poiName,
    }
  }

  const ADDRESS_SEPARATOR = ', '
  if (address.includes(ADDRESS_SEPARATOR)) {
    const [displayValueTitle, ...displayValueRest] = address.split(ADDRESS_SEPARATOR)
    const displayValueSubtitle = displayValueRest.filter((v) => v && v.length > 0).join(ADDRESS_SEPARATOR)

    return {
      displayValue: address,
      displayValueTitle,
      displayValueSubtitle,
      displayValueShort: localName || displayValueTitle,
    }
  }

  return {
    displayValue: address,
    displayValueTitle: address,
    displayValueSubtitle: '',
    displayValueShort: localName || address,
  }
}
