import { SvgIcon, SvgIconProps } from '@mui/material'
import { FunctionComponent, SVGProps } from 'react'
import altitude from './svgs/altitude'
import arrowAscent from './svgs/arrow-ascent'
import arrowDescent from './svgs/arrow-descent'
import arrowDistance from './svgs/arrow-distance'
import ascentCircle from './svgs/ascent-circle'
import bikeComputer from './svgs/bike-computer'
import bikemapIcon from './svgs/bikemap'
import compassIcon from './svgs/compass'
import copyIcon from './svgs/copy'
import descentCircle from './svgs/descent-circle'
import destinationIcon from './svgs/destination'
import distance from './svgs/distance'
import duration from './svgs/duration'
import fileBlankIcon from './svgs/file-blank'
import gpxIcon from './svgs/gpx-import'
import navigation from './svgs/navigation'
import premiumBadgeIcon from './svgs/premium-badge'
import premiumLockIcon from './svgs/premium-lock'
import profileSettingsIcon from './svgs/profile-settings'
import routeCollectionIcon from './svgs/route-collection'
import routeDot from './svgs/route-dot'
import speed from './svgs/speed'
import verifiedIcon from './svgs/verified'

interface GenericIconProps extends SvgIconProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  className?: string
}

const Icon = ({ icon, ...rest }: GenericIconProps) => {
  return <SvgIcon component={icon} inheritViewBox {...rest} />
}

export const AltitudeIcon = (props: SvgIconProps) => <Icon icon={altitude} data-testid='AltitudeIcon' {...props} />
export const ArrowAscent = (props: SvgIconProps) => <Icon icon={arrowAscent} data-testid='ArrowAscent' {...props} />
export const ArrowDescent = (props: SvgIconProps) => <Icon icon={arrowDescent} data-testid='ArrowDescent' {...props} />
export const ArrowDistance = (props: SvgIconProps) => <Icon icon={arrowDistance} data-testid='ArrowDistamce' {...props} />
export const AscentCircleIcon = (props: SvgIconProps) => <Icon icon={ascentCircle} data-testid='AscentCircleIcon' {...props} />
export const BikeComputerIcon = (props: SvgIconProps) => <Icon icon={bikeComputer} data-testid='BikeComputerIcon' {...props} />
export const BikemapIcon = (props: SvgIconProps) => <Icon icon={bikemapIcon} data-testid='BikemapIcon' {...props} />
export const CompassIcon = (props: SvgIconProps) => <Icon icon={compassIcon} data-testid='CompassIcon' {...props} />
export const CopyIcon = (props: SvgIconProps) => <Icon icon={copyIcon} data-testid='CopyIcon' {...props} />
export const DescentCircleIcon = (props: SvgIconProps) => <Icon icon={descentCircle} data-testid='DescentCircleIcon' {...props} />
export const DestinationIcon = (props: SvgIconProps) => <Icon icon={destinationIcon} data-testid='DestinationIcon' {...props} />
export const DistanceIcon = (props: SvgIconProps) => <Icon icon={distance} data-testid='DistanceIcon' {...props} />
export const DurationIcon = (props: SvgIconProps) => <Icon icon={duration} data-testid='DurationIcon' {...props} />
export const FileBlankIcon = (props: SvgIconProps) => <Icon icon={fileBlankIcon} data-testid='FileBlankIcon' {...props} />
export const GPXUploadIcon = (props: SvgIconProps) => <Icon icon={gpxIcon} data-testid='GPXUploadIcon' {...props} />
export const NavigationIcon = (props: SvgIconProps) => <Icon icon={navigation} data-testid='NavigationIcon' {...props} />
export const PremiumBadgeIcon = (props: SvgIconProps) => <Icon icon={premiumBadgeIcon} data-testid='PremiumBadgeIcon' {...props} />
export const PremiumLockIcon = (props: SvgIconProps) => <Icon icon={premiumLockIcon} data-testid='PremiumLockIcon' {...props} />
export const ProfileSettingsIcon = (props: SvgIconProps) => <Icon icon={profileSettingsIcon} data-testid='ProfileSettingsIcon' {...props} />
export const RouteCollectionIcon = (props: SvgIconProps) => <Icon icon={routeCollectionIcon} data-testid='RouteCollectionIcon' {...props} />
export const RouteDot = (props: SvgIconProps) => <Icon icon={routeDot} data-testid='RouteDot' {...props} />
export const SpeedIcon = (props: SvgIconProps) => <Icon icon={speed} data-testid='SpeedIcon' {...props} />
export const VerifiedIcon = (props: SvgIconProps) => <Icon icon={verifiedIcon} data-testid='VerifiedIcon' {...props} />
