import { times } from 'lodash'
import { Switch } from '../Switch'
import { Typography } from '@mui/material'
import { MapStyleButton, MapStyleButtonSkeleton } from './map-style-button'
import { RequiredMapStyleData } from './types'

import styles from './map-style-control-content.module.scss'

export interface MapStyleControlContentProps<MapStyleData> {
  messages: {
    stylesHeading: string
    heatmapHeading: string
    globalHeatmapLabel: string
  }
  mapStyles?: MapStyleData[]
  active?: number
  onSelect: (mapStyle: MapStyleData) => void
  withPremiumIndicators?: boolean
  globalHeatmapChecked?: boolean
  onGlobalHeatmapChange?: (checked: boolean) => void
}

export function MapStyleControlContent<MapStyleData extends RequiredMapStyleData>({
  messages,
  mapStyles,
  active,
  onSelect,
  withPremiumIndicators,
  globalHeatmapChecked,
  onGlobalHeatmapChange,
}: MapStyleControlContentProps<MapStyleData>) {
  return (
    <>
      <Typography variant='h4'>{messages.stylesHeading}</Typography>
      <ul className={styles['map-styles-list']}>
        {mapStyles ? mapStyles.map((mapStyle, i) => (
          <MapStyleButton
            key={i}
            mapStyle={mapStyle}
            active={i === active}
            withPremiumIndicator={!!withPremiumIndicators}
            onClick={() => { onSelect(mapStyle) }}
          />
        )) : (
          times(3, i => <MapStyleButtonSkeleton key={i} />)
        )}
      </ul>
      {typeof globalHeatmapChecked !== 'undefined' && !!onGlobalHeatmapChange && (
        <>
          <Typography variant='h4'>{messages.heatmapHeading}</Typography>
          <Switch
            label={messages.globalHeatmapLabel}
            checked={globalHeatmapChecked}
            onChange={onGlobalHeatmapChange}
          />
        </>
      )}
    </>
  )
}
