import { useState } from 'react'
import { useRoutePois } from '../route-pois-context'
import { RoutePoiForm, RoutePoiFormData } from './route-poi-form'
import { useLocale } from 'shared/util-intl'
import { LngLat } from 'shared/ui-map'
import { createRoutePoi } from 'shared/data-access-core'

interface RoutePoiCreationProps {
  routeId: number
  position: LngLat
  onError: (message: string) => void
}

export const RoutePoiCreation = ({ routeId, position, onError }: RoutePoiCreationProps) => {
  const { intl } = useLocale()
  const { onRoutePoiAdded } = useRoutePois()

  const [formData, setFormData] = useState<RoutePoiFormData>({
    type: 'sight',
    description: '',
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const res = await createRoutePoi({
      ...formData,
      routeId,
      position,
    })
    if (res.success) {
      onRoutePoiAdded(res.data)
    } else {
      onError(
        intl.formatMessage({
          id: 'new_route_poi_save_error',
          defaultMessage: 'The route highlight could not be saved.',
        }),
      )
    }
    setIsSubmitting(false)
  }

  return (
    <RoutePoiForm
      formData={formData}
      onChange={setFormData}
      onSubmit={handleSubmit}
      onImageFileError={onError}
      loading={isSubmitting}
    />
  )
}
