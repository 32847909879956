import { Point } from 'geojson'
import { ApiResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from 'shared/util-network'
import { addAuthHeader, getRequest } from '../network'
import { API_URL_NEAREST } from '../config'
import { LngLat, LngLatElevation } from 'shared/util-geo'

type NearestPointResultData = {
  distance: number
  point: LngLatElevation
}

type NearestPointErrors = MinimalEndpointErrors & {
  unexpectedResponse?: true
}

interface NearestPointResponse {
  distance: number
  point: Point
}

/**
 * Get the nearest routable point with elevation and its distance to the given point.
 */
export async function getNearestPoint(point: LngLat): ApiResult<NearestPointResultData, NearestPointErrors> {
  try {
    const response: NearestPointResponse = await getRequest(API_URL_NEAREST, {
      headers: await addAuthHeader(),
      queryParams: {
        point: `${point.lat},${point.lng}`,
        elevation: 'true',
      },
    })

    const [lng, lat, elevation] = response.point.coordinates
    if (elevation) {
      return createSuccessResult({
        distance: response.distance,
        point: { lng, lat, elevation },
      })
    }
    return createFailureResult({ unexpectedResponse: true })
  } catch (e) {
    return createFailureResult({ unexpectedError: true })
  }
}
