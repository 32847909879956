import { RouteBikeTypes } from 'shared/data-access-core'
import { TagsRow, TagsRowSkeleton } from './tags-row'
import { useLocale } from 'shared/util-intl'
import { TagSelectField } from 'shared/ui-components'
import { Typography } from '@mui/material'
import { EditableContent } from '../editable-content'
import { useBikeTypeLabels } from 'shared/feature-routes'

interface BikeTypesProps {
  bikeTypes: RouteBikeTypes
}

const BikeTypes = ({ bikeTypes }: BikeTypesProps) => {
  const { intl } = useLocale()
  const labels = useBikeTypeLabels()

  return bikeTypes.length ? (
    <TagsRow tags={bikeTypes} labels={labels} />
  ) : (
    <Typography paragraph color="textSecondary">
      {intl.formatMessage({
        id: 'route_bike_types_empty_placeholder',
        defaultMessage: 'No bike types set.',
      })}
    </Typography>
  )
}

interface BikeTypesFormProps {
  name: string
  value: RouteBikeTypes
  onChange: (value: RouteBikeTypes) => void
}

const BikeTypesForm = ({ name, value, onChange }: BikeTypesFormProps) => {
  const { intl } = useLocale()
  const labels = useBikeTypeLabels()

  return (
    <TagSelectField
      name={name}
      label={intl.formatMessage({
        id: 'route_bike_types_label',
        defaultMessage: 'Bike types',
      })}
      labelHidden
      options={labels}
      value={value}
      onChange={(selectedValues) => onChange(selectedValues as RouteBikeTypes)}
    />
  )
}

interface EditableBikeTypesProps {
  heading?: string
  bikeTypes?: RouteBikeTypes
}

export const EditableBikeTypes = ({ heading, bikeTypes }: EditableBikeTypesProps) => (
  <EditableContent
    name="bikeTypes"
    currentValue={bikeTypes}
    renderContent={({ value }) => <BikeTypes bikeTypes={value} />}
    renderForm={(props) => <BikeTypesForm {...props} />}
    renderLoading={() => <TagsRowSkeleton />}
    heading={heading}
  />
)
