import { ReactNode } from 'react'
import { Skeleton, Typography } from '@mui/material'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useIsOwnRoute, useRoute } from 'web-app/feature-route'
import { FavoriteButton } from './favorite-button'
import { EditableContent } from '../editable-content'
import { RouteTitleForm } from './route-title-form'

import styles from './route-header.module.css'

interface RouteHeaderProps {
  title: string
  editButton?: ReactNode
}

const RouteHeader = ({ title, editButton }: RouteHeaderProps) => {
  const route = useRoute()
  const isOwnRoute = useIsOwnRoute()

  return (
    <>
      {route && (
        <div className={styles['floating-container']}>
          {editButton || (!isOwnRoute && <FavoriteButton route={route} />)}
        </div>
      )}
      <Typography variant="h3" component="h1" className={styles['title']}>
        {title}
      </Typography>
      {route?.location && (
        <Typography variant="body2" color="textSecondary" paddingTop="0.25rem">
          <span className={styles['secondary-info']}>
            <FmdGoodIcon className={styles['secondary-info-icon']} />
            &nbsp;{route.location}
          </span>
          {isOwnRoute && (
            <span className={styles['secondary-info']}>
              <FavoriteIcon className={styles['secondary-info-icon']} />
              &nbsp;{route.favoriteCount}
            </span>
          )}
        </Typography>
      )}
    </>
  )
}

const RouteHeaderSkeleton = () => (
  <>
    <Typography variant="h3" component="h1" marginBottom="0.25rem">
      <Skeleton width="80%" data-testid="skeleton" />
    </Typography>
    <Typography variant="body2" color="textSecondary" paddingTop="0.25rem">
      <Skeleton width="50%" data-testid="skeleton" />
    </Typography>
  </>
)

interface EditableRouteHeaderProps {
  title?: string
}

export const EditableRouteHeader = ({ title }: EditableRouteHeaderProps) => (
  <EditableContent
    name="title"
    currentValue={title}
    renderContent={({ value, editButton }) => <RouteHeader title={value} editButton={editButton} />}
    renderForm={(props) => <RouteTitleForm {...props} />}
    renderLoading={() => <RouteHeaderSkeleton />}
  />
)
