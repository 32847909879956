import { createSelector } from 'reselect'
import {
  CompleteWaypoints,
  IncompleteWaypoints,
  PLANNER_SLICE_KEY,
  RoutePlannerState,
  SavableRouteState,
  SelectedWaypointState,
  StateWithRoutePlannerSlice,
  WaypointsState,
  RoutedWaypoint,
} from './types'
import { combineSegmentsToLine, deriveControlPointIndexesFromSegments, deriveControlPointsFromSegments } from './helpers'
import { ROUTE_SLICE_KEY, StateWithRouteSlice } from 'web-app/feature-route'
import { getBoundsFromGeometry } from 'shared/ui-map'
import { LineString } from 'geojson'
import { RouteEntity } from 'shared/data-access-core'
import { LngLat, LngLatBoundsArray, areLngLatsEqual } from 'shared/util-geo'

type UndoRedoSelectorReturn = {
  canUndo: boolean
  canRedo: boolean
}

export const undoRedoSelector: (state: StateWithRoutePlannerSlice) => UndoRedoSelectorReturn = createSelector(
  (state: StateWithRoutePlannerSlice) => state[PLANNER_SLICE_KEY],
  (slice) => ({
    canUndo: slice.past.length > 0,
    canRedo: slice.future.length > 0,
  })
)

export const routePlannerSliceSelector: (state: StateWithRoutePlannerSlice) => RoutePlannerState = createSelector(
  (state: StateWithRoutePlannerSlice) => state[PLANNER_SLICE_KEY],
  (slice) => slice.present
)

export const waypointsSelector: (state: StateWithRoutePlannerSlice) => WaypointsState = createSelector(
  (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).waypoints,
  (waypoints) => waypoints.length > 2 || (waypoints[0] && waypoints[waypoints.length - 1]) ? {
    isFullRoute: true,
    waypoints: waypoints as CompleteWaypoints,
    start: waypoints[0] as RoutedWaypoint,
    via: waypoints.slice(1, waypoints.length - 1) as RoutedWaypoint[],
    end: waypoints[waypoints.length - 1] as RoutedWaypoint,
  } : {
    isFullRoute: false,
    waypoints: waypoints as IncompleteWaypoints,
    start: waypoints[0],
    via: [],
    end: waypoints[waypoints.length - 1],
  }
)

export const isRoundTripSelector: (state: StateWithRoutePlannerSlice) => boolean = createSelector(
  waypointsSelector,
  ({ start, end }: WaypointsState) => !!(start && end) && start.lng === end.lng && start.lat === end.lat
)

export const selectedWaypointSelector: (
  state: StateWithRoutePlannerSlice
) => SelectedWaypointState | null = createSelector(
  [
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).selectedWaypoint,
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).waypoints,
  ],
  (selectedWaypoint, waypoints) => {
    const hasSelectedWaypoint = selectedWaypoint !== null
    const waypoint = hasSelectedWaypoint ? waypoints[selectedWaypoint] : null

    return hasSelectedWaypoint && waypoint
      ? {
        index: selectedWaypoint,
        waypoint,
      }
      : null
  }
)

export const hasRouteDistanceSelector: (state: StateWithRoutePlannerSlice) => boolean = createSelector(
  (state: StateWithRoutePlannerSlice) => waypointsSelector(state),
  ({ isFullRoute, waypoints }) => {
    if (!isFullRoute) return false
    let firstLng: number | undefined, firstLat: number | undefined
    for (const waypoint of waypoints) {
      if (typeof firstLng === 'undefined' || typeof firstLat === 'undefined') {
        firstLng = waypoint.lng
        firstLat = waypoint.lat
      } else {
        if (waypoint.lng !== firstLng || waypoint.lat !== firstLat) {
          return true
        }
      }
    }
    return false
  }
)

export const baseRouteSelector: (
  state: StateWithRoutePlannerSlice & StateWithRouteSlice
) => RouteEntity | null = createSelector(
  [
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).basedOnRouteId,
    (state: StateWithRouteSlice) => state[ROUTE_SLICE_KEY].route,
  ],
  (basedOnRouteId, route) => basedOnRouteId && route && basedOnRouteId === route.id ? route : null
)

export const finalGeometrySelector: (state: StateWithRoutePlannerSlice) => LineString | null = createSelector(
  (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).geometry,
  (geometry) => geometry && combineSegmentsToLine(geometry)
)

export const controlPointsSelector: (state: StateWithRoutePlannerSlice) => LngLat[] | null = createSelector(
  (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).geometry,
  (geometry) => geometry && deriveControlPointsFromSegments(geometry)
)

export const controlPointIndexesSelector: (
  state: StateWithRoutePlannerSlice
) => [number, number, ...number[]] | null = createSelector(
  (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).geometry,
  (geometry) => geometry && deriveControlPointIndexesFromSegments(geometry)
)

export const geometryBoundsSelector: (state: StateWithRoutePlannerSlice) => LngLatBoundsArray | null = createSelector(
  [
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).isFittingGeometryBounds,
    (state: StateWithRoutePlannerSlice) => finalGeometrySelector(state),
  ],
  (isFittingGeometryBounds, geometry) => isFittingGeometryBounds && geometry ? getBoundsFromGeometry(geometry) : null
)

export const hasRouteBeenChangedSelector: (
  state: StateWithRoutePlannerSlice & StateWithRouteSlice
) => boolean = createSelector(
  [
    (state: StateWithRoutePlannerSlice & StateWithRouteSlice) => baseRouteSelector(state),
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).distance,
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).durationInS,
    waypointsSelector,
  ],
  (baseRoute, distance, durationInS, { waypoints }) => (
    !baseRoute ||
    distance !== baseRoute.distance ||
    durationInS !== (baseRoute.durationInS ?? null) ||
    waypoints.length !== baseRoute.waypoints.length ||
    !!waypoints.find((w, i) => w && !areLngLatsEqual(w, baseRoute.waypoints[i]))
  )
)

export const savableRouteSelector: (state: StateWithRoutePlannerSlice) => SavableRouteState | null = createSelector(
  [
    finalGeometrySelector,
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).distance,
    (state: StateWithRoutePlannerSlice) => routePlannerSliceSelector(state).durationInS,
    waypointsSelector,
    controlPointIndexesSelector,
  ],
  (geometry, distance, durationInS, { isFullRoute, waypoints }, controlPointIndexes) => {
    if (
      isFullRoute &&
      geometry &&
      distance &&
      durationInS &&
      waypoints.length >= 2 &&
      controlPointIndexes
    ) {
      return {
        geometry,
        distanceInM: distance,
        durationInS,
        waypoints,
        controlPointIndexes,
        start: waypoints[0],
        via: waypoints.length > 2 ? waypoints.slice(1, -1) : [],
        end: waypoints[waypoints.length - 1],
      }
    }
    return null
  }
)
