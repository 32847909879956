import { WIDGETS_BASE_ROUTE } from './paths'

const WIDGETS_BASE = process.env['NX_PUBLIC_WIDGETS_URL']

type RouteWidgetUrlArgs = {
  routeId: number
  unitPreference: 'metric' | 'imperial'
  extended: boolean
  distanceMarkers: boolean
}

export function getRouteWidgetUrl({ routeId, unitPreference, extended, distanceMarkers }: RouteWidgetUrlArgs): string {
  const params = new URLSearchParams({
    unit: unitPreference,
  })
  if (extended) {
    params.append('extended', '1')
  }
  if (distanceMarkers) {
    params.append('distanceMarkers', '1')
  }
  return `${WIDGETS_BASE}${WIDGETS_BASE_ROUTE.replace(':routeId', routeId.toString())}?${params.toString()}`
}

export function getHelpCenterUrl(language: 'en' | 'de'): string {
  return `https://help.bikemap.net/hc/${language === 'de' ? 'de' : 'en-us'}`
}

export function getHelpCenterGiveawayUrl(language: 'en' | 'de'): string {
  const slug = language === 'de' ? '12773741053852-Bikemap-Gewinnspiel' : '12773741053852-Bikemap-Giveaway'
  return `${getHelpCenterUrl(language)}/articles/${slug}`
}

export function getWhatsNewUrl(language: 'en' | 'de') {
  return language === 'de' ? 'https://blog.bikemap.net/de/was-gibt-es-neues/' : 'https://blog.bikemap.net/whats-new/'
}

export function getTourRadarUrl(language: 'en' | 'de') {
  return language === 'de' ? 'https://bikemap.travel.tourradar.com/de/' : 'https://bikemap.travel.tourradar.com/'
}

export const STORE_URL_ANDROID = 'https://play.google.com/store/apps/details?id=com.toursprung.bikemap'

export const STORE_URL_IOS = 'https://apps.apple.com/app/bikemap-cycling-map-gps/id625759466'
