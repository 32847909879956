import { useRoutePois } from './route-pois-context'

export const useRoutePoiHandlers = () => {
  const { onCreateRoutePoi } = useRoutePois()

  return {
    /** Triggers form and marker to create a new route POI */
    onCreateRoutePoi,
  }
}
