import { API_PATH_USER_ROUTE_COLLECTIONS } from '../config'
import { RouteCollectionEntity } from '../entities'
import { addApiHeaders, getFromCoreApi } from '../network'
import { RouteCollectionGetResponse } from '../responses'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

type GetUserRouteCollectionsQueryParams = {
  page?: number
  route_id?: number
}

export async function getUserRouteCollections(
  userId: number | 'self' = 'self',
  routeId?: number
): ApiResult<RouteCollectionEntity[]> {
  try {
    const queryParams: GetUserRouteCollectionsQueryParams = {}
    if (routeId) {
      queryParams.route_id = routeId
    }

    let res: { results: RouteCollectionGetResponse[], page: { next: number | null } }
    let page = 1
    let results: RouteCollectionGetResponse[] = []
    do {
      res = await getFromCoreApi(API_PATH_USER_ROUTE_COLLECTIONS, {
        headers: await addApiHeaders(),
        params: { userId },
        queryParams: {
          ...queryParams,
          page: page++,
        },
      })
      results = results.concat(res.results)
    } while (res.page.next)

    return createSuccessResult(results.map(convertToRouteCollectionEntity))
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { userId, routeId })
  }
}

function convertToRouteCollectionEntity(res: RouteCollectionGetResponse): RouteCollectionEntity {
  return {
    id: res.id,
    title: res.title,
    image: res.cover_image,
    routesCount: res.route_count,
    isPrivate: !res.visibility,
  }
}
