import { MapLayerTouchEvent } from 'maplibre-gl'
import { useEffect } from 'react'
import { useMap } from 'react-map-gl/maplibre'

const LONG_PRESS_CANCEL_EVENT_TYPES = [
  'touchend', 'touchcancel', 'touchmove', 'pointerdrag', 'pointermove', 'moveend', 'gesturestart', 'gesturechange', 'gestureend',
]

export const useMapLongPress = (mapId: string, onLongPress: (event: MapLayerTouchEvent) => void) => {
  const { [mapId]: map } = useMap()

  useEffect(() => {
    let longPressTimeout: ReturnType<typeof setTimeout>
    const cancelLongPressTimeout = () => clearTimeout(longPressTimeout)
    const handleLongPressStart = (event: MapLayerTouchEvent) => {
      cancelLongPressTimeout()
      if (event.originalEvent.touches.length > 1) return
      longPressTimeout = setTimeout(() => {
        if (typeof navigator.vibrate === 'function') {
          navigator.vibrate(10)
        }
        onLongPress(event)
      }, 500)
    }

    if (map) {
      map.on('touchstart', handleLongPressStart)
      LONG_PRESS_CANCEL_EVENT_TYPES.forEach(type => map.on(type, cancelLongPressTimeout))
    }

    return () => {
      map?.off('touchstart', handleLongPressStart)
      LONG_PRESS_CANCEL_EVENT_TYPES.forEach(type => map?.off(type, cancelLongPressTimeout))
      cancelLongPressTimeout()
    }
  }, [map, onLongPress])
}
