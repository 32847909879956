import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { LayoutProvider } from './layout-provider'
import { useLayoutMediaQueries } from './layout-hooks'
import { UserNavigationContainer } from './user-navigation-container'
import { VhProvider } from './vh-provider'

import styles from './web-app-layout.module.css'

export interface WebAppLayoutProps {
  children: ReactNode
  UserNavigation?: FC
  fullScreenContent?: boolean
}

const WebAppLayoutInner = ({
  children,
  UserNavigation,
  fullScreenContent,
}: WebAppLayoutProps) => {
  const { isLargeViewport, isUserMenuShown } = useLayoutMediaQueries()

  return (
    <div
      className={clsx(
        styles['root'],
        isLargeViewport ? styles['large-viewport'] : fullScreenContent ? styles['full-screen-content'] : styles['small-viewport']
      )}
    >
      {isUserMenuShown && (
        <UserNavigationContainer>
          {UserNavigation && <UserNavigation />}
        </UserNavigationContainer>
      )}
      {children}
    </div>
  )
}

/**
 * Wrapper for the whole Web App layout. It also provides context to use the layout hooks inside child components.
 */
export const WebAppLayout = (props: WebAppLayoutProps) => (
  <LayoutProvider fullScreenContent={!!props.fullScreenContent}>
    <VhProvider>
      <WebAppLayoutInner {...props} />
    </VhProvider>
  </LayoutProvider>
)
