import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { CookieConsentState } from './types'
import { COOKIE_CONSENT_SLICE_KEY } from './state'

interface GlobalState {
  [COOKIE_CONSENT_SLICE_KEY]: CookieConsentState,
}

const sliceSelector = (state: GlobalState) => state[COOKIE_CONSENT_SLICE_KEY]

export const useCookieConsentState = () => useSelector(sliceSelector)

export const useCookieConsentStatistics = () => useSelector(createSelector(
  sliceSelector,
  slice => {
    if (typeof slice.settings.statistics === 'undefined') return null
    return !!slice.settings.statistics
  },
))
