import { TrackingParameters } from './types'

export const LANGUAGE_URL_PARAM = 'language'
export const UNIT_PREFERENCE_URL_PARAM = 'unit'
export const COOKIE_CONSENT_STATISTICS_URL_PARAM = 'cookieConsentStatistics'

/**
 * Try to find provided param in URL.
 */
export function getFromUrl(param: string): string | undefined {
  const url = new URL(window.location.toString())
  const val = url.searchParams.get(param)
  return val || undefined
}

/**
 * Remove a param from URL.
 */
export function removeFromUrl(param: string): void {
  const url = new URL(window.location.toString())
  url.searchParams.delete(param)
  window.history.replaceState(null, '', url)
}

/**
 * Try to find provided param in URL and also remove it if found.
 */
export function extractFromUrl(param: string): string | undefined {
  try {
    const value = getFromUrl(param)
    if (value) {
      removeFromUrl(param)
    }
    return value
  } catch {
    return undefined
  }
}

export function extractUnitPreferenceFromUrl(): 'metric' | 'imperial' | null {
  const urlUnitPreference = extractFromUrl(UNIT_PREFERENCE_URL_PARAM)
  if (urlUnitPreference === 'metric' || urlUnitPreference === 'imperial') {
    return urlUnitPreference
  }
  return null
}

export function extractLanguageFromUrl(): 'en' | 'de' | null {
  const urlLanguage = extractFromUrl(LANGUAGE_URL_PARAM)
  if (urlLanguage === 'de' || urlLanguage === 'en') {
    return urlLanguage
  }
  return null
}

export function extractCookieConsentFromUrl(): { statistics: boolean } | null {
  const statisticsParam = extractFromUrl(COOKIE_CONSENT_STATISTICS_URL_PARAM)
  if (statisticsParam) {
    return { statistics: statisticsParam === '1' }
  }
  return null
}

export function addTrackingParameters(
  values: TrackingParameters,
  params: URLSearchParams = new URLSearchParams(),
): URLSearchParams {
  params.set('utm_source', 'website')
  params.set('utm_medium', values.utmMedium)
  params.set('utm_campaign', values.utmCampaign)
  return params
}
