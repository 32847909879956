import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { MAP_SLICE_KEY, StateWithMapSlice } from './state'

const sliceSelector = (state: StateWithMapSlice) => state[MAP_SLICE_KEY]

/**
 * Provides the whole map state slice. Note that it will make the component rerender on any viewport change.
 */
export const useMapState = () => useSelector(sliceSelector)

/**
 * Provides the current global map viewport. Note that it will make the component rerender on any viewport change.
 */
export const useMapViewport = () => useSelector(createSelector(
  sliceSelector,
  slice => slice.viewport,
))

/**
 * Provides the current global map bounds. Note that it will make the component rerender on any viewport change.
 */
export const useMapBounds = () => useSelector(createSelector(
  sliceSelector,
  slice => slice.bounds,
))
