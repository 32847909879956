import { MapProvider } from 'react-map-gl/maplibre'
import { OverviewPage } from './overview-page'
import { useRoutePrintState } from '../state'
import { DetailedMapPage } from './detailed-map-page'
import { ImagesPage } from './images-page'

export const Pages = () => {
  const { detailedMapViewports, selectedImages } = useRoutePrintState()

  const mapPagesOffset = 2 + Math.ceil(selectedImages.length / 5)

  return (
    <MapProvider>
      <OverviewPage />
      {selectedImages.length > 0 && <ImagesPage pageNumber={2} />}
      {selectedImages.length > 5 && <ImagesPage pageNumber={3} secondPage />}
      {Object.keys(detailedMapViewports).map((mapKey, i) => (
        <DetailedMapPage key={mapKey} mapKey={mapKey} pageNumber={i + mapPagesOffset} />
      ))}
    </MapProvider>
  )
}
