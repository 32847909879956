import { useDispatch } from 'react-redux'
import { extractUrlLanguage } from './helpers'
import { languageUpdated } from './state'

/**
 * Updates language to redux state if found in URL.
 */
export function useUrlLanguage(): void {
  const dispatch = useDispatch()

  const urlLanguage = extractUrlLanguage()
  if (urlLanguage) {
    dispatch(languageUpdated(urlLanguage))
  }
}
