import { ApiServiceFailureResult, ApiServiceSuccessResult } from './types'

export function getSuccessResult<DataType>(data: DataType): ApiServiceSuccessResult<DataType> {
  return {
    success: true,
    data,
  }
}

export function getFailureResult<ErrorsType>(errors: ErrorsType): ApiServiceFailureResult<ErrorsType> {
  return {
    success: false,
    errors,
  }
}
