import { useLocale } from 'shared/util-intl'
import { showNotification } from './show-notification'

export function useCommonErrorNotification() {
  const { intl } = useLocale()

  return (details?: string) => {
    showNotification(
      {
        title: intl.formatMessage({
          id: 'common_error_notification_title',
          defaultMessage: 'Something went wrong',
        }),
        description: intl.formatMessage(
          {
            id: 'common_error_notification_description',
            defaultMessage: '{details} If the problem persists, please contact {email}.',
          },
          {
            details:
              details ||
              intl.formatMessage({
                id: 'common_error_notification_details_default',
                defaultMessage: 'Please try again later.',
              }),
            email: <a href="mailto:support@bikemap.net">support@bikemap.net</a>,
          },
        ),
        variant: 'danger',
      },
      'common_error',
    )
  }
}
