import { LineString } from 'geojson'
import { useEffect, useState } from 'react'
import { getBoundsFromGeometry } from 'shared/ui-map'
import { LngLatBoundsArray } from 'shared/util-geo'

type FitBoundsProps = {
  boundsToFit: LngLatBoundsArray | null
  onFitBounds: () => void
}

/**
 * Local state for fitting map bounds to a given geometry (if available) only once on initialization. Pass
 * the returned props to a map component.
 */
export const useInitialFitGeometry = (geometry: LineString | null): FitBoundsProps => {
  const [boundsToFit, setBoundsToFit] = useState<LngLatBoundsArray | null>(null)

  useEffect(() => {
    if (geometry) {
      setBoundsToFit(geometry ? getBoundsFromGeometry(geometry) : null)
    }
  }, [geometry])

  return {
    boundsToFit: boundsToFit || null,
    onFitBounds: () => {
      setBoundsToFit(null)
    },
  }
}
