import { useDispatch } from 'react-redux'
import { embedConfiguratorClosed, lightboxClosed, useRouteDetailsState } from '../state'
import { ImagesLightbox } from '../components/images/images-lightbox'
import { EmbedConfigurator } from '../components/embedding/embed-configurator'
import { WebAppAdditionalContent } from 'web-app/ui-layout'

export const RouteDetailsAdditionalContent = () => {
  const dispatch = useDispatch()
  const { openImageIndex, isEmbedConfiguratorOpen } = useRouteDetailsState()

  return (
    <>
      <WebAppAdditionalContent
        open={openImageIndex !== null}
        onClose={() => dispatch(lightboxClosed())}
        closeButtonOnColor
      >
        <ImagesLightbox />
      </WebAppAdditionalContent>
      <WebAppAdditionalContent
        open={isEmbedConfiguratorOpen}
        onClose={() => dispatch(embedConfiguratorClosed())}
        closeButtonOnColor
      >
        <EmbedConfigurator />
      </WebAppAdditionalContent>
    </>
  )
}
