import { useMemo } from 'react'
import { Feature, Position } from 'geojson'
import { getRouteLineLayerProps, getRouteOutlineLayerProps, getRouteLineFeatures } from './helpers'
import { LineLayerSpecification } from 'maplibre-gl'

type LayerProps = Omit<LineLayerSpecification, 'source'>

const identifier = 'inactive-route'

export const useInactiveRouteLines = (id: string, segments: Position[][]): {
  features: Feature[]
  lineLayerProps: LayerProps
  outlineLayerProps: LayerProps
} => {
  const features = useMemo<Feature[]>(() => getRouteLineFeatures(identifier, segments), [segments])
  const lineLayerProps = useMemo<LayerProps>(() => ({
    ...getRouteLineLayerProps('inactive'),
    id: `${id}-${identifier}-line`,
    filter: ['==', ['get', 'type'], identifier],
  }), [id])
  const outlineLayerProps = useMemo<LayerProps>(() => ({
    ...getRouteOutlineLayerProps('inactive'),
    id: `${id}-${identifier}-outline`,
    filter: ['==', ['get', 'type'], identifier],
  }), [id])
  return { features, lineLayerProps, outlineLayerProps }
}
