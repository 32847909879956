import { pushToDataLayer } from './helpers'

/**
 * Push event for a triggered notification.
 */
export function pushNotificationEvent(id: string) {
  pushToDataLayer({
    event: 'notification',
    id,
  })
}
