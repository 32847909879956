import clsx from 'clsx'
import React, { ForwardedRef, forwardRef } from 'react'
import { colors } from 'shared/ui-design-system'
import { BaseButton, BaseButtonProps } from '../../base/BaseButton'
import { PremiumLockIcon } from '../icons'

import styles from './Button.module.scss'

export interface ButtonProps extends BaseButtonProps {
  children?: string
  icon?: React.ReactNode
  size?: (
    'default' |
    'medium' |
    'small'
  )
  type?: (
    'button' |
    'submit' |
    'reset'
  )
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  block?: boolean
  withPremiumIndicator?: boolean
}

export const Button = forwardRef(({
  children,
  className,
  icon,
  variant = 'primary',
  type = 'button',
  disabled = false,
  onClick,
  size = 'default',
  block = false,
  withPremiumIndicator = false,
  ...rest
}: ButtonProps, ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>) => {
  const isIconOnly = !!icon && !children

  const baseButtonProps: BaseButtonProps = {
    className: clsx(
      styles[size],
      className,
      {
        [styles['iconOnly']]: isIconOnly,
        [styles['block']]: block,
        [styles['with-premium-lock']]: withPremiumIndicator,
      }
    ),
    disabled,
    variant,
    type,
    onClick,
    ...rest,
  }

  const iconClasses = clsx(styles['icon'], {
    [styles['iconOnlyIcon']]: isIconOnly,
  })

  return (
    <BaseButton {...baseButtonProps} ref={ref}>
      {!!icon && (
        <span className={iconClasses}>{icon}</span>
      )}
      {children}
      {withPremiumIndicator && (
        <PremiumLockIcon className={styles['premium-lock']} style={{ color: colors.premiumComp.premiumIndicator }} />
      )}
    </BaseButton>
  )
})

export default Button
