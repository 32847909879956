export const DEFAULT_ROUTING_PROFILE = 'bike_networks' // could be labeled "Balanced"
export const ROUTING_PROFILES = [
  DEFAULT_ROUTING_PROFILE,
  'cycling_paths', // not a real profile (bike_networks + high/medium/low)
  'bike_fastest',
  'e_bike',
  'heatmap',
  'smooth_ride',
  'racingbike',
  'mtb',
] as const

export const PREMIUM_ROUTING_PROFILES = [
  'cycling_paths',
  'e_bike',
  'heatmap',
  'smooth_ride',
  'racingbike',
  'mtb',
]

export const DEFAULT_ROUTING_PROFILE_CYCLING_PATH = 'medium'
export const ROUTING_PROFILE_CYCLING_PATHS = ['high', DEFAULT_ROUTING_PROFILE_CYCLING_PATH, 'low'] as const
