import { useMemo } from 'react'
import { AlongTheRouteAttribute, WayTypeCategory } from 'shared/data-access-core'
import { useLocale } from 'shared/util-intl'
import { AlongTheRouteDiagram } from './along-the-route-diagram'
import { colors } from 'shared/ui-design-system'

interface WayTypesDiagramProps {
  wayTypesAlongTheRoute: AlongTheRouteAttribute<WayTypeCategory>
  routeDistanceM: number
}

export const WayTypesDiagram = ({
  wayTypesAlongTheRoute,
  routeDistanceM,
}: WayTypesDiagramProps) => {
  const { intl } = useLocale()

  const labels = useMemo<Record<WayTypeCategory, string>>(() => ({
    missing: intl.formatMessage({
      id: 'way_type_missing_label',
      defaultMessage: 'Undefined',
    }),
    busy_road: intl.formatMessage({
      id: 'way_type_busy_road_label',
      defaultMessage: 'Busy road',
    }),
    road: intl.formatMessage({
      id: 'way_type_road_label',
      defaultMessage: 'Road',
    }),
    quiet_road: intl.formatMessage({
      id: 'way_type_quiet_road_label',
      defaultMessage: 'Quiet road',
    }),
    living_street: intl.formatMessage({
      id: 'way_type_living_street_label',
      defaultMessage: 'Living street',
    }),
    access_road: intl.formatMessage({
      id: 'way_type_access_road_label',
      defaultMessage: 'Access road',
    }),
    pedestrian_area: intl.formatMessage({
      id: 'way_type_pedestrian_area_label',
      defaultMessage: 'Pedestrian area',
    }),
    steps: intl.formatMessage({
      id: 'way_type_steps_label',
      defaultMessage: 'Steps',
    }),
    track: intl.formatMessage({
      id: 'way_type_track_label',
      defaultMessage: 'Track',
    }),
    path: intl.formatMessage({
      id: 'way_type_path_label',
      defaultMessage: 'Path',
    }),
    cycleway: intl.formatMessage({
      id: 'way_type_cycleway_label',
      defaultMessage: 'Cycleway',
    }),
  }), [intl])

  return (
    <AlongTheRouteDiagram
      alongTheRouteAttribute={wayTypesAlongTheRoute}
      colors={colors.wayTypesComp}
      labels={labels}
      routeDistanceM={routeDistanceM}
    />
  )
}
