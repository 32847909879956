import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userApi, UserEntity } from 'shared/data-access-core'
import { extractUnitPreferenceFromUrl } from 'shared/util-navigation'
import {
  USER_SLICE_KEY,
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  unitPreferenceUpdated,
  StateWithUserSlice,
} from './state'

export const useUserState = () => useSelector((state: StateWithUserSlice) => state[USER_SLICE_KEY])

/**
 * Get user from state with current fetch status.
 */
export const useUser = (): [UserEntity | null, boolean] => {
  const { user, isUserLoaded } = useUserState()
  return [user, isUserLoaded]
}

/**
 * Used only once on app load to fetch user and update the state.
 * Elsewhere, we just read the user from state.
 */
export const useFetchedUser = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserRequest())
    userApi.read().then((result) => {
      if (result.success) {
        dispatch(getUserSuccess(result.data))
      } else {
        dispatch(getUserFailure())
      }
    })
  }, [dispatch])
}

/**
 * Updates unit preference in redux state if found in URL.
 */
export function useUrlUnitPreference(): void {
  const dispatch = useDispatch()

  const urlUnitPreference = extractUnitPreferenceFromUrl()
  if (urlUnitPreference) {
    dispatch(unitPreferenceUpdated(urlUnitPreference))
  }
}
