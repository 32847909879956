import { Button } from 'shared/ui-components'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { useRoute } from 'web-app/feature-route'
import { embedConfiguratorOpened } from '../../state'

import styles from './embed-action.module.css'

export const EmbedAction = () => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const route = useRoute()

  return (
    <Button
      variant="secondary"
      icon={<CodeRoundedIcon />}
      disabled={!route || route.isPrivate}
      onClick={() => dispatch(embedConfiguratorOpened())}
      className={styles['button']}
    >
      {intl.formatMessage({
        id: 'route_details_sharing_button_embed',
        defaultMessage: 'Embed',
      })}
    </Button>
  )
}
