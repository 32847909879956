import { Typography } from '@mui/material'
import clsx from 'clsx'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { BaseButton } from '../../base/BaseButton'

import styles from './heart-button.module.css'

export interface HeartButtonProps {
  active: boolean
  amount: number
  onClick?: () => void
}

export const HeartButton = ({
  active,
  amount,
  onClick,
}: HeartButtonProps) => {
  const content = (
    <>
      {active ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      <Typography variant='body2' color='textPrimary' fontWeight={500} component='span'>
        {formatFavoriteCount(amount)}
      </Typography>
    </>
  )

  const className = clsx(styles['button'], { [styles['active']]: active })

  return onClick ? (
    <BaseButton className={className} onClick={onClick}>
      {content}
    </BaseButton>
  ) : (
    <div className={className}>
      {content}
    </div>
  )
}

function formatFavoriteCount(favoriteCount: number): string {
  if (favoriteCount > 1000000) {
    return '> 1M'
  }
  if (favoriteCount > 1000) {
    return `> ${Math.floor(favoriteCount / 1000)}k`
  }
  return favoriteCount.toString()
}
