declare global {
  interface Window {
    newrelic?: {
      noticeError?: (error: unknown, customAttributes?: object) => void
    }
  }
}

/**
 * Push errors to NewRelic.
 */
export const pushError = (error: unknown, details?: object, level?: number) => {
  if (
    level &&
    level >= 10 &&
    window.location.hostname !== 'localhost' &&
    window.newrelic &&
    typeof window.newrelic.noticeError === 'function'
  ) {
    window.newrelic.noticeError(error, details)
  }
}
