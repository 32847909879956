import clsx from 'clsx'
import React from 'react'
import { Modal, ModalProps } from '../Modal'
import { Typography } from '@mui/material'

import styles from './Dialog.module.scss'

export interface DialogProps {
  title: string
  text: string
  buttons: React.ReactNode
  open: boolean
  onClose?: ModalProps['onClose']
}

export const Dialog = ({
  title,
  text,
  buttons,
  open,
  onClose,
}: DialogProps) => (
  <Modal open={open} onClose={onClose} aria-label={title} role='dialog'>
    <div className={clsx(styles['content'], { [styles['with-close']]: onClose })}>
      <Typography variant='h3'>{title}</Typography>
      <Typography paragraph>{text}</Typography>
      <div className={styles['buttons']}>
        {buttons}
      </div>
    </div>
  </Modal>
)
