import { LocationList } from 'shared/ui-components'
import { ActionCurrentLocation } from './action-current-location'
import { ActionRoundTrip } from './action-round-trip'
import { ListSearchResults } from './list-search-results'
import {
  GeocodedWaypoint,
  RoutePlannerSliceDispatch,
  Waypoint,
  updateWaypoint,
  insertWaypoint,
  useIsRoundtrip,
  useWaypoints,
} from '../../state'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

interface SearchSuggestionsProps {
  waypointsListIndex: number
  waypointIndex: number | null
  beforeIndex: number | null
  isLast: boolean
  searchResults: GeocodedWaypoint[]
  afterSelect: () => void
  onInsert: () => void
}

export const SearchSuggestions = ({
  waypointsListIndex,
  waypointIndex,
  beforeIndex,
  isLast,
  searchResults,
  afterSelect,
  onInsert,
}: SearchSuggestionsProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { start } = useWaypoints()
  const isRoundtrip = useIsRoundtrip()

  const isRoundtripActionAvailable =
    start && // there is a start to route back to
    !isRoundtrip && // would make a difference for the route
    isLast && // is last item in the waypoints list
    waypointsListIndex > 1 // route wouldn't just collapse to two waypoints at the same position

  const handleResultSelect = useCallback((waypoint: Waypoint) => {
    if (waypointIndex !== null) {
      dispatch(updateWaypoint(waypointIndex, waypoint))
    } else if (beforeIndex !== null) {
      onInsert()
      dispatch(insertWaypoint(beforeIndex, waypoint))
    }
    afterSelect()
  }, [onInsert, afterSelect, beforeIndex, dispatch, waypointIndex])

  return (
    <LocationList.Holder>
      <ActionCurrentLocation onSelect={handleResultSelect} />
      {isRoundtripActionAvailable && <ActionRoundTrip onClick={() => handleResultSelect({ ...start })} />}
      <ListSearchResults results={searchResults} onSelect={handleResultSelect} />
    </LocationList.Holder>
  )
}
