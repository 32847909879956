import { useMemo } from 'react'
import { RouteSurfaces } from 'shared/data-access-core'
import { useLocale } from 'shared/util-intl'

type SurfaceLabels = {
  1: string
  2: string
  3: string
}

export const useSurfaceLabels = () => {
  const { intl } = useLocale()

  const labels = useMemo<SurfaceLabels>(
    () => ({
      1: intl.formatMessage({
        id: 'route_surface_paved',
        defaultMessage: 'Paved',
      }),
      2: intl.formatMessage({
        id: 'route_surface_unpaved',
        defaultMessage: 'Unpaved',
      }),
      3: intl.formatMessage({
        id: 'route_surface_gravel',
        defaultMessage: 'Gravel',
      }),
    }),
    [intl],
  )

  return labels
}

export const useSurfaces = (surfaces: RouteSurfaces): string[] => {
  const labels = useSurfaceLabels()
  return useMemo(() => surfaces.map(key => labels[key]), [surfaces, labels])
}
