import { ReactNode } from 'react'
import { Skeleton, Typography } from '@mui/material'

import styles from './route-statistics.module.css'

export const RouteStatisticsChangeIndicator = () => <span className={styles['change-indicator']} />

export interface RouteStatisticsItemProps {
  icon: ReactNode
  value?: string
  label: string
  withChangeIndicator?: boolean
}

export const RouteStatisticsItem = ({
  icon,
  value,
  label,
  withChangeIndicator,
}: RouteStatisticsItemProps) => (
  <div className={styles['item']}>
    {icon}
    <Typography variant='body2' fontWeight='500'>
      {value ?? <Skeleton width='70%' />}
      {withChangeIndicator && <RouteStatisticsChangeIndicator />}
    </Typography>
    <Typography variant='body2' color='textSecondary'>
      {label}
    </Typography>
  </div>
)

export interface RouteStatisticsProps {
  children: ReactNode
  columns?: number
}

export const RouteStatistics = ({
  children,
  columns = 4,
}: RouteStatisticsProps) => (
  <div className={styles['container']} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
    {children}
  </div>
)
