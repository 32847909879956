import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { INTL_SLICE_KEY, StateWithIntlSlice } from './types'

/**
 * To be used only once when providing language to `react-intl` wrapper.
 * Later it can be read with our useLocale() custom hook.
 */
export const useStateLanguage = () =>
  useSelector(
    createSelector(
      (state: StateWithIntlSlice) => state[INTL_SLICE_KEY],
      (slice) => slice.language,
    ),
  )

export default useStateLanguage
