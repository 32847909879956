import { LineString } from 'geojson'
import { API_URL_ROUTING } from '../../config'
import { addAuthHeader, postRequest } from '../../network'
import { getFailureResult, getSuccessResult } from '../shared/helpers'
import { ApiServiceResult } from '../shared/types'
import { ApiService } from '../shared/api-service'
import { RoutingRouteData, RoutingProfile, RoutingProfileCyclingPath, GetRouteErrors } from './types'
import { DEFAULT_ROUTING_PROFILE } from './config'
import { LngLat, decodePath, lngLatToPosition2d, positionToLngLat } from 'shared/util-geo'
import { RoutingResponse } from '../../responses/routing'
import { convertAlongTheRouteAttributes } from '../../responses/shared/along-the-route-attributes'

const USE_ELEVATION = true

export class RoutingApiService extends ApiService {

  /**
   * Get route data (geometry/polyline, distance, time, etc.) from provided waypoints.
   * @link https://bikemap.atlassian.net/wiki/spaces/IN/pages/1030914074/GraphHopper+Routing+Service
   * @link https://github.com/graphhopper/graphhopper/blob/6.2/docs/web/api-doc.md#http-post
   * @link https://docs.graphhopper.com/#operation/postRoute
   */
  getRoute = async (
    waypoints: LngLat[],
    locale: string,
    profile?: RoutingProfile,
    cyclingPath?: RoutingProfileCyclingPath
  ): ApiServiceResult<RoutingRouteData, GetRouteErrors> => {
    try {
      const res: RoutingResponse = await postRequest(API_URL_ROUTING, {
        headers: await addAuthHeader(),
        body: {
          points: waypoints.map(lngLatToPosition2d),
          locale,
          instructions: false,
          elevation: USE_ELEVATION,
          points_encoded: true,
          distance_details: ['bm_surface', 'bm_way_type', 'bike_network'],
          ...this.getRoutingProfileConfig(profile, cyclingPath),
        },
        type: 'json',
        skipResponseValidation: true,
      })

      if (res.paths && res.paths[0].points && res.paths[0].distance_details) {
        const path = res.paths[0]

        const geometry: RoutingRouteData['geometry'] = {
          type: 'LineString',
          coordinates: decodePath(path.points, USE_ELEVATION),
        }

        if (this.isValidGeometry(geometry)) {
          return getSuccessResult({
            geometry,
            distance: path.distance,
            time: path.time,
            ascent: path.ascend,
            descent: path.descend,
            snappedWaypoints: this.formatWaypoints(path.snapped_waypoints, waypoints.length),
            ...convertAlongTheRouteAttributes(path.distance_details),
          })
        }

        this.logError('Invalid geometry in routing response', null, { res, geometry })
        return getFailureResult({ invalidGeometryResponse: true })
      }

      this.logError('Invalid routing response', null, { res })

      if (res.hints && res.hints[0] && res.hints[0].details) {
        if (res.hints[0].details === 'com.graphhopper.util.exceptions.PointNotFoundException') {
          return getFailureResult({ pointNotFoundException: res.hints[0].point_index })
        }
        if (res.hints[0].details === 'com.graphhopper.util.exceptions.ConnectionNotFoundException') {
          return getFailureResult({ connectionNotFoundException: true })
        }
        if (res.hints[0].details === 'com.graphhopper.util.exceptions.MaximumNodesExceededException') {
          return getFailureResult({ maximumNodesExceededException: res.hints[0].max_visited_nodes })
        }
      }

      return getFailureResult({ unexpectedResponse: true })
    } catch (e) {
      this.logError('Could not get valid route', e)
      return getFailureResult({ unexpectedError: true })
    }
  }

  /**
   * Formats API request params needed for custom routing profile.
   */
  private getRoutingProfileConfig = (profile?: RoutingProfile, cyclingPath?: RoutingProfileCyclingPath) => {
    if (profile === 'cycling_paths') {
      return {
        profile: 'bike_networks',
        custom_model: {
          priority: [{
            if: 'road_class != CYCLEWAY',
            multiply_by: this.getCyclingPathsModelMultiplier(cyclingPath),
          }],
        },
        'ch.disable': true,
      }
    }

    return {
      profile: profile || DEFAULT_ROUTING_PROFILE,
    }
  }

  private getCyclingPathsModelMultiplier = (cyclingPath?: RoutingProfileCyclingPath): number => {
    if (cyclingPath === 'high') {
      return 0.01
    }
    if (cyclingPath === 'low') {
      return 0.85
    }
    return 0.5
  }

  /**
   * Checks if received geometry has at least 2 coordinates.
   */
  private isValidGeometry = (geometry: LineString|null): boolean => {
    return !!(geometry && Array.isArray(geometry.coordinates) && geometry.coordinates.length >= 2)
  }

  /**
   * Decode and extract waypoints to our lng/lat format.
   */
  private formatWaypoints = (waypoints: string|undefined, expectedLength: number): LngLat[] => {
    if (waypoints) {
      const decoded = decodePath(waypoints, USE_ELEVATION).map(positionToLngLat)
      if (decoded.length === expectedLength) {
        return decoded
      }
    }
    return []
  }

}
