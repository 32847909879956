export const PREMIUM_PLAN_PERIODS = [
  'monthly',
  'quarterly',
  'yearly',
  '3-year',
  'lifetime',
  'referral',
] as const

export type PremiumPlanPeriod = typeof PREMIUM_PLAN_PERIODS[number]|'free'

export type UserPreviewEntity = {
  id: number
  slug: string
  name: string
  avatar?: string
  isPremium: boolean
}

export const dummyUserPreviewEntity: UserPreviewEntity = {
  id: 12,
  name: 'John Doe',
  isPremium: false,
  avatar: '/avatar',
  slug: 'doe',
}

export type UserEntity = UserPreviewEntity & {
  planPeriod: PremiumPlanPeriod
  externalId?: string
  favoriteRoutesCount: number
  isStaff: boolean
  email: string
  coverImage?: string
  avatarRaw?: string
  coverImageRaw?: string
  hadTrial: boolean
}

export const dummyUserEntity: UserEntity = {
  ...dummyUserPreviewEntity,
  planPeriod: 'free',
  externalId: 'abc123',
  favoriteRoutesCount: 5,
  isStaff: false,
  email: 'user@email.at',
  avatarRaw: '/avatar',
  hadTrial: false,
}
