import { Point } from 'geojson'
import { RouteBikeTypes, RoutePreviewEntity, RouteSurfaces, UserPreviewEntity } from '../entities'
import { positionToLngLat } from 'shared/util-geo'

export type RouteSearchResponse = {
  ascent_total: number
  bounds: [number, number, number, number]
  count: number
  descent_total: number
  distance_total: number
  next: string | null
  previous: string | null
  results: {
    content_object: {
      altitude_difference_back: number
      altitude_difference: number
      category: RouteSurfaces | null
      created: string
      distance: number
      ground: RouteBikeTypes | null
      id: number
      is_favorite: boolean
      is_loop: boolean
      is_private: boolean
      location?: string
      route_favorite_count: number
      route_images: string[]
      start: Point
      staticmap: string | null
      title: string
      user: {
        id: number
        displayname: string
        is_subscribed: boolean
        avatar_image: string
        slug: string
        profile_url: string
      } | null
      views: number
    }
    content_type: string
    title: string
  }[]
}

export const dummyRouteSearchResponse: RouteSearchResponse = {
  count: 1673,
  next: '/search/?page=2',
  previous: null,
  distance_total: 138497776.84144592,
  ascent_total: 491048.55110168457,
  descent_total: 476963.15981411934,
  bounds: [
    -0.04496608029593653,
    -0.04496608029593653,
    0.04496608029593653,
    0.04496608029593653,
  ],
  results: [
    {
      content_type: 'route',
      content_object: {
        id: 2692507,
        user: {
          id: 6960,
          displayname: 'John Doe',
          avatar_image: '/avatar.jpg',
          is_subscribed: true,
          profile_url: '/profile',
          slug: 'doe',
        },
        title: 'Nibelungengasse 5, Vienna to Große Sperlgasse 37A, Vienna',
        location: 'Wien, Wien, Österreich',
        category: [],
        ground: [],
        views: 110,
        distance: 221021.89142979562,
        altitude_difference: 2662,
        altitude_difference_back: 2512,
        is_private: false,
        is_loop: false,
        start: {
          type: 'Point',
          coordinates: [
            16.318814,
            48.234703,
          ],
        },
        route_images: [
          '/image1.jpg',
          '/image2.jpg',
        ],
        staticmap: '/staticmap.png',
        is_favorite: false,
        created: '2024-01-25T08:01:53Z',
        route_favorite_count: 0,
      },
      title: 'Nibelungengasse 5, Vienna to Große Sperlgasse 37A, Vienna',
    },
    {
      content_type: 'route',
      content_object: {
        id: 2692508,
        user: null,
        title: 'Route in Vienna',
        location: 'Wien, Wien, Österreich',
        category: [1],
        ground: [],
        views: 11,
        distance: 81021.9179562,
        altitude_difference: 262,
        altitude_difference_back: 252,
        is_private: false,
        is_loop: false,
        start: {
          type: 'Point',
          coordinates: [
            16.310814,
            48.534703,
          ],
        },
        route_images: [],
        staticmap: '/staticmap.png',
        is_favorite: true,
        created: '2024-01-25T08:01:53Z',
        route_favorite_count: 0,
      },
      title: 'Route in Vienna',
    },
  ],
}

export function convertToRoutePreviewEntityResults(res: RouteSearchResponse): {
  results: number[]
  routes: { [routeId: number]: RoutePreviewEntity }
  creators: { [userId: number]: UserPreviewEntity }
} {
  const routes: { [routeId: number]: RoutePreviewEntity } = {}
  const creators: { [userId: number]: UserPreviewEntity } = {}

  const results: number[] = res.results.map((result) => {
    const contentObject = result.content_object
    routes[contentObject.id] = {
      id: contentObject.id,
      title: contentObject.title,
      distance: contentObject.distance,
      ascent: contentObject.altitude_difference,
      descent: contentObject.altitude_difference_back,
      isPrivate: contentObject.is_private,
      location: contentObject.location,
      favoriteCount: contentObject.route_favorite_count,
      created: new Date(contentObject.created).getTime(),
      surfaces: contentObject.ground || [],
      bikeTypes: contentObject.category || [],
      images: contentObject.route_images,
      isFavorite: contentObject.is_favorite,
      isLoop: contentObject.is_loop,
      staticMap: contentObject.staticmap || undefined,
      start: positionToLngLat(contentObject.start.coordinates),
      creatorId: contentObject.user?.id,
    }
    if (contentObject.user) {
      creators[contentObject.user.id] = {
        id: contentObject.user.id,
        slug: contentObject.user.slug,
        name: contentObject.user.displayname,
        avatar: contentObject.user.avatar_image,
        isPremium: contentObject.user.is_subscribed,
      }
    }
    return contentObject.id
  })

  return { results, routes, creators }
}
