import { DiscoverRouteMarkers } from 'shared/ui-map'
import { HomeSliceDispatch, routeSelectionCleared, routesFound, routesSearched, useHomeState, useResultsList } from '../state'
import { RouteBikeTypes, searchRoutes } from 'shared/data-access-core'
import { MAP_ID, WebAppMapPopup, useMapBounds } from 'web-app/feature-map'
import { useDispatch } from 'react-redux'
import { selectRoute } from '../state/thunks'
import { MainMapRoute, MainRouteTile, useRoute } from 'web-app/feature-route'
import { useEffect } from 'react'

const NUM_ROUTES = 12

export const MapDiscoverRoutes = () => {
  const dispatch = useDispatch() as HomeSliceDispatch
  const mapBounds = useMapBounds()
  const { selectedRoute } = useHomeState()
  const resultsList = useResultsList()
  const route = useRoute()

  useEffect(() => {
    dispatch(routesSearched())
    if (!mapBounds) return
    searchRoutes({
      bounds: mapBounds,
    }, {
      pageSize: NUM_ROUTES,
    }).then((result) => {
      if (result.success) {
        dispatch(routesFound(result.data))
      }
    })
  }, [dispatch, mapBounds])

  return (
    <>
      <DiscoverRouteMarkers
        id='discover-routes'
        mapId={MAP_ID}
        routeStarts={resultsList.map(({ route }) => ({
          routeId: route.id,
          routeType: getRouteType(route.bikeTypes),
          position: route.start,
        }))}
        onClick={(routeId) => dispatch(selectRoute(routeId))}
        onClickOutside={() => selectedRoute && dispatch(routeSelectionCleared())}
      />
      {selectedRoute && (
        <>
          <MainMapRoute />
          <WebAppMapPopup
            longitude={selectedRoute.lng}
            latitude={selectedRoute.lat}
            markerHeight={route && 40}
          >
            <MainRouteTile onClick={() => dispatch(routeSelectionCleared())} />
          </WebAppMapPopup>
        </>
      )}
    </>
  )
}

function getRouteType(bikeTypes: RouteBikeTypes): 'default' | 'road-bike' | 'mtb' {
  if (bikeTypes.length === 1) {
    if (bikeTypes[0] === 1) return 'road-bike'
    if (bikeTypes[0] === 2) return 'mtb'
  }
  return 'default'
}
