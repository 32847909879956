import { ExtensiveRouteEntity, UserPreviewEntity } from 'shared/data-access-core'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { StateWithUserSlice } from 'web-app/feature-user'

export const ROUTE_SLICE_KEY = 'route'

export type RouteState = {
  /** When this is false, the route state is either empty or cached and can be outdated / inconsistent */
  isRouteLoaded: boolean

  /** Whether the route is either private or can't be accessed for another reason */
  isRouteUnavailable: boolean

  /** The main route entity of the app's global state */
  route?: ExtensiveRouteEntity

  /** User who created the main route entity of the app's global state */
  creator?: UserPreviewEntity

  /** IDs of route collections assigned to the main route entity of the app's global state */
  assignedRouteCollectionIds?: number[]
}

export interface StateWithRouteSlice {
  [ROUTE_SLICE_KEY]: RouteState
}

export type RouteSliceDispatch = ThunkDispatch<StateWithRouteSlice & StateWithUserSlice, undefined, UnknownAction>
