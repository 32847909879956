import { useCallback, useEffect } from 'react'
import { useMap } from 'react-map-gl/maplibre'

export function useMapImage(url: string, name: string) {
  const mapRef = useMap()
  const currentMap = mapRef.current

  const addImage = useCallback(async () => {
    if (currentMap) {
      const map = currentMap.getMap()

      if (!map.hasImage(name)) {
        const { data } = await map.loadImage(url)
        if (data && !map.hasImage(name)) {
          map.addImage(name, data)
        }
      }
    }
  }, [currentMap, name, url])

  useEffect(() => {
    addImage()
    currentMap?.on('styledata', addImage)

    return () => {
      currentMap?.off('styledata', addImage)
    }
  }, [addImage, currentMap])
}
