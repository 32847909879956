import { useCallback } from 'react'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled'
import { LocationList } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useUserGeolocation } from 'web-app/feature-user'
import { Waypoint } from '../../state'

interface ActionCurrentLocationProps {
  onSelect: (waypoint: Waypoint) => void
}

export function ActionCurrentLocation({ onSelect }: ActionCurrentLocationProps) {
  const { intl } = useLocale()

  const { geolocation, isGeolocationLoading, isGeolocationDenied, geolocate } = useUserGeolocation()

  const handleClick = useCallback(() => {
    if (geolocation) {
      const { position, ...reverseGeocodedStrings } = geolocation.geocoded
      const waypoint = {
        ...position,
        ...reverseGeocodedStrings,
      }
      onSelect(waypoint)
    } else {
      geolocate()
    }
  }, [geolocate, geolocation, onSelect])

  return (
    <LocationList.Action
      title={intl.formatMessage({
        id: 'route_planner_location_results_current_location',
        defaultMessage: 'Current location',
      })}
      subtitle={
        isGeolocationLoading
          ? intl.formatMessage({
            id: 'route_planner_current_location_loading_subtitle',
            defaultMessage: 'Getting your current location...',
          })
          : geolocation?.geocoded.address
      }
      icon={isGeolocationDenied ? <LocationDisabledIcon /> : <MyLocationIcon />}
      onClick={handleClick}
    />
  )
}
