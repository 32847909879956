import { API_PATH_ROUTE_IMAGE, API_PATH_ROUTE_IMAGES, API_PATH_ROUTE_UPLOAD_IMAGE } from '../config'
import { RouteImageEntity } from '../entities'
import { addApiHeaders, deleteFromCoreApi, getFromCoreApi, postToCoreApi } from '../network'
import { RouteImageResponse } from '../responses'
import { ApiResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from 'shared/util-network'

type AddRouteImageErrors = MinimalEndpointErrors & {
  imageNotCreated?: true
}

export async function addRouteImage(routeId: number, image: File): ApiResult<File, AddRouteImageErrors> {
  try {
    const res: { route_image_created: boolean } = await postToCoreApi(API_PATH_ROUTE_UPLOAD_IMAGE, {
      params: { routeId },
      body: { image },
      headers: await addApiHeaders(),
    })

    if (res.route_image_created) {
      return createSuccessResult(image)
    }
    return createFailureResult({ imageNotCreated: true })
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}

export async function getRouteImages(routeId: number): ApiResult<RouteImageEntity[]> {
  try {
    const res: RouteImageResponse[] = await getFromCoreApi(API_PATH_ROUTE_IMAGES, {
      params: { routeId },
      headers: await addApiHeaders(),
    })
    return createSuccessResult(res.map(convertToRouteImageEntity))
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}

export async function deleteRouteImage(routeId: number, routeImageId: number): ApiResult<null> {
  try {
    await deleteFromCoreApi(API_PATH_ROUTE_IMAGE, {
      params: { routeId, routeImageId },
      headers: await addApiHeaders(),
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId, routeImageId })
  }
}

function convertToRouteImageEntity(res: RouteImageResponse): RouteImageEntity {
  return {
    id: res.id,
    routeId: res.route,
    url: res.url,
  }
}
