import { MarkerDragEvent, MarkerProps } from 'react-map-gl/maplibre'
import svg from './svgs/map-marker-edit.svg'
import { CommonMapMarker } from './shared/common-map-marker'
import { useState } from 'react'
import { LngLat } from '../types'

interface MapMarkerEditProps extends Omit<MarkerProps, 'onDragEnd'> {
  onDragStart: () => void
  onDragEnd: (position: LngLat) => void
}

/**
 * Moveable map marker that represents an editable position.
 */
export const MapMarkerEdit = ({
  onDragStart,
  onDragEnd,
  ...props
}: MapMarkerEditProps) => {
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [draggingPosition, setDraggingPosition] = useState<LngLat>({
    lng: props.longitude,
    lat: props.latitude,
  })

  const style = {
    cursor: isDragging ? 'grabbing' : 'grab',
    ...props.style,
  }

  const handleDragStart = (e: MarkerDragEvent) => {
    setDraggingPosition(e.lngLat)
    setIsDragging(true)
    onDragStart()
  }

  const handleDrag = (e: MarkerDragEvent) => {
    setDraggingPosition(e.lngLat)
  }

  const handleDragEnd = (e: MarkerDragEvent) => {
    onDragEnd(e.lngLat)
    setIsDragging(false)
    setDraggingPosition(e.lngLat)
  }

  return (
    <CommonMapMarker
      {...props}
      longitude={isDragging ? draggingPosition.lng : props.longitude}
      latitude={isDragging ? draggingPosition.lat : props.latitude}
      style={style}
      svg={svg}
      size='large'
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
    />
  )
}
