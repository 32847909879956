import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import styles from './select-row.module.scss'

export interface SelectRowOption<ValueType> {
  value: ValueType
  label: React.ReactNode
}

export interface SelectRowProps<ValueType> {
  options: SelectRowOption<ValueType>[]
  value: ValueType
  onSelect: (value: ValueType) => void
}

export function SelectRow<ValueType extends string>({
  options,
  value,
  onSelect,
}: SelectRowProps<ValueType>) {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: ValueType,
  ) => {
    if (value) {
      onSelect(value)
    }
  }

  return (
    <ToggleButtonGroup className={styles['root']} exclusive fullWidth value={value} onChange={handleChange}>
      {options.map((option, i) => (
        <ToggleButton className={styles['button']} key={i} value={option.value as string}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
