import { useMemo } from 'react'
import { AlongTheRouteAttribute, BikeNetworkCategory } from 'shared/data-access-core'
import { colors } from 'shared/ui-design-system'
import { useLocale } from 'shared/util-intl'
import { AlongTheRouteDiagram } from './along-the-route-diagram'

interface BikeNetworkDiagramProps {
  bikeNetworkAlongTheRoute: AlongTheRouteAttribute<BikeNetworkCategory>
  routeDistanceM: number
}

export const BikeNetworkDiagram = ({
  bikeNetworkAlongTheRoute,
  routeDistanceM,
}: BikeNetworkDiagramProps) => {
  const { intl } = useLocale()

  const labels = useMemo<Record<BikeNetworkCategory, string>>(() => ({
    missing: intl.formatMessage({
      id: 'bike_network_missing_label',
      defaultMessage: 'Other',
    }),
    international: intl.formatMessage({
      id: 'bike_network_international_label',
      defaultMessage: 'International cycling route',
    }),
    national: intl.formatMessage({
      id: 'bike_network_national_label',
      defaultMessage: 'National cycling route',
    }),
    regional: intl.formatMessage({
      id: 'bike_network_regional_label',
      defaultMessage: 'Regional cycling route',
    }),
    local: intl.formatMessage({
      id: 'bike_network_local_label',
      defaultMessage: 'Local cycling route',
    }),
  }), [intl])

  return (
    <AlongTheRouteDiagram
      alongTheRouteAttribute={bikeNetworkAlongTheRoute}
      colors={colors.bikeNetworkComp}
      labels={labels}
      routeDistanceM={routeDistanceM}
    />
  )
}
