import { dummyGeometry } from 'shared/util-geo'

export type AlongTheRouteAttribute<Category> = [
  startPositionIndex: number,
  endPositionIndex: number,
  category: Category,
  sectionDistanceM: number
][]

export const SURFACE_CATEGORIES = [
  'missing',
  'paving_stones',
  'concrete',
  'asphalt',
  'paved',
  'gravel',
  'unpaved',
  'snow',
  'metal',
  'rock',
  'metal_grid',
  'pebblestone',
  'mud',
  'wood',
  'ground',
  'woodchips',
  'cobblestones',
  'sand',
  'loose_gravel',
  'shells',
] as const

export type SurfaceCategory = (typeof SURFACE_CATEGORIES)[number]

export const WAY_TYPE_CATEGORIES = [
  'missing',
  'busy_road',
  'road',
  'quiet_road',
  'living_street',
  'access_road',
  'pedestrian_area',
  'steps',
  'track',
  'path',
  'cycleway',
] as const

export type WayTypeCategory = (typeof WAY_TYPE_CATEGORIES)[number]

export const BIKE_NETWORK_CATEGORIES = [
  'missing',
  'international',
  'national',
  'regional',
  'local',
] as const

export type BikeNetworkCategory = (typeof BIKE_NETWORK_CATEGORIES)[number]

export type AlongTheRouteAttributes = {
  surfacesAlongTheRoute: AlongTheRouteAttribute<SurfaceCategory> | null
  wayTypesAlongTheRoute: AlongTheRouteAttribute<WayTypeCategory> | null
  bikeNetworkAlongTheRoute: AlongTheRouteAttribute<BikeNetworkCategory> | null
}

export const dummyAlongTheRouteAttributes: AlongTheRouteAttributes = {
  surfacesAlongTheRoute: [
    [0, 3, 'missing', 34],
    [3, 12, 'asphalt', 1200],
    [12, dummyGeometry.coordinates.length - 1, 'missing', 766],
  ],
  wayTypesAlongTheRoute: [
    [0, 3, 'missing', 34],
    [3, 12, 'cycleway', 1200],
    [12, dummyGeometry.coordinates.length - 1, 'missing', 766],
  ],
  bikeNetworkAlongTheRoute: [
    [0, 3, 'missing', 34],
    [3, 12, 'regional', 1200],
    [12, dummyGeometry.coordinates.length - 1, 'missing', 766],
  ],
}
