import { Typography } from '@mui/material'
import { BikeNetworkDiagram, SurfacesAndWayTypes } from 'web-app/feature-route'
import { useRoutePlannerState } from '../state'
import { useLocale } from 'shared/util-intl'

export const RoutePlannerDiagrams = () => {
  const { intl } = useLocale()
  const {
    distance,
    surfacesAlongTheRoute,
    wayTypesAlongTheRoute,
    bikeNetworkAlongTheRoute,
  } = useRoutePlannerState()

  return distance && (surfacesAlongTheRoute || wayTypesAlongTheRoute) && bikeNetworkAlongTheRoute ? (
    <>
      <SurfacesAndWayTypes
        surfacesAlongTheRoute={surfacesAlongTheRoute}
        wayTypesAlongTheRoute={wayTypesAlongTheRoute}
        routeDistanceM={distance}
      />
      <Typography variant="h4" component="h2" marginTop='2rem'>
        {intl.formatMessage({
          id: 'route_planner_bike_networks_heading',
          defaultMessage: 'Cycle route network',
        })}
      </Typography>
      <BikeNetworkDiagram
        bikeNetworkAlongTheRoute={bikeNetworkAlongTheRoute}
        routeDistanceM={distance}
      />
    </>
  ) : (
    <Typography color='textSecondary'>
      {intl.formatMessage({
        id: 'route_planner_no_diagrams_available',
        defaultMessage: 'Save the route to see surface, way type and cycle route network information.',
      })}
    </Typography>
  )
}
