import React, { useEffect, useState } from 'react'

export const VhProvider = ({ children } : { children: React.ReactNode }) => {
  const [vh, setVh] = useState<number>()

  useEffect(() => {
    const getVh = () => {
      setVh(window.innerHeight * 0.01)
    }
    getVh()
    window.addEventListener('resize', getVh)
    return () => window.removeEventListener('resize', getVh)
  }, [])

  return (
    <div style={vh ? ({ '--vh': `${vh}px` } as React.CSSProperties) : undefined}>
      {children}
    </div>
  )
}
