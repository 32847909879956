import React, { useMemo } from 'react'
import _uniqueId from 'lodash/uniqueId'
import { AnalyticsObjectType } from '../../shared/types'

import styles from './BaseRadioButton.module.scss'

export interface BaseRadioButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  name: string
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  analytics?: AnalyticsObjectType
}

interface FinalBaseRadioButtonProps extends BaseRadioButtonProps {
  children: React.ReactNode
}

export function BaseRadioButton(props: FinalBaseRadioButtonProps) {
  const {
    children,
    className,
    name,
    analytics = {},
    ...inputProps
  } = props

  const id = useMemo(() => _uniqueId(name + '-'), [name])

  const testId = `radio-button-${name}-${inputProps.checked ? 'yes' : 'no'}`

  return (
    <div className={className}>
      <input
        className={styles['radio']}
        type='radio'
        id={id}
        name={name}
        data-testid={`${testId}-native`}
        {...inputProps}
      />
      <label
        className={styles['container']}
        htmlFor={id}
        {...analytics}
        role='radio'
        aria-checked={inputProps.checked}
        data-testid={testId}
      >
        <div className={styles['button']}>
          <div className={styles['selector']} />
        </div>
        {children}
      </label>
    </div>
  )
}

export default BaseRadioButton
