import { useMemo } from 'react'
import { Feature, Position } from 'geojson'
import { colors } from 'shared/ui-design-system'
import { LineLayerSpecification } from 'maplibre-gl'

type LayerProps = Omit<LineLayerSpecification, 'source'>

const identifier = 'control-point-relation'

export const useControlPointRelations = (id: string, lines: [Position, Position][]): {
  features: Feature[]
  layerProps: LayerProps
} => {
  const features = useMemo<Feature[]>(() => lines.map((coordinates: [Position, Position]) => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates,
    },
    properties: {
      type: identifier,
    },
  })), [lines])
  const layerProps = useMemo<LayerProps>(() => ({
    id: `${id}-${identifier}`,
    type: 'line',
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-dasharray': [1, 2],
      'line-width': 3,
      'line-color': colors.polylineComp.background,
    },
    filter: ['==', ['get', 'type'], identifier],
  }), [id])
  return { features, layerProps }
}
