import { ChangeEvent, useState } from 'react'
import { Checkbox, Input } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'

import styles from './embed-options-form.module.scss'

const MIN_SIZE = 250

export type EmbedOptions = {
  extended: boolean
  distanceMarkers: boolean
  width: number
  height: number
}

interface EmbedOptionsFormProps {
  value: EmbedOptions
  onChange: (value: EmbedOptions) => void
}

export const EmbedOptionsForm = ({ value, onChange }: EmbedOptionsFormProps) => {
  const { intl } = useLocale()

  const [size, setSize] = useState<{ width: number; height: number }>(value)

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      [e.target.name]: e.target.checked,
    })
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const changedValue = Number.parseInt(e.target.value)
    setSize({
      ...value,
      [e.target.name]: changedValue,
    })
    onChange({
      ...value,
      [e.target.name]: Math.max(changedValue, MIN_SIZE),
    })
  }

  const handleInputBlur = () => {
    setSize(value)
  }

  return (
    <form className={styles['options-form']}>
      <Checkbox name="extended" checked={value.extended} onChange={handleCheckboxChange}>
        {intl.formatMessage({
          id: 'embed_configurator_options_extended',
          defaultMessage: 'Show extended content',
        })}
      </Checkbox>
      <Checkbox name="distanceMarkers" checked={value.distanceMarkers} onChange={handleCheckboxChange}>
        {intl.formatMessage({
          id: 'embed_configurator_options_distance_markers',
          defaultMessage: 'Show distance markers',
        })}
      </Checkbox>
      <Input
        type="number"
        name="width"
        value={size.width.toString()}
        label={intl.formatMessage({
          id: 'embed_configurator_options_width_label',
          defaultMessage: 'Width',
        })}
        onChange={handleInputChange}
        min={MIN_SIZE}
        onBlur={handleInputBlur}
      />
      <Input
        type="number"
        name="height"
        value={size.height.toString()}
        label={intl.formatMessage({
          id: 'embed_configurator_options_height_label',
          defaultMessage: 'Height',
        })}
        onChange={handleInputChange}
        min={MIN_SIZE}
        onBlur={handleInputBlur}
      />
    </form>
  )
}
