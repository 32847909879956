import { MouseEvent, ReactElement, useEffect, useState } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { EditingField, editingDone, editingStarted, useIsEditingFlags } from '../state'
import { useDispatch } from 'react-redux'
import { Button, useMessages } from 'shared/ui-components'
import { useSaveContentChangesErrorNotification } from '../hooks/notifications'
import { Typography } from '@mui/material'
import { RouteSliceDispatch, saveRouteChanges, useIsOwnRoute, useRoute } from 'web-app/feature-route'

import styles from './editable-content.module.css'

interface EditContentButtonProps {
  name: EditingField
}

export const EditContentButton = ({ name }: EditContentButtonProps) => {
  const dispatch = useDispatch()
  const { editLabel } = useMessages()

  const handleEdit = () => {
    dispatch(editingStarted(name))
  }

  return (
    <Button variant="secondary" size="small" icon={<EditRoundedIcon />} onClick={handleEdit}>
      {editLabel}
    </Button>
  )
}

interface ContentFormButtonsProps {
  onSubmit: () => void
  onCancel: () => void
  isSubmitDisabled?: boolean
}

export const ContentFormButtons = ({ onSubmit, onCancel, isSubmitDisabled }: ContentFormButtonsProps) => {
  const { saveLabel, cancelLabel } = useMessages()

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <div className={styles['form-buttons']}>
      <Button variant="secondary" size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button type="submit" size="small" onClick={handleSubmit} disabled={isSubmitDisabled}>
        {saveLabel}
      </Button>
    </div>
  )
}

type RenderContentProps<ValueType> = {
  value: ValueType
  editButton?: ReactElement
}

type RenderFormProps<ValueType> = {
  name: string
  value: ValueType
  onChange: (value: ValueType, isValid?: boolean) => void
  buttons: ReactElement
}

interface EditableContentProps<ValueType> {
  name: EditingField
  currentValue?: ValueType
  renderContent: (props: RenderContentProps<ValueType>) => ReactElement
  renderForm: (props: RenderFormProps<ValueType>) => ReactElement
  renderLoading: () => ReactElement
  heading?: string
}

export function EditableContent<ValueType>({
  name,
  currentValue,
  renderContent,
  renderForm,
  renderLoading,
  heading,
}: EditableContentProps<ValueType>) {
  const dispatch = useDispatch() as RouteSliceDispatch
  const { isEditing, isEditingAnotherField } = useIsEditingFlags(name)
  const route = useRoute()
  const isOwnRoute = useIsOwnRoute()
  const showSaveContentChangesErrorNotification = useSaveContentChangesErrorNotification()

  const [value, setValue] = useState<ValueType | undefined>(currentValue)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    setValue(currentValue)
  }, [currentValue])

  const handleSubmit = async () => {
    if (isValid && route) {
      setIsSaving(true)
      try {
        const form = { [name]: value }
        await dispatch(saveRouteChanges({ form })).unwrap()
        dispatch(editingDone())
      } catch {
        showSaveContentChangesErrorNotification()
      }
      setIsSaving(false)
    }
  }

  const handleCancel = () => {
    setValue(currentValue)
    dispatch(editingDone())
  }

  return (
    <>
      {heading && (
        <div className={styles['heading']}>
          <Typography variant="h4" component="h2" marginBottom={0}>
            {heading}
          </Typography>
          {isOwnRoute &&
            !isSaving &&
            (isEditing ? (
              <ContentFormButtons onSubmit={handleSubmit} onCancel={handleCancel} isSubmitDisabled={!isValid} />
            ) : (
              !isEditingAnotherField && <EditContentButton name={name} />
            ))}
        </div>
      )}
      {isSaving || currentValue === undefined
        ? renderLoading()
        : isEditing && value !== undefined
          ? renderForm({
            name,
            value,
            onChange: (value, isValid = true) => {
              setValue(value)
              setIsValid(isValid)
            },
            buttons: (
              <ContentFormButtons onSubmit={handleSubmit} onCancel={handleCancel} isSubmitDisabled={!isValid} />
            ),
          })
          : renderContent({
            value: currentValue,
            editButton: isOwnRoute && !isEditingAnotherField ? <EditContentButton name={name} /> : undefined,
          })}
    </>
  )
}
