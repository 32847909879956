import { ReactNode } from 'react'

import styles from './web-app-footer.module.css'

interface WebAppFooterProps {
  children: ReactNode
}

/**
 * Main page footer of the Web App.
 */
export const WebAppFooter = ({
  children,
}: WebAppFooterProps) => (
  <div className={styles['container']}>
    {children}
  </div>
)
