import { Popup, PopupProps } from 'react-map-gl/maplibre'
import { useRef } from 'react'
import { useFixBlurryRendering } from './use-fix-blurry-rendering'

import styles from './map-popup.module.scss'

export interface MapPopupProps extends PopupProps {
  mapId: string
  hidden?: boolean
  markerHeight?: number
}

export const MapPopup = ({
  mapId,
  hidden,
  children,
  markerHeight = 60,
  ...props
}: MapPopupProps) => {
  const popupContentRef = useRef<HTMLDivElement>(null)
  useFixBlurryRendering(mapId, popupContentRef)

  return (
    <Popup
      className={styles['map-popup']}
      closeButton={false}
      closeOnClick={false}
      maxWidth="20rem"
      offset={{
        bottom: [0, -markerHeight],
        top: [0, 8],
        right: [-24, -30],
        left: [24, -30],
        'top-right': [0, 8],
        'top-left': [0, 8],
        'bottom-right': [0, -markerHeight],
        'bottom-left': [0, -markerHeight],
      }}
      focusAfterOpen
      style={{ visibility: hidden ? 'hidden' : 'visible' }}
      {...props}
    >
      <div ref={popupContentRef}>
        {children}
      </div>
    </Popup>
  )
}
