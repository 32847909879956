import { WebAppMap } from 'web-app/feature-map'
import { fittingGeometryBoundsEnded, useGeometryBounds } from '../state'
import { useDispatch } from 'react-redux'
import { MapFeatures } from './map-features'

export const RoutePlannerMap = () => {
  const dispatch = useDispatch()
  const geometryBounds = useGeometryBounds()

  return (
    <WebAppMap boundsToFit={geometryBounds} onFitBounds={() => dispatch(fittingGeometryBoundsEnded())}>
      <MapFeatures />
    </WebAppMap>
  )
}
