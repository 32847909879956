import { WebAppMap } from 'web-app/feature-map'
import { MapRoutes } from './map-routes'
import { useInitialFitGeometry } from './use-initial-fit-geometry'
import { useActiveGeometry, useInactiveGeometry, useRouteImportState, useSelectedRouteGeometry } from '../state'

export const RouteImportMap = () => {
  const { step, fromStep } = useRouteImportState()
  const activeGeometry = useActiveGeometry()
  const inactiveGeometry = useInactiveGeometry()
  const selectedRouteGeometry = useSelectedRouteGeometry()

  const fitBoundsProps = useInitialFitGeometry(
    step === 'matching' || step === 'simplify'
      ? fromStep === 'upload'
        ? activeGeometry
        : null
      : step === 'save'
        ? selectedRouteGeometry
        : activeGeometry,
  )

  return (
    <WebAppMap {...fitBoundsProps}>
      <MapRoutes activeGeometry={activeGeometry} inactiveGeometry={inactiveGeometry} />
    </WebAppMap>
  )
}
