import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { reset, useBaseRoute } from '../state'
import { useNavigate } from 'react-router-dom'
import { WEB_APP_PATH_ROUTE_DETAILS } from 'shared/util-navigation'
import { useCancel } from 'web-app/feature-navigation'

export const useRoutePlannerCancel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cancel = useCancel()
  const baseRoute = useBaseRoute()

  return useCallback(() => {
    dispatch(reset())
    if (baseRoute) {
      navigate(WEB_APP_PATH_ROUTE_DETAILS.replace(':routeId', baseRoute.id.toString()))
    } else {
      cancel()
    }
  }, [baseRoute, cancel, dispatch, navigate])
}
