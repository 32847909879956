import { Middleware, isAction } from '@reduxjs/toolkit'
import { routingFailure } from './state'
import { undo } from './state-undoable'
import { PLANNER_SLICE_KEY } from './types'

/**
 * Dispatch undo/redo-related actions.
 * Doing it here ensures correct async order of actions, either before or after specific action.
 */
export const undoRedoMiddleware: Middleware = (store) => (next) => (action) => {
  const nextAction = next(action)

  // Dispatch undo action when routing fails
  if (isAction(action) && action.type === routingFailure.type) {
    const canUndo = store.getState()[PLANNER_SLICE_KEY].past.length > 0
    if (canUndo) {
      store.dispatch(undo())
    }
  }

  return nextAction
}
