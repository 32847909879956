import { Skeleton } from '@mui/material'
import { LocationListButton, LocationListButtonProps } from './LocationListButton'

import styles from './LocationListItem.module.css'

export const LocationListItem = (props: LocationListButtonProps) => (
  <li className={styles['item']}>
    <LocationListButton {...props} />
  </li>
)

export const LocationListLoadingItem = () => (
  <li className={styles['item']}>
    <Skeleton variant='rectangular' animation='wave' className={styles['loading-item']} />
  </li>
)
