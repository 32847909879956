import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LngLat } from 'shared/util-geo'
import { useElevationCurveContext } from 'shared/feature-elevation-curve'
import { EditableMapRoute } from 'shared/ui-map'
import {
  useRoutePlannerState,
  waypointSelected,
  selectionCanceled,
  RoutePlannerSliceDispatch,
  moveWaypoint,
  useBaseRoute,
  useControlPoints,
  removeControlPoint,
  updateDraggedControlPoint,
  insertDraggedControlPoint,
  segmentDivided,
} from '../../state'
import { throttle } from 'lodash'
import { ROUTE_ID } from '../settings'
import { getSegmentsAffectedByControlPoint, getSegmentsAffectedByWaypoint } from './helpers'
import { useRoutePreview } from './use-route-preview'
import { useRouteTooltips } from './use-route-tooltips'

export function RoutePlannerMapRoute() {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { selectionIndexes, onSelectionIndexesChange } = useElevationCurveContext()
  const route = useBaseRoute()
  const { selectedWaypoint, routingMode, waypoints } = useRoutePlannerState()
  const controlPoints = useControlPoints()
  const {
    calculateWaypointDragPreview,
    calculateControlPointDragPreview,
    calculateSegmentDragPreview,
    resetPreview,
    mapRouteGeometry,
    highlightedPreviewSegments,
  } = useRoutePreview()
  const { tooltip, triggerControlPointTooltip, triggerSegmentTooltip, resetTooltip } = useRouteTooltips()

  /** Indexes of segments that would be affected by what's currently hovered */
  const [hoveredSegments, setHoveredSegments] = useState<number[]>([])

  const dragPreviewThrottling = useMemo(() => routingMode === 'routing' ? 300 : 0, [routingMode])

  const handleWaypointHover = (waypointIndex: number) => {
    const affectedSegments = getSegmentsAffectedByWaypoint(waypoints, controlPoints, waypointIndex)
    if (affectedSegments) {
      setHoveredSegments(affectedSegments)
      onSelectionIndexesChange()
    }
  }

  const handleWaypointClick = (waypointIndex: number) => {
    const waypoint = waypoints[waypointIndex]
    if (waypointIndex === waypoints.length - 1 && waypoint?.isImplicit && waypoint.controlPointIndex) {
      dispatch(removeControlPoint(waypoint.controlPointIndex)) // remove implicit destination immediately
    } else {
      dispatch(waypointSelected(waypointIndex))
    }
  }

  const handleWaypointDrag = useMemo(
    () => throttle(async (waypointIndex: number, lngLat: LngLat) => {
      if (selectedWaypoint) {
        dispatch(selectionCanceled())
      }
      calculateWaypointDragPreview(waypointIndex, lngLat)
    }, dragPreviewThrottling),
    [dragPreviewThrottling, selectedWaypoint, calculateWaypointDragPreview, dispatch]
  )

  const handleWaypointDrop = async (index: number, lngLat: LngLat) => {
    handleWaypointDrag.cancel()
    await dispatch(moveWaypoint(index, lngLat))
    resetPreview()
    setHoveredSegments([])
  }

  const handleControlPointHover = (controlPointIndex: number) => {
    const affectedSegments = getSegmentsAffectedByControlPoint(controlPoints, controlPointIndex)
    if (affectedSegments) {
      setHoveredSegments(affectedSegments)
      onSelectionIndexesChange()
    }
    triggerControlPointTooltip()
  }

  const handleControlPointDrag = useMemo(
    () => throttle(calculateControlPointDragPreview, dragPreviewThrottling),
    [calculateControlPointDragPreview, dragPreviewThrottling]
  )

  const handleControlPointDrop = async (index: number, lngLat: LngLat) => {
    handleControlPointDrag.cancel()
    await dispatch(updateDraggedControlPoint(index, lngLat))
    resetPreview()
    setHoveredSegments([])
  }

  const handleSegmentClick = (segmentIndex: number, lngLat: LngLat) => {
    dispatch(segmentDivided({
      segmentIndex,
      location: { ...lngLat },
    }))
    setHoveredSegments([])
  }

  const handleSegmentHover = (index: number) => {
    setHoveredSegments([index])
    onSelectionIndexesChange()
    triggerSegmentTooltip()
  }

  const handleSegmentDrag = useMemo(
    () => throttle(async (segmentIndex: number, lngLat: LngLat) => {
      if (selectedWaypoint) {
        dispatch(selectionCanceled())
      }
      calculateSegmentDragPreview(segmentIndex, lngLat)
    }, dragPreviewThrottling),
    [dragPreviewThrottling, selectedWaypoint, calculateSegmentDragPreview, dispatch]
  )

  const handleSegmentDrop = async (segmentIndex: number, lngLat: LngLat) => {
    handleSegmentDrag.cancel()
    await dispatch(insertDraggedControlPoint(segmentIndex, lngLat))
    resetPreview()
    setHoveredSegments([])
  }

  const handleLeave = () => {
    setHoveredSegments([])
    resetTooltip()
  }

  /** Preview segments or combination of potentially affected segments from hovering and selection */
  const highlightedSegments = useMemo<number[]>(() => (
    (selectedWaypoint !== null && getSegmentsAffectedByWaypoint(waypoints, controlPoints, selectedWaypoint)) || []
  ).concat(hoveredSegments),
  [controlPoints, hoveredSegments, selectedWaypoint, waypoints])

  // Reset selected section (from elevation curve) when geometry changes
  useEffect(() => {
    if (selectionIndexes) {
      onSelectionIndexesChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRouteGeometry])

  return (
    <EditableMapRoute
      id={ROUTE_ID}
      geometry={mapRouteGeometry}
      waypoints={waypoints}
      originalGeometry={route?.geometry}
      selectedWaypoint={selectedWaypoint}
      highlightedSegments={highlightedPreviewSegments || highlightedSegments}
      tooltip={tooltip}
      onWaypointHover={handleWaypointHover}
      onWaypointClick={handleWaypointClick}
      onWaypointDrag={handleWaypointDrag}
      onWaypointDrop={handleWaypointDrop}
      onControlPointHover={handleControlPointHover}
      onControlPointClick={(index) => dispatch(removeControlPoint(index))}
      onControlPointDrag={handleControlPointDrag}
      onControlPointDrop={handleControlPointDrop}
      onSegmentHover={handleSegmentHover}
      onSegmentClick={handleSegmentClick}
      onSegmentDrag={handleSegmentDrag}
      onSegmentDrop={handleSegmentDrop}
      onLeave={handleLeave}
    />
  )
}
