import { addApiHeaders, getFromCoreApi } from '../../network'
import { API_PATH_MAP_STYLES } from '../../config'
import { MapStyleEntity } from './types'
import { ApiServiceResult } from '../shared/types'
import { ApiService } from '../shared/api-service'
import { getFailureResult, getSuccessResult } from '../shared/helpers'

export interface MapStyleResponse {
  name: string,
  thumbnail: string,
  style: string,
  is_premium: boolean,
  is_default: boolean,
}

type MapStylesListErrors = {
  unexpectedError?: true
}

export class MapStylesApiService extends ApiService {

  /**
   * Get the list of available map styles.
   */
  async list(): ApiServiceResult<MapStyleEntity[], MapStylesListErrors> {
    try {
      const res: MapStyleResponse[] = await getFromCoreApi(API_PATH_MAP_STYLES, {
        headers: await addApiHeaders(),
      })

      return getSuccessResult(res.map(this.getEntityFromResponse))
    } catch (e) {
      this.logError('Unexpected error while fetching map styles', e)
      return getFailureResult({ unexpectedError: true })
    }
  }

  /**
   * Convert a single map style entry of the response to a MapStyleEntity.
   */
  private getEntityFromResponse(response: MapStyleResponse): MapStyleEntity {
    return {
      name: response.name,
      thumbnail: response.thumbnail,
      isPremium: response.is_premium,
      isDefault: response.is_default,
      url: response.style,
    }
  }

}
