import { AVAILABLE_LOCALES } from './config'
import { ReactElement } from 'react'
import { IntlShape as BaseIntlShape } from 'react-intl'

export type AvailableLocale = (typeof AVAILABLE_LOCALES)[number]

export type IntlNamespace = 'web-app' | 'widgets' | 'website'

export const INTL_SLICE_KEY = 'intl'

export type IntlStateType = {
  language: AvailableLocale
}

export interface StateWithIntlSlice {
  [INTL_SLICE_KEY]: IntlStateType
}

export interface IntlProviderProps {
  locale?: AvailableLocale
  loading?: ReactElement
  children?: ReactElement
  namespace: IntlNamespace
}

export type IntlShape = BaseIntlShape & {
  locale: AvailableLocale
}
