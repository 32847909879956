import { FC, ReactNode, RefObject, useEffect, useRef } from 'react'
import { BottomSheet, BottomSheetSnapPointProps } from 'shared/ui-components'
import { useLayoutMediaQueries, useLayoutRefs, useLayoutState } from './layout-hooks'
import { TopContentContainer } from './web-app-top-content'
import { ScrollTooltip } from './scroll-tooltip'

import styles from './web-app-main-content.module.scss'

export interface WebAppMainContentProps {
  Header: FC<{ withUserNavigation: boolean }>
  children: ReactNode
  footer?: ReactNode
  secondary?: boolean
}

const STANDARD_SHEET_HEIGHT = 320
const SHEET_GRAB_HEIGHT = 32 // needs to match height of "div[data-rsbs-header]" 2rem
const MAIN_NAV_TO_BOTTOM_SHEET = SHEET_GRAB_HEIGHT + 8 // 0.5rem between main nav and top of BottomSheet

const getDefaultSheetHeight = (minHeight: number): number => Math.min(minHeight, STANDARD_SHEET_HEIGHT)

const getClosedSheetHeight = (headerHeight: number, footerHeight: number): number => headerHeight + footerHeight

const getSnapPoints = (
  snapPointProps: BottomSheetSnapPointProps,
  contentHeaderRef: RefObject<HTMLDivElement>
): number[] => {
  const { minHeight, maxHeight, headerHeight, footerHeight } = snapPointProps
  const mainNavigationHeight = contentHeaderRef.current ? contentHeaderRef.current.offsetHeight : 0
  const belowMainNavigation = maxHeight - mainNavigationHeight + headerHeight - MAIN_NAV_TO_BOTTOM_SHEET
  return [
    // Slightly below main navigation - or at least the sheet itself (minHeight)
    Math.min(belowMainNavigation, minHeight),
    // Standard height from bottom - but not bigger than sheet itself
    getDefaultSheetHeight(minHeight),
    // Sheet's header + footer
    getClosedSheetHeight(headerHeight, footerHeight),
    // Sheet's grab handle
    headerHeight,
  ]
}

const MainContentLargeViewport = ({
  Header,
  children,
  footer,
}: WebAppMainContentProps) => {
  const { contentFooterRef } = useLayoutRefs()
  const { isUserMenuShown } = useLayoutMediaQueries()

  const mainContentRef = useRef<HTMLDivElement | null>(null)

  return (
    <div className={styles['root']}>
      <Header withUserNavigation={!isUserMenuShown} />
      <div
        ref={mainContentRef}
        className={styles['main-content']}
      >
        {children}
        {footer && (
          <>
            <div className={styles['main-content-bottom-overlay']} />
            <div className={styles['main-content-shadow']} />
          </>
        )}
      </div>
      {footer && (
        <div className={styles['content-footer']}>
          <ScrollTooltip mainContentRef={mainContentRef} />
          <div ref={contentFooterRef}>
            {footer}
          </div>
        </div>
      )}
    </div>
  )
}

const MainContentFullScreen = ({
  Header,
  children,
  footer,
}: WebAppMainContentProps) => {
  return (
    <div className={styles['full-screen']}>
      <Header withUserNavigation />
      {children}
      {footer && (
        <div className={styles['full-screen-content-footer']}>
          {footer}
        </div>
      )}
    </div>
  )
}

const MainContentSmallViewport = ({
  Header,
  children,
  footer,
  secondary,
}: WebAppMainContentProps) => {
  const { contentHeaderRef, bottomSheetRef, contentFooterRef } = useLayoutRefs()
  const { isUserMenuShown } = useLayoutMediaQueries()

  const mainContentRef = useRef<HTMLDivElement | null>(null)
  const mainContentInnerRef = useRef<HTMLDivElement>(null)

  const innerMainContent = mainContentInnerRef.current
  useEffect(() => {
    if (innerMainContent) {
      const grandparent = innerMainContent.parentElement?.parentElement
      if (grandparent instanceof HTMLDivElement) {
        mainContentRef.current = grandparent
      }
    }
  }, [innerMainContent, mainContentRef])

  return (
    <>
      <div
        className={styles['content-header-small-viewport']}
        ref={contentHeaderRef}
      >
        <Header withUserNavigation={!isUserMenuShown} />
      </div>
      <BottomSheet
        ref={bottomSheetRef}
        open
        skipInitialTransition
        header={<TopContentContainer />}
        footer={footer && (
          <div ref={contentFooterRef} onPointerMove={e => e.stopPropagation()}>
            <ScrollTooltip mainContentRef={mainContentRef} />
            {footer}
          </div>
        )}
        snapPoints={(p) => getSnapPoints(p, contentHeaderRef)}
        defaultSnap={({ minHeight, headerHeight, footerHeight }) => getDefaultSheetHeight(minHeight)}
        blocking={false}
        scrollLocking={false} // necessary for scrollable premium modal
        secondary={secondary}
      >
        <div ref={mainContentInnerRef}>
          {children}
        </div>
      </BottomSheet>
    </>
  )
}

/**
 * Main content of a Web App view. Header and footer need to be injected. It renders as a card on
 * large viewports, on small viewports optionally full screen or as bottom sheet.
 */
export const WebAppMainContent = (props: WebAppMainContentProps) => {
  const { isLargeViewport } = useLayoutMediaQueries()
  const { isFullScreenContent } = useLayoutState()

  return isLargeViewport ? (
    <MainContentLargeViewport {...props} />
  ) : isFullScreenContent ? (
    <MainContentFullScreen {...props} />
  ) : (
    <MainContentSmallViewport {...props} />
  )
}
