import { ArrowAscent, ArrowDescent } from '../icons'
import { TooltipProps } from 'recharts'
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent'
import clsx from 'clsx'

import styles from './tooltip.module.scss'

export const Tooltip = ({ payload = [], labelFormatter }: TooltipProps<ValueType, NameType>) => {
  const label = labelFormatter && payload && payload[0] !== undefined
    ? labelFormatter(payload[0]?.value, [])
    : payload[0]?.value

  return (
    <div className={styles['tooltip']}>
      <span className={styles['tooltiptext']}>{label}</span>
    </div>
  )
}

interface SectionTooltipProps extends TooltipProps<ValueType, NameType> {
  ascent?: string
  descent?: string
}

export const SectionTooltip = ({ ascent, descent } : SectionTooltipProps) => {
  return (
    <div className={styles['tooltip']}>
      <div className={clsx(styles['tooltiptext'], styles['tooltiptext-long'])}>
        <span className={styles['section-tooltiptext']}>
          <ArrowAscent fontSize="small" />
          {ascent}
        </span>
        <span className={styles['section-tooltiptext']}>
          <ArrowDescent fontSize="small" />
          {descent}
        </span>
      </div>
    </div>
  )
}
