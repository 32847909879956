import { has, isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { LineString } from 'geojson'
import { RouteImportStateSliceType, RouteImportState, RouteImportSelectedRouteType } from './types'
import { IMPORT_SLICE_KEY } from './state'

/**
 * Get part of the global reducer responsible for route import.
 */
export const routeImportSliceSelector = (state: RouteImportStateSliceType) => state[IMPORT_SLICE_KEY]

/**
 * Get route data from state.
 */
const selectRouteData = (state: RouteImportState) => state.routeData

/**
 * If we have original route data.
 */
export const hasGeometry: (state: RouteImportState) => boolean = createSelector(
  selectRouteData,
  (routeData) => !!(routeData && has(routeData, 'geometry') && !isEmpty(routeData.geometry)),
)

/**
 * If we have matched route data.
 */
export const hasMatching: (state: RouteImportState) => boolean = createSelector(
  selectRouteData,
  (routeData) => !!(routeData && has(routeData, 'geometryMatched') && !isEmpty(routeData.geometryMatched)),
)

/**
 * If we have simplified route data.
 */
export const hasSimplify: (state: RouteImportState) => boolean = createSelector(
  (state: RouteImportState) => state,
  (state: RouteImportState) => !!(has(state, 'simplifiedRouteData') && !isEmpty(state.simplifiedRouteData)),
)

/**
 * Find which route type to use for import based on current state.
 * Both matching and simplification are selected (true) by default
 * and both can be skipped depending on backend responses.
 */
export const getSelectedRouteType: (state: RouteImportState) => RouteImportSelectedRouteType = createSelector(
  [
    (state) => state.isMatchingSelected,
    (state) => state.isSimplifySelected,
    (state) => hasMatching(state),
    (state) => hasSimplify(state),
  ],
  (isMatchingSelected, isSimplifySelected, hasMatching, hasSimplify) => {
    if (isMatchingSelected && hasMatching) {
      return 'matched'
    }
    if (isSimplifySelected && hasSimplify) {
      return 'simplified'
    }
    return 'original'
  },
)

/**
 * Try to find geometry of the original route.
 */
const getOriginalGeometry: (state: RouteImportState) => LineString | null = createSelector(
  selectRouteData,
  (routeData) => (routeData && has(routeData, 'geometry') ? routeData.geometry : null),
)

/**
 * Try to find geometry of the map-matched route.
 */
const getMatchedGeometry: (state: RouteImportState) => LineString | null = createSelector(
  selectRouteData,
  (routeData) => (routeData && has(routeData, 'geometryMatched') ? routeData.geometryMatched : null),
)

/**
 * Try to find geometry of simplified route.
 */
const getSimplifiedGeometry: (state: RouteImportState) => LineString | null = createSelector(
  (state: RouteImportState) => state.simplifiedRouteData,
  (simplifiedRouteData) =>
    simplifiedRouteData && has(simplifiedRouteData, 'geometry') ? simplifiedRouteData.geometry : null,
)

/**
 * Find `geometry` data for currently selected route.
 */
export const getSelectedRouteGeometry: (state: RouteImportState) => LineString | null = createSelector(
  (state: RouteImportState) => state,
  (state: RouteImportState) => {
    const geometryMatched = getMatchedGeometry(state)
    const geometrySimplified = getSimplifiedGeometry(state)

    const selectedRouteType = getSelectedRouteType(state)
    if (selectedRouteType === 'matched' && geometryMatched) {
      return geometryMatched
    }
    if (selectedRouteType === 'simplified' && geometrySimplified) {
      return geometrySimplified
    }
    return getOriginalGeometry(state)
  },
)

/**
 * Extract `geometry` data for active (highlighted) route based on current step and selection.
 * Returns null if current step doesn't have active route (e.g. upload).
 */
export const getActiveGeometry: (state: RouteImportState) => LineString | null = createSelector(
  (state: RouteImportState) => state,
  (state: RouteImportState) => {
    const { step, isMatchingSelected, isSimplifySelected } = state

    const geometry = getOriginalGeometry(state)

    if (step === 'upload') {
      return geometry
    }

    const geometryMatched = getMatchedGeometry(state)
    const geometrySimplified = getSimplifiedGeometry(state)

    if (step === 'matching' && geometryMatched) {
      if (isMatchingSelected) {
        return geometryMatched
      } else {
        return geometry
      }
    }
    if (step === 'simplify' && geometrySimplified) {
      if (isSimplifySelected) {
        return geometrySimplified
      } else {
        return geometry
      }
    }
    if (step === 'save') {
      return getSelectedRouteGeometry(state)
    }

    return null
  },
)

/**
 * Extract `geometry` data for inactive (grayed out) route based on current step and selection.
 * Returns null if current step doesn't have inactive route (e.g. upload or save)
 */
export const getInactiveGeometry: (state: RouteImportState) => LineString | null = createSelector(
  (state: RouteImportState) => state,
  (state: RouteImportState) => {
    const { step, isMatchingSelected, isSimplifySelected } = state

    if (step === 'upload' || step === 'save') {
      return null
    }

    const geometry = getOriginalGeometry(state)
    const geometryMatched = getMatchedGeometry(state)
    const geometrySimplified = getSimplifiedGeometry(state)

    if (step === 'matching' && geometryMatched) {
      if (isMatchingSelected) {
        return geometry
      } else {
        return geometryMatched
      }
    }

    if (step === 'simplify' && geometrySimplified) {
      if (isSimplifySelected) {
        return geometry
      } else {
        return geometrySimplified
      }
    }

    return null
  },
)

/**
 * Find distance for currently selected route.
 */
export const getSelectedRouteDistance: (state: RouteImportState) => number = createSelector(
  (state: RouteImportState) => state,
  (state: RouteImportState) => {
    const selectedRouteType = getSelectedRouteType(state)
    const routeData = selectRouteData(state)
    if (selectedRouteType === 'matched' && routeData) {
      return routeData.distanceMatched
    }
    if (selectedRouteType === 'simplified' && state.simplifiedRouteData) {
      // Distance from simplification is not reliable
      if (!routeData || !routeData.distance) return state.simplifiedRouteData.distance
      const isDistancePlausible = Math.abs(1 - state.simplifiedRouteData.distance / routeData.distance) < 0.01
      return isDistancePlausible ? state.simplifiedRouteData.distance : routeData.distance
    }
    if (selectedRouteType === 'original' && routeData) {
      return routeData.distance
    }
    return 0
  },
)
