import AddIcon from '@mui/icons-material/Add'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { useDropzone } from 'react-dropzone'
import { Button } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'

import styles from './route-poi-form-image.module.css'

const MAX_IMAGE_FILE_SIZE_MB = 3

interface PreviewImageProps {
  src: string
  onReplace: () => void
  onRemove: () => void
}

const PreviewImage = ({ src, onReplace, onRemove }: PreviewImageProps) => {
  const { intl } = useLocale()

  return (
    <div className={styles['preview-image']}>
      <img
        src={src}
        alt={intl.formatMessage({
          id: 'route_poi_form_image_alt',
          defaultMessage: 'Photo of this route highlight',
        })}
      />
      <div className={styles['preview-image-buttons']}>
        <Button variant="secondary" size="medium" icon={<EditRoundedIcon />} onClick={onReplace}>
          {intl.formatMessage({
            id: 'route_poi_form_image_replace_label',
            defaultMessage: 'Replace',
          })}
        </Button>
        <Button variant="danger-secondary" size="medium" icon={<DeleteForeverRoundedIcon />} onClick={onRemove} />
      </div>
    </div>
  )
}

interface RoutePoiFormImageProps {
  currentImage?: string
  value?: File
  onChange: (file: File) => void
  onRemove: () => void
  onFileError: (message: string) => void
}

export const RoutePoiFormImage = ({ currentImage, value, onChange, onRemove, onFileError }: RoutePoiFormImageProps) => {
  const { intl } = useLocale()

  const { getInputProps, open } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxSize: MAX_IMAGE_FILE_SIZE_MB * 1024 * 1024,
    multiple: false,
    onDrop: (files) => onChange(files[0]),
    onDropRejected: () =>
      onFileError(
        intl.formatMessage(
          {
            id: 'route_poi_form_image_file_error',
            defaultMessage:
              'This image is not supported. Please use another JPEG or PNG file with a maximum size of {maxFileSize}MB.',
          },
          {
            maxFileSize: MAX_IMAGE_FILE_SIZE_MB,
          },
        ),
      ),
  })

  return (
    <>
      <input name="image" {...getInputProps()} />
      {currentImage ? (
        <PreviewImage src={currentImage} onReplace={open} onRemove={onRemove} />
      ) : value ? (
        <PreviewImage src={URL.createObjectURL(value)} onReplace={open} onRemove={onRemove} />
      ) : (
        <Button variant="secondary" icon={<AddIcon />} onClick={open}>
          {intl.formatMessage({
            id: 'route_poi_form_image_add_label',
            defaultMessage: 'Add a Photo',
          })}
        </Button>
      )}
    </>
  )
}
