'use client'

import { createTheme } from '@mui/material/styles'
import { colors } from 'shared/ui-design-system'
import { MuiSwitch } from '../Switch/theme-overrides'
import { styleDefinitions } from './helpers/style-definitions'
import { CSSProperties } from 'react'

declare module '@mui/material/styles' {
  interface TypeText {
    tertiary: string
  }

  interface PaletteOptions {
    onColor: string
  }

  interface TypographyVariants {
    ch2: CSSProperties
    ch3: CSSProperties
    lead: CSSProperties
  }

  interface TypographyVariantsOptions {
    ch2?: CSSProperties
    ch3?: CSSProperties
    lead?: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    ch2: true
    ch3: true
    lead: true
  }
}

/**
 * When using a MUI component within our UI components, make sure all necessary customization
 * is configured here so that it appears consistent.
 */
export const theme = createTheme({
  palette: {
    primary: {
      main: colors.actionColor.primary,
    },
    text: {
      secondary: colors.onNeutral.secondary,
      tertiary: colors.onNeutral.tertiary,
    },
    onColor: colors.onColor.primary,
    error: {
      main: colors.dangerColor.primary,
    },
  },
  transitions: {
    duration: {
      enteringScreen: styleDefinitions.timing.transitionLong,
      leavingScreen: styleDefinitions.timing.transitionShort,
    },
  },
  components: {
    MuiSwitch,
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          ch2: 'h2',
          ch3: 'h3',
          lead: 'p',
        },
      },
      styleOverrides: {
        paragraph: {
          marginBottom: '0.5rem',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.neutral.tertiary,
        },
      },
    },
  },
  typography: {
    fontFamily: styleDefinitions.fontFamilyDefault,
    fontWeightBold: styleDefinitions.fontWeightMedium,
    h1: {
      fontSize: 'clamp(2rem, 1rem + 3vw, 3rem)',
      lineHeight: 1.333,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: 1.333,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    ch2: {
      fontSize: 'clamp(1.75rem, 1rem + 3vw, 2.125rem)',
      lineHeight: 1.333,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    h3: {
      fontSize: '1.25rem',
      lineHeight: 1.3,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    ch3: {
      fontSize: 'clamp(1.25rem, 3vw, 1.75rem)',
      lineHeight: 1.3,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    h4: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: 1.5,
    },
    lead: {
      fontSize: 'clamp(1rem, 0.5rem + 2vw, 1.25rem)',
      lineHeight: 1.5,
    },
  },
})
