import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteSliceDispatch, initRouteById, useRoute } from './state'

export const useRouteById = (routeId: number) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const route = useRoute()

  useEffect(() => {
    if (routeId) {
      dispatch(initRouteById(routeId))
    }
  }, [dispatch, routeId])

  return route?.id === routeId ? route : undefined
}
