import clsx from 'clsx'
import { CSSProperties } from 'react'
import { Typography } from '@mui/material'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Link } from '../Link'

import styles from './user-content.module.css'

export interface UserContentProps {
  children: string

  /** Prevent block elements, so that it results in a predictable height based on the number of lines */
  squeezed?: boolean

  secondary?: boolean
  linesLimit?: number
}

export const UserContent = ({
  children,
  squeezed,
  secondary,
  linesLimit,
}: UserContentProps) => (
  <div
    className={clsx(styles['root'], { [styles['truncated']]: linesLimit })}
    style={linesLimit ? { '--lines-limit': linesLimit } as CSSProperties : undefined}
  >
    <Markdown
      allowedElements={squeezed ? ['br', 'strong', 'a'] : ['br', 'p', 'strong', 'ul', 'ol', 'li', 'a']}
      components={{
        p: ({ children }) => <Typography paragraph color={secondary ? 'textSecondary' : undefined}>{children}</Typography>,
        a: ({ href, children }) => <Link href={href}>{children}</Link>,
      }}
      unwrapDisallowed
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </Markdown>
  </div>
)
