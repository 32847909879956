import { Campaign } from './types'

import summerGiveaway from './summer-giveaway'
import summerGiveawayOffer from './summer-giveaway-offer'

const now = new Date
const currentCampaigns: Campaign[] = [
  summerGiveaway,
  summerGiveawayOffer,
].filter(({ start, end }) => new Date(start) < now && new Date(end) > now) || null

export default currentCampaigns
