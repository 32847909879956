import React, { useCallback } from 'react'

type UseWaypointLocationFocusReturn = {
  onWaypointItemFocus: (waypointRef: React.RefObject<HTMLDivElement>) => void
}

export const useWaypointLocationFocus = (
  contentFooterRef: React.RefObject<HTMLDivElement>
): UseWaypointLocationFocusReturn => {
  /**
   * Scroll currently active/focused location field to the top if it's too close to the bottom.
   */
  const onWaypointItemFocus = useCallback(
    (waypointRef: React.RefObject<HTMLDivElement>) => {
      if (waypointRef.current && contentFooterRef.current) {
        const waypoint = waypointRef.current.getBoundingClientRect()
        const contentFooter = contentFooterRef.current.getBoundingClientRect()

        const isTooCloseToBottom = waypoint.bottom + 300 > contentFooter.top

        if (isTooCloseToBottom) {
          waypointRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
    [contentFooterRef]
  )

  return {
    onWaypointItemFocus,
  }
}
