import { LineString, MultiPoint } from 'geojson'
import { ExtensiveRouteEntity, RouteBikeTypes, RouteSurfaces, UserPreviewEntity } from '../entities'
import { ApiResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from 'shared/util-network'
import { isEmpty } from 'lodash'
import { addApiHeaders, formatDateToISO, postToCoreApi } from '../network'
import { API_PATH_ROUTES } from '../config'
import {
  RouteResponse,
  RouteResponseIncludedControlPointIndexes,
  RouteResponseIncludedWaypoints,
  convertToExtensiveRouteEntity,
  convertToUserEntity,
} from '../responses'
import { Waypoints } from 'shared/util-geo'

export type RouteAppVersion = 'Import' | 'Create' | 'Copy'

export type RouteForm = {
  geometry: LineString
  title: string
  appVersion: RouteAppVersion
  description?: string
  surfaces?: RouteSurfaces
  bikeTypes?: RouteBikeTypes
  distanceInM: number
  durationInS?: number
  isPrivate?: boolean
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]] | null
  copiedFrom?: number
}

/** Route create endpoint seems to expect a LineString with type="MultiLineString". */
interface RouteCreateRequestBodyPoints {
  type: 'MultiLineString',
  coordinates: LineString['coordinates']
}

type CreateRouteRequestBody = {
  points: RouteCreateRequestBodyPoints
  title: string
  description?: string
  ground: number[]
  category: number[]
  distance: number
  duration?: number
  is_private: boolean
  planned: true
  created: string
  app_type: 'Web'
  app_version: RouteAppVersion
  waypoints: MultiPoint
  control_point_indexes?: number[]
  copied_from?: number
}

type CreateRouteErrors = MinimalEndpointErrors & {
  unexpectedResponse?: true
}

export type CreateRouteData = {
  route: ExtensiveRouteEntity
  creator?: UserPreviewEntity
}

export const CREATE_ROUTE_TITLE_MIN_LENGTH = 5
export const CREATE_ROUTE_TITLE_MAX_LENGTH = 160

export async function createRoute(form: RouteForm): ApiResult<CreateRouteData, CreateRouteErrors> {
  try {
    const body: CreateRouteRequestBody = {
      title: form.title,
      description: form.description,
      ground: form.surfaces && !isEmpty(form.surfaces) ? form.surfaces : [0],
      category: form.bikeTypes && !isEmpty(form.bikeTypes) ? form.bikeTypes : [0],
      points: {
        ...form.geometry,
        type: 'MultiLineString',
      },
      distance: form.distanceInM,
      duration: form.durationInS,
      is_private: form.isPrivate ?? true,
      planned: true,
      created: formatDateToISO(new Date()),
      app_type: 'Web',
      app_version: form.appVersion,
      copied_from: form.copiedFrom,
      waypoints: {
        type: 'MultiPoint',
        coordinates: form.waypoints.map(({ lng, lat, controlPointIndex }) => [lng, lat, controlPointIndex]),
      },
    }

    if (form.controlPointIndexes) {
      body.control_point_indexes = form.controlPointIndexes
    }

    const res: (
      RouteResponse &
      RouteResponseIncludedWaypoints &
      RouteResponseIncludedControlPointIndexes
    ) = await postToCoreApi(API_PATH_ROUTES, {
      headers: await addApiHeaders(),
      body,
      type: 'json',
    })

    try {
      return createSuccessResult({
        route: convertToExtensiveRouteEntity({
          ...res,
          // Along the route data wouldn't be ready yet anyway
          bm_surface: null,
          bm_way_type: null,
          bike_network: null,
        }),
        creator: res.user ? convertToUserEntity(res.user) : undefined,
      })
    } catch (e) {
      return createFailureResult({ unexpectedResponse: true }, { form, res })
    }
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { form })
  }
}
