import clsx from 'clsx'
import { Skeleton } from '@mui/material'
import { styleDefinitions } from '../theme'

import styles from './tag.module.css'

export interface TagProps {
  children: string
  active?: boolean
  className?: string
  onClick?: () => void
}

export const Tag = ({
  children,
  active,
  className,
  onClick,
}: TagProps) => {
  const props = {
    className: clsx(className, styles['tag'], { [styles['active']]: active }),
    onClick,
  }

  return onClick ? (
    <button type='button' {...props}>{children}</button>
  ) : (
    <span {...props}>{children}</span>
  )
}

export const TagSkeleton = () => (
  <Skeleton width='5rem' height='1.625rem' variant='rectangular' style={{ borderRadius: styleDefinitions.borderRadiusSmall }} />
)
