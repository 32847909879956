import clsx from 'clsx'
import { ReactNode } from 'react'
import { useLocale } from 'shared/util-intl'

import styles from './subscription-plan-badge.module.scss'

interface SubscriptionPlanBadgeProps {
  children: ReactNode
  primary?: boolean
}

const SubscriptionPlanBadge = ({
  children,
  primary,
}: SubscriptionPlanBadgeProps) => (
  <span className={clsx(styles['container'], { [styles['primary']]: primary })}>
    {children}
  </span>
)

interface SubscriptionPlanBadgeSaveProps extends Omit<SubscriptionPlanBadgeProps, 'children'> {
  price: number
  higherPrice: number
}

export const SubscriptionPlanBadgeSave = ({
  price,
  higherPrice,
  ...props
}: SubscriptionPlanBadgeSaveProps) => {
  const { intl } = useLocale()

  return (
    <SubscriptionPlanBadge {...props}>
      {intl.formatMessage({
        id: 'subscription_plan_badge_save',
        defaultMessage: 'Save {discount, number, ::percent}',
      }, {
        discount: 1 - price / higherPrice,
      })}
    </SubscriptionPlanBadge>
  )
}

export const SubscriptionPlanBadgeBestValue = (props: Omit<SubscriptionPlanBadgeProps, 'children'>) => {
  const { intl } = useLocale()

  return (
    <SubscriptionPlanBadge {...props}>
      {intl.formatMessage({
        id: 'subscription_plan_badge_best_value',
        defaultMessage: 'Best value',
      })}
    </SubscriptionPlanBadge>
  )
}

export const SubscriptionPlanBadgePopular = (props: Omit<SubscriptionPlanBadgeProps, 'children'>) => {
  const { intl } = useLocale()

  return (
    <SubscriptionPlanBadge {...props}>
      {intl.formatMessage({
        id: 'subscription_plan_badge_popular',
        defaultMessage: 'Popular',
      })}
    </SubscriptionPlanBadge>
  )
}
