import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { WebAppContent } from 'web-app/ui-layout'

export const PrintTips = () => {
  const { intl } = useLocale()

  return (
    <WebAppContent>
      <Typography variant="h4" component="h2">
        {intl.formatMessage({
          id: 'route_print_tips_heading',
          defaultMessage: 'How to optimise your printout ✨',
        })}
      </Typography>
      <Typography>
        {intl.formatMessage({
          id: 'route_print_tips_paragraph',
          defaultMessage: 'In the browser print preview:',
        })}
      </Typography>
      <ul style={{ margin: '0.25rem 0 0.5rem' }}>
        <Typography component="li">
          {intl.formatMessage({
            id: 'route_print_tip_format',
            defaultMessage: 'Select A4 portrait format',
          })}
        </Typography>
        <Typography component="li">
          {intl.formatMessage({
            id: 'route_print_tip_header_footer',
            defaultMessage: 'Disable header and footer',
          })}
        </Typography>
        <Typography component="li">
          {intl.formatMessage({
            id: 'route_print_tip_margin',
            defaultMessage: 'Make sure page margin is 10 mm',
          })}
        </Typography>
      </ul>
    </WebAppContent>
  )
}
