import { MapStyleControl } from 'web-app/feature-map'
import { WebAppControls, useLayoutMediaQueries } from 'web-app/ui-layout'

export const RoutePrintControls = () => {
  const { isLargeViewport } = useLayoutMediaQueries()

  return (
    <WebAppControls>
      <MapStyleControl small={!isLargeViewport} />
    </WebAppControls>
  )
}
