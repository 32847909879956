import { useRoute } from '../state'
import { RouteStats } from './route-stats'
import { useMemo } from 'react'
import { Position } from 'geojson'
import { isPosition3d } from 'shared/util-geo'

export type RouteStatsOverrides = {
  durationInS?: number
  distanceInM?: number
  ascentInM?: number
  descentInM?: number
  averageSpeedInMs?: number
  maxAltitudeInM?: number
}

export interface MainRouteStatsProps {
  extended?: boolean
  overrides?: RouteStatsOverrides
  loading?: boolean
}

/**
 * Route statistics for the current main route in global state.
 */
export const MainRouteStats = ({
  extended,
  overrides,
  loading,
}: MainRouteStatsProps) => {
  const route = useRoute()

  const overrideKeys = useMemo(() => {
    if (!overrides) return []
    const definedKeys = []
    for (const key in overrides) {
      if (overrides[key as keyof RouteStatsOverrides] !== undefined) {
        definedKeys.push(key)
      }
    }
    return definedKeys as (keyof RouteStatsOverrides)[]
  }, [overrides])

  return (
    <RouteStats
      extended={extended}
      durationInS={route && !loading ? overrides?.durationInS ?? route.durationInS ?? null : undefined}
      distanceInM={route && !loading ? overrides?.distanceInM ?? route.distance : undefined}
      averageSpeedInMs={extended && route && !loading ? (
        overrides?.averageSpeedInMs || (!overrides && route.averageSpeedInMs) || (
          overrides?.durationInS && (overrides?.distanceInM || route.distance) / overrides?.durationInS
        ) || (
          route.durationInS && (overrides?.distanceInM || route.distance) / route.durationInS
        ) || null
      ) : undefined}
      ascentInM={route && !loading ? overrides?.ascentInM ?? route.ascent ?? null : undefined}
      descentInM={route && !loading ? overrides?.descentInM ?? route.descent ?? null : undefined}
      maxAltitudeInM={extended && route && !loading ? (
        overrides?.maxAltitudeInM ?? findMaxAltitude(route.geometry.coordinates)
      ) : undefined}
      overrides={overrideKeys}
    />
  )
}

function findMaxAltitude(coordinates: Position[]): number | null {
  let max = 0
  for (const position of coordinates) {
    if (!isPosition3d(position)) return null
    if (position[2] > max) {
      max = position[2]
    }
  }
  return max
}
