import { ChangeEvent, ReactElement, useState } from 'react'
import { Input } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { isRouteTitleTooLong, isRouteTitleTooShort } from '../../helpers'
import { MAX_ROUTE_TITLE_LENGTH, MIN_ROUTE_TITLE_LENGTH } from '../../config'

interface RouteTitleFormProps {
  name: string
  value: string
  onChange: (value: string, isValid: boolean) => void
  buttons: ReactElement
}

export const RouteTitleForm = ({ onChange, buttons, ...inputProps }: RouteTitleFormProps) => {
  const { intl } = useLocale()

  const [error, setError] = useState<string | undefined>()

  const validateTitle = (value: string): boolean => {
    if (!value) {
      setError(
        intl.formatMessage({
          id: 'route_title_form_error_empty',
          defaultMessage: 'Every route needs a title. Please specify one.',
        }),
      )
      return false
    }

    if (isRouteTitleTooShort(value) || isRouteTitleTooLong(value)) {
      setError(
        intl.formatMessage(
          {
            id: 'route_title_form_error_too_short',
            defaultMessage: 'The title must be between {min} and {max} characters long.',
          },
          {
            min: MIN_ROUTE_TITLE_LENGTH.toString(),
            max: MAX_ROUTE_TITLE_LENGTH.toString(),
          },
        ),
      )
      return false
    }

    setError(undefined)
    return true
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange(value, validateTitle(value))
  }

  const handleReset = () => {
    const value = ''
    onChange(value, validateTitle(value))
  }

  return (
    <form style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Input
        {...inputProps}
        label={intl.formatMessage({
          id: 'route_title_input_label',
          defaultMessage: 'Route title',
        })}
        placeholder={intl.formatMessage({
          id: 'route_title_input_placeholder',
          defaultMessage: 'Enter a route title ...',
        })}
        isRequired
        autoFocus
        error={error}
        onChange={handleChange}
        onReset={handleReset}
        data-testid="save-route-title"
      />
      {buttons}
    </form>
  )
}
