import { API_PATH_ROUTE_COLLECTION_ROUTES } from '../config'
import { addApiHeaders, deleteFromCoreApi, postToCoreApi } from '../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export async function addRouteToCollection(
  routeId: number,
  routeCollectionId: number
): ApiResult<null> {
  try {
    await postToCoreApi(API_PATH_ROUTE_COLLECTION_ROUTES, {
      headers: await addApiHeaders(),
      params: { routeCollectionId },
      body: { route_id: routeId },
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId, routeCollectionId })
  }
}

export async function removeRouteFromCollection(
  routeId: number,
  routeCollectionId: number
): ApiResult<null> {
  try {
    await deleteFromCoreApi(API_PATH_ROUTE_COLLECTION_ROUTES, {
      headers: await addApiHeaders(),
      params: { routeCollectionId },
      body: { route_id: routeId },
    })
    return createSuccessResult(null)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { routeId, routeCollectionId })
  }
}
