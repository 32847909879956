import { authToken } from 'shared/util-auth-token'
import { RequestHeadersType } from './types'

type AuthHeaderType = {
  Authorization: string
}

export async function addAuthHeader(): Promise<AuthHeaderType> {
  return {
    Authorization: await authToken.getAuthHeader(),
  }
}

export async function addApiHeaders(headers?: RequestHeadersType): Promise<RequestHeadersType & AuthHeaderType> {
  return {
    'X-Local-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...(await addAuthHeader()),
    ...(headers || {}),
  }
}
