import React, { useCallback, useMemo } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  useDndContext,
  UseDndContextReturnValue,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'

import styles from './SortableList.module.scss'

export interface SortableHandleProps {
  role: string
  tabIndex: number
  'aria-disabled': boolean
  'aria-pressed': boolean | undefined
  'aria-roledescription': string
  'aria-describedby': string
  ref: (node: HTMLElement | null) => void
  onKeyDown?: React.KeyboardEventHandler
  onPointerDown?: React.PointerEventHandler
}

export type SortableContextData = {
  active: UseDndContextReturnValue['active']
}

export type SortableItemData = {
  index: number
  isDragging: boolean
}

type RenderItem<ItemType> = (
  item: ItemType,
  handleProps: SortableHandleProps,
  contextData: SortableContextData,
  itemData: SortableItemData
) => React.ReactNode

export interface SortableListProps<ItemType> {
  items: ItemType[]
  renderItem: RenderItem<ItemType>
  onSort: (items: ItemType[]) => void
}

export function SortableList<ItemType>({
  items,
  onSort,
  ...itemProps
}: SortableListProps<ItemType>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const sortableItems = useMemo(() => items.map((item, index) => ({
    item,
    id: index + 1,
  })), [items])

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (over !== null && active.id !== over.id) {
      const oldIndex = active.id as number - 1
      const newIndex = over.id as number - 1
      const sortedItems = arrayMove(items, oldIndex, newIndex)
      onSort(sortedItems)
    }
  }, [items, onSort])

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[
        restrictToVerticalAxis,
        restrictToFirstScrollableAncestor,
      ]}
    >
      <SortableContext
        items={sortableItems}
        strategy={verticalListSortingStrategy}
      >
        <ol className={styles['list']}>
          {sortableItems.map(({ item, id }, index) => (
            <SortableItem<ItemType> key={id} id={id} index={index} item={item} {...itemProps} />
          ))}
        </ol>
      </SortableContext>
    </DndContext>
  )
}

interface SortableItemProps<ItemType> {
  id: number
  index: number
  item: ItemType
  renderItem: RenderItem<ItemType>
}

function SortableItem<ItemType>({
  id,
  index,
  item,
  renderItem,
}: SortableItemProps<ItemType>) {
  const { active } = useDndContext()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const contextData: SortableContextData = {
    active,
  }

  const itemData: SortableItemData = {
    index,
    isDragging,
  }

  return (
    <li style={style}>
      {renderItem(item, { ref: setNodeRef, ...attributes, ...listeners }, contextData, itemData)}
    </li>
  )
}
