import React, { useMemo } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import { colors } from 'shared/ui-design-system'
import { ButtonGroup } from '../ButtonGroup'
import { useMessages } from '../MessagesProvider'
import { Button } from '../Button'
import { ToolButton } from '../ToolButton'

import styles from './Notification.module.scss'

export interface NotificationButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  href?: string
  label: string
  closesNotification?: boolean
}

export interface NotificationProps {
  variant?: 'info' | 'success' | 'warning' | 'danger'
  title: string
  description?: React.ReactNode
  duration?: number
  primaryButton?: NotificationButtonProps
  secondaryButton?: NotificationButtonProps
  onDismiss?: () => void
}

export function Notification({
  variant = 'info',
  title,
  description,
  duration,
  primaryButton,
  secondaryButton,
  onDismiss,
}: NotificationProps) {
  const { closeLabel } = useMessages()

  const color = useMemo<string>(() => colors.notificationComp[variant], [variant])

  return (
    <div className={styles['root']} style={{ backgroundColor: color }}>
      <ToolButton
        variant='onColor-ghost'
        onClick={() => {
          if (onDismiss) {
            onDismiss()
          }
        }}
        icon={<CloseIcon />}
        className={styles['dismiss-button']}
        data-testid="dismiss-button"
        ariaLabel={closeLabel}
      />
      <Typography variant='h3' paddingRight='2rem' color='onColor'>{title}</Typography>
      {description && <Typography paragraph color='onColor' margin='0.5rem 0 0'>{description}</Typography>}
      {(primaryButton || secondaryButton) && (
        <div className={styles['buttons']}>
          <ButtonGroup>
            {primaryButton && (
              <Button
                variant='onColor-primary'
                onColorBaseColor={color}
                href={primaryButton.href}
                onClick={(e) => {
                  if (primaryButton.onClick) {
                    primaryButton.onClick(e)
                  }
                  if (primaryButton.closesNotification && onDismiss) {
                    onDismiss()
                  }
                }}
                ariaLabel={primaryButton.label}
              >
                {primaryButton.label}
              </Button>
            )}
            {secondaryButton && (
              <Button
                variant='onColor-secondary'
                href={secondaryButton.href}
                onClick={(e) => {
                  if (secondaryButton.onClick) {
                    secondaryButton.onClick(e)
                  }
                  if (secondaryButton.closesNotification && onDismiss) {
                    onDismiss()
                  }
                }}
                ariaLabel={secondaryButton.label}
              >
                {secondaryButton.label}
              </Button>
            )}
          </ButtonGroup>
        </div>
      )}
      {duration && duration !== Infinity && (
        <LinearProgress
          variant="determinate"
          value={100}
          sx={{
            animationDuration: `${duration}ms`,
          }}
          classes={{
            root: styles['progress'],
            bar: styles['progress-bar'],
          }}
        />
      )}
    </div>
  )
}
