import { useLocale } from 'shared/util-intl'
import { useEffect, useMemo, useState } from 'react'

export const useRouteTooltips = () => {
  const { intl } = useLocale()

  const [tooltipFeature, setTooltipFeature] = useState<'control-point' | 'segment' | null>(null)
  const [tooltip, setTooltip] = useState<string | undefined>()

  const controlPointTooltip = useMemo(
    () =>
      intl.formatMessage({
        id: 'route_planner_control_point_tooltip',
        defaultMessage: 'Click to remove this point',
      }),
    [intl],
  )

  const segmentTooltip = useMemo(
    () =>
      intl.formatMessage({
        id: 'route_planner_segment_tooltip',
        defaultMessage: 'Click to set a point',
      }),
    [intl],
  )

  const dragTooltip = useMemo(
    () =>
      intl.formatMessage({
        id: 'route_planner_drag_route_tooltip',
        defaultMessage: 'Drag to edit between points',
      }),
    [intl],
  )

  useEffect(() => {
    setTooltip(undefined)
    if (!tooltipFeature) return

    let timeout = setTimeout(() => {
      if (tooltipFeature === 'control-point') {
        setTooltip(controlPointTooltip)
      } else if (tooltipFeature === 'segment') {
        setTooltip(segmentTooltip)
      }
      timeout = setTimeout(() => {
        setTooltip(dragTooltip)
        timeout = setTimeout(() => {
          setTooltip(undefined)
          setTooltipFeature(null)
        }, 2000)
      }, 2000)
    }, 500)

    return () => clearTimeout(timeout)
  }, [controlPointTooltip, dragTooltip, segmentTooltip, tooltipFeature])

  return {
    tooltip,
    triggerControlPointTooltip: () => setTooltipFeature('control-point'),
    triggerSegmentTooltip: () => setTooltipFeature('segment'),
    resetTooltip: () => setTooltipFeature(null),
  }
}
