import { useSelector } from 'react-redux'
import {
  assignedRouteCollectionIdsSelector,
  creatorSelector,
  routeSliceSelector,
  routeSelector,
  isOwnRouteSelector,
} from './selectors'

export const useRouteState = () => useSelector(routeSliceSelector)

/** Use the current main route from the app's global state */
export const useRoute = () => useSelector(routeSelector)

/** Use the creator of the current main route from the app's global state */
export const useCreator = () => useSelector(creatorSelector)

/** Use the IDs of route collections the current main route from the app's global state is assigned to */
export const useAssignedRouteCollectionIds = () => useSelector(assignedRouteCollectionIdsSelector)

/** Use the information whether the main route belongs to the current user */
export const useIsOwnRoute = () => useSelector(isOwnRouteSelector)
