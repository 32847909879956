import { RouteCollectionEntity } from 'shared/data-access-core'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { LngLat } from 'shared/ui-map'
import { StateWithRouteSlice } from 'web-app/feature-route'
import { StateWithUserSlice } from 'web-app/feature-user'

export const ROUTE_DETAILS_SLICE_KEY = 'routeDetails'

export type EditingField = 'title' | 'description' | 'bikeTypes' | 'surfaces' | 'isPrivate' | 'images'

export type RouteDetailsState = {
  openImageIndex: number | null
  routeCollections: Record<number, RouteCollectionEntity>
  isEmbedConfiguratorOpen: boolean
  editingField: EditingField | null
  selectedLocation: LngLat | null
}

export interface StateWithRouteDetailsSlice {
  [ROUTE_DETAILS_SLICE_KEY]: RouteDetailsState
}

export type RouteDetailsSliceDispatch = ThunkDispatch<
  StateWithRouteDetailsSlice & StateWithRouteSlice & StateWithUserSlice,
  undefined,
  AnyAction
>
