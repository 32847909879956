import { RoutePoiContent, RoutePoiContentEditable, useRoutePoiPopupProps } from 'shared/feature-route-pois'
import { useRouteDetailsState } from '../state'
import { MapLocationActions } from '../components/map-location-actions'
import { useCommonErrorNotification } from 'web-app/feature-notifications'
import { WebAppMapPopup } from 'web-app/feature-map'
import { useIsOwnRoute } from 'web-app/feature-route'

export const RouteDetailsMapPopup = () => {
  const showCommonErrorNotification = useCommonErrorNotification()
  const routePoiPopupProps = useRoutePoiPopupProps()
  const { selectedLocation } = useRouteDetailsState()
  const isOwnRoute = useIsOwnRoute()

  if (routePoiPopupProps) {
    return (
      <WebAppMapPopup {...routePoiPopupProps}>
        {isOwnRoute ? <RoutePoiContentEditable onError={showCommonErrorNotification} /> : <RoutePoiContent />}
      </WebAppMapPopup>
    )
  }

  if (selectedLocation) {
    return (
      <WebAppMapPopup longitude={selectedLocation.lng} latitude={selectedLocation.lat}>
        <MapLocationActions location={selectedLocation} />
      </WebAppMapPopup>
    )
  }

  return null
}
