import { useContext } from 'react'
import { LayoutContext, LayoutMediaQueries, LayoutProviderValues, LayoutRefs } from './layout-provider'

/**
 * Get various element refs.
 */
export function useLayoutRefs(): LayoutRefs {
  const contextValues = useContext<LayoutProviderValues | undefined>(LayoutContext)
  if (!contextValues) {
    throw new Error('useLayoutRefs must be used inside WebAppLayout')
  }
  return contextValues.refs
}

/**
 * Get media queries of this layout.
 */
export function useLayoutMediaQueries(): LayoutMediaQueries {
  const contextValues = useContext<LayoutProviderValues | undefined>(LayoutContext)
  if (!contextValues) {
    throw new Error('useLayoutMediaQueries must be used inside WebAppLayout')
  }
  return contextValues.mediaQueries
}

/**
 * Get layout state.
 */
export function useLayoutState(): { isFullScreenContent: boolean } {
  const contextValues = useContext<LayoutProviderValues | undefined>(LayoutContext)
  if (!contextValues) {
    throw new Error('useLayoutState must be used inside WebAppLayout')
  }
  return {
    isFullScreenContent: contextValues.isFullScreenContent,
  }
}
