import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createMigrate, MigrationManifest, PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  CompleteWaypoints,
  IncompleteWaypoints,
  PLANNER_SLICE_KEY,
  RoutedWaypoint,
  RoutePlannerInitializationState,
  RoutePlannerRouteData,
  RoutePlannerState,
  RoutingMode,
  Waypoint,
} from './types'
import {
  areMoreWaypoints,
  areWaypointsComplete,
  createFreehandSegments,
  estimateRouteStats,
  getInitialCompleteWaypoints,
  splitLineAtIndexes,
  splitLineAtPoints,
  splitLineNearLocation,
  splitLineNearMultipleLocations,
  updateControlPointRelations,
} from './helpers'
import { LineString } from 'geojson'
import {
  AlongTheRouteAttribute,
  BikeNetworkCategory,
  DEFAULT_ROUTING_PROFILE,
  DEFAULT_ROUTING_PROFILE_CYCLING_PATH,
  ExtensiveRouteEntity,
  GetRouteErrors,
  RoutingProfile,
  RoutingProfileCyclingPath,
  SurfaceCategory,
  WayTypeCategory,
} from 'shared/data-access-core'
import { areLngLatsEqual, LngLat, LngLatElevation } from 'shared/util-geo'
import { GeocoderLocation } from 'shared/data-access-geocoding'

const emptyRouteStats: Omit<RoutePlannerRouteData, 'geometry'> = {
  distance: null,
  durationInS: null,
  surfacesAlongTheRoute: null,
  wayTypesAlongTheRoute: null,
  bikeNetworkAlongTheRoute: null,
}

export const emptyRouteData: RoutePlannerRouteData = {
  ...emptyRouteStats,
  geometry: null,
}

export const initialState: RoutePlannerState = {
  isInitialized: false,
  basedOnRouteId: null,
  waypoints: [null, null],
  routingMode: 'routing',
  routingProfile: DEFAULT_ROUTING_PROFILE,
  routingLevel: DEFAULT_ROUTING_PROFILE_CYCLING_PATH,
  isWholeRouteRouted: true,
  ...emptyRouteData,
  selectedLocation: null,
  selectedWaypoint: null,
  isFittingGeometryBounds: false,
  isFetchingRoute: false,
  routingErrors: null,
}

const slice = createSlice({
  name: PLANNER_SLICE_KEY,
  initialState,
  reducers: {
    /**
     * A new, empty route planner session is initialized.
     */
    initializedBlank(state) {
      if (state.basedOnRouteId) {
        Object.assign(state, initialState)
      }
      state.isInitialized = true
    },

    /**
     * The route planner is initialized based on one or more locations to be used as waypoints.
     */
    initializedWithWaypoints(state, action: PayloadAction<[Waypoint, ...Waypoint[]]>) {
      const numPoints = action.payload.length
      Object.assign(state, initialState)
      if (numPoints === 1) {
        state.waypoints = [null, action.payload[0]]
      } else if (numPoints > 1) {
        state.waypoints = getInitialCompleteWaypoints(action.payload as [Waypoint, Waypoint, ...Waypoint[]])
      }
      state.isInitialized = true
    },

    /**
     * The route planner is initialized with a certain initial state.
     */
    initializedWithState(state, action: PayloadAction<RoutePlannerInitializationState>) {
      const { origin, destination } = action.payload
      Object.assign(state, initialState)
      state.waypoints = origin && destination
        ? getInitialCompleteWaypoints([origin, destination])
        : [origin || null, destination || null] as IncompleteWaypoints
      state.isInitialized = true
    },

    /**
     * The route planner is initialized based on an existing route.
     */
    initializedFromRoute(state, action: PayloadAction<ExtensiveRouteEntity>) {
      const route = action.payload
      state.basedOnRouteId = route.id
      state.waypoints = route.waypoints
      state.distance = route.distance
      state.durationInS = route.durationInS || null
      state.surfacesAlongTheRoute = route.surfacesAlongTheRoute
      state.wayTypesAlongTheRoute = route.wayTypesAlongTheRoute
      state.bikeNetworkAlongTheRoute = route.bikeNetworkAlongTheRoute
      state.isWholeRouteRouted = false
      state.isInitialized = true

      if (route.controlPointIndexes) {
        state.geometry = splitLineAtIndexes(route.geometry, route.controlPointIndexes)
      } else {
        state.geometry = splitLineAtPoints(route.geometry, route.waypoints)
      }
    },

    /**
     * A route segment is initially divided by inserting control points along it without recalculating.
     */
    initialControlPointsInserted(state, action: PayloadAction<{ segmentIndex: number, locations: LngLat[] }>) {
      const { segmentIndex, locations } = action.payload
      const segment = state.geometry?.coordinates[segmentIndex]
      if (segment && state.geometry) {
        const splitSegments = splitLineNearMultipleLocations(segment, locations)
        state.geometry.coordinates.splice(segmentIndex, 1, ...splitSegments)
        updateControlPointRelations(state.waypoints, segmentIndex + 1, locations.length)
      }
    },

    /**
     * A waypoint is removed which requires updating parts of the route.
     */
    waypointRemoved(state, action: PayloadAction<number>) {
      const waypointIndex = action.payload
      if (waypointIndex < 1) { // origin
        if (areMoreWaypoints(state.waypoints) && state.geometry) {
          state.waypoints.shift()
          const numSegments = state.waypoints[0].controlPointIndex
          updateControlPointRelations(state.waypoints, 0, -numSegments)
          state.geometry.coordinates.splice(0, numSegments)
        } else {
          state.waypoints = [
            null,
            state.waypoints[1] ? { ...state.waypoints[1], controlPointIndex: undefined } : null,
          ]
          state.geometry = null
        }
      } else if (waypointIndex >= state.waypoints.length - 1) { // destination
        if (areMoreWaypoints(state.waypoints) && state.geometry) {
          state.waypoints.splice(-1)
          state.geometry.coordinates.splice(state.waypoints[state.waypoints.length - 1].controlPointIndex)
        } else {
          state.waypoints = [
            state.waypoints[0] ? { ...state.waypoints[0], controlPointIndex: undefined } : null,
            null,
          ]
          state.geometry = null
        }
      } else if (areMoreWaypoints(state.waypoints)) { // via
        state.waypoints.splice(waypointIndex, 1)
        const nextWaypoint = state.waypoints[waypointIndex]
        updateControlPointRelations(state.waypoints, nextWaypoint.controlPointIndex, -1)
      }
      Object.assign(state, emptyRouteStats)
      state.selectedWaypoint = null
    },

    /**
     * A via control point on a given index is being removed, which requires an update of relations.
     */
    controlPointRemoved(state, action: PayloadAction<{ controlPointIndex: number }>) {
      const { controlPointIndex } = action.payload
      if (!state.geometry || controlPointIndex < 1 || controlPointIndex > state.geometry.coordinates.length - 1) return

      const wapyointIndex = state.waypoints.findIndex(w => w?.controlPointIndex === controlPointIndex)
      if (wapyointIndex > 0) {
        state.waypoints.splice(wapyointIndex, 1)
      }
      updateControlPointRelations(state.waypoints, controlPointIndex + 1, -1)
      state.selectedWaypoint = null
    },

    /**
     * First segment is removed, which doesn't require route calculation but updated stats. The origin is replaced by
     * either a following waypoint related to the new start or a new, implicit origin.
     */
    firstSegmentRemoved(state) {
      if (!state.geometry || !areWaypointsComplete(state.waypoints)) return
      if (state.geometry.coordinates.length > 1) {
        state.geometry.coordinates.shift()
        state.waypoints.forEach(w => w.controlPointIndex--)
        if (state.waypoints[1].controlPointIndex === 0) {
          state.waypoints.shift()
        } else {
          const [lng, lat] = state.geometry.coordinates[0][0]
          state.waypoints[0] = { lng, lat, controlPointIndex: 0 }
        }
      } else {
        state.geometry = null
        state.waypoints = [null, { ...state.waypoints[1], controlPointIndex: undefined }]
      }
      const statsEstimation = estimateRouteStats(state.geometry)
      state.distance = statsEstimation.distanceInM
      state.durationInS = statsEstimation.durationInS
      state.surfacesAlongTheRoute = null
      state.wayTypesAlongTheRoute = null
      state.bikeNetworkAlongTheRoute = null
      state.selectedWaypoint = null
    },

    /**
     * Last segment is removed, which doesn't require route calculation but updated stats. The destination is replaced
     * by either a preceding waypoint related to the new end or a new, implicit destination.
     */
    lastSegmentRemoved(state) {
      if (!state.geometry || !areWaypointsComplete(state.waypoints)) return
      if (state.geometry.coordinates.length > 1) {
        const [removedSegment] = state.geometry.coordinates.splice(-1)
        const precedingWaypoint = state.waypoints[state.waypoints.length - 2]
        const controlPointIndex = state.geometry.coordinates.length
        if (precedingWaypoint.controlPointIndex === controlPointIndex) {
          state.waypoints.splice(-1)
        } else {
          const [lng, lat] = removedSegment[0]
          state.waypoints[state.waypoints.length - 1] = { lng, lat, controlPointIndex, isImplicit: true }
        }
      } else {
        state.geometry = null
        state.waypoints = [{ ...state.waypoints[0], controlPointIndex: undefined }, null]
      }
      const statsEstimation = estimateRouteStats(state.geometry)
      state.distance = statsEstimation.distanceInM
      state.durationInS = statsEstimation.durationInS
      state.surfacesAlongTheRoute = null
      state.wayTypesAlongTheRoute = null
      state.bikeNetworkAlongTheRoute = null
      state.selectedWaypoint = null
    },

    /**
     * Waypoint inserted at any position in the list.
     */
    waypointInserted(state, action: PayloadAction<{ beforeIndex: number, waypoint: Waypoint}>) {
      const { beforeIndex, waypoint } = action.payload
      const beforeIndexInRange = Math.min(beforeIndex, state.waypoints[1] === null ? 1 : state.waypoints.length)
      state.waypoints.splice(beforeIndexInRange, state.waypoints[beforeIndexInRange] === null ? 1 : 0, waypoint)
      state.selectedLocation = null
      state.selectedWaypoint = null

      if (areWaypointsComplete(state.waypoints)) {
        if (state.waypoints.length > 2) {
          const controlPointIndex = beforeIndex > 0 ? (
            state.waypoints[beforeIndex - 1]?.controlPointIndex + 1
          ) : 0
          state.waypoints[beforeIndex] = {
            ...state.waypoints[beforeIndex],
            controlPointIndex,
          }
          const nextWaypoint = state.waypoints[beforeIndex + 1]
          if (nextWaypoint) {
            // Next waypoint will be one segment after inserted one, all following need to be updated
            const delta = 1 - nextWaypoint.controlPointIndex + controlPointIndex
            updateControlPointRelations(state.waypoints.slice(beforeIndex + 1), controlPointIndex, delta)
          }
        } else {
          state.waypoints = [
            { ...state.waypoints[0], controlPointIndex: 0 },
            { ...state.waypoints[1], controlPointIndex: 1 },
          ]
        }
      }
    },

    /**
     * When drawing the route by click, an implicit waypoint is inserted.
     */
    implicitWaypointDrawn(state, action: PayloadAction<LngLat>) {
      if (!state.waypoints[0] && !state.waypoints[1]) {
        state.waypoints = [action.payload, null]
      } else if (!state.waypoints[0] && state.waypoints[1]) {
        state.waypoints = [
          { ...action.payload, controlPointIndex: 0 },
          { ...state.waypoints[1], controlPointIndex: 1 },
        ]
      } else if (state.waypoints[0] && !state.waypoints[1]) {
        state.waypoints = [
          { ...state.waypoints[0], controlPointIndex: 0 },
          { ...action.payload, controlPointIndex: 1, isImplicit: true },
        ]
      } else {
        const previousWaypoint = state.waypoints[state.waypoints.length - 1]
        const newWaypoint = {
          ...action.payload,
          controlPointIndex: previousWaypoint.controlPointIndex + 1,
          isImplicit: true,
        }
        if (previousWaypoint.isImplicit) {
          state.waypoints[state.waypoints.length - 1] = newWaypoint
        } else {
          state.waypoints.push(newWaypoint)
        }
      }
    },

    /**
     * The currently selected location is added as a waypoint between existing waypoints that are connected via
     * a given segment.
     */
    selectedLocationAddedAlongRoute(state, action: PayloadAction<{ segmentIndex: number }>) {
      if (!areWaypointsComplete(state.waypoints) || !state.selectedLocation) return
      const { segmentIndex } = action.payload
      for (let i = state.waypoints.length - 1; i >= 0; i--) {
        const waypoint = state.waypoints[i]
        if (waypoint.controlPointIndex > segmentIndex) {
          waypoint.controlPointIndex++
        } else {
          state.waypoints.splice(i + 1, 0, { ...state.selectedLocation, controlPointIndex: segmentIndex + 1 })
          break
        }
      }
      state.selectedLocation = null
    },

    /**
     * A route segment is divided by inserting another control point along it without recalculating.
     */
    segmentDivided(state, action: PayloadAction<{ segmentIndex: number, location: LngLat }>) {
      const { segmentIndex, location } = action.payload
      const segment = state.geometry?.coordinates[segmentIndex]
      if (segment) {
        const splitSegments = splitLineNearLocation(segment, location)
        state.geometry?.coordinates.splice(segmentIndex, 1, ...splitSegments)
        updateControlPointRelations(state.waypoints, segmentIndex + 1)
      }
    },

    /**
     * A control point is being inserted before a given index, which requires an update of relations.
     */
    controlPointInserted(state, action: PayloadAction<{ beforeIndex: number }>) {
      const { beforeIndex } = action.payload
      updateControlPointRelations(state.waypoints, beforeIndex || 1, 1)
    },

    /**
     * A waypoint is moved to another location on the map.
     */
    waypointMoved(state, action: PayloadAction<{ index: number; waypoint: Waypoint }>) {
      const { index, waypoint } = action.payload
      const existingWaypoint = state.waypoints[index]
      if (existingWaypoint) {
        Object.assign(existingWaypoint, waypoint)
        state.selectedLocation = null
        state.selectedWaypoint = null
      }
    },

    controlPointMoved(state) {
      return state // for undo/redo
    },

    /**
     * A waypoint at a given index in the list is replaced by a new one.
     */
    waypointUpdated(state, action: PayloadAction<{ index: number; waypoint: Waypoint }>) {
      const { index, waypoint } = action.payload

      const updatedWaypoint = state.waypoints[index]
      if (updatedWaypoint) {
        Object.assign(updatedWaypoint, waypoint)
        if (areWaypointsComplete(state.waypoints)) {
          if (index < 1) { // origin
            const sectionEndControlPointIndex = state.waypoints[1].controlPointIndex
            updateControlPointRelations(state.waypoints, sectionEndControlPointIndex, 1 - sectionEndControlPointIndex)
          } else if (index > state.waypoints.length - 2) { // destination
            const sectionStartControlPointIndex = state.waypoints[state.waypoints.length - 2].controlPointIndex
            updatedWaypoint.controlPointIndex = sectionStartControlPointIndex + 1
          } else { // via
            const sectionStartControlPointIndex = state.waypoints[index - 1].controlPointIndex
            const sectionEndControlPointIndex = state.waypoints[index + 1].controlPointIndex
            updatedWaypoint.controlPointIndex = sectionStartControlPointIndex + 1
            updateControlPointRelations(
              state.waypoints,
              sectionEndControlPointIndex,
              sectionStartControlPointIndex - sectionEndControlPointIndex + 2
            )
          }
        }
        state.selectedLocation = null
        state.selectedWaypoint = null
      }
    },

    /**
     * The waypoints list is being reversed.
     */
    routeReversed(state) {
      state.waypoints.reverse()
      Object.assign(state, emptyRouteStats)
      state.selectedWaypoint = null
      if (areWaypointsComplete(state.waypoints) && state.geometry) {
        // Also reverse control point relations
        const numSegments = state.geometry.coordinates.length
        for (const waypoint of state.waypoints) {
          waypoint.controlPointIndex = numSegments - waypoint.controlPointIndex
        }
      }
    },

    /**
     * The order of waypoints is changed, so certain parts of the route need to change.
     */
    waypointsListSorted(state, action: PayloadAction<CompleteWaypoints>) {
      const originalWaypoints = areWaypointsComplete(state.waypoints)
        ? state.waypoints.map(w => ({ ...w } as RoutedWaypoint))
        : null

      state.waypoints = action.payload.map(w => ({ ...w })) as IncompleteWaypoints | CompleteWaypoints
      state.selectedWaypoint = null

      if (originalWaypoints && areWaypointsComplete(state.waypoints) && state.geometry) {
        state.waypoints[0].controlPointIndex = 0
        for (let i = 1; i < state.waypoints.length; i++) {
          // Update control point relations as some control points might disappear
          const waypoint = state.waypoints[i]
          const previousWaypoint = state.waypoints[i - 1]
          const previousControlPointIndex = state.waypoints[i - 1].controlPointIndex
          const originalIndex = originalWaypoints.findIndex(w => areLngLatsEqual(w, waypoint))
          const originalWaypoint = originalWaypoints[originalIndex]
          const originalPreviousWaypoint = originalWaypoints[originalIndex - 1]
          const isSectionAffected = !(
            originalIndex > 0 &&
            areLngLatsEqual(originalPreviousWaypoint, previousWaypoint)
          )
          if (isSectionAffected) {
            waypoint.controlPointIndex = previousControlPointIndex + 1
          } else {
            const numSegments = originalWaypoint.controlPointIndex - originalPreviousWaypoint.controlPointIndex
            waypoint.controlPointIndex = previousControlPointIndex + numSegments

            if (i === 1) {
              state.geometry.coordinates.splice(0, originalPreviousWaypoint.controlPointIndex)
            }
            if (i === state.waypoints.length - 1) {
              state.geometry.coordinates.splice(originalWaypoint.controlPointIndex)
            }
          }
        }
        Object.assign(state, emptyRouteStats)
      }
    },

    /**
     * A location has been geocoded, which could now be anywhere in state and needs to be updated there.
     */
    locationGeocoded(
      state,
      action: PayloadAction<{ location: LngLat, geocoded: GeocoderLocation }>
    ) {
      const { location, geocoded } = action.payload
      const { position, ...reverseGeocodedStrings } = geocoded
      if (state.selectedLocation && areLngLatsEqual(state.selectedLocation, location)) {
        // Still same location selected
        Object.assign(state.selectedLocation, reverseGeocodedStrings)
      } else {
        // If location already in waypoints, update it there
        state.waypoints.forEach((waypoint) => {
          if (waypoint && areLngLatsEqual(waypoint, location)) {
            Object.assign(waypoint, reverseGeocodedStrings)
          }
        })
      }
    },

    routeDataOutdated(state) {
      return {
        ...state,
        ...emptyRouteData,
        willFitGeometryBounds: false,
        isFetchingRoute: false,
      }
    },

    routingRequest(state) {
      state.isFetchingRoute = true
    },

    /**
     * The entire route has been successfully calculated, so we can overwrite all route data.
     */
    routingSuccess(state, action: PayloadAction<{
      geometry: LineString,
      distanceInM: number
      durationInS: number
      surfacesAlongTheRoute: AlongTheRouteAttribute<SurfaceCategory> | null
      wayTypesAlongTheRoute: AlongTheRouteAttribute<WayTypeCategory> | null
      bikeNetworkAlongTheRoute: AlongTheRouteAttribute<BikeNetworkCategory> | null
      snappedWaypoints: LngLat[]
    }>) {
      if (state.waypoints.length >= 2) {
        state.distance = action.payload.distanceInM
        state.durationInS = action.payload.durationInS
        state.surfacesAlongTheRoute = action.payload.surfacesAlongTheRoute
        state.wayTypesAlongTheRoute = action.payload.wayTypesAlongTheRoute
        state.bikeNetworkAlongTheRoute = action.payload.bikeNetworkAlongTheRoute
        state.geometry = splitLineAtPoints(action.payload.geometry, action.payload.snappedWaypoints)
        state.isFetchingRoute = false
        state.routingErrors = null
      }
    },

    freehandCalculated(state, action: PayloadAction<{ controlPoints: LngLatElevation[] }>) {
      const { geometry, distanceInM, durationInS } = createFreehandSegments(action.payload.controlPoints)
      state.geometry = geometry
      state.distance = distanceInM
      state.durationInS = durationInS
      state.surfacesAlongTheRoute = null
      state.wayTypesAlongTheRoute = null
      state.bikeNetworkAlongTheRoute = null
    },

    /**
     * Parts of the route have been successfully calculated. This is a secondary action, so any changes to waypoints
     * or other parts of the state that don't directly result from route calculation should have happened before.
     */
    segmentRoutingSuccess(
      state,
      action: PayloadAction<{
        geometry: LineString,
        distanceInM: number
        durationInS: number
        surfacesAlongTheRoute: AlongTheRouteAttribute<SurfaceCategory> | null
        wayTypesAlongTheRoute: AlongTheRouteAttribute<WayTypeCategory> | null
        bikeNetworkAlongTheRoute: AlongTheRouteAttribute<BikeNetworkCategory> | null
        snappedWaypoints: LngLat[]
        segmentIndex: number
        numSegments: number
      }>
    ) {
      const { distanceInM, durationInS, geometry, snappedWaypoints, segmentIndex, numSegments } = action.payload
      const newSegments = splitLineAtPoints(geometry, snappedWaypoints)
      if (!state.geometry || state.geometry.coordinates.length === numSegments) { // everything changes
        state.geometry = newSegments
        state.distance = distanceInM
        state.durationInS = durationInS
        state.surfacesAlongTheRoute = action.payload.surfacesAlongTheRoute
        state.wayTypesAlongTheRoute = action.payload.wayTypesAlongTheRoute
        state.bikeNetworkAlongTheRoute = action.payload.bikeNetworkAlongTheRoute
      } else {
        const insertedSegments = [...newSegments.coordinates]
        if (segmentIndex > 0) { // has to be connected to previous segment
          const previousSegment = state.geometry.coordinates[segmentIndex - 1]
          insertedSegments[0][0] = previousSegment[previousSegment.length - 1]
        }
        if (segmentIndex + numSegments < state.geometry.coordinates.length) { // has to be connected to next segment
          const lastSegment = insertedSegments[insertedSegments.length - 1]
          lastSegment[lastSegment.length - 1] = state.geometry.coordinates[segmentIndex + numSegments][0]
        }
        state.geometry.coordinates.splice(segmentIndex, numSegments, ...insertedSegments)
        if (numSegments === 0 && state.distance !== null && state.durationInS !== null) { // only additions
          state.distance += distanceInM
          state.durationInS += durationInS
        } else {
          const statsEstimation = estimateRouteStats(state.geometry)
          state.distance = statsEstimation.distanceInM
          state.durationInS = statsEstimation.durationInS
        }
        state.surfacesAlongTheRoute = null
        state.wayTypesAlongTheRoute = null
        state.bikeNetworkAlongTheRoute = null
      }
      state.selectedWaypoint = null
      state.isFetchingRoute = false
      state.routingErrors = null
    },

    freehandSegmentsCalculated(state, action: PayloadAction<{
      controlPoints: LngLatElevation[]
      segmentIndex: number
      numSegments: number
    }>) {
      const { controlPoints, segmentIndex, numSegments } = action.payload
      const { geometry, distanceInM, durationInS } = createFreehandSegments(controlPoints)
      if (!state.geometry || state.geometry.coordinates.length === numSegments) { // everything changes
        state.geometry = geometry
        state.distance = distanceInM
        state.durationInS = durationInS
      } else {
        state.geometry.coordinates.splice(segmentIndex, numSegments, ...geometry.coordinates)
        if (numSegments === 0 && state.distance !== null && state.durationInS !== null) { // only additions
          state.distance += distanceInM
          state.durationInS += durationInS
        } else {
          const statsEstimation = estimateRouteStats(state.geometry)
          state.distance = statsEstimation.distanceInM
          state.durationInS = statsEstimation.durationInS
        }
      }
      state.surfacesAlongTheRoute = null
      state.wayTypesAlongTheRoute = null
      state.bikeNetworkAlongTheRoute = null
    },

    routingFailure(state, action: PayloadAction<GetRouteErrors>) {
      return {
        ...state,
        ...emptyRouteData,
        isFetchingRoute: false,
        routingErrors: action.payload,
      }
    },

    locationSelected(state, action: PayloadAction<Waypoint>) {
      state.selectedLocation = action.payload
      state.selectedWaypoint = null
    },

    waypointSelected(state, action: PayloadAction<number>) {
      state.selectedWaypoint = state.waypoints[action.payload] ? action.payload : null
      state.selectedLocation = null
    },

    selectionCanceled(state) {
      state.selectedLocation = null
      state.selectedWaypoint = null
    },

    fittingGeometryBoundsStarted(state) {
      state.isFittingGeometryBounds = true
    },

    fittingGeometryBoundsEnded(state) {
      state.isFittingGeometryBounds = false
    },

    routingModeChanged(state, action: PayloadAction<RoutingMode>) {
      state.routingMode = action.payload
      state.isWholeRouteRouted = false
    },

    routingProfileChanged(state, action: PayloadAction<RoutingProfile>) {
      state.routingProfile = action.payload
    },

    routingLevelChanged(state, action: PayloadAction<RoutingProfileCyclingPath>) {
      state.routingLevel = action.payload
    },

    routingAppliedToWholeRoute(state) {
      state.isWholeRouteRouted = true
    },

    routingAppliedToChanges(state) {
      state.isWholeRouteRouted = false
    },

    reset() {
      return initialState
    },
  },
})

export const {
  initializedBlank,
  initializedWithWaypoints,
  initializedWithState,
  initializedFromRoute,
  initialControlPointsInserted,
  waypointRemoved,
  controlPointRemoved,
  firstSegmentRemoved,
  lastSegmentRemoved,
  waypointInserted,
  implicitWaypointDrawn,
  selectedLocationAddedAlongRoute,
  segmentDivided,
  controlPointInserted,
  waypointMoved,
  controlPointMoved,
  waypointUpdated,
  routeReversed,
  waypointsListSorted,
  locationGeocoded,
  routeDataOutdated,
  routingSuccess,
  freehandCalculated,
  segmentRoutingSuccess,
  freehandSegmentsCalculated,
  routingRequest,
  routingFailure,
  locationSelected,
  waypointSelected,
  selectionCanceled,
  fittingGeometryBoundsStarted,
  fittingGeometryBoundsEnded,
  routingModeChanged,
  routingProfileChanged,
  routingLevelChanged,
  routingAppliedToWholeRoute,
  routingAppliedToChanges,
  reset,
} = slice.actions

type RoutePlannerStateOriginal = Omit<RoutePlannerState, 'waypoints'> & {
  start: Waypoint | null
  end: Waypoint | null
  via: Waypoint[]
}

type RoutePlannerStateBasedOnWaypointsList = Omit<RoutePlannerState, 'waypoints'> & {
  waypointsList: (Waypoint | null)[]
}

type RoutePlannerStateWithoutControlPoints = Omit<RoutePlannerState, 'waypoints'> & {
  waypoints: IncompleteWaypoints | [Waypoint, Waypoint, ...Waypoint[]]
}

export const migrations = {
  1: (state: RoutePlannerStateOriginal): RoutePlannerStateBasedOnWaypointsList => {
    const { start, via, end, ...newState } = state
    return {
      ...newState,
      waypointsList: [start, ...via, end],
    }
  },
  2: (state: RoutePlannerStateBasedOnWaypointsList): RoutePlannerStateWithoutControlPoints => {
    const { waypointsList, ...newState } = state
    const definedWaypoints = waypointsList.filter(v => v !== null) as Waypoint[]
    return {
      ...newState,
      waypoints: definedWaypoints.length > 1
        ? definedWaypoints as [Waypoint, Waypoint, ...Waypoint[]]
        : [null, null],
    }
  },
  3: (state: RoutePlannerStateWithoutControlPoints): RoutePlannerState => {
    return {
      ...state,
      waypoints: state.waypoints[0] && state.waypoints[1]
        ? getInitialCompleteWaypoints(state.waypoints)
        : state.waypoints,
    }
  },
}

const persistConfig: PersistConfig<RoutePlannerState> = {
  version: 3,
  storage,
  key: PLANNER_SLICE_KEY,
  whitelist: [
    'basedOnRouteId',
    'waypoints',
    'routingMode',
    'routingProfile',
    'routingLevel',
    'isWholeRouteRouted',
    'geometry',
    'distance',
    'durationInS',
    'surfacesAlongTheRoute',
    'wayTypesAlongTheRoute',
    'bikeNetworkAlongTheRoute',
  ],
  migrate: createMigrate(migrations as unknown as MigrationManifest, { debug: true }),
}

export const routePlannerReducer = persistReducer(persistConfig, slice.reducer)
