import { useState, useEffect, useCallback } from 'react'
import { garminApi, UserEntity } from 'shared/data-access-core'
import { getUserGarminUrl } from 'shared/util-navigation'
import { useSessionInfo } from 'web-app/feature-navigation'

/**
 * Checks if provided user has Garmin connection or not and returns Garmin URL if there is connection.
 */
export const useGarmin = (user: UserEntity | null, isUserLoaded: boolean): [string | null] => {
  const sessionInfo = useSessionInfo()

  const [garminUrl, setGarminUrl] = useState<string | null>(null)
  const [isGarminFetched, setIsGarminFetched] = useState<boolean>(false)

  const init = useCallback(async () => {
    if (!isGarminFetched && isUserLoaded && user && user.id && user.slug) {
      const result = await garminApi.isConnected(user.id)
      if (!result.success || !result.data) {
        setIsGarminFetched(true)
        setGarminUrl(
          getUserGarminUrl({
            ...sessionInfo,
            slug: user.slug as string,
          }),
        )
      }
    }
  }, [isGarminFetched, isUserLoaded, user, sessionInfo])

  useEffect(() => {
    init()
  }, [init])

  return [garminUrl]
}
