import { API_PATH_ROUTE_COLLECTIONS } from '../config'
import { RouteCollectionEntity } from '../entities'
import { addApiHeaders, postToCoreApi } from '../network'
import { RouteCollectionPostResponse } from '../responses'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

export type RouteCollectionForm = {
  title: string
  isPrivate: boolean
}

export async function createRouteCollection(
  form: RouteCollectionForm
): ApiResult<RouteCollectionEntity> {
  try {
    const { title, isPrivate } = form
    const res: RouteCollectionPostResponse = await postToCoreApi(API_PATH_ROUTE_COLLECTIONS, {
      headers: await addApiHeaders(),
      body: {
        title,
        visibility: !isPrivate,
      },
    })
    return createSuccessResult({
      id: res.id,
      title: res.title,
      image: res.cover_image,
      isPrivate: !res.visibility,
      routesCount: 0,
    })
  } catch (error) {
    return createFailureResult({
      unexpectedError: true,
    }, { form })
  }
}
