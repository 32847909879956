import { useEffect } from 'react'
import { getLoginUrl } from 'shared/util-navigation'
import { useUser, useUserState } from './hooks'
import { useLocale } from 'shared/util-intl'
import { useCookieConsentStatistics } from 'web-app/feature-cookie-consent'

/**
 * Checks if user (currently in state) is logged in and redirects to login page if not.
 */
export const useRequireLogin = (): [boolean] => {
  const [user, isUserLoaded] = useUser()
  const { language } = useLocale()
  const { unitPreference } = useUserState()
  const cookieConsentStatistics = useCookieConsentStatistics()

  useEffect(() => {
    if (isUserLoaded && !user) {
      // Will lead back to current page after login
      window.location.href = getLoginUrl({
        language,
        unitPreference,
        cookieConsentStatistics,
        webAppPath: window.location.pathname,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoaded, user])

  const isLoggedIn = !!(isUserLoaded && user)

  return [isLoggedIn]
}
