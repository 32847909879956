import { RoutePlannerBase } from './base'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RoutePlannerInitializationState, RoutePlannerSliceDispatch, initBlank, initWithState, initWithWaypoints } from '../state'

interface RoutePlannerCreateProps {
  waypoints?: string
  initializationState?: RoutePlannerInitializationState
}

export const RoutePlannerCreate = ({
  waypoints,
  initializationState,
}: RoutePlannerCreateProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch

  useEffect(() => {
    if (waypoints) {
      dispatch(initWithWaypoints(waypoints))
    } else if (initializationState) {
      dispatch(initWithState(initializationState))
    } else {
      dispatch(initBlank())
    }
  }, [dispatch, initializationState, waypoints])

  return <RoutePlannerBase />
}
