import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import { formatAnalyticsData } from 'shared/util-analytics'
import {
  Uploader,
  UploaderProps,
  Divider,
  Button,
  GPXUploadIcon,
  FileBlankIcon,
  ButtonGroup,
  MainNavigation,
} from 'shared/ui-components'
import { WebAppContent, WebAppMainContent } from 'web-app/ui-layout'
import { useUser } from 'web-app/feature-user'
import { useRouteImportState, useIsAlreadyUploaded, stepNext, reset } from '../state'
import { useGarmin } from './use-garmin'
import { useRouteUpload } from './use-route-upload'
import { Typography } from '@mui/material'
import { useCancel } from 'web-app/feature-navigation'

import styles from './step-upload.module.scss'

const RouteImportStepUpload = () => {
  const { intl } = useLocale()
  const dispatch = useDispatch()
  const cancel = useCancel()

  const { isUploadInProgress, routeFileName, statusTaskId, routeData } = useRouteImportState()
  const [onRouteFileDrop, onRouteUploadCancel] = useRouteUpload()
  const [user, isUserLoaded] = useUser()
  const [garminUrl] = useGarmin(user, isUserLoaded)
  const isAlreadyUploaded = useIsAlreadyUploaded()

  const uploaderProps: UploaderProps = {
    variant: isAlreadyUploaded ? 'default' : 'primary',
    label: intl.formatMessage({
      id: 'route_import_step_upload_uploader_label',
      defaultMessage: 'Upload file',
    }),
    hideLabel: true,
    dropzoneOptions: {
      onDrop: onRouteFileDrop,
      accept: {
        // Only .gpx files are allowed on iOS and all files are allowed on iPadOS
        'application/*': ['.gpx', '.kml'],
      },
      multiple: false,
    },
    onCancel: onRouteUploadCancel,
    loading: !!(isUploadInProgress || (statusTaskId && !routeData)),
    inputDataTestId: 'route-import-route-file',
    messages: {
      dropFilesHere: intl.formatMessage({
        id: 'route_import_step_upload_uploader_message_drop_files_here',
        defaultMessage: 'Drop your files here...',
      }),
      button: isAlreadyUploaded
        ? intl.formatMessage({
          id: 'route_import_step_upload_uploader_button_replace',
          defaultMessage: 'Replace file',
        })
        : intl.formatMessage({
          id: 'route_import_step_upload_uploader_button_upload',
          defaultMessage: 'Upload file',
        }),
      cancel: intl.formatMessage({
        id: 'route_import_step_upload_uploader_button_cancel',
        defaultMessage: 'Cancel',
      }),
    },
  }

  const onCancel = () => {
    dispatch(reset())
    cancel()
  }

  const mainNavigation = (
    <MainNavigation
      title={intl.formatMessage({
        id: 'route_import_title_step_upload',
        defaultMessage: 'Route import',
      })}
      onCancel={onCancel}
    />
  )

  if (isAlreadyUploaded) {
    const nextButtonLabel = intl.formatMessage({
      id: 'route_import_next_step_button',
      defaultMessage: 'Next step',
    })
    return (
      <WebAppMainContent
        Header={() => mainNavigation}
        footer={
          <Button block onClick={() => dispatch(stepNext())} disabled={isUploadInProgress}>
            {nextButtonLabel}
          </Button>
        }
      >
        <WebAppContent>
          <Uploader {...uploaderProps}>
            <FileBlankIcon className={styles['uploadIcon'] + ' ' + styles['uploadedIcon']} />
            <Typography paragraph>{routeFileName}</Typography>
          </Uploader>
        </WebAppContent>
      </WebAppMainContent>
    )
  }

  const garminButtonLabel = intl.formatMessage({
    id: 'route_import_garmin_button',
    defaultMessage: 'Connect with Garmin',
  })

  return (
    <WebAppMainContent Header={() => mainNavigation}>
      <WebAppContent>
        <Uploader {...uploaderProps}>
          <GPXUploadIcon className={styles['uploadIcon']} />
          <Typography paragraph>
            {intl.formatMessage({
              id: 'route_import_upload_dialog',
              defaultMessage: 'Drag and drop your .gpx and .kml files here or click the button to browse your files.',
            })}
          </Typography>
        </Uploader>
        {garminUrl && (
          <>
            <Divider>
              {intl.formatMessage({
                id: 'route_import_step_upload_content_separator',
                defaultMessage: 'or',
              })}
            </Divider>
            <div className={styles['garmin']}>
              <Typography variant="h3">
                {intl.formatMessage({
                  id: 'route_import_step_upload_heading_garmin',
                  defaultMessage: 'Garmin Connect',
                })}
              </Typography>
              <Typography paragraph padding="0 1rem" margin="0 0 1.5rem">
                {intl.formatMessage({
                  id: 'route_import_step_upload_garmin_description',
                  defaultMessage: 'Seamlessly sync your Garmin activities with your Bikemap profile.',
                })}
              </Typography>
              <ButtonGroup>
                <Button
                  href={garminUrl}
                  analytics={formatAnalyticsData({ event: 'garmin_connect' })}
                  variant="secondary"
                  ariaLabel={garminButtonLabel}
                >
                  {garminButtonLabel}
                </Button>
              </ButtonGroup>
            </div>
          </>
        )}
      </WebAppContent>
    </WebAppMainContent>
  )
}

export default RouteImportStepUpload
