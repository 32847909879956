import { HOME_SLICE_KEY, HomeState, ResultsListItemState, StateWithHomeSlice } from './types'
import { createSelector } from 'reselect'

export const homeSliceSelector = (state: StateWithHomeSlice): HomeState => state[HOME_SLICE_KEY]

export const resultsListSelector: (state: StateWithHomeSlice) => ResultsListItemState[] = createSelector(
  homeSliceSelector,
  ({ results, routes, creators }) => results.reduce((items: ResultsListItemState[], routeId) => {
    const route = routes[routeId]
    if (!route) return items
    const creator = typeof route.creatorId === 'number' ? creators[route.creatorId] : undefined
    items.push({ route, creator })
    return items
  }, [])
)
