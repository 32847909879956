import { MultiLineString, MultiPoint, Point, Polygon } from 'geojson'
import bbox from '@turf/bbox'
import {
  AlongTheRouteAttribute,
  ExtensiveRouteEntity,
  RouteBikeTypes,
  RouteEntity,
  RouteSurfaces,
  UserPreviewEntity,
} from '../entities'
import { Waypoints, convertToLineString, dummyMultiGeometry, positionToLngLat } from 'shared/util-geo'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { dummyAlongTheRouteAttributesResponse, convertAlongTheRouteAttributes } from './shared/along-the-route-attributes'

export type RouteResponse = {
  altitude_difference: number
  altitude_difference_back: number
  average_speed: number|null
  bounding_box: Polygon
  category: RouteSurfaces | null
  created: string
  description: string|null
  distance: number
  duration: number|null
  gpx: string
  ground: RouteBikeTypes | null
  has_pois: boolean
  hasz: boolean
  id: number
  is_favorite: boolean
  is_loop: boolean
  is_private: boolean
  kml: string
  location?: string
  points: MultiLineString
  route_favorite_count: number
  route_images: string[]
  start: Point
  staticmap: string | null
  title: string
  user: {
    id: number
    displayname: string
    is_subscribed: boolean
    avatar_image: string
    slug: string
  }
  views: number
  external_id?: string
  copied_from: number | null
}

export type RouteResponseIncludedCollections = {
  routecollections: number[]
}

export type RouteResponseIncludedWaypoints = {
  /** 3rd dimension is index of control point */
  waypoints: MultiPoint | null
}

export type RouteResponseIncludedControlPointIndexes = {
  control_point_indexes: number[] | null
}

export type RouteResponseIncludedSurfaceAlongTheRoute = {
  bm_surface: AlongTheRouteAttribute<string>[] | null
}

export type RouteResponseIncludedWayTypeAlongTheRoute = {
  bm_way_type: AlongTheRouteAttribute<string>[] | null
}

export type RouteResponseIncludedBikeNetworkAlongTheRoute = {
  bike_network: AlongTheRouteAttribute<string>[] | null
}

export const dummyRouteResponse: RouteResponse = {
  altitude_difference: 100,
  altitude_difference_back: 200,
  average_speed: 3.738,
  bounding_box: {
    type: 'Polygon',
    coordinates: [[[1, 2], [2, 3], [3, 4], [4, 5]]],
  },
  category: [1],
  created: '2023-03-24T16:44:51Z',
  description: 'Some description',
  distance: 2000,
  duration: 535,
  gpx: '/gpx',
  ground: [2, 3],
  has_pois: true,
  hasz: true,
  id: 100,
  is_favorite: false,
  is_loop: false,
  is_private: false,
  kml: '/kml',
  location: 'Ramingstein, Salzburg, Österreich',
  points: dummyMultiGeometry,
  route_favorite_count: 3,
  route_images: ['/image1', '/image2'],
  start: {
    type: 'Point',
    coordinates: [17.111, 49.112],
  },
  staticmap: '/static-map',
  title: 'Title',
  user: {
    id: 12,
    displayname: 'John Doe',
    is_subscribed: false,
    avatar_image: '/avatar',
    slug: 'doe',
  },
  views: 198,
  external_id: '69e77c75-7e51-46e4-83bf-0a215fea6492',
  copied_from: null,
}

export const extendedDummyRouteResponse: (
  RouteResponse &
  RouteResponseIncludedCollections &
  RouteResponseIncludedWaypoints &
  RouteResponseIncludedControlPointIndexes &
  RouteResponseIncludedSurfaceAlongTheRoute &
  RouteResponseIncludedWayTypeAlongTheRoute &
  RouteResponseIncludedBikeNetworkAlongTheRoute
) = {
  ...dummyRouteResponse,
  routecollections: [823, 9120],
  waypoints: {
    type: 'MultiPoint',
    coordinates: [
      [17.121, 49.112, 0], [17.113, 49.115, 1],
    ],
  },
  control_point_indexes: [0, 2],
  bm_surface: dummyAlongTheRouteAttributesResponse.bm_surface
    ? [dummyAlongTheRouteAttributesResponse.bm_surface]
    : null,
  bm_way_type: dummyAlongTheRouteAttributesResponse.bm_way_type
    ? [dummyAlongTheRouteAttributesResponse.bm_way_type]
    : null,
  bike_network: dummyAlongTheRouteAttributesResponse.bike_network
    ? [dummyAlongTheRouteAttributesResponse.bike_network]
    : null,
}

/**
 * Up to this number, waypoints of existing routes are treated like actual waypoints. Reason: Older routes can have
 * ridiculous amounts of waypoints, which have actually just been used as control points.
 */
export const MAX_REASONABLE_WAYPOINTS = 20

export function convertToRouteEntity(
  res: RouteResponse & RouteResponseIncludedWaypoints & RouteResponseIncludedControlPointIndexes
): RouteEntity {
  const bboxResult = bbox(res.bounding_box)

  return {
    id: res.id,
    title: res.title,
    description: res.description || undefined,
    geometry: convertToLineString(res.points),
    distance: res.distance,
    ascent: res.altitude_difference,
    descent: res.altitude_difference_back,
    isPrivate: res.is_private,
    externalId: res.external_id,
    location: res.location,
    favoriteCount: res.route_favorite_count,
    durationInS: res.duration ?? undefined,
    averageSpeedInMs: res.average_speed ?? undefined,
    created: new Date(res.created).getTime(),
    surfaces: res.ground || [],
    bikeTypes: res.category || [],
    images: res.route_images,
    isFavorite: res.is_favorite,
    gpx: res.gpx,
    kml: res.kml,
    hasPois: res.has_pois,
    isLoop: res.is_loop,
    bounds: bboxResult.length === 4
      ? bboxResult
      : [bboxResult[0], bboxResult[1], bboxResult[3], bboxResult[4]],
    copiedFrom: res.copied_from,
    staticMap: res.staticmap || undefined,
    start: positionToLngLat(res.points.coordinates[0][0]),
    ...getWaypointsAndControlPointIndexes(res),
  }
}

function getWaypointsAndControlPointIndexes(res: (
  RouteResponse &
  RouteResponseIncludedWaypoints &
  RouteResponseIncludedControlPointIndexes
)): {
  waypoints: Waypoints
  controlPointIndexes: [number, number, ...number[]]
} {
  if (res.waypoints && areWaypointsValid(res.waypoints)) {
    if (areControlPointIndexesValid(res.control_point_indexes, res.points, res.waypoints)) {
      return {
        waypoints: res.waypoints.coordinates.map(
          ([lng, lat, controlPointIndex]) => ({ lng, lat, controlPointIndex })
        ) as Waypoints,
        controlPointIndexes: res.control_point_indexes,
      }
    }
    const waypointCoordinates = res.waypoints.coordinates
    return {
      waypoints: waypointCoordinates.map(
        ([lng, lat], i) => ({ lng, lat, controlPointIndex: i })
      ) as Waypoints,
      controlPointIndexes: waypointCoordinates.map((waypoint, i) => {
        if (i === 0) return 0
        if (i === waypointCoordinates.length - 1) return res.points.coordinates[0].length - 1
        return nearestPointOnLine(convertToLineString(res.points), waypoint).properties.index ?? i
      }) as [number, number, ...number[]],
    }
  }
  const geometryCoordinates = res.points.coordinates[0]
  return {
    waypoints: [
      { ...positionToLngLat(geometryCoordinates[0]), controlPointIndex: 0 },
      { ...positionToLngLat(geometryCoordinates[geometryCoordinates.length - 1]), controlPointIndex: 1 },
    ],
    controlPointIndexes: [0, geometryCoordinates.length - 1],
  }
}

function areWaypointsValid(resWaypoints: MultiPoint): boolean {
  const waypointsLength = resWaypoints.coordinates.length
  return waypointsLength >= 2 && waypointsLength <= MAX_REASONABLE_WAYPOINTS
}

function areControlPointIndexesValid(
  resIndexes: number[] | null,
  resPoints: MultiLineString,
  resWaypoints: MultiPoint
): resIndexes is [number, number, ...number[]] {
  return !!(
    resIndexes &&
    resIndexes.length >= 2 &&
    resIndexes[0] === 0 &&
    resIndexes[resIndexes.length - 1] === resPoints.coordinates[0].length - 1 &&
    resIndexes.length >= resWaypoints.coordinates.length
  )
}

export function convertToExtensiveRouteEntity(
  res: (
    RouteResponse &
    RouteResponseIncludedWaypoints &
    RouteResponseIncludedControlPointIndexes &
    RouteResponseIncludedSurfaceAlongTheRoute &
    RouteResponseIncludedWayTypeAlongTheRoute &
    RouteResponseIncludedBikeNetworkAlongTheRoute
  )
): ExtensiveRouteEntity {
  return {
    ...convertToRouteEntity(res),
    ...convertAlongTheRouteAttributes({
      // In the route response these properties are wrapped in another array
      bm_surface: res.bm_surface ? res.bm_surface[0] : null,
      bm_way_type: res.bm_way_type ? res.bm_way_type[0] : null,
      bike_network: res.bike_network ? res.bike_network[0] : null,
    }),
  }
}

export function convertToUserEntity(res: RouteResponse['user']): UserPreviewEntity {
  return {
    id: res.id,
    slug: res.slug,
    name: res.displayname,
    avatar: res.avatar_image,
    isPremium: res.is_subscribed,
  }
}

export interface RouteImageResponse {
  id: number
  route: number
  image: string
  url: string
}

export const dummyRouteImageResponse: RouteImageResponse = {
  id: 934,
  route: 100,
  image: 'w0948htrfwu',
  url: '/image1',
}
