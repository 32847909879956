'use client'

import React, { useEffect, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { isEmpty } from 'lodash'
import { useStateLanguage } from './use-state-language'
import { useUrlLanguage } from './use-url-language'
import { DEFAULT_LOCALE } from './config'
import { IntlProviderProps } from './types'
import { getMessages } from './get-messages'

/**
 * Can be used outside redux state provider.
 */
export function BaseIntlProvider({ children, locale = DEFAULT_LOCALE, loading, namespace }: IntlProviderProps) {
  const [messages, setMessages] = useState({})

  useEffect(() => {
    getMessages(locale, namespace).then((m) => setMessages(m))
  }, [locale, namespace])

  const isWaitingForMessages = locale !== DEFAULT_LOCALE && isEmpty(messages)

  return isWaitingForMessages ? (
    loading || null
  ) : (
    <ReactIntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  )
}

/**
 * Needs to be wrapped in redux state provider.
 */
export function IntlProvider({ children, locale, ...rest }: IntlProviderProps) {
  useUrlLanguage()
  const preferredLanguage = useStateLanguage()
  const language = locale || preferredLanguage
  return (
    <BaseIntlProvider locale={language} {...rest}>
      {children}
    </BaseIntlProvider>
  )
}
