import { useDispatch } from 'react-redux'
import { PrintMap } from './print-map'
import { detailedMapRendered, useRoutePrintState } from '../state'
import { MapViewport } from 'shared/ui-map'
import { useState } from 'react'

interface DetailedMapProps {
  mapKey: string
}

export const DetailedMap = ({
  mapKey,
}: DetailedMapProps) => {
  const dispatch = useDispatch()
  const { detailedMapViewports, detailedMapImages } = useRoutePrintState()

  const [viewport, setViewport] = useState<MapViewport>(detailedMapViewports[mapKey])

  return (
    <PrintMap
      id={'detailed-map-' + mapKey}
      onRender={image => dispatch(detailedMapRendered({ mapKey, image, viewport }))}
      mapImage={detailedMapImages[mapKey]}
      viewport={viewport}
      onViewportChanged={setViewport}
      interactive
    />
  )
}
