import React from 'react'
import { ColorValue, darkColors, lightColors } from 'shared/ui-design-system'

export type StylesMode = 'light' | 'dark'

export type Definitions<ValueType> = {
  [key: string]: ValueType
}

type Converter<ValueType> = (val: ValueType) => string

type ColorGroup = {
  [key: string]: ColorValue
}

type FlattenedColorGroup = {
  [key: string]: string
}

/**
 * Get a subset of the color palette as custom properties.
 * A nested color like colors.group.name becomes the custom property "--group-name".
 */
export function getColorsAsCustomProperties(mode: StylesMode = 'light'): React.CSSProperties {
  const allColors = mode === 'dark' ? darkColors : lightColors

  const cssProperties: FlattenedColorGroup = {}
  for (const group of ['neutral', 'onNeutral', 'coloredBG', 'onColor', 'actionColor', 'accentColor', 'dangerColor']) {
    const flattened = getFlattenedColorGroup(allColors[group])
    for (const key in flattened) {
      cssProperties['--' + group + key] = flattened[key]
    }
  }
  return cssProperties
}

/**
 * Convert hierarchical groups of colors into a flat object where keys are concatinated from all sub-groups.
 */
function getFlattenedColorGroup(colorGroup: ColorGroup): FlattenedColorGroup {
  const flattened: FlattenedColorGroup = {}
  for (const key in colorGroup) {
    const value = colorGroup[key]
    if (typeof value === 'string') {
      flattened['-' + key] = value
    } else {
      const flattenedValue = getFlattenedColorGroup(value)
      for (const subKey in flattenedValue) {
        flattened['-' + key + subKey] = flattenedValue[subKey]
      }
    }
  }
  return flattened
}

export function getDefinitionsAsCustomProperties<ValueType = string>(
  definitions:Definitions<ValueType>,
  converter?: Converter<ValueType>
): React.CSSProperties {
  return Object.keys(definitions).reduce((prevDefinitions, key) => ({
    ...prevDefinitions,
    [convertKeyToCustomProperty(key)]: converter ? converter(definitions[key]) : definitions[key],
  }), {})
}

function convertKeyToCustomProperty(key: string): string {
  const kebabCase = key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
  const numbersKebabCase = kebabCase.replace(/([A-Z]|[a-z])(?=[0-9])/, letter => `${letter}-`)
  return `--${numbersKebabCase}`
}
