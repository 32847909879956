import { logError } from 'shared/util-error-handling'
import { AccessTokenDataType, AccessTokenDataExternalType, AccessTokenRequestDataType } from './types'

/**
 * Format access token data from object received from API endpoint.
 */
export function formatAccessTokenFromResponse(data: AccessTokenDataExternalType): AccessTokenDataType {
  return {
    accessToken: data.access_token,
    expiresIn: data.expires_in,
    scope: data.scope,
    tokenType: data.token_type,
    refreshToken: data.refresh_token,
    isLoggedIn: data.is_logged_in,
  }
}

/**
 * Retrieve auth tokens.
 * @throws Error
 */
export async function fetchAccessToken(refreshToken?: string): Promise<AccessTokenDataType> {
  const API_BASE_URL = process.env['NX_PUBLIC_API_URL']
  const API_CLIENT_ID = process.env['NX_PUBLIC_API_CLIENT_ID']
  const API_CLIENT_SECRET = process.env['NX_PUBLIC_API_CLIENT_SECRET']
  const API_DEV_USERNAME = process.env['NX_PUBLIC_API_DEV_USERNAME']
  const API_DEV_PASSWORD = process.env['NX_PUBLIC_API_DEV_PASSWORD']
  const API_BASE_URL_CACHED = process.env['NX_PUBLIC_API_URL_CACHED']

  if (!API_BASE_URL) {
    throw Error(logError('API URL is not defined'))
  }

  if (!API_CLIENT_ID || !API_CLIENT_SECRET) {
    throw Error(logError('Client ID/Secret not defined'))
  }

  const body: AccessTokenRequestDataType = {
    client_id: API_CLIENT_ID,
    client_secret: API_CLIENT_SECRET,
    // When calling the API on the same host and logged in, we are already authenticated by the cookie
    grant_type: 'web_access_token',
  }

  if (refreshToken) {
    // A refresh token should be used to refresh an existing authentication
    body['grant_type'] = 'refresh_token'
    body['refresh_token'] = refreshToken
  } else if (API_DEV_USERNAME && API_DEV_PASSWORD) {
    // When configured to call the development API with defined user credentials. This is meant for
    // development only.
    body['grant_type'] = 'password'
    body['username'] = API_DEV_USERNAME
    body['password'] = API_DEV_PASSWORD
  }

  const res = await fetch(API_BASE_URL_CACHED + '/v4/oauth2/access_token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(body as unknown as Record<string, string>),
    credentials: 'include',
  })
  const data = await res.json()

  return formatAccessTokenFromResponse(data)
}

export default fetchAccessToken
