import { Button, ButtonGroup, DeletingIndicator, Dialog, Modal } from 'shared/ui-components'
import { useState } from 'react'
import { useLocale } from 'shared/util-intl'
import { routesApi } from 'shared/data-access-core'
import { useDeleteRouteErrorNotification } from '../../hooks/notifications'
import { useRoute } from 'web-app/feature-route'
import { useCancel } from 'web-app/feature-navigation'

export const DeleteRouteAction = () => {
  const { intl } = useLocale()
  const cancel = useCancel()
  const route = useRoute()
  const showDeleteRouteErrorNotification = useDeleteRouteErrorNotification()

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const handleRouteDelete = async () => {
    if (!route) return

    setIsDeleteDialogShown(false)
    setIsDeleting(true)

    const result = await routesApi.delete(route.id)

    if (result.success) {
      cancel()
    } else {
      showDeleteRouteErrorNotification()
      setIsDeleting(false)
    }
  }

  const deletingMessage = intl.formatMessage({
    id: 'route_details_creator_deleting_message',
    defaultMessage: 'Deleting route...',
  })

  return (
    <>
      <Button variant="danger-secondary" block onClick={() => setIsDeleteDialogShown(true)}>
        {intl.formatMessage({
          id: 'route_details_creator_button_delete',
          defaultMessage: 'Delete route',
        })}
      </Button>
      <Dialog
        open={isDeleteDialogShown}
        title={intl.formatMessage({
          id: 'route_details_creator_delete_dialog_title',
          defaultMessage: 'Are you sure?',
        })}
        text={intl.formatMessage({
          id: 'route_details_creator_delete_dialog_text',
          defaultMessage: 'This route and all related content will be permanently deleted.',
        })}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" onClick={() => setIsDeleteDialogShown(false)}>
              {intl.formatMessage({
                id: 'route_details_creator_delete_dialog_button_cancel',
                defaultMessage: 'Cancel',
              })}
            </Button>
            <Button variant="danger-primary" onClick={handleRouteDelete}>
              {intl.formatMessage({
                id: 'route_details_creator_delete_dialog_button_delete',
                defaultMessage: 'Delete',
              })}
            </Button>
          </ButtonGroup>
        }
        onClose={() => setIsDeleteDialogShown(false)}
      />
      <Modal open={isDeleting} aria-label={deletingMessage}>
        <DeletingIndicator message={deletingMessage} />
      </Modal>
    </>
  )
}
