import React, { useState, useEffect, useCallback, MutableRefObject } from 'react'
import { Button } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useLayoutRefs } from './layout-hooks'

import styles from './scroll-tooltip.module.scss'

interface ScrollTooltipProps {
  mainContentRef: MutableRefObject<HTMLDivElement | null>
}

export function ScrollTooltip({ mainContentRef }: ScrollTooltipProps) {
  const { contentScrollToRef, contentFooterRef } = useLayoutRefs()

  const { intl } = useLocale()

  const [isVisible, setIsVisible] = useState(false)

  /**
   * Show only when target content is below / behind main content footer.
   */
  const handleVisibility = useCallback(() => {
    if (contentScrollToRef.current && contentFooterRef.current) {
      const cardScrollTo = contentScrollToRef.current.getBoundingClientRect()
      const contentFooter = contentFooterRef.current.getBoundingClientRect()
      setIsVisible(cardScrollTo.top > contentFooter.top)
    } else {
      setIsVisible(false)
    }
  }, [contentFooterRef, contentScrollToRef])

  /**
   * Update visibility on: init, scroll, and resize.
   */
  useEffect(() => {
    const scrollableContent = mainContentRef?.current
    if (!scrollableContent) {
      return
    }

    handleVisibility()

    scrollableContent.addEventListener('scroll', handleVisibility)

    return () => {
      scrollableContent.removeEventListener('scroll', handleVisibility)
    }
  }, [handleVisibility, mainContentRef])

  return isVisible && contentScrollToRef.current ? (
    <div className={styles['root']}>
      <div className={styles['tooltip']}>
        <Button
          size="small"
          onClick={() => {
            if (contentScrollToRef.current) {
              contentScrollToRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          }}
        >
          {intl.formatMessage({
            id: 'route_planner_scroll_tooltip_route_estimates',
            defaultMessage: 'Route estimates',
          })}
        </Button>
      </div>
    </div>
  ) : null
}
