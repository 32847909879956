import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { API_URL_GET_DEFAULT_MAP_STYLE, MapStyleEntity } from 'shared/data-access-core'
import { MapViewport } from 'shared/ui-map'
import { DEFAULT_VIEWPORT } from '../settings'
import { LngLatBoundsArray } from 'shared/util-geo'

export const MAP_SLICE_KEY = 'map'

export interface MapState {
  viewport: MapViewport
  mapStyles: MapStyleEntity[] | null
  mapStyle: string
  isGlobalHeatmapEnabled: boolean
  isGlobalHeatmapSupported: boolean
  bounds: LngLatBoundsArray | null
}

export type StateWithMapSlice = {
  [MAP_SLICE_KEY]: MapState,
}

/**
 * Only changes to patch in a MapViewport.
 */
export type MapViewportChanges = {
  center?: MapViewport['center']
  zoom?: MapViewport['zoom']
  bearing?: MapViewport['bearing']
  pitch?: MapViewport['pitch']
}

export const initialState: MapState = {
  viewport: DEFAULT_VIEWPORT,
  mapStyles: null,
  mapStyle: API_URL_GET_DEFAULT_MAP_STYLE,
  isGlobalHeatmapEnabled: false,
  isGlobalHeatmapSupported: false,
  bounds: null,
}

const slice = createSlice({
  name: MAP_SLICE_KEY,
  initialState,
  reducers: {
    viewportChanged(state, action: PayloadAction<MapViewportChanges>) {
      state.viewport = {
        ...state.viewport,
        ...action.payload,
      }
    },
    mapStyleChanged(state, action: PayloadAction<string>) {
      state.mapStyle = action.payload
    },
    mapStylesLoaded(state, action: PayloadAction<MapStyleEntity[]>) {
      state.mapStyles = action.payload
    },
    globalHeatmapEnabled(state) {
      state.isGlobalHeatmapEnabled = true
    },
    globalHeatmapDisabled(state) {
      state.isGlobalHeatmapEnabled = false
    },
    globalHeatmapSupportDetected(state, action: PayloadAction<boolean>) {
      state.isGlobalHeatmapSupported = action.payload
    },
    boundsChanged(state, action: PayloadAction<LngLatBoundsArray>) {
      state.bounds = action.payload
    },
  },
})

export const {
  viewportChanged,
  mapStyleChanged,
  mapStylesLoaded,
  globalHeatmapEnabled,
  globalHeatmapDisabled,
  globalHeatmapSupportDetected,
  boundsChanged,
} = slice.actions

const persistConfig: PersistConfig<MapState> = {
  key: MAP_SLICE_KEY,
  storage,
  whitelist: [
    'viewport',
    'mapStyle',
    'isGlobalHeatmapEnabled',
    'isGlobalHeatmapSupported',
    'bounds',
  ],
}

export const mapReducer = persistReducer(persistConfig, slice.reducer)
