import { Source, Layer, useMap } from 'react-map-gl/maplibre'
import { LineString } from 'geojson'
import {
  ROUTE_DESTINATION_MARKER_LAYER_ID,
  ROUTE_GEOMETRY_SOURCE_ID,
  ROUTE_LINE_LAYER_ID,
  ROUTE_OUTLINE_LAYER_ID,
} from '../settings'
import { findBeforeIdBehindSymbols, findBeforeIdOnTop } from '../helpers'
import { getRouteLineLayerProps, getRouteOutlineLayerProps } from './helpers'
import { useCallback, useEffect, useState } from 'react'

export interface MapRouteLineProps {
  id: string

  /** Map route ID behind which this line should be drawn */
  beforeMapRouteId?: string

  /** Map route ID in which this line should be drawn (right after its line)  */
  mapRouteId?: string

  geometry?: LineString
  type?: 'default'|'inactive'|'highlight'
}

/**
 * Only the line of a route or route segment.
 * @deprecated Use Source and Layer directly, especially if showing multiple route lines
 */
export const MapRouteLine = ({
  id,
  beforeMapRouteId,
  mapRouteId,
  geometry,
  type = 'default',
}: MapRouteLineProps) => {
  const { current: map } = useMap()

  const [routeLayerBeforeId, setRouteLayerBeforeId] = useState<string | undefined>()

  const findBeforeId = useCallback(() => {
    if (mapRouteId && map) {
      setRouteLayerBeforeId(findBeforeIdOnTop(map, ROUTE_DESTINATION_MARKER_LAYER_ID.replace('{baseId}', mapRouteId)))
    } else if (map) {
      setRouteLayerBeforeId(findBeforeIdBehindSymbols(map, beforeMapRouteId ? ROUTE_OUTLINE_LAYER_ID.replace('{baseId}', beforeMapRouteId) : undefined))
    }
  }, [beforeMapRouteId, map, mapRouteId])

  useEffect(() => {
    map?.on('data', findBeforeId)
    return () => {
      map?.off('data', findBeforeId)
    }
  }, [findBeforeId, map])

  return geometry ? (
    <Source
      id={ROUTE_GEOMETRY_SOURCE_ID.replace('{baseId}', id)}
      type='geojson'
      data={{
        type: 'Feature',
        geometry,
        properties: null,
      }}
    >
      <Layer
        id={ROUTE_OUTLINE_LAYER_ID.replace('{baseId}', id)}
        beforeId={routeLayerBeforeId}
        {...getRouteOutlineLayerProps(type)}
      />
      <Layer
        id={ROUTE_LINE_LAYER_ID.replace('{baseId}', id)}
        beforeId={routeLayerBeforeId}
        {...getRouteLineLayerProps(type)}
      />
    </Source>
  ) : null
}

export default MapRouteLine
