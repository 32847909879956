import { useLocale } from 'shared/util-intl'
import { Campaign, CampaignPopupProps } from '../types'
import { Typography } from '@mui/material'
import { Button, ButtonGroup, Link, Modal } from 'shared/ui-components'
import { PremiumModalHeader } from '../../premium-modal/premium-modal-header'
import { pushModalEvent } from 'shared/util-analytics'
import { STORE_URL_ANDROID, STORE_URL_IOS, getHelpCenterGiveawayUrl } from 'shared/util-navigation'
import { ReactNode } from 'react'

import avif from './cover.avif'
import webp from './cover.webp'
import png from './cover.png'

import styles from './popup.module.css'

const Popup = ({
  isOpen,
  onClose,
}: CampaignPopupProps) => {
  const { intl, language } = useLocale()

  const label = intl.formatMessage({
    id: 'campaign_popup_badge_summer_giveaway',
    defaultMessage: 'Giveaway',
  })

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-label={label}
      onOpen={() => pushModalEvent('premium_campaign')}
    >
      <div>
        <PremiumModalHeader
          image={{ avif, webp, png, alt: label }}
          badge={label}
        />
        <div style={{ padding: '0 1rem 1rem' }}>
          <Typography variant="h2" textAlign="center" p="2rem 1rem 0.75rem">
            {intl.formatMessage({
              id: 'campaign_popup_heading_summer_giveaway',
              defaultMessage: 'Exclusive <b>in-app giveaway</b> for Premium users',
            }, {
              b: (chunks: ReactNode) => <strong key={chunks?.toString()}>{chunks}</strong>,
            })}
          </Typography>
          <Typography paragraph textAlign='center'>
            {intl.formatMessage({
              id: 'campaign_popup_text_summer_giveaway',
              defaultMessage: `
As a Premium user, you can join our <b>in-app giveaway</b> through the Bikemap app. For this exclusive giveaway, we're
offering 1x <b>{bike24Amount, number, ::currency/EUR precision-integer} gift card for BIKE24</b> and 3x <b>
{tourRadarAmount, number, ::currency/EUR precision-integer} vouchers for TourRadar</b> as well as <b>10 upgrades</b>
to Bikemap Premium lifetime. Plus, everyone who doesn't win and is subscribed to our newsletter will receive a <b>
{tourRadarConsolationAmount, number, ::currency/EUR precision-integer} TourRadar voucher</b>!
              `,
            }, {
              bike24Amount: 1500,
              tourRadarAmount: 250,
              tourRadarConsolationAmount: 50,
              b: (chunks: ReactNode) => <strong key={chunks?.toString()}>{chunks}</strong>,
            })}
          </Typography>
          <Typography paragraph textAlign='center' variant='body2' color='textSecondary' marginBottom='1rem'>
            {intl.formatMessage({
              id: 'campaign_popup_fineprint_summer_giveaway',
              defaultMessage: `
The giveaway closes on August 31st, 2024. Winners will be drawn the following week. This giveaway is not affiliated
with BIKE24. {learnMore}
              `,
            }, {
              learnMore: (
                <Link href={getHelpCenterGiveawayUrl(language)}>
                  {intl.formatMessage({
                    id: 'campaign_popup_fineprint_link_summer_giveaway',
                    defaultMessage: 'Learn more...',
                  })}
                </Link>
              ),
            })}
          </Typography>
          <ButtonGroup>
            <Button
              block
              variant='primary'
              href={'https://bikemap.page.link/giveaway-summer'}
              className={styles['cta-small']}
            >
              {intl.formatMessage({
                id: 'campaign_popup_cta_summer_giveaway',
                defaultMessage: 'Open the Bikemap app',
              })}
            </Button>
            <Button
              variant='primary'
              href={STORE_URL_IOS}
              className={styles['cta-large']}
            >
              {intl.formatMessage({
                id: 'campaign_popup_cta_ios_summer_giveaway',
                defaultMessage: 'iOS app',
              })}
            </Button>
            <Button
              variant='primary'
              href={STORE_URL_ANDROID}
              className={styles['cta-large']}
            >
              {intl.formatMessage({
                id: 'campaign_popup_cta_android_summer_giveaway',
                defaultMessage: 'Android app',
              })}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Modal>
  )
}

const campaign: Campaign = {
  start: '2024-08-10',
  end: '2024-08-31 23:55',
  isUserTarget: (isLoggedIn, isPremium) => isLoggedIn && isPremium,
  popup: Popup,
}

export default campaign
