import { useMemo, useState } from 'react'
import { AlongTheRouteAttribute } from 'shared/data-access-core'
import { Typography } from '@mui/material'
import { formatLargeLength } from 'shared/util-formatting'
import { useLocale } from 'shared/util-intl'
import { useUserState } from 'web-app/feature-user'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import clsx from 'clsx'
import { Button } from 'shared/ui-components'

import styles from './along-the-route-diagram.module.css'

type DiagramData<Category> = {
  category: Category
  percentage: number
}

type CategoryGroup<Category> = {
  categories: Category[]
  label: string
}

export type CategoryGroups<Category> = [CategoryGroup<Category>, CategoryGroup<Category>]

interface AlongTheRouteDiagramProps<Category extends string> {
  alongTheRouteAttribute: AlongTheRouteAttribute<Category>
  colors: Record<Category, string>
  labels: Record<Category, string>
  routeDistanceM: number
  groups?: CategoryGroups<Category>
}

export function AlongTheRouteDiagram<Category extends string>({
  alongTheRouteAttribute,
  colors,
  labels,
  routeDistanceM,
  groups,
}: AlongTheRouteDiagramProps<Category>) {
  const { intl, language } = useLocale()
  const { unitPreference } = useUserState()

  const diagramData = useMemo<DiagramData<Category>[]>(() => {
    let totalDistanceM = 0
    const distanceMSumsPerCategory: Record<Category, number> = {} as Record<Category, number>
    for (const section of alongTheRouteAttribute) {
      const category = section[2]
      const sectionDistanceM = section[3]
      totalDistanceM += sectionDistanceM
      distanceMSumsPerCategory[category] = (distanceMSumsPerCategory[category] || 0) + sectionDistanceM
    }
    const data: DiagramData<Category>[] = []
    let missing: DiagramData<Category> | null = null
    for (const category in distanceMSumsPerCategory) {
      const percentage = distanceMSumsPerCategory[category] / totalDistanceM * 100
      if (category === 'missing') {
        missing = { category, percentage }
      } else {
        data.push({ category, percentage })
      }
    }
    const sortedData = data.sort((a, b) => b.percentage - a.percentage)
    if (missing) {
      sortedData.push(missing)
    }
    return sortedData
  }, [alongTheRouteAttribute])

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const legendData = useMemo<DiagramData<Category>[]>(() => (
    isExpanded ? diagramData : diagramData.slice(0, 3)
  ), [diagramData, isExpanded])

  return (
    <div className={styles['container']}>
      <div className={styles['diagram-container']}>
        {diagramData.map(({ category, percentage }) => (
          <div
            key={category}
            className={styles['diagram-area']}
            style={{ width: `${percentage}%`, backgroundColor: colors[category] }}
          />
        ))}
      </div>
      {groups && (
        <div className={styles['groups-container']}>
          {groups.map(({ categories, label }, i) => {
            const percentage = diagramData.reduce((sum, { category, percentage }) => (
              sum + (categories.includes(category) ? percentage : 0)
            ), 0)
            return !!percentage && (
              <div key={i} className={styles['group']}>
                <Typography variant='body2' fontWeight={500}>{label}</Typography>
                <Typography variant='body2' color='textSecondary'>
                  {formatLargeLength(routeDistanceM * percentage / 100, language, unitPreference === 'imperial')}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  ({percentage < 1 ? '<1' : Math.round(percentage)} %)
                </Typography>
              </div>
            )
          })}
        </div>
      )}
      <ul className={styles['legend']}>
        {legendData.map(({ category, percentage }, i) => (
          <li
            key={category}
            className={clsx(styles['legend-item'], {
              [styles['legend-item-before-expand']]: i === 2 && !isExpanded && diagramData.length > 3,
            })}
          >
            <div className={styles['legend-color']} style={{ backgroundColor: colors[category] }} />
            <Typography variant='body2' fontWeight={500}>{labels[category]}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {formatLargeLength(routeDistanceM * percentage / 100, language, unitPreference === 'imperial')}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              ({percentage < 1 ? '<1' : Math.round(percentage)} %)
            </Typography>
          </li>
        ))}
        {!isExpanded && diagramData.length > 3 && (
          <li key='more' className={styles['expand-button-item']}>
            <Button
              variant="secondary"
              size="small"
              icon={<ExpandMoreRoundedIcon />}
              onClick={() => setIsExpanded(true)}
            >
              {intl.formatMessage({
                id: 'along_the_route_diagram_more_button',
                defaultMessage: 'More',
              })}
            </Button>
          </li>
        )}
      </ul>
    </div>
  )
}
