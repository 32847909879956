import { PrivacyItem, PrivacyItemSkeleton } from './privacy-item'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { useLocale } from 'shared/util-intl'
import { useCommonPrivacyLabels } from './hooks'
import { PrivacyFieldSet } from './privacy-field-set'
import { EditableContent } from '../editable-content'

const useRoutePrivacyDescriptions = () => {
  const { intl } = useLocale()

  return {
    publicDescription: intl.formatMessage({
      id: 'route_privacy_public_description',
      defaultMessage: 'The route is public and shared with the Bikemap community.',
    }),
    privateDescription: intl.formatMessage({
      id: 'route_privacy_private_description',
      defaultMessage: 'The route is private and only visible to you.',
    }),
  }
}

interface RoutePrivacyProps {
  isPrivate: boolean
}

const RoutePrivacy = ({ isPrivate }: RoutePrivacyProps) => {
  const { privateLabel, publicLabel } = useCommonPrivacyLabels()
  const { publicDescription, privateDescription } = useRoutePrivacyDescriptions()

  return isPrivate ? (
    <PrivacyItem icon={<VisibilityOffRoundedIcon />} title={privateLabel} description={privateDescription} />
  ) : (
    <PrivacyItem icon={<VisibilityRoundedIcon />} title={publicLabel} description={publicDescription} />
  )
}

interface RoutePrivacyFormProps {
  value: boolean
  onChange: (value: boolean) => void
}

const RoutePrivacyForm = ({ value, onChange }: RoutePrivacyFormProps) => {
  const { publicDescription, privateDescription } = useRoutePrivacyDescriptions()

  return (
    <form>
      <PrivacyFieldSet
        noLabel
        value={value}
        onChange={onChange}
        publicDescription={publicDescription}
        privateDescription={privateDescription}
      />
    </form>
  )
}

interface EditableRoutePrivacyProps {
  heading?: string
  isPrivate?: boolean
}

export const EditableRoutePrivacy = ({ heading, isPrivate }: EditableRoutePrivacyProps) => (
  <EditableContent
    name="isPrivate"
    currentValue={isPrivate}
    renderContent={({ value }) => <RoutePrivacy isPrivate={value} />}
    renderForm={(props) => <RoutePrivacyForm {...props} />}
    renderLoading={() => <PrivacyItemSkeleton />}
    heading={heading}
  />
)
