import React, { useState, useCallback, useEffect } from 'react'
import BaseMap, { MapProps as BaseMapProps, ViewStateChangeEvent, useMap } from 'react-map-gl/maplibre'
import { MapViewport, VisibleMapPadding } from '../types'
import { useTransformRequest } from './use-transform-request'
import { getViewportFromViewState } from '../helpers'
import { useGlobalHeatmapLayer } from './use-global-heatmap-layer'
import { LngLatBoundsArray } from 'shared/util-geo'

import styles from './Map.module.scss'

export interface MapProps {
  id: string
  children?: React.ReactNode
  viewport: MapViewport
  padding?: VisibleMapPadding
  boundsToFit?: LngLatBoundsArray | null
  onFitBounds?: () => void
  onViewportChanged: (viewport: MapViewport) => void
  isGlobalHeatmapEnabled?: boolean
  onGlobalHeatmapSupportDetection?: (isSupported: boolean) => void
  mapStyle: string
  onClick?: BaseMapProps['onClick']
  preserveDrawingBuffer?: BaseMapProps['preserveDrawingBuffer']
  onRender?: BaseMapProps['onRender']
  interactive?: BaseMapProps['interactive']
  reuseMaps?: BaseMapProps['reuseMaps']
  onBoundsChange?: (bounds: LngLatBoundsArray) => void
}

export const Map = ({
  id,
  children,
  viewport,
  padding,
  boundsToFit,
  onFitBounds,
  onViewportChanged,
  isGlobalHeatmapEnabled,
  onGlobalHeatmapSupportDetection,
  reuseMaps = true,
  onBoundsChange,
  ...mapProps
}: MapProps) => {
  const maps = useMap()
  const map = maps[id]

  const [transformRequest, isAuthenticated] = useTransformRequest()

  const [localViewport, setLocalViewport] = useState<MapViewport>(viewport)
  const [isViewportChanging, setIsViewportChanging] = useState<boolean>(false)
  const [lastFitBounds, setLastFitBounds] = useState<string|null>(null)

  const updateGlobalHeatmapLayer = useGlobalHeatmapLayer(map, isGlobalHeatmapEnabled, onGlobalHeatmapSupportDetection)

  useEffect(() => {
    if (!isViewportChanging && JSON.stringify(localViewport) !== JSON.stringify(viewport)) {
      setLocalViewport(viewport)
    }
  }, [isViewportChanging, localViewport, viewport])

  useEffect(() => {
    if (map && boundsToFit && lastFitBounds !== boundsToFit.toString()) {
      map.resize() // Make sure map canvas has correct dimensions right now
      map.fitBounds(boundsToFit, { padding, duration: 1000 }, { wasFitBounds: true })
    }
  }, [boundsToFit, map, lastFitBounds, padding])

  const handleMove = useCallback((event: ViewStateChangeEvent) => {
    setIsViewportChanging(true)
    setLocalViewport(getViewportFromViewState(event.viewState))
  }, [])

  const handleBoundsChange = useCallback(() => {
    if (map && onBoundsChange) {
      const [sw, ne] = map.getBounds().toArray()
      onBoundsChange([...sw, ...ne])
    }
  }, [map, onBoundsChange])

  useEffect(() => {
    map?.once('load', handleBoundsChange)
    return () => {
      map?.off('load', handleBoundsChange)
    }
  }, [handleBoundsChange, map])

  const handleMoveEnd = useCallback((event: ViewStateChangeEvent) => {
    if ((event as { wasFitBounds?: boolean }).wasFitBounds) {
      setLastFitBounds(boundsToFit ? boundsToFit.toString() : null)
      onFitBounds && onFitBounds()
    } else if (lastFitBounds) {
      setLastFitBounds(null)
    }
    setIsViewportChanging(false)
    if (map) {
      onViewportChanged(getViewportFromViewState(event.viewState))
    }
    handleBoundsChange()
  }, [boundsToFit, handleBoundsChange, lastFitBounds, map, onFitBounds, onViewportChanged])

  if (isAuthenticated) {
    return (
      <div className={styles['root']}>
        <BaseMap
          id={id}
          longitude={localViewport.center[0]}
          latitude={localViewport.center[1]}
          zoom={localViewport.zoom}
          pitch={localViewport.pitch || 0}
          bearing={localViewport.bearing || 0}
          onMove={handleMove}
          onMoveEnd={handleMoveEnd}
          transformRequest={transformRequest}
          attributionControl={false}
          reuseMaps={reuseMaps}
          styleDiffing={false}
          maxZoom={21}
          onData={updateGlobalHeatmapLayer}
          {...mapProps}
        >
          {children}
        </BaseMap>
      </div>
    )
  }
  return null
}

export default Map
