import { useMap } from 'react-map-gl/maplibre'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { CompassIcon, Control, ToolButton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'

interface NavigationControlProps {
  mapId: string
  bearing: number
  pitch: number
}

export const NavigationControl = ({ mapId, bearing, pitch }: NavigationControlProps) => {
  const { intl } = useLocale()
  const maps = useMap()
  const map = maps[mapId]

  const rotateX = pitch ? `rotateX(${pitch}deg)` : ''
  const rotate = bearing ? `rotate(${bearing}deg)` : ''

  const zoomInLabel = intl.formatMessage({
    id: 'navigation_control_zoom_in_label',
    defaultMessage: 'Zoom in',
  })
  const zoomOutLabel = intl.formatMessage({
    id: 'navigation_control_zoom_out_label',
    defaultMessage: 'Zoom out',
  })
  const orientationLabel = intl.formatMessage({
    id: 'navigation_control_orientation_label',
    defaultMessage: 'Orientation',
  })

  return (
    <Control>
      <ToolButton
        data-testid="zoom-in-button"
        variant="ghost-primary"
        ariaLabel={zoomInLabel}
        icon={<AddIcon />}
        onClick={() => {
          map?.zoomIn()
        }}
      />
      <ToolButton
        data-testid="zoom-out-button"
        variant="ghost-primary"
        ariaLabel={zoomOutLabel}
        icon={<RemoveIcon />}
        onClick={() => {
          map?.zoomOut()
        }}
      />
      <ToolButton
        variant="ghost-primary"
        data-testid="compass-button"
        ariaLabel={orientationLabel}
        icon={<CompassIcon style={{ transform: `${rotateX} ${rotate}` }} />}
        onClick={() => {
          map?.resetNorthPitch()
        }}
      />
    </Control>
  )
}
