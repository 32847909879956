import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { IntlProvider, commonMessages, useLocale, useSyncLangAttribute } from 'shared/util-intl'
import { GlobalThemeStyles, ThemeProvider, MessagesProvider } from 'shared/ui-components'
import {
  WEB_APP_EDIT,
  WEB_APP_IMPORT,
  WEB_APP_PATH_ROUTE_DETAILS,
  WEB_APP_PATH_ROUTE_PLANNER,
  WEB_APP_PRINT,
} from 'shared/util-navigation'
import { WebAppCookieConsent, useUrlCookieConsent, useCookieConsentStatistics } from 'web-app/feature-cookie-consent'
import { useFetchedUser, useUser, useUrlUnitPreference, useUserState, PremiumUpsellTriggers } from 'web-app/feature-user'
import { ErrorBoundary, ErrorBoundaryFallback, ConnectionLostModal } from 'web-app/utils-error-handling'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { pushUserDataEvent } from 'shared/util-analytics'
import { NotificationsContainer } from 'web-app/feature-notifications'
import { store, persistor } from '../state/store'
import FallbackView from '../views/fallback-view'
import { environment } from '../environments/environment'

const HomeView = environment.local
  ? lazy(() => import(/* webpackMode: "eager" */ '../views/home-view'))
  : lazy(() => import(/* webpackChunkName: "lazy-home" */ '../views/home-view'))
const RouteDetailsView = environment.local
  ? lazy(() => import(/* webpackMode: "eager" */ '../views/route-details-view'))
  : lazy(() => import(/* webpackChunkName: "lazy-route-details" */ '../views/route-details-view'))
const RouteImportView = environment.local
  ? lazy(() => import(/* webpackMode: "eager" */ '../views/route-import-view'))
  : lazy(() => import(/* webpackChunkName: "lazy-route-import" */ '../views/route-import-view'))
const RoutePlannerView = environment.local
  ? lazy(() => import(/* webpackMode: "eager" */ '../views/route-planner-view'))
  : lazy(() => import(/* webpackChunkName: "lazy-route-planner" */ '../views/route-planner-view'))
const RoutePrintView = environment.local
  ? lazy(() => import(/* webpackMode: "eager" */ '../views/route-print-view'))
  : lazy(() => import(/* webpackChunkName: "lazy-route-print" */ '../views/route-print-view'))

// If these are empty, global loading from index.ejs will be visible
const LoadingScreen = () => null
const IntlProviderLoading = LoadingScreen
const LoadingPages = LoadingScreen
const PersistStateLoading = LoadingScreen

/**
 * Run initial global actions.
 * Extracted to prevent additional re-rendering.
 */
const InitialAppLoad = () => {
  useSyncLangAttribute()

  useFetchedUser()

  useUrlUnitPreference()

  useUrlCookieConsent()

  const statisticsCookiesEnabled = useCookieConsentStatistics()

  const [user, isUserLoaded] = useUser()
  if (user && isUserLoaded) {
    pushUserDataEvent({
      userExternalId: statisticsCookiesEnabled && user.externalId ? user.externalId : 'anonymous',
      userPlanPeriod: user.planPeriod,
    })
  }

  return null
}

/**
 * Use unit from user state.
 * Extracted to prevent additional re-rendering.
 */
const ConnectedCookieConsent = () => {
  const { unitPreference } = useUserState()

  return <WebAppCookieConsent unitPreference={unitPreference} />
}

const AppContent = () => {
  return (
    <Suspense fallback={<LoadingPages />}>
      <InitialAppLoad />
      <NotificationsContainer />
      <Router>
        <Routes>
          <Route path={'/'} element={<HomeView />} />
          <Route path={`${WEB_APP_IMPORT}/*`} element={<RouteImportView />} />
          <Route path={`${WEB_APP_PATH_ROUTE_PLANNER}/*`} element={<RoutePlannerView />} />
          <Route path={`${WEB_APP_EDIT}/*`} element={<RoutePlannerView />} />
          <Route path={`${WEB_APP_PRINT}/*`} element={<RoutePrintView />} />
          <Route path={`${WEB_APP_PATH_ROUTE_DETAILS}/*`} element={<RouteDetailsView />} />
          <Route path="*" element={<FallbackView />} />
        </Routes>
      </Router>
      <PremiumUpsellTriggers />
      <ConnectedCookieConsent />
      <ConnectionLostModal />
    </Suspense>
  )
}

const UIProviders = (props: { children: React.ReactElement }) => {
  const { intl } = useLocale()
  return (
    <MessagesProvider messages={commonMessages(intl)}>
      <ThemeProvider>
        <GlobalThemeStyles />
        {props.children}
      </ThemeProvider>
    </MessagesProvider>
  )
}

export function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<PersistStateLoading />} persistor={persistor}>
        <IntlProvider loading={<IntlProviderLoading />} namespace='web-app'>
          <UIProviders>
            <ErrorBoundary fallback={<ErrorBoundaryFallback beforeReload={persistor.purge} />}>
              <AppContent />
            </ErrorBoundary>
          </UIProviders>
        </IntlProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
