import { logError } from 'shared/util-error-handling'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { LngLat } from 'shared/ui-map'
import { RoutePoiEntity, getRoutePois } from 'shared/data-access-core'

export type RoutePoisContextValue = {
  routeId: number | null
  routePois: RoutePoiEntity[]
  selectedRoutePoi: RoutePoiEntity | null
  editingPosition: LngLat | null
  isDragging: boolean
  onRoutePoiSelectionChange: (routePoiId: number | null) => void
  onRoutePoiAdded: (routePoi: RoutePoiEntity) => void
  onEditRoutePoi: (routePoi: RoutePoiEntity) => void
  onRoutePoiUpdated: (routePoi: RoutePoiEntity) => void
  onRoutePoiRemoved: (routePoiId: number) => void
  onDragStart: () => void
  onDragEnd: (position: LngLat) => void
  onCreateRoutePoi: (position: LngLat) => void
  onCancel: () => void
}

const placeholder = () => logError('Route POI context handlers not yet available')

const RoutePoisContext = createContext<RoutePoisContextValue>({
  routeId: null,
  routePois: [],
  selectedRoutePoi: null,
  editingPosition: null,
  isDragging: false,
  onRoutePoiSelectionChange: placeholder,
  onRoutePoiAdded: placeholder,
  onEditRoutePoi: placeholder,
  onRoutePoiUpdated: placeholder,
  onRoutePoiRemoved: placeholder,
  onDragStart: placeholder,
  onDragEnd: placeholder,
  onCreateRoutePoi: placeholder,
  onCancel: placeholder,
})

interface RoutePoisProviderProps {
  routeId?: number
  children: ReactNode
}

export const RoutePoisProvider = ({ routeId, children }: RoutePoisProviderProps) => {
  const [routePois, setRoutePois] = useState<RoutePoiEntity[]>([])
  const [selectedRoutePoiId, setSelectedRoutePoiId] = useState<number | null>(null)
  const [editingPosition, setEditingPosition] = useState<LngLat | null>(null)
  const [isDragging, setIsDragging] = useState<boolean>(false)

  useEffect(() => {
    if (routeId) {
      getRoutePois(routeId).then((res) => {
        if (res.success) {
          setRoutePois(res.data)
        }
      })
    }
  }, [routeId])

  const onRoutePoiSelectionChange = (routePoiId: number | null) => {
    if (!editingPosition) {
      setSelectedRoutePoiId(routePoiId)
    }
  }

  const onRoutePoiAdded = (routePoi: RoutePoiEntity) => {
    setEditingPosition(null)
    setRoutePois((routePois) => [...routePois, routePoi])
    setSelectedRoutePoiId(routePoi.id)
  }

  const onEditRoutePoi = (routePoi: RoutePoiEntity) => {
    if (editingPosition) {
      logError('Can not edit route POI while another one is already being edited.')
      return
    }
    setSelectedRoutePoiId(routePoi.id)
    setEditingPosition({
      lng: routePoi.lng,
      lat: routePoi.lat,
    })
  }

  const onRoutePoiUpdated = (updated: RoutePoiEntity) => {
    setRoutePois((routePois) => routePois.map((routePoi) => (routePoi.id === updated.id ? updated : routePoi)))
    setEditingPosition(null)
  }

  const onRoutePoiRemoved = (routePoiId: number) => {
    if (selectedRoutePoiId === routePoiId) {
      setSelectedRoutePoiId(null)
    }
    setRoutePois((routePois) => routePois.filter((routePoi) => routePoi.id !== routePoiId))
  }

  const onDragEnd = (position: LngLat) => {
    if (editingPosition) {
      setEditingPosition(position)
    }
    setIsDragging(false)
  }

  const onCreateRoutePoi = (position: LngLat) => {
    if (editingPosition) {
      logError('Can not create route POI while another one is still being edited.')
      return
    }
    setSelectedRoutePoiId(null)
    setEditingPosition(position)
  }

  const onCancel = () => {
    setEditingPosition(null)
  }

  const selectedRoutePoi =
    (selectedRoutePoiId && routePois.find((routePoi) => routePoi.id === selectedRoutePoiId)) || null

  return (
    <RoutePoisContext.Provider
      value={{
        routeId: routeId || null,
        routePois,
        selectedRoutePoi,
        editingPosition,
        isDragging,
        onRoutePoiSelectionChange,
        onRoutePoiAdded,
        onEditRoutePoi,
        onRoutePoiUpdated,
        onRoutePoiRemoved,
        onDragStart: () => setIsDragging(true),
        onDragEnd,
        onCreateRoutePoi,
        onCancel,
      }}
    >
      {children}
    </RoutePoisContext.Provider>
  )
}

export const useRoutePois = (): RoutePoisContextValue => {
  const context = useContext(RoutePoisContext)
  if (!context) {
    logError('useRoutePois must be used inside RoutePoisProvider')
  }
  return context
}
