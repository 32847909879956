import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { RouteCollectionEntity, getUserRouteCollections } from 'shared/data-access-core'
import { Button, Checkbox, RouteCollectionItemSkeleton } from 'shared/ui-components'
import { FavoritesItem } from './favorites-item'
import { CollectionItem } from './collection-item'
import {
  RouteDetailsSliceDispatch,
  routeAddedToCollection,
  routeCollectionsFetched,
  routeRemovedFromCollection,
  useRouteCollections,
} from '../../state'
import { useLocale } from 'shared/util-intl'
import {
  RouteSliceDispatch,
  assignCollection,
  toggleFavoriteRoute,
  unassignCollection,
  useAssignedRouteCollectionIds,
  useRoute,
} from 'web-app/feature-route'
import {
  useAddRouteToCollectionErrorNotification,
  useRemoveRouteFromCollectionErrorNotification,
} from '../../hooks/notifications'

import styles from './collection-assignment-list.module.css'

const FavoritesListItem = () => {
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const route = useRoute()

  const isFavorite = !!route?.isFavorite

  return (
    <li>
      <Checkbox controlPosition="end" name="favorites" checked={isFavorite} onChange={() => dispatch(toggleFavoriteRoute())}>
        <FavoritesItem />
      </Checkbox>
    </li>
  )
}

interface CollectionListItemProps {
  collection: RouteCollectionEntity
}

const CollectionListItem = ({ collection }: CollectionListItemProps) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const route = useRoute()
  const assignedRouteCollectionIds = useAssignedRouteCollectionIds()
  const showAddRouteToCollectionErrorNotification = useAddRouteToCollectionErrorNotification()
  const showRemoveRouteFromCollectionErrorNotification = useRemoveRouteFromCollectionErrorNotification()

  const isAssigned = !!assignedRouteCollectionIds?.includes(collection.id)
  const handleChange = async () => {
    if (!route) return
    if (isAssigned) {
      try {
        await dispatch(unassignCollection(collection.id)).unwrap()
        dispatch(routeRemovedFromCollection(collection.id))
      } catch {
        showRemoveRouteFromCollectionErrorNotification(collection)
      }
    } else {
      try {
        await dispatch(assignCollection(collection.id)).unwrap()
        dispatch(routeAddedToCollection(collection.id))
      } catch {
        showAddRouteToCollectionErrorNotification(collection)
      }
    }
  }

  return (
    <li>
      <Checkbox controlPosition="end" name="favorites" checked={isAssigned} onChange={handleChange}>
        <CollectionItem collection={collection} />
      </Checkbox>
    </li>
  )
}

interface CollectionAssignmentListProps {
  onCreate: () => void
}

export const CollectionAssignmentList = ({ onCreate }: CollectionAssignmentListProps) => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const routeCollections = useRouteCollections()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const sortedUserRouteCollections = useMemo(
    () => Object.values(routeCollections).sort((a, b) => a.title.localeCompare(b.title)),
    [routeCollections],
  )

  useEffect(() => {
    if (!sortedUserRouteCollections.length) {
      setIsLoading(true)
    }
    getUserRouteCollections().then((result) => {
      if (result.success) {
        dispatch(routeCollectionsFetched(result.data))
        setIsLoading(false)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ul className={styles['list']}>
      <li>
        <Button variant="secondary" block icon={<AddRoundedIcon />} onClick={onCreate}>
          {intl.formatMessage({
            id: 'collections_popover_add_new_collection',
            defaultMessage: 'Add new collection',
          })}
        </Button>
      </li>
      <FavoritesListItem />
      {isLoading ? (
        <>
          <li>
            <RouteCollectionItemSkeleton />
          </li>
          <li>
            <RouteCollectionItemSkeleton />
          </li>
          <li>
            <RouteCollectionItemSkeleton />
          </li>
        </>
      ) : (
        sortedUserRouteCollections.map((collection) => (
          <CollectionListItem key={collection.id} collection={collection} />
        ))
      )}
    </ul>
  )
}
