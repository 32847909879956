import { logError } from 'shared/util-error-handling'
import { replaceParams, serializeParams } from './helpers'
import {
  DeleteRequestOptionsType,
  FetchOptionsType,
  GetRequestOptionsType,
  PostRequestOptionsType,
  FormDataValueType,
  RequestBodyType,
  PatchRequestOptionsType,
} from './types'
import { API_BASE_URL, API_BASE_URL_CACHED } from '../config'

export class AuthError extends Error {

  constructor(res?: Response) {
    super(res && `[${res.status}] ${res.statusText || 'Unknown'}`)
    this.name = 'AuthError'
  }

}

export class GoneError extends Error {

  constructor(res?: Response) {
    super(res && `[${res.status}] ${res.statusText || 'Unknown'}`)
    this.name = 'GoneError'
  }

}

export class ResponseError extends Error {

  constructor(res: Response) {
    super(`[${res.status}] ${res.statusText || 'Unknown'}`)
    this.name = 'ResponseError'
  }

}

/**
 * Make network requests with our custom headers and error handling.
 * @throws Error
 */
function makeRequest(url: string, options: FetchOptionsType) {
  return fetch(url, {
    method: options.method,
    body: options.body,
    headers: options.headers,
    credentials: options.credentials || 'same-origin',
    signal: options.signal,
    next: options.next,
  } as FetchOptionsType)
    .then((res: Response) => {
      if (res.ok && res.status === 204) {
        return {}
      }

      if (res.ok || options.skipResponseValidation) {
        return res.json()
      }

      if (res.status === 401 || res.status === 403) {
        return Promise.reject(new AuthError(res))
      }
      if (res.status === 410) {
        return Promise.reject(new GoneError(res))
      }

      // Handle invalid responses below in catch
      return Promise.reject(new ResponseError(res))
    })
    .catch((e) => {
      if (options.isSilent) {
        return Promise.resolve(e)
      }
      if (e instanceof AuthError) {
        logError('Network request - AuthError', e, { url, options })
      } else if (e instanceof ResponseError) {
        logError('Network request - ResponseError', e, { url, options })
      } else if (e instanceof Error) {
        logError('Network request - Error', e, { url, options })
      } else {
        logError('Network request - unknown', null, { url, options })
      }
      return Promise.reject(e)
    })
}

/**
 * Make GET requests.
 * @throws Error
 */
export function getRequest(url: string, options: GetRequestOptionsType = {}) {
  const requestUrl = serializeParams(replaceParams(url, options.params), options.queryParams)

  return makeRequest(requestUrl, {
    method: 'GET',
    headers: options.headers || {},
    credentials: options.credentials,
    signal: options.signal,
    isSilent: options.isSilent,
    next: { revalidate: 120 }, // SSR data cache to prevent render-blocking request
  })
}

/**
 * TODO WEB-1017: Make these the default once other APIs are moved to separate libs
 */
export function getFromCoreApi(path: string, options: GetRequestOptionsType = {}) {
  return getRequest(API_BASE_URL_CACHED + path, options)
}

/**
 * Make POST requests.
 * @throws Error
 */
export function postRequest(url: string, options: PostRequestOptionsType = {}) {
  const requestUrl = replaceParams(url, options.params)
  const { body, headers } = prepareRequestBody(options.body, options.type)

  return makeRequest(requestUrl, {
    method: 'POST',
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
    signal: options.signal,
    skipResponseValidation: options.skipResponseValidation,
  })
}

/**
 * TODO WEB-1017: Make these the default once other APIs are moved to separate libs
 */
export function postToCoreApi(path: string, options: PostRequestOptionsType = {}) {
  return postRequest(API_BASE_URL + path, options)
}

/**
 * Make PATCH requests.
 * @throws Error
 */
export function patchRequest(url: string, options: PatchRequestOptionsType = {}) {
  const requestUrl = replaceParams(url, options.params)
  const { body, headers } = prepareRequestBody(options.body, options.type)

  return makeRequest(requestUrl, {
    method: 'PATCH',
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
    signal: options.signal,
    skipResponseValidation: options.skipResponseValidation,
  })
}

/**
 * TODO WEB-1017: Make these the default once other APIs are moved to separate libs
 */
export function patchToCoreApi(path: string, options: PatchRequestOptionsType = {}) {
  return patchRequest(API_BASE_URL + path, options)
}

/**
 * Make a DELETE request.
 * @throws Error
 */
export function deleteRequest(url: string, options: DeleteRequestOptionsType = {}) {
  const requestUrl = serializeParams(replaceParams(url, options.params), options.queryParams)
  const { body, headers } = prepareRequestBody(options.body, options.type)

  return makeRequest(requestUrl, {
    method: 'DELETE',
    headers: {
      ...headers,
      ...options.headers,
    },
    body,
    credentials: options.credentials,
    signal: options.signal,
    isSilent: options.isSilent,
  })
}

/**
 * TODO WEB-1017: Make these the default once other APIs are moved to separate libs
 */
export function deleteFromCoreApi(path: string, options: DeleteRequestOptionsType = {}) {
  return deleteRequest(API_BASE_URL + path, options)
}

type RequestBodyPreparation = {
  body?: FetchOptionsType['body']
  headers: FetchOptionsType['headers']
}

function prepareRequestBody(
  body?: RequestBodyType,
  type: 'urlencoded' | 'json' | 'form-data' = 'form-data',
): RequestBodyPreparation {
  if (body) {
    if (type === 'urlencoded') {
      return {
        body: new URLSearchParams(body as Record<string, string>),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    }

    if (type === 'json') {
      return {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      }
    }

    if (type === 'form-data') {
      const formDataBody = new FormData()
      Object.entries(body).forEach(([key, val]) => {
        if (Array.isArray(val)) {
          val.forEach((item) => formDataBody.append(key, item))
        } else {
          formDataBody.append(key, val as FormDataValueType)
        }
      })
      return {
        body: formDataBody,
        headers: {},
      }
    }
  }

  return {
    headers: {},
  }
}
