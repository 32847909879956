import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { RoutePreviewEntity, UserPreviewEntity } from 'shared/data-access-core'
import { LngLat } from 'shared/util-geo'
import { StateWithRouteSlice } from 'web-app/feature-route'
import { StateWithUserSlice } from 'web-app/feature-user'

export const HOME_SLICE_KEY = 'home'

export type HomeState = {
  isSearching: boolean
  results: number[]
  selectedRoute: LngLat & {
    id: number
  } | null
  routes: {
    [routeId: number]: RoutePreviewEntity
  }
  creators: {
    [userId: number]: UserPreviewEntity
  }
}

export type ResultsListItemState = {
  route: RoutePreviewEntity
  creator?: UserPreviewEntity
}

export interface StateWithHomeSlice {
  [HOME_SLICE_KEY]: HomeState
}

export type HomeSliceDispatch = ThunkDispatch<
  StateWithHomeSlice & StateWithRouteSlice & StateWithUserSlice,
  undefined,
  UnknownAction
>
