import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { useLocale } from 'shared/util-intl'
import {
  Modal,
  ModalProps,
  Button,
  VerifiedIcon,
  NavigationIcon,
  BikeComputerIcon,
} from 'shared/ui-components'
import { getPremiumUrl } from 'shared/util-navigation'
import { pushModalEvent } from 'shared/util-analytics'
import { Typography } from '@mui/material'
import { useUserState } from '../hooks'
import { useCookieConsentStatistics } from 'web-app/feature-cookie-consent'
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded'
import LayersRoundedIcon from '@mui/icons-material/LayersRounded'
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded'
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import { PremiumActions } from './premium-actions'
import { PremiumModalHeader } from './premium-modal-header'

import styles from './premium-modal.module.scss'

interface PremiumFeaturesListItemProps {
  title: string
  highlighted?: boolean
  icon: React.ReactElement
}

function PremiumFeaturesListItem(props: PremiumFeaturesListItemProps) {
  return (
    <li className={styles['entry']}>
      {props.icon}
      <span className={styles['label']}>{props.title}</span>
      <VerifiedIcon className={clsx(styles['verified-icon'], props.highlighted && styles['highlighted'])} />
    </li>
  )
}

type ImageProps = {
  png: string
  webp: string
  avif: string
  alt: string
}

export interface PremiumModalCommonProps {
  isOpen: ModalProps['open']
  onClose: ModalProps['onClose']
  checkoutSuccessUrl?: string
  checkoutCancelUrl?: string
}

interface PremiumModalProps extends PremiumModalCommonProps {
  image: ImageProps
  children: React.ReactNode
  closeButtonOnColor?: boolean
  heading?: string
}

export function PremiumModal({
  isOpen,
  onClose,
  closeButtonOnColor,
  image,
  children,
  checkoutSuccessUrl,
  checkoutCancelUrl,
  heading: customHeading,
}: PremiumModalProps) {
  const { intl, language } = useLocale()
  const { unitPreference } = useUserState()
  const cookieConsentStatistics = useCookieConsentStatistics()

  const heading = customHeading || intl.formatMessage({
    id: 'premium_modal_headline',
    defaultMessage: 'Unlock all exclusive features with Bikemap Premium',
  })

  const modalProps: Omit<ModalProps, 'children'> = {
    open: isOpen,
    onClose,
    closeButtonOnColor,
    onOpen: () => pushModalEvent('premium_map_style'),
    'aria-label': heading,
    buttons: (
      <PremiumActions checkoutSuccessUrl={checkoutSuccessUrl} checkoutCancelUrl={checkoutCancelUrl} />
    ),
  }

  return (
    <Modal {...modalProps}>
      <div className={styles['root-with-footer']} data-testid='premium-modal'>
        <PremiumModalHeader image={image} />
        <div className={styles['content']}>
          <Typography variant="h2" textAlign="center" p="2rem 1rem 0.75rem">
            {heading}
          </Typography>
          {children}
          <Button
            block
            variant='secondary'
            href={getPremiumUrl({
              language,
              unitPreference,
              cookieConsentStatistics,
            })}
          >
            {intl.formatMessage({
              id: 'premium_modal_info_button',
              defaultMessage: 'Learn more',
            })}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const PremiumFeaturesList = ({ children }: { children: ReactNode }) => (
  <ul className={styles['list']}>{children}</ul>
)

interface PremiumFeatureItemProps {
  highlighted?: boolean
}

export const RoutePlannerItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<DirectionsRoundedIcon className={styles['feature-icon']} />}
      title={intl.formatMessage({
        id: 'premium_modal_feature_route_planner',
        defaultMessage: 'Advanced route planner',
      })}
    />
  )
}

export const MapStylesItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<LayersRoundedIcon className={styles['feature-icon']} />}
      title={intl.formatMessage({
        id: 'premium_modal_feature_advanced_cycling_maps',
        defaultMessage: 'Advanced cycling maps',
      })}
    />
  )
}

export const ExportItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<LocalPrintshopRoundedIcon className={styles['feature-icon']} />}
      title={intl.formatMessage({
        id: 'premium_modal_feature_export_routes',
        defaultMessage: 'Print and export',
      })}
    />
  )
}

export const TurnByTurnNavigationItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<NavigationIcon className={styles['feature-icon']} />}
      title={intl.formatMessage({
        id: 'premium_modal_feature_turn_by_turn_navigation',
        defaultMessage: 'Turn-by-turn navigation',
      })}
    />
  )
}

export const SelfGuidedNavigationItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<ExploreRoundedIcon className={styles['feature-icon']} />}
      title={intl.formatMessage({
        id: 'premium_modal_feature_self_guided_navigation',
        defaultMessage: 'Self-guided navigation',
      })}
    />
  )
}

export const OfflineItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<CloudDownloadRoundedIcon className={styles['feature-icon']} />}
      title={intl.formatMessage({
        id: 'premium_modal_feature_offline_maps',
        defaultMessage: 'Offline maps & navigation',
      })}
    />
  )
}

export const BikeComputerItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<BikeComputerIcon className={styles['feature-icon']} />}
      title={intl.formatMessage({
        id: 'premium_modal_feature_bike_computer',
        defaultMessage: 'Custom bike computer',
      })}
    />
  )
}

export const RoutePreviewsItem = (props: PremiumFeatureItemProps) => {
  const { intl } = useLocale()

  return (
    <PremiumFeaturesListItem
      {...props}
      icon={<Typography component='span' className={clsx(styles['feature-icon'], styles['feature-icon-text'])}>3D</Typography>}
      title={intl.formatMessage({
        id: 'premium_modal_feature_route_previews',
        defaultMessage: 'Detailed route preview',
      })}
    />
  )
}
