import { useLocale } from 'shared/util-intl'

import enAvif from './freehand-en.avif'
import enWebp from './freehand-en.webp'
import enPng from './freehand-en.png'
import deAvif from './freehand-de.avif'
import deWebp from './freehand-de.webp'
import dePng from './freehand-de.png'

interface FreehandRoutingIllustrationProps {
  alt: string
}

export const FreehandRoutingIllustration = ({ alt }: FreehandRoutingIllustrationProps) => {
  const { language } = useLocale()

  return (
    <picture>
      <source srcSet={language === 'de' ? deAvif : enAvif} type="image/avif" />
      <source srcSet={language === 'de' ? deWebp : enWebp} type="image/webp" />
      <img src={language === 'de' ? dePng : enPng} alt={alt} width="344" height="100" />
    </picture>
  )
}
