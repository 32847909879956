import { routePlannerSliceSelector, waypointsSelector } from '../selectors'
import {
  fittingGeometryBoundsStarted,
  initialControlPointsInserted,
  initializedBlank,
  initializedFromRoute,
  initializedWithState,
  initializedWithWaypoints,
  reset,
} from '../state'
import { RoutePlannerSliceDispatch, Waypoint, StateWithRoutePlannerSlice, RoutePlannerInitializationState } from '../types'
import { logError } from 'web-app/utils-error-handling'
import { isEqual } from 'lodash'
import { DEFAULT_VIEWPORT, MAP_SLICE_KEY, StateWithMapSlice, getIpLocationViewport, viewportChanged } from 'web-app/feature-map'
import { StateWithRouteSlice, initRouteById, isOwnRouteSelector, routeSelector } from 'web-app/feature-route'
import { reverseGeocodeWaypoints } from './geocoding'
import { calculateRoute } from './route-calculation'
import { LineString, Position } from 'geojson'
import { Position3d, decodeLngLatsPath, lngLatToPosition2d, positionToLngLat } from 'shared/util-geo'
import { mapMatchRouteGeometry, routeSimplificationApi } from 'shared/data-access-core'
import { StateWithUserSlice } from 'web-app/feature-user'

/**
 * Initialize a blank route planner session.
 */
export function initBlank() {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithMapSlice
  ) => {
    const state = getState()
    const { start, end } = waypointsSelector(state)
    const { viewport } = state[MAP_SLICE_KEY]
    const { geometry } = routePlannerSliceSelector(state)

    dispatch(initializedBlank())

    if (start && end) {
      // a route is already being planned
      if (geometry) {
        dispatch(fittingGeometryBoundsStarted())
      } else {
        await dispatch(calculateRoute())
        dispatch(fittingGeometryBoundsStarted())
        if (routePlannerSliceSelector(getState()).routingErrors) {
          dispatch(reset()) // otherwise there would be no way to get out of this state (nothing to undo)
        }
      }
      dispatch(reverseGeocodeWaypoints())
    } else if (isEqual(viewport, DEFAULT_VIEWPORT)) {
      // no custom map viewport from user actions yet
      const locationViewport = await getIpLocationViewport()
      if (locationViewport) {
        dispatch(viewportChanged(locationViewport))
      }
    }
  }
}

/**
 * Initialize the route planner based on an encoded waypoints string.
 */
export function initWithWaypoints(waypointsParam: string) {
  return async (dispatch: RoutePlannerSliceDispatch) => {
    const waypointsFromUrl = decodeLngLatsPath(waypointsParam)
    if (waypointsFromUrl.length) {
      dispatch(initializedWithWaypoints(waypointsFromUrl as [Waypoint, ...Waypoint[]]))
      await dispatch(calculateRoute())
      if (waypointsFromUrl.length > 1) {
        dispatch(fittingGeometryBoundsStarted())
      } else {
        dispatch(viewportChanged({
          center: lngLatToPosition2d(waypointsFromUrl[0]),
          zoom: 12,
        }))
      }
      dispatch(reverseGeocodeWaypoints())
    } else {
      logError('Could not initialize waypoints from URL param.')
      dispatch(initBlank())
    }
  }
}

/**
 * Initialize the route planner with a certain initial state, eg for entry points from other views.
 */
export function initWithState(initializationState: RoutePlannerInitializationState) {
  return async (dispatch: RoutePlannerSliceDispatch, getState: () => StateWithRoutePlannerSlice) => {
    dispatch(initializedWithState(initializationState))
    if (waypointsSelector(getState()).isFullRoute) {
      await dispatch(calculateRoute())
      dispatch(fittingGeometryBoundsStarted())
      dispatch(reverseGeocodeWaypoints())
    }
  }
}

/**
 * Initialize the route planner based on an existing route for editing or creating a copy.
 */
export function initFromRoute(routeId: number, signal: AbortSignal) {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithRouteSlice & StateWithUserSlice
  ) => {
    const { basedOnRouteId } = routePlannerSliceSelector(getState())

    if (basedOnRouteId !== routeId) {
      dispatch(reset())
    }

    await dispatch(initRouteById(routeId))
    if (signal.aborted) return
    const route = routeSelector(getState())
    if (!route) return

    if (basedOnRouteId !== route.id) {
      dispatch(initializedFromRoute({
        ...route,
        geometry: route.geometry.coordinates[0].length === 3
          ? route.geometry
          : await getApproximateElevation(route.geometry),
      }))

      const isOwnRoute = isOwnRouteSelector(getState())
      if (!isOwnRoute || route.controlPointIndexes.length < 3) {
        insertInitialControlPoints(dispatch, getState, signal)
      }
    }

    dispatch(reverseGeocodeWaypoints())
    dispatch(fittingGeometryBoundsStarted())
  }
}

async function insertInitialControlPoints(
  dispatch: RoutePlannerSliceDispatch,
  getState: () => StateWithRoutePlannerSlice,
  signal: AbortSignal
) {
  const { geometry, distance } = routePlannerSliceSelector(getState())
  if (!geometry || !distance) return
  let offset = 0
  for (let segmentIndex = 0; segmentIndex < geometry.coordinates.length; segmentIndex++) {
    const res = await routeSimplificationApi.getSimplified({
      type: 'LineString',
      coordinates: geometry.coordinates[segmentIndex],
    }, Math.max(distance * 0.04, 500))
    if (signal.aborted) return
    if (res.success) {
      const splitLocations = res.data.geometry.coordinates.slice(1, -1).map(positionToLngLat)
      if (splitLocations.length) {
        dispatch(initialControlPointsInserted({
          segmentIndex: segmentIndex + offset,
          locations: splitLocations,
        }))
        offset += splitLocations.length
      }
    }
  }
}

async function getApproximateElevation(geometry: LineString): Promise<LineString> {
  const res = await mapMatchRouteGeometry(geometry)
  if (res.success) {
    return {
      type: 'LineString',
      coordinates: geometry.coordinates.map(([lng, lat]: Position, i: number): Position3d => {
        const referenceIndex = (i + 1) / geometry.coordinates.length * res.data.geometry.coordinates.length - 1
        return [
          lng,
          lat,
          res.data.geometry.coordinates[Math.round(referenceIndex)][2],
        ]
      }),
    }
  }
  return geometry
}
