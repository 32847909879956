import React, { SVGProps } from 'react'

export const NotFoundIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 248 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#view-not-found-svg-a)">
      <path d="M181.718 43.093a44.51 44.51 0 0 0-13.474 2.082L157.896 19.3V7.8h16.114a1.534 1.534 0 0 1 0 3.066 3.232 3.232 0 0 0 0 6.465 7.976 7.976 0 0 0 8.287-7.654 7.977 7.977 0 0 0-7.651-8.29 3.254 3.254 0 0 0-.654-.07h-19.336a3.232 3.232 0 0 0-3.232 3.232v12.146H75.462L72.1 8.618h15.069a4.05 4.05 0 0 0 0-8.102H57.772a4.05 4.05 0 0 0 0 8.102h7.316l4.721 11.326-10.6 25.38c-18.127-5.945-38.027.273-49.547 15.481-11.52 15.208-12.12 36.054-1.493 51.899 10.627 15.846 30.137 23.198 48.575 18.306 18.438-4.892 31.74-20.951 33.116-39.982h11.785c.217-.001.433-.025.645-.07h.043l.174-.052.296-.096h.183l.218-.113c.087-.052.183-.087.27-.148l.105-.087.2-.166c.061-.052.122-.095.174-.148.053-.052.096-.122.148-.183l.105-.104 49.552-63.452 8.493 21.153c-20.45 9.92-30.196 33.615-22.643 55.057 7.552 21.441 29.994 33.794 52.145 28.701 22.15-5.093 36.946-26.008 34.38-48.596-2.566-22.588-21.678-39.649-44.406-39.64l-.009.009Zm-33.691-19.925-45.528 58.285-24.337-58.285h69.865Zm-74.716 5.14 23.517 56.256H89.86A44.764 44.764 0 0 0 65.184 47.78l8.127-19.472Zm6.82 56.256H49.837L61.439 56.77A35.041 35.041 0 0 1 80.13 84.564ZM45.29 122.802a34.997 34.997 0 0 1-31.893-20.559 35.013 35.013 0 0 1 5.576-37.54 34.992 34.992 0 0 1 36.49-10.397L42.05 86.533v.07a2.77 2.77 0 0 0-.218 1.045v.13c-.001.214.02.427.06.636.029.119.064.235.105.349 0 .087.044.166.079.253.051.113.11.224.174.33 0 .07.07.149.122.219.065.09.135.178.209.26.06.08.122.158.191.227.092.087.188.169.288.244.06.053.122.114.192.157.178.12.367.223.566.305h.096c.364.15.755.227 1.15.227H80.13c-1.663 18.007-16.753 31.79-34.833 31.817h-.008Zm136.42 0c-16.216.046-30.335-11.064-34.11-26.838-3.774-15.773 3.787-32.073 18.266-39.376l12.934 32.41a3.233 3.233 0 1 0 6.002-2.404l-12.97-32.367a34.983 34.983 0 0 1 36.31 10.66 35.005 35.005 0 0 1 5.378 37.468 34.988 34.988 0 0 1-31.845 20.447h.035Z" stroke="#1D4159" strokeWidth="1.031" strokeLinecap="round" strokeDasharray="5.16 5.16"/>
      <path d="M210.414 66.77c19.593 0 35.513 15.723 35.829 35.24l.005.593v27.35a2.062 2.062 0 0 1 1.752 2.039v1.031a2.062 2.062 0 0 1-2.062 2.062h-9.281a2.062 2.062 0 0 1-2.062-2.062v-1.031c0-1.07.814-1.95 1.857-2.053v-27.336c0-14.236-11.425-25.804-25.607-26.034l-.431-.004h-60.415c-14.095 0-25.603 11.208-26.026 25.245l-.009.426-.398 28.237a2.062 2.062 0 0 1 1.723 2.034v1.032a2.062 2.062 0 0 1-2.062 2.062h-9.281a2.062 2.062 0 0 1-2.062-2.062v-1.032c0-1.079.829-1.965 1.885-2.055l.4-28.353c.273-19.396 15.918-35.015 35.243-35.325l.587-.005h60.415Z" fill="#1D4159"/>
    </g>
    <defs>
      <clipPath id="view-not-found-svg-a">
        <path fill="#fff" d="M0 0h248v135.601H0z"/>
      </clipPath>
    </defs>
  </svg>
)
