import { debounce } from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'

export type UseInputValueReturn = {
  inputProps: {
    value: string
    onChange: (e: React.FormEvent<HTMLInputElement>) => void
    onReset: () => void
  }
  setValue: (value: string) => void
}

/**
 * Group change events and run only if value changes.
 */
export function useInputValue(propValue: string, onDebouncedChange?: (val: string) => void): UseInputValueReturn {
  const [value, setValue] = useState<string>(propValue)

  // ESLint does not recognize deps which are in "external function" (lodash.debounce in this case), but deps are added
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebounced = useCallback(
    debounce((val) => {
      if (onDebouncedChange) {
        onDebouncedChange(val)
      }
    }, 300),
    [onDebouncedChange]
  )

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  return {
    inputProps: {
      value,
      onChange: (e: React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value
        setValue(val)
        onDebounced(val)
      },
      onReset: () => {
        setValue('')
        onDebounced('')
      },
    },
    setValue,
  }
}
