import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { useLocale } from 'shared/util-intl'
import { FullScreenOverlay, Input, useInputValue } from 'shared/ui-components'

interface SmallScreenResultsProps {
  open: boolean
  defaultValue: string
  onSearch: (val: string) => void
  onClose: () => void
  children: React.ReactElement
}

export function ResultsSmallScreen(props: SmallScreenResultsProps) {
  const { open, defaultValue, onSearch, onClose, children } = props

  const { intl } = useLocale()

  const { inputProps } = useInputValue(defaultValue, onSearch)

  return (
    <FullScreenOverlay
      open={open}
      onClose={onClose}
      // Prevent brining back focus to input field which would just re-open this overlay
      disableRestoreFocus
      aria-label={intl.formatMessage({
        id: 'route_planner_search_location_results_aria_label',
        defaultMessage: 'Search location results popup',
      })}
      header={
        <Input
          {...inputProps}
          autoFocus
          name="destination"
          hideLabel
          label={intl.formatMessage({
            id: 'route_planner_search_location_label',
            defaultMessage: 'Search Location',
          })}
          placeholder={intl.formatMessage({
            id: 'route_planner_search_location_input_placeholder',
            defaultMessage: 'Destination',
          })}
          icon={<SearchIcon />}
        />
      }
    >
      {children}
    </FullScreenOverlay>
  )
}
