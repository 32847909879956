import { useState } from 'react'
import { getDynamicLink } from 'shared/data-access-core'
import { Button, ButtonGroup, NavigationIcon } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { getAppsUrl, getNavigationDeepLink, getRouteDetailUrl } from 'shared/util-navigation'
import { RouteEditLink, useSessionInfo } from 'web-app/feature-navigation'
import { useIsOwnRoute, useRoute } from 'web-app/feature-route'
import { PremiumModalNavigation, useUser } from 'web-app/feature-user'
import { useLayoutMediaQueries } from 'web-app/ui-layout'

export const RouteDetailsMainActions = () => {
  const { intl } = useLocale()
  const route = useRoute()
  const isOwnRoute = useIsOwnRoute()
  const [user, isUserLoaded] = useUser()
  const sessionInfo = useSessionInfo()
  const { isLargeViewport } = useLayoutMediaQueries()

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState<boolean>(false)

  const handleNavigateClick = async () => {
    if (!isUserLoaded || !route) return
    if (user?.isPremium) {
      const res = await getDynamicLink(getNavigationDeepLink(route.id), {
        fallbackUrl: getAppsUrl(sessionInfo),
        utmSource: 'web_app',
        utmMedium: 'route_detail',
        utmCampaign: 'navigate',
      })
      if (res.success) {
        window.location.href = res.data
      }
    } else {
      setIsPremiumModalOpen(true)
    }
  }

  const handleOpenInAppClick = async () => {
    if (!route) return
    const routeUrl = getRouteDetailUrl({
      routeId: route.id,
      ...sessionInfo,
    })
    const res = await getDynamicLink(routeUrl, {
      fallbackUrl: getAppsUrl(sessionInfo),
      utmSource: 'web_app',
      utmMedium: 'route_detail',
      utmCampaign: 'open_in_app',
    })
    if (res.success) {
      window.location.href = res.data
    }
  }

  return route ? (
    <ButtonGroup>
      <Button
        icon={<NavigationIcon />}
        onClick={handleNavigateClick}
        withPremiumIndicator={isUserLoaded && !user?.isPremium}
        disabled={!isUserLoaded || !route}
      >
        {intl.formatMessage({
          id: 'route_details_footer_button_navigate',
          defaultMessage: 'Navigate',
        })}
      </Button>
      <PremiumModalNavigation isOpen={isPremiumModalOpen} onClose={() => setIsPremiumModalOpen(false)} />
      {isOwnRoute ? (
        <RouteEditLink routeId={route.id}>
          {intl.formatMessage({
            id: 'route_details_footer_button_edit',
            defaultMessage: 'Edit route',
          })}
        </RouteEditLink>
      ) : !isLargeViewport && !user?.isPremium && (
        <Button variant='secondary' onClick={handleOpenInAppClick}>
          {intl.formatMessage({
            id: 'route_details_footer_button_app',
            defaultMessage: 'Open in app',
          })}
        </Button>
      )}
    </ButtonGroup>
  ) : null
}
