import { PopoverProps, Typography } from '@mui/material'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import { UserEntity } from 'shared/data-access-core'
import {
  Avatar,
  Button,
  ButtonGroup,
  ProfileSettingsIcon,
} from 'shared/ui-components'
import { DropdownMenu, DropdownMenuSettings } from 'shared/ui-navigation'
import { useLocale } from 'shared/util-intl'
import { getLogoutUrl, getProfileSettingsUrl } from 'shared/util-navigation'
import { useSessionInfo } from './hooks'
import { WebAppLanguageSelect } from './web-app-language-select'
import { UnitSelect } from './unit-select'
import { DiscoverLink, MyRoutesLink, PremiumLink, RouteImportLink, RoutePlannerLink, SignupLoginLink } from './links'

import styles from './user-menu.module.scss'

interface UserMenuProps extends PopoverProps {
  user?: UserEntity
}

export const UserMenu = ({ user, ...popoverProps }: UserMenuProps) => {
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()

  return (
    <DropdownMenu {...popoverProps} data-testid='user-menu'>
      {user ? (
        <div className={styles['user-container']}>
          <Avatar name={user.name} image={user.avatar} isPremium={user.isPremium} />
          <Typography variant="h4" component="h2" className={styles['user-name']} marginBottom={0}>
            {user.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" className={styles['user-label']}>
            {user.email}
          </Typography>
          <Button
            variant="secondary"
            icon={<ProfileSettingsIcon />}
            ariaLabel={intl.formatMessage({
              id: 'user_menu_button_profile_settings',
              defaultMessage: 'Profile settings',
            })}
            href={getProfileSettingsUrl({
              ...sessionInfo,
              slug: user.slug,
            })}
          />
        </div>
      ) : (
        <SignupLoginLink primary />
      )}
      {user && !user.isPremium && (
        <PremiumLink primary />
      )}
      <ButtonGroup stack>
        {user && <MyRoutesLink user={user} />}
        <RoutePlannerLink />
        <DiscoverLink />
        <RouteImportLink />
        {user?.isPremium && (
          <Button variant="secondary" icon={<EmailRoundedIcon />} href="mailto:support@bikemap.net">
            {intl.formatMessage({
              id: 'user_menu_button_support',
              defaultMessage: 'Send us a message',
            })}
          </Button>
        )}
      </ButtonGroup>
      <DropdownMenuSettings>
        <WebAppLanguageSelect />
        <UnitSelect />
      </DropdownMenuSettings>
      {user && (
        <Button
          variant="danger-secondary"
          href={getLogoutUrl({
            ...sessionInfo,
            path: window.location.pathname,
          })}
        >
          {intl.formatMessage({
            id: 'user_menu_button_log_out',
            defaultMessage: 'Log out',
          })}
        </Button>
      )}
    </DropdownMenu>
  )
}
