import { useLocale } from 'shared/util-intl'
import {
  BikeComputerItem,
  ExportItem,
  MapStylesItem,
  OfflineItem,
  PremiumFeaturesList,
  PremiumModal,
  PremiumModalCommonProps,
  RoutePlannerItem,
  RoutePreviewsItem,
  SelfGuidedNavigationItem,
  TurnByTurnNavigationItem,
} from './premium-modal'
import png from './img/header-image-map-styles.png'
import webp from './img/header-image-map-styles.webp'
import avif from './img/header-image-map-styles.avif'

export const PremiumModalMapStyles = (props: PremiumModalCommonProps) => {
  const { intl } = useLocale()

  return (
    <PremiumModal
      {...props}
      image={{
        png,
        webp,
        avif,
        alt: intl.formatMessage({
          id: 'premium_modal_map_styles_image_alt_text',
          defaultMessage: 'Premium map styles',
        }),
      }}
      closeButtonOnColor
    >
      <PremiumFeaturesList>
        <MapStylesItem highlighted />
        <RoutePlannerItem />
        <ExportItem />
        <TurnByTurnNavigationItem />
        <SelfGuidedNavigationItem />
        <OfflineItem />
        <BikeComputerItem />
        <RoutePreviewsItem />
      </PremiumFeaturesList>
    </PremiumModal>
  )
}
