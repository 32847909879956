import { useLocale } from 'shared/util-intl'
import { Campaign, CampaignPopupProps } from '../types'
import { Typography } from '@mui/material'
import { Button, Link, Modal } from 'shared/ui-components'
import { PremiumModalHeader } from '../../premium-modal/premium-modal-header'
import { pushModalEvent } from 'shared/util-analytics'
import { getCheckoutUrl, getHelpCenterGiveawayUrl } from 'shared/util-navigation'
import { useUserState } from '../../hooks'
import { useCookieConsentStatistics } from 'web-app/feature-cookie-consent'
import { ReactNode } from 'react'

import avif from '../summer-giveaway/cover.avif'
import webp from '../summer-giveaway/cover.webp'
import png from '../summer-giveaway/cover.png'

const DISCOUNT = 0.3
const VOUCHER_CODE = 'win30'

const Popup = ({
  isOpen,
  onClose,
}: CampaignPopupProps) => {
  const { intl, language } = useLocale()
  const { unitPreference, currencyPreference } = useUserState()
  const cookieConsentStatistics = useCookieConsentStatistics()

  const alt = intl.formatMessage({
    id: 'campaign_popup_alt_summer_giveaway_offer',
    defaultMessage: 'Promotional offer',
  })

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-label={alt}
      onOpen={() => pushModalEvent('premium_campaign')}
    >
      <div>
        <PremiumModalHeader
          image={{ avif, webp, png, alt }}
          badge={intl.formatMessage({
            id: 'campaign_popup_badge_summer_giveaway_offer',
            defaultMessage: 'Offer & giveaway',
          })}
        />
        <div style={{ padding: '0 1rem 1rem' }}>
          <Typography variant="h2" textAlign="center" p="2rem 1rem 0.75rem">
            {intl.formatMessage({
              id: 'campaign_popup_heading_summer_giveaway_offer',
              defaultMessage: 'Save <b>{discount, number, ::percent} on Premium</b> + win amazing prizes',
            }, {
              discount: DISCOUNT,
              b: (chunks: ReactNode) => <strong key={chunks?.toString()}>{chunks}</strong>,
            })}
          </Typography>
          <Typography paragraph textAlign='center'>
            {intl.formatMessage({
              id: 'campaign_popup_text_summer_giveaway_offer',
              defaultMessage: `
Redeem the voucher code <b>{voucher}</b> to save {discount, number, ::percent} on your annual Bikemap Premium
membership, unlock all exclusive features and get the chance to win <b>a
{bike24Amount, number, ::currency/EUR precision-integer} gift card for BIKE24</b> and so much more. As a
Premium user, you will be able to join our <b>in-app giveaway</b> through the Bikemap app.
              `,
            }, {
              discount: DISCOUNT,
              voucher: <strong>{VOUCHER_CODE.toUpperCase()}</strong>,
              bike24Amount: 1500,
              b: (chunks: ReactNode) => <strong key={chunks?.toString()}>{chunks}</strong>,
            })}
          </Typography>
          <Typography paragraph textAlign='center' variant='body2' color='textSecondary' marginBottom='1rem'>
            {intl.formatMessage({
              id: 'campaign_popup_fineprint_summer_giveaway_offer',
              defaultMessage: `
The giveaway closes on August 31st, 2024. Winners will be drawn the following week. This giveaway is not affiliated
with BIKE24. {learnMore}
              `,
            }, {
              learnMore: (
                <Link href={getHelpCenterGiveawayUrl(language)}>
                  {intl.formatMessage({
                    id: 'campaign_popup_fineprint_link_summer_giveaway_offer',
                    defaultMessage: 'Learn more...',
                  })}
                </Link>
              ),
            })}
          </Typography>
          <Button
            block
            variant='accent'
            href={getCheckoutUrl({
              language,
              unitPreference,
              cookieConsentStatistics,
              plan: 'pro-yearly',
              currency: currencyPreference?.code || 'EUR',
              voucherCode: VOUCHER_CODE,
              successUrl: window.location.href,
              cancelUrl: window.location.href,
              trackingParameters: {
                utmMedium: 'popup',
                utmCampaign: 'summer_giveaway',
              },
            })}
          >
            {intl.formatMessage({
              id: 'campaign_popup_cta_summer_giveaway_offer',
              defaultMessage: 'Get Premium with {discount, number, ::percent} off',
            }, {
              discount: DISCOUNT,
            })}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const campaign: Campaign = {
  start: '2024-08-10',
  end: '2024-08-31 23:55',
  isUserTarget: (isLoggedIn, isPremium) => !isLoggedIn || !isPremium,
  popup: Popup,
}

export default campaign
