import { ReactNode } from 'react'
import clsx from 'clsx'
import { BaseRadioButton, BaseRadioButtonProps } from '../../base/BaseRadioButton'

import styles from './MassiveRadioButton.module.scss'

export interface MassiveRadioButtonProps extends BaseRadioButtonProps {
  label: string
  description?: string
  image?: ReactNode
  isCompact?: boolean
}

export function MassiveRadioButton(props: MassiveRadioButtonProps) {
  const {
    className,
    name,
    label,
    image,
    description,
    isCompact = false,
    ...rest
  } = props

  if (isCompact) {
    return (
      <BaseRadioButton className={clsx(styles['root'], styles['compactRoot'], className)} name={name} {...rest}>
        <span className={styles['label']}>{label}</span>
        <div className={styles['compactContent']} data-testid='massive-radio-button-compact-content'>
          {image && (
            <div className={clsx(styles['image'], styles['compactImage'])}>
              {image}
            </div>
          )}
          {description && <span className={styles['description']}>{description}</span>}
        </div>
      </BaseRadioButton>
    )
  }

  return (
    <BaseRadioButton className={clsx(className, styles['root'])} name={name} {...rest}>
      {image && (
        <div className={styles['image']}>
          {image}
        </div>
      )}
      <span className={styles['label']}>{label}</span>
      {description && <span className={styles['description']}>{description}</span>}
    </BaseRadioButton>
  )
}

export default MassiveRadioButton
