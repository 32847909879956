import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { BikemapIcon } from '../icons'
import { ToolButton } from '../ToolButton'
import { useMessages } from '../MessagesProvider'

import styles from './MainNavigation.module.scss'

export interface MainNavigationProps {
  title: string
  actions?: React.ReactNode
  onCancel: () => void
  onBack?: () => void
}

export function MainNavigation({
  title,
  actions,
  onCancel,
  onBack,
}: MainNavigationProps) {

  const { backLabel, closeLabel } = useMessages()

  return (
    <nav className={styles['container']}>
      <div className={styles['start']}>
        {onBack
          ? (
            <ToolButton
              variant='ghost-primary'
              icon={<ArrowBackIcon />}
              onClick={() => { onBack() }}
              ariaLabel={backLabel}
            />
          )
          : (
            <BikemapIcon className={styles['bikemap-icon']} />
          )
        }
        <div className={styles['title']} data-testid="main-navigation-title">{title}</div>
      </div>
      <div className={styles['end']}>
        {actions}
        <ToolButton
          variant='ghost-secondary'
          icon={<CloseIcon />}
          onClick={() => { onCancel() }}
          ariaLabel={closeLabel}
        />
      </div>
    </nav>
  )
}
