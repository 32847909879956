import { useEffect } from 'react'
import { MapLayerMouseEvent, useMap } from 'react-map-gl/maplibre'

export const useMapContextMenu = (mapId: string, onContextMenu: (event: MapLayerMouseEvent) => void) => {
  const { [mapId]: map } = useMap()

  useEffect(() => {
    if (map) {
      map.on('contextmenu', onContextMenu)
    }
    return () => {
      map?.off('contextmenu', onContextMenu)
    }
  }, [map, onContextMenu])
}
