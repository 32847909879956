import clsx from 'clsx'
import { ReactNode } from 'react'
import { Skeleton } from '@mui/material'
import { PremiumBadge } from '../PremiumBadge'
import ProfilePictureIllu from './svg/profile-picture.svg'

import styles from './Avatar.module.scss'

type RenderImageProps = {
  className: string
  src: string
  width: number
  height: number
  alt: string
  loading: 'lazy' | 'eager'
}

export interface AvatarProps {
  className?: string
  name: string
  image?: string
  isPremium?: boolean
  isLoading?: boolean
  eagerLoading?: boolean
  size?: string
  renderImage?: (props: RenderImageProps) => ReactNode
}

export const Avatar = ({
  className,
  name,
  image,
  isPremium = false,
  isLoading = false,
  eagerLoading,
  size = '1em',
  renderImage,
}: AvatarProps) => {
  let img = null
  if (image) {
    const imageProps: RenderImageProps = {
      className: styles['image'],
      src: image,
      width: 33,
      height: 33,
      alt: name,
      loading: eagerLoading ? 'eager' : 'lazy',
    }
    img = renderImage ? renderImage(imageProps) : <img {...imageProps} alt={imageProps.alt} />
  }

  return (
    <div className={clsx(styles['root'], className)} style={{ fontSize: size }}>
      {isLoading ? (
        <AvatarSkeleton />
      ) : (
        <>
          <div className={styles['img-container']}>
            {img || <ProfilePictureIllu data-testid='profile-picture-illu' />}
          </div>
          {isPremium && <PremiumBadge className={styles['premium-badge']} size='0.75em' />}
        </>
      )}
    </div>
  )
}

export const AvatarSkeleton = () => (
  <Skeleton className={styles['skeleton']} variant='circular' data-testid='avatar-skeleton' />
)
