import { Button } from 'shared/ui-components'
import { SavableRouteState, useSavableRoute } from '../../state'
import { useAuthenticationWall } from 'web-app/feature-navigation'
import { useState } from 'react'

interface BaseRouteActionProps {
  primary?: boolean
  children: string
  disabled?: boolean
  onRouteSubmit: (savableRouteState: SavableRouteState) => Promise<void>
}

export const BaseRouteAction = ({
  primary,
  children,
  disabled,
  onRouteSubmit,
}: BaseRouteActionProps) => {
  const { doForAuthenticatedUser, isUserLoaded } = useAuthenticationWall()
  const savableRouteState = useSavableRoute()

  const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false)

  const handleClick = () => {
    doForAuthenticatedUser(async () => {
      if (savableRouteState) {
        setIsSaveInProgress(true)
        await onRouteSubmit(savableRouteState)
        setIsSaveInProgress(false)
      }
    })
  }

  return (
    <Button
      variant={primary ? 'primary' : 'secondary'}
      onClick={handleClick}
      disabled={!isUserLoaded || isSaveInProgress || !savableRouteState || disabled}
    >
      {children}
    </Button>
  )
}
