import { FormControlLabel, Switch as MuiSwitch } from '@mui/material'
import clsx from 'clsx'

import styles from './switch.module.scss'

export interface SwitchProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  contained?: boolean
}

export const Switch = ({
  label,
  checked,
  onChange,
  contained,
}: SwitchProps) => (
  <FormControlLabel
    control={
      <MuiSwitch
        checked={checked}
        onChange={(e, checked) => { onChange(checked) }}
      />
    }
    label={label}
    labelPlacement='start'
    className={clsx(styles['switch'], { [styles['contained']]: contained })}
  />
)
