import { gtag } from './helpers'

/**
 * Renders the main GTM integration JS into a string. Include this in a script tag at the
 * top of the HTML head.
 */
export function renderGTMIntegration(): string {
  return `
    dataLayer = [];
    function gtag(){dataLayer.push(arguments);}
    gtag('consent', 'default', {
        'analytics_storage': 'denied'
    });
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-PHMSWCF');
  `
}

/**
 * Renders the GTM integration fallback into a string. Include this in a noscript tag at the
 * top of the HTML body.
 */
export function renderGTMFallback(): string {
  return `
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PHMSWCF"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `
}

/**
 * Allow analytics services to set cookies, which is disabled by default on each page load.
 */
export function enableAnalyticsCookies() {
  gtag('consent', 'update', {
    analytics_storage: 'granted',
  })
}
