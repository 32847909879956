import { useDispatch } from 'react-redux'
import { extractCookieConsentFromUrl } from 'shared/util-navigation'
import { cookieBannerHidden, settingsChanged } from './state'

/**
 * Updates statistics and hides CookieConsent if statistcs settings are found in URL.
 * Used when receiving these settings from website.
 */
export function useUrlCookieConsent(): void {
  const dispatch = useDispatch()

  const cookieConsentFromUrl = extractCookieConsentFromUrl()
  if (cookieConsentFromUrl) {
    dispatch(settingsChanged(cookieConsentFromUrl))
    dispatch(cookieBannerHidden())
  }
}
