import { useState } from 'react'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Button, UserContent } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'

const COLLAPSE_LENGTH_THRESHOLD = 350

interface CollapsedDescriptionProps {
  children: string
}

export const CollapsedDescription = ({ children }: CollapsedDescriptionProps) => {
  const { intl } = useLocale()

  const [isCollapsed, setIsCollapsed] = useState<boolean>(children.length >= COLLAPSE_LENGTH_THRESHOLD)

  return (
    <>
      <UserContent secondary linesLimit={isCollapsed ? 5 : undefined}>
        {children}
      </UserContent>
      {isCollapsed && (
        <Button
          block
          variant="secondary"
          size="medium"
          icon={<ExpandMoreRoundedIcon />}
          onClick={() => setIsCollapsed(false)}
        >
          {intl.formatMessage({
            id: 'collapsed_description_show_more',
            defaultMessage: 'Show more',
          })}
        </Button>
      )}
    </>
  )
}
