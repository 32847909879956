import clsx from 'clsx'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocale } from 'shared/util-intl'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'
import { ClickAwayListener } from '@mui/base'
import { Button, SortableList } from 'shared/ui-components'
import { useLayoutMediaQueries } from 'web-app/ui-layout'
import {
  Waypoint,
  useWaypoints,
  reverseRoute,
  RoutePlannerSliceDispatch,
  updateWaypointsOrder,
  CompleteWaypoints,
  useRoutePlannerState,
} from '../../state'
import { WaypointsListItem } from './waypoints-list-item'

import styles from './index.module.scss'

type DefinedWaypoint = Waypoint & {
  index: number
}

interface LocationsListProps {
  backgroundColor: string
  onWaypointItemFocus: (waypointRef: React.RefObject<HTMLDivElement>) => void
}

export const LocationsList = ({ backgroundColor, onWaypointItemFocus }: LocationsListProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { isInitialized } = useRoutePlannerState()
  const { isFullRoute, waypoints, start, end } = useWaypoints()
  const { isLargeViewport, isFlatViewport } = useLayoutMediaQueries()
  const isFullScreenOverlay = !isLargeViewport || isFlatViewport

  /** Empty waypoints list items represented by the index of the defined waypoint they are inserted before  */
  const [insertedItems, setInsertedItems] = useState<number[]>([])

  /** Index of the item in the waypoints list that is currently searched on */
  const [searchItem, setSearchItem] = useState<number | null>(null)

  /** Combined list of existing and inserted (empty) waypoints in order */
  const waypointsList: (DefinedWaypoint | number)[] = useMemo(() => {
    if (!isFullRoute) {
      return [start ? { ...start, index: 0 } : 0, end ? { ...end, index: 1 } : 1]
    }

    const list: (DefinedWaypoint | number)[] = waypoints.map((waypoint, index) => ({ ...waypoint, index }))
    for (const beforeIndex of insertedItems) {
      const definedItemIndex = list.findIndex((w) => typeof w === 'object' && w.index === beforeIndex)
      if (definedItemIndex >= 0) {
        list.splice(definedItemIndex, 0, beforeIndex)
      } else {
        list.push(beforeIndex)
      }
    }
    return list
  }, [end, insertedItems, isFullRoute, start, waypoints])

  /**
   * When location field is focused:
   * - set scroll position to this waypoint if scroll is needed,
   * - reset search results,
   * - set this waypoint as active
   */
  const handleFocus = useCallback(
    (waypointsListIndex: number, ref: React.RefObject<HTMLDivElement>) => {
      // If there is previously active waypoint, there are too many conflicting animations so scrolled position is wrong
      const noActiveSearch = typeof searchItem !== 'number'
      if (!isFullScreenOverlay && noActiveSearch && waypointsList.length > 2) {
        if (ref.current) {
          onWaypointItemFocus(ref)
        }
      }
      setSearchItem(waypointsListIndex)
    },
    [searchItem, isFullScreenOverlay, waypointsList.length, onWaypointItemFocus],
  )

  const handleSort = useCallback(
    (sortedWaypointsList: (DefinedWaypoint | number)[]) => {
      if (waypointsList.length < 3) return // no sorting with only two waypoint items
      const definedWaypoints: Waypoint[] = []
      const insertedItems: number[] = []
      let nextIndex = 0
      for (const item of sortedWaypointsList) {
        if (typeof item === 'object') {
          definedWaypoints.push(item)
          nextIndex++
        } else {
          insertedItems.push(nextIndex)
        }
      }
      for (const waypointIndex in definedWaypoints) {
        // dispatch action if any item changed
        const original = waypoints[waypointIndex]
        const sorted = definedWaypoints[waypointIndex]
        if (!(original?.lng === sorted.lng && original.lat === sorted.lat)) {
          dispatch(updateWaypointsOrder(definedWaypoints as CompleteWaypoints))
          break
        }
      }
      setInsertedItems(insertedItems)
    },
    [dispatch, waypoints, waypointsList.length],
  )

  /**
   * Close search suggestions on ESC click.
   */
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setSearchItem(null)
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => document.removeEventListener('keydown', onKeyDown, false)
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isLargeViewport && isInitialized) {
        if (!start && !end) {
          setSearchItem(1) // Focus destination item initially
        }
        if (!start && end) {
          setSearchItem(0) // Focus origin item if destination has already been selected
        }
      }
    }, 300)

    return () => clearTimeout(timeout)
  }, [end, isInitialized, isLargeViewport, start])

  const handleInsertedItemObsolete = useCallback(
    (waypointsListIndex: number, willTurnIntoWaypoint: boolean) => {
      if (searchItem === waypointsListIndex) {
        setSearchItem(null)
      }
      if (isFullRoute) {
        const insertedItems: number[] = []
        let nextIndex = 0
        waypointsList.forEach((item, i) => {
          if (typeof item === 'object' || (willTurnIntoWaypoint && i === waypointsListIndex)) {
            nextIndex++
          } else if (i !== waypointsListIndex) {
            insertedItems.push(nextIndex)
          }
        })
        setInsertedItems(insertedItems)
      }
    },
    [isFullRoute, searchItem, waypointsList],
  )

  return (
    <>
      <ClickAwayListener onClickAway={() => !isFullScreenOverlay && searchItem !== null && setSearchItem(null)}>
        <div
          className={clsx({
            [styles['list-small-screen']]: !isLargeViewport,
            [styles['list-large-screen']]: isLargeViewport,
            [styles['list-large-screen-initial-with-results']]:
              isLargeViewport && !isFullRoute && searchItem === waypointsList.length - 1,
          })}
        >
          <SortableList<DefinedWaypoint | number>
            items={waypointsList}
            renderItem={(item, handleProps, contextData, itemData) => (
              <WaypointsListItem
                waypointsListIndex={itemData.index}
                waypointIndex={typeof item === 'object' ? item.index : null}
                beforeIndex={typeof item === 'number' ? item : null}
                isLast={itemData.index === waypointsList.length - 1}
                item={typeof item === 'object' ? item : null}
                isSearchActive={itemData.index === searchItem}
                isSorting={!!contextData.active}
                sortableHandleProps={waypointsList.length > 2 ? handleProps : undefined}
                backgroundColor={backgroundColor}
                onSearchClose={() => setSearchItem(null)}
                onInsertedItemObsolete={handleInsertedItemObsolete}
                onFocus={handleFocus}
              />
            )}
            onSort={handleSort}
          />
        </div>
      </ClickAwayListener>
      {isFullRoute && (
        <div className={styles['buttons']}>
          <Button
            size="small"
            variant="secondary-contrast"
            icon={<AddRoundedIcon />}
            onClick={(e) => {
              // Skip ClickAwayListener's onClickAway
              e.stopPropagation()
              setSearchItem(null)
              setInsertedItems([...insertedItems, waypoints.length])
            }}
          >
            {intl.formatMessage({
              id: 'route_planner_add_waypoint_title',
              defaultMessage: 'Add waypoint',
            })}
          </Button>
          <Button
            size="small"
            variant="secondary-contrast"
            icon={<SwapVertRoundedIcon />}
            onClick={() => dispatch(reverseRoute())}
          >
            {intl.formatMessage({
              id: 'route_planner_reverse_order_title',
              defaultMessage: 'Reverse order',
            })}
          </Button>
        </div>
      )}
    </>
  )
}

export default LocationsList
