import { CSSProperties } from 'react'
import { SvgMapMarker, SvgMapMarkerProps } from './svg-map-marker'
import { PointLike } from 'maplibre-gl'

type MapMarkerSizes = 'small' | 'default' | 'large'

const SIZE_PROPS: Record<MapMarkerSizes, { style: CSSProperties, offset: PointLike }> = {
  small: {
    style: { width: 24, height: 42 },
    offset: [0, 6],
  },
  default: {
    style: { width: 32, height: 56 },
    offset: [0, 10],
  },
  large: {
    style: { width: 44, height: 67 },
    offset: [0, 11],
  },
}

export interface CommonMapMarkerProps extends SvgMapMarkerProps {
  size?: MapMarkerSizes
}

export const CommonMapMarker = ({
  size = 'default',
  style,
  ...props
}: CommonMapMarkerProps) => (
  <SvgMapMarker
    {...props}
    anchor='bottom'
    style={{ ...SIZE_PROPS[size].style, ...style }}
    offset={SIZE_PROPS[size].offset}
  />
)
