import { dummyGeometry } from 'shared/util-geo'
import { AlongTheRouteAttribute, AlongTheRouteAttributes, BIKE_NETWORK_CATEGORIES, BikeNetworkCategory, SURFACE_CATEGORIES, SurfaceCategory, WAY_TYPE_CATEGORIES, WayTypeCategory } from '../../entities'

export type AlongTheRouteAttributesResponse = {
  bm_surface: AlongTheRouteAttribute<string> | null
  bm_way_type: AlongTheRouteAttribute<string> | null
  bike_network: AlongTheRouteAttribute<string> | null
}

export const dummyAlongTheRouteAttributesResponse: AlongTheRouteAttributesResponse = {
  bm_surface: [
    [0, 3, 'foo', 34],
    [3, 12, 'asphalt', 1200],
    [12, dummyGeometry.coordinates.length - 1, 'missing', 766],
  ],
  bm_way_type: [
    [0, 3, 'missing', 34],
    [3, 12, 'cycleway', 1200],
    [12, dummyGeometry.coordinates.length - 1, 'missing', 766],
  ],
  bike_network: [
    [0, 3, 'missing', 34],
    [3, 12, 'regional', 1200],
    [12, dummyGeometry.coordinates.length - 1, 'missing', 766],
  ],
}

export function convertAlongTheRouteAttributes(res: AlongTheRouteAttributesResponse): AlongTheRouteAttributes {
  return {
    surfacesAlongTheRoute: getValidAlongTheRouteAttribute(
      res.bm_surface,
      SURFACE_CATEGORIES as unknown as SurfaceCategory[]
    ),
    wayTypesAlongTheRoute: getValidAlongTheRouteAttribute(
      res.bm_way_type,
      WAY_TYPE_CATEGORIES as unknown as WayTypeCategory[]
    ),
    bikeNetworkAlongTheRoute: getValidAlongTheRouteAttribute(
      res.bike_network,
      BIKE_NETWORK_CATEGORIES as unknown as BikeNetworkCategory[]
    ),
  }
}

function getValidAlongTheRouteAttribute<ValidCategory>(
  alongTheRouteRes: AlongTheRouteAttribute<string> | null,
  validCategories: (ValidCategory | 'missing')[]
): AlongTheRouteAttribute<ValidCategory | 'missing'> | null {
  if (!alongTheRouteRes?.length || !alongTheRouteRes[0].length) return null
  let hasData = false
  const validAttribute: AlongTheRouteAttribute<ValidCategory> = alongTheRouteRes.map((section) => {
    const [startPositionIndex, endPositionIndex, category, sectionDistanceM] = section
    const validCategory: ValidCategory = ((validCategories as unknown as string[]).includes(category) ? category : 'missing') as ValidCategory
    if (validCategory !== 'missing') {
      hasData = true
    }
    return [startPositionIndex, endPositionIndex, validCategory, sectionDistanceM]
  })
  return hasData ? validAttribute : null
}
