import { NavigationControl as BaseNavigationControl } from 'shared/ui-map'
import { MAP_ID } from '../settings'
import { useMapState } from '../state'

export const NavigationControl = () => {
  const { viewport } = useMapState()
  const { bearing, pitch } = viewport

  return <BaseNavigationControl mapId={MAP_ID} bearing={bearing} pitch={pitch} />
}
