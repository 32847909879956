import { useMemo } from 'react'
import { SelectRow } from 'shared/ui-components'
import { AvailableLocale } from 'shared/util-intl'

interface LanguageSelectRowProps {
  language: AvailableLocale
  onSelect: (language: AvailableLocale) => void
}

export const LanguageSelectRow = ({
  language,
  onSelect,
}: LanguageSelectRowProps) => {
  const options: { value: AvailableLocale; label: string }[] = useMemo(
    () => [
      { value: 'en', label: 'English' },
      { value: 'de', label: 'Deutsch' },
    ],
    [],
  )

  return (
    <SelectRow options={options} value={language} onSelect={onSelect} />
  )
}
