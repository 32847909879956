import { LineString } from 'geojson'
import { UnitPreference } from '../types'
import { MapRouteDistanceMarkers } from 'shared/ui-map'

interface ElevationCurveDistanceMarkersProps {
  geometry: LineString
  distance: number
  unitPreference: UnitPreference
  visible?: boolean
  mapRouteId: string
}

export const ElevationCurveDistanceMarkers = ({
  geometry,
  distance,
  unitPreference,
  visible,
  mapRouteId,
}: ElevationCurveDistanceMarkersProps) => (
  <MapRouteDistanceMarkers
    id={mapRouteId}
    geometry={geometry}
    distance={distance}
    unitPreference={unitPreference}
    visible={visible}
  />
)
