import { useMemo, useState } from 'react'
import { AlongTheRouteAttribute, SurfaceCategory, WayTypeCategory } from 'shared/data-access-core'
import { InlineTabs, InlineTabsOption } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { SurfacesDiagram } from './surfaces-diagram'
import { WayTypesDiagram } from './way-types-diagram'

type TabKey = 'surfaces' | 'wayTypes'

interface SurfacesAndWayTypesProps {
  surfacesAlongTheRoute: AlongTheRouteAttribute<SurfaceCategory> | null
  wayTypesAlongTheRoute: AlongTheRouteAttribute<WayTypeCategory> | null
  routeDistanceM: number
}

export const SurfacesAndWayTypes = ({
  surfacesAlongTheRoute,
  wayTypesAlongTheRoute,
  routeDistanceM,
}: SurfacesAndWayTypesProps) => {
  const { intl } = useLocale()

  const [openTab, setOpenTab] = useState<TabKey>(surfacesAlongTheRoute ? 'surfaces' : 'wayTypes')

  const tabOptions = useMemo<InlineTabsOption<TabKey>[]>(() => [
    {
      value: 'surfaces',
      label: intl.formatMessage({
        id: 'route_surfaces_tab',
        defaultMessage: 'Surface',
      }),
    },
    {
      value: 'wayTypes',
      label: intl.formatMessage({
        id: 'route_way_types_tab',
        defaultMessage: 'Waytype',
      }),
    },
  ], [intl])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {surfacesAlongTheRoute && wayTypesAlongTheRoute && (
        <InlineTabs
          options={tabOptions}
          value={openTab}
          onSelect={setOpenTab}
        />
      )}
      {surfacesAlongTheRoute && openTab === 'surfaces' && (
        <SurfacesDiagram surfacesAlongTheRoute={surfacesAlongTheRoute} routeDistanceM={routeDistanceM} />
      )}
      {wayTypesAlongTheRoute && openTab === 'wayTypes' && (
        <WayTypesDiagram wayTypesAlongTheRoute={wayTypesAlongTheRoute} routeDistanceM={routeDistanceM} />
      )}
    </div>
  )
}
