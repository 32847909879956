import { useDispatch } from 'react-redux'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import { LocationList } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { Waypoint, RoutePlannerSliceDispatch, useWaypoints, GeocodedWaypoint } from '../../state'
import { viewportChanged } from 'web-app/feature-map'
import { useCallback } from 'react'
import { formatLocationDisplayValues } from 'shared/util-formatting'

interface ResultsListProps {
  results: GeocodedWaypoint[]
  onSelect: (waypoint: Waypoint) => void
}

export const ListSearchResults = ({ results, onSelect }: ResultsListProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { start, end } = useWaypoints()

  const onClick = useCallback(
    (waypoint: Waypoint) => {
      if (!start && !end) {
        dispatch(viewportChanged({ center: [waypoint.lng, waypoint.lat], zoom: 12 }))
      }
      onSelect(waypoint)
    },
    [start, end, onSelect, dispatch],
  )

  return results.length > 0 ? (
    <LocationList.List
      title={intl.formatMessage({
        id: 'route_planner_location_suggestions',
        defaultMessage: 'Suggestions',
      })}
      secondary
    >
      {results.map((result, index) => {
        const { displayValueTitle, displayValueSubtitle } = formatLocationDisplayValues(result)
        return (
          <LocationList.Item
            key={`location-result-item-${index}`}
            title={displayValueTitle || ''}
            subtitle={displayValueSubtitle}
            icon={<FmdGoodIcon />}
            onClick={() => onClick(result)}
          />
        )
      })}
    </LocationList.List>
  ) : null
}
