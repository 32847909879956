import { useEffect } from 'react'
import { MapLayerMouseEvent, useMap } from 'react-map-gl/maplibre'

export const useMapClick = (mapId: string, onMapClick: (event: MapLayerMouseEvent) => void) => {
  const { [mapId]: map } = useMap()

  useEffect(() => {
    if (map) {
      map.on('click', onMapClick)
    }
    return () => {
      map?.off('click', onMapClick)
    }
  }, [map, onMapClick])
}
