import React from 'react'
import { Skeleton, Typography } from '@mui/material'

import styles from './privacy-item.module.css'

interface PrivacyItemProps {
  icon: React.ReactNode
  title: string
  description: string
}

export const PrivacyItem = ({ icon, title, description }: PrivacyItemProps) => (
  <div className={styles['privacy-item']}>
    <div className={styles['privacy-item-icon']}>{icon}</div>
    <div>
      <Typography variant="body2" fontWeight={500}>
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>
    </div>
  </div>
)

export const PrivacyItemSkeleton = () => (
  <div className={styles['privacy-item']}>
    <Skeleton variant="circular" width="2rem" height="2rem" />
    <div>
      <Typography variant="body2">
        <Skeleton width="6rem" />
        <Skeleton />
      </Typography>
    </div>
  </div>
)
