import { useCallback, useEffect } from 'react'
import { showNotification } from 'web-app/feature-notifications'
import { useLocale } from 'shared/util-intl'
import { useRoutePlannerState } from '../state'

/**
 * Show error notifications when routing fails.
 */
export const useRoutingErrorNotifications = () => {
  const { intl } = useLocale()
  const { routingErrors } = useRoutePlannerState()

  const showRoutingErrorNotification = useCallback(
    (description: string) => {
      showNotification(
        {
          title: intl.formatMessage({
            id: 'route_planner_routing_failed_notification_title',
            defaultMessage: 'Route could not be calculated',
          }),
          description,
          variant: 'danger',
        },
        'error_route_planner_routing_failed',
      )
    },
    [intl],
  )

  useEffect(() => {
    if (!routingErrors) return

    if (typeof routingErrors.pointNotFoundException !== 'undefined') {
      return showRoutingErrorNotification(
        intl.formatMessage({
          id: 'route_planner_routing_failed_point_not_found_notification_description',
          defaultMessage:
            'One of the waypoints caused issues with route calculation. ' +
            'Please try changing the waypoints or using a different routing profile.',
        }),
      )
    }

    if (routingErrors.connectionNotFoundException) {
      return showRoutingErrorNotification(
        intl.formatMessage({
          id: 'route_planner_routing_failed_connection_not_found_notification_description',
          defaultMessage:
            'The route between these waypoints could not be calculated. ' +
            'Please try changing the waypoints or using a different routing profile.',
        }),
      )
    }

    if (typeof routingErrors.maximumNodesExceededException !== 'undefined') {
      return showRoutingErrorNotification(
        intl.formatMessage({
          id: 'route_planner_routing_failed_maximum_nodes_exceeded_exception_notification_description',
          defaultMessage:
            'The route is too long. Please try changing the waypoints or using a different routing profile.',
        }),
      )
    }

    if (routingErrors.invalidGeometryResponse || routingErrors.unexpectedResponse || routingErrors.unexpectedError) {
      return showRoutingErrorNotification(
        intl.formatMessage({
          id: 'route_planner_routing_failed_notification_description',
          defaultMessage:
            'There was an unexpected error while calculating the route. ' +
            'Please try changing the waypoints or using a different routing profile.',
        }),
      )
    }
  }, [intl, routingErrors, showRoutingErrorNotification])
}
