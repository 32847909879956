import { RouteSliceDispatch, toggleFavoriteRoute, useCreator, useRoute } from '../state'
import { RouteTileSkeleton } from 'shared/ui-components'
import { useUserState } from 'web-app/feature-user'
import { useDispatch } from 'react-redux'
import { WebAppRouteEntityTile } from './route-entity-tile'
import React from 'react'

interface MainRouteTileProps {
  onClick?: (e: React.MouseEvent) => void
}

export const MainRouteTile = ({
  onClick,
}: MainRouteTileProps) => {
  const dispatch = useDispatch() as RouteSliceDispatch
  const route = useRoute()
  const creator = useCreator()
  const { unitPreference } = useUserState()

  return route ? (
    <WebAppRouteEntityTile
      route={route}
      creator={creator}
      onFavoriteToggle={() => dispatch(toggleFavoriteRoute())}
      imperial={unitPreference === 'imperial'}
      onClick={onClick}
    />
  ) : <RouteTileSkeleton />
}
