import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { useLocale } from 'shared/util-intl'
import { useCreator, useIsOwnRoute, useRoute } from 'web-app/feature-route'
import { formatCreatedTimeAgo } from '../../helpers'
import { CreatorLink } from './creator-link'
import { DeleteRouteAction } from './delete-route-action'
import { CreatorFlagActions } from './creator-flag-actions'
import { RouteEditLink } from 'web-app/feature-navigation'
import { CopyIcon } from 'shared/ui-components'

import styles from './creator-actions.module.css'

export const CreatorActions = () => {
  const { intl } = useLocale()
  const route = useRoute()
  const creator = useCreator()
  const isOwnRoute = useIsOwnRoute()

  const createdString = useMemo<string | undefined>(() => {
    if (!route) return undefined

    if (isOwnRoute) {
      return intl.formatMessage(
        {
          id: 'route_details_created_string',
          defaultMessage: 'on {date}',
        },
        {
          date: intl.formatDate(route.created),
        },
      )
    }

    return formatCreatedTimeAgo(
      route.created,
      intl.formatRelativeTime,
      intl.formatMessage({
        id: 'route_details_created_less_than_minute_ago',
        defaultMessage: 'less than a minute ago',
      }),
    )
  }, [intl, isOwnRoute, route])

  return (
    <div className={styles['container']}>
      <div className={styles['creator-row']}>
        {creator ? (
          <CreatorLink creator={creator} createdTimeAgo={createdString} />
        ) : (
          <Typography paragraph color="textSecondary" margin={0}>
            {intl.formatMessage(
              {
                id: 'route_details_creator_anonymous',
                defaultMessage: 'Created {createdString}.',
              },
              {
                createdString,
              },
            )}
          </Typography>
        )}
        {!isOwnRoute && <CreatorFlagActions />}
      </div>
      {isOwnRoute && <DeleteRouteAction />}
      {!isOwnRoute && route && (
        <RouteEditLink routeId={route.id} icon={<CopyIcon />}>
          {intl.formatMessage({
            id: 'route_details_edit_copy_action',
            defaultMessage: 'Use as template',
          })}
        </RouteEditLink>
      )}
    </div>
  )
}
