import { useLocale } from 'shared/util-intl'
import {
  BikeComputerItem,
  ExportItem,
  MapStylesItem,
  OfflineItem,
  PremiumFeaturesList,
  PremiumModal,
  PremiumModalCommonProps,
  RoutePlannerItem,
  RoutePreviewsItem,
  SelfGuidedNavigationItem,
  TurnByTurnNavigationItem,
} from './premium-modal'
import png from './img/header-image-navigation.png'
import webp from './img/header-image-navigation.webp'
import avif from './img/header-image-navigation.avif'

export const PremiumModalNavigation = (props: PremiumModalCommonProps) => {
  const { intl } = useLocale()

  const heading = intl.formatMessage({
    id: 'premium_modal_navigation_heading',
    defaultMessage: 'Navigate on iOS and Android with Bikemap Premium',
  })

  return (
    <PremiumModal
      {...props}
      image={{
        png,
        webp,
        avif,
        alt: heading,
      }}
      heading={heading}
    >
      <PremiumFeaturesList>
        <TurnByTurnNavigationItem highlighted />
        <SelfGuidedNavigationItem highlighted />
        <RoutePlannerItem />
        <MapStylesItem />
        <ExportItem />
        <OfflineItem />
        <BikeComputerItem />
        <RoutePreviewsItem />
      </PremiumFeaturesList>
    </PremiumModal>
  )
}
