import { Typography } from '@mui/material'
import { Checkbox } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { WebAppContent } from 'web-app/ui-layout'
import { useDispatch } from 'react-redux'
import { descriptionOptionChanged, distanceMarkersOptionChanged, useRoutePrintState } from '../state'
import { useRoute } from 'web-app/feature-route'

import styles from './printing-options.module.css'

export const PrintingOptions = () => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const route = useRoute()
  const { areDistanceMarkersShown, isDescriptionShown } = useRoutePrintState()

  return (
    <WebAppContent>
      <Typography variant="h4" component="h2">
        {intl.formatMessage({
          id: 'route_print_options_heading',
          defaultMessage: 'Printing options',
        })}
      </Typography>
      <form className={styles['form']}>
        {route?.description && (
          <Checkbox
            name="description"
            checked={isDescriptionShown}
            onChange={() => dispatch(descriptionOptionChanged(!isDescriptionShown))}
          >
            {intl.formatMessage({
              id: 'route_print_option_description',
              defaultMessage: 'Route description',
            })}
          </Checkbox>
        )}
        <Checkbox
          name="distanceMarkers"
          checked={areDistanceMarkersShown}
          onChange={() => dispatch(distanceMarkersOptionChanged(!areDistanceMarkersShown))}
        >
          {intl.formatMessage({
            id: 'route_print_option_distance_markers',
            defaultMessage: 'Distance markers',
          })}
        </Checkbox>
      </form>
    </WebAppContent>
  )
}
