import { HeartButton } from 'shared/ui-components'
import { useUser } from 'web-app/feature-user'
import { getLoginUrl } from 'shared/util-navigation'
import { useSessionInfo } from 'web-app/feature-navigation'
import { RouteEntity } from 'shared/data-access-core'
import { useDispatch } from 'react-redux'
import { RouteDetailsSliceDispatch } from '../../state'
import { toggleFavoriteRoute } from 'web-app/feature-route'

interface FavoriteButtonProps {
  route: RouteEntity
}

export const FavoriteButton = ({ route }: FavoriteButtonProps) => {
  const dispatch = useDispatch() as RouteDetailsSliceDispatch
  const [user, isUserLoaded] = useUser()
  const sessionInfo = useSessionInfo()

  const handleHeartButtonClick = async () => {
    if (!route) return
    if (isUserLoaded && !user) {
      window.location.href = getLoginUrl({
        webAppPath: window.location.pathname,
        ...sessionInfo,
      })
    } else {
      dispatch(toggleFavoriteRoute())
    }
  }

  return <HeartButton active={route.isFavorite} amount={route.favoriteCount} onClick={handleHeartButtonClick} />
}
