import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createMigrate, MigrationManifest, PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { CookieConsentState } from './types'
import { CookieConsentSettings } from 'shared/feature-cookie-consent'

export const COOKIE_CONSENT_SLICE_KEY = 'cookieConsent'

const initialState: CookieConsentState = {
  settings: {
    statistics: undefined,
  },
  hidden: false,
}

const slice = createSlice({
  name: COOKIE_CONSENT_SLICE_KEY,
  initialState,
  reducers: {
    settingsChanged(state, action: PayloadAction<CookieConsentSettings>) {
      return {
        ...state,
        settings: action.payload,
      }
    },
    cookieBannerHidden(state) {
      return {
        ...state,
        hidden: true,
      }
    },
    cookieBannerShown(state) {
      return {
        ...state,
        hidden: false,
      }
    },
  },
})

export const { settingsChanged, cookieBannerHidden, cookieBannerShown } = slice.actions

type CookieConsentStateOriginal = {
  statistics: boolean
  hidden: boolean
}

const migrations = {
  0: (state: CookieConsentStateOriginal): CookieConsentState => ({
    settings: {
      statistics: state.statistics,
    },
    hidden: state.hidden,
  }),
}

const persistConfig: PersistConfig<CookieConsentState> = {
  key: COOKIE_CONSENT_SLICE_KEY,
  storage,
  version: 0,
  migrate: createMigrate(migrations as unknown as MigrationManifest, { debug: false }),
}

export const cookieConsentReducer = persistReducer(persistConfig, slice.reducer)
