import { Layer, MarkerProps, Source } from 'react-map-gl/maplibre'
import { useMapImage } from '../use-map-image'

import locationMarkerImg from '../img/location-marker.png'

/**
 * Map marker for a generic location.
 */
export const MapMarkerLocation = (props: MarkerProps) => {
  useMapImage(locationMarkerImg, 'location-marker')

  return (
    <Source
      id='selected-location'
      type='geojson'
      data={{
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [props.longitude, props.latitude],
        },
        properties: {},
      }}
    >
      <Layer
        id='selected-location'
        type='symbol'
        layout={{
          'icon-image': 'location-marker',
          'icon-anchor': 'bottom',
          'icon-offset': [0, 21],
          'icon-size': 0.35,
          'icon-allow-overlap': true,
        }}
      />
    </Source>
  )
}

export default MapMarkerLocation
