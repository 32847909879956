import {
  AltitudeIcon,
  AscentCircleIcon,
  DescentCircleIcon,
  DistanceIcon,
  DurationIcon,
  RouteStatistics,
  RouteStatisticsItem,
  SpeedIcon,
} from 'shared/ui-components'
import { useUserState } from 'web-app/feature-user'
import { useRouteMessages, useRouteStatsFormatters } from 'shared/feature-routes'

type RouteStat = 'durationInS' | 'distanceInM' | 'ascentInM' | 'descentInM' | 'averageSpeedInMs' | 'maxAltitudeInM'

export interface RouteStatsProps {
  extended?: boolean
  durationInS?: number | null
  distanceInM?: number
  ascentInM?: number | null
  descentInM?: number | null
  averageSpeedInMs?: number | null
  maxAltitudeInM?: number | null
  overrides?: RouteStat[]
}

/**
 * Shows detailed route stats with fallbacks.
 */
export const RouteStats = ({
  extended,
  durationInS,
  distanceInM,
  ascentInM,
  descentInM,
  averageSpeedInMs,
  maxAltitudeInM,
  overrides,
}: RouteStatsProps) => {
  const {
    durationLabel,
    distanceLabel,
    averageSpeedLabel,
    ascentLabel,
    descentLabel,
    maxAltitudeLabel,
  } = useRouteMessages()
  const { unitPreference } = useUserState()
  const {
    formatRouteDuration,
    formatRouteDistance,
    formatRouteSpeed,
    formatRouteElevation,
  } = useRouteStatsFormatters(unitPreference === 'imperial')

  return (
    <RouteStatistics columns={extended ? 3 : 4}>
      <RouteStatisticsItem
        icon={<DurationIcon />}
        value={durationInS !== undefined ? formatRouteDuration(durationInS) : undefined}
        label={durationLabel}
        withChangeIndicator={overrides?.includes('durationInS')}
      />
      <RouteStatisticsItem
        icon={<DistanceIcon />}
        value={distanceInM !== undefined ? formatRouteDistance(distanceInM) : undefined}
        label={distanceLabel}
        withChangeIndicator={overrides?.includes('distanceInM')}
      />
      {extended && (
        <RouteStatisticsItem
          icon={<SpeedIcon />}
          value={
            averageSpeedInMs !== undefined ? formatRouteSpeed(averageSpeedInMs) : undefined
          }
          label={averageSpeedLabel}
          withChangeIndicator={overrides?.includes('averageSpeedInMs')}
        />
      )}
      <RouteStatisticsItem
        icon={<AscentCircleIcon />}
        value={ascentInM !== undefined ? formatRouteElevation(ascentInM) : undefined}
        label={ascentLabel}
        withChangeIndicator={overrides?.includes('ascentInM')}
      />
      <RouteStatisticsItem
        icon={<DescentCircleIcon />}
        value={descentInM !== undefined ? formatRouteElevation(descentInM) : undefined}
        label={descentLabel}
        withChangeIndicator={overrides?.includes('descentInM')}
      />
      {extended && (
        <RouteStatisticsItem
          icon={<AltitudeIcon />}
          value={
            maxAltitudeInM !== undefined ? formatRouteElevation(maxAltitudeInM) : undefined
          }
          label={maxAltitudeLabel}
          withChangeIndicator={overrides?.includes('maxAltitudeInM')}
        />
      )}
    </RouteStatistics>
  )
}
