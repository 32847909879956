import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import {
  YAxis,
  XAxis,
  Tooltip,
  ReferenceArea,
  ReferenceDot,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Line,
} from 'recharts'
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'
import { colors } from 'shared/ui-design-system'
import { XAxisTick, YAxisTick } from './axis-tick'
import { SectionTooltip, Tooltip as CustomTooltip } from './tooltip'
import { roundToNearest } from './helpers'
import { RouteDot } from '../icons'

import styles from './elevation-curve.module.scss'

export type ElevationData = {
  distance: number
  elevation: number
}

export interface ElevationCurveProps {
  height: string
  elevationData: ElevationData[]
  selectionIndexes?: [number] | [number, number]
  ascent?: string
  descent?: string
  tooltipLabelFormatter?: (label: string | number) => string
  yAxisFormatter?: (label: string | number) => string
  xAxisFormatter?: (label: string | number) => string
  onClick?: (index: number) => void
  onHover?: (index: number) => void
  onHoverOut?: () => void
  interactive?: boolean
}

export const ElevationCurve = ({
  height,
  elevationData,
  selectionIndexes,
  onClick,
  onHover,
  onHoverOut,
  ascent,
  descent,
  tooltipLabelFormatter,
  yAxisFormatter,
  xAxisFormatter,
  interactive = true,
}: ElevationCurveProps) => {
  const [refAreaRight, setRefAreaRight] = useState<number | undefined>(undefined)
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number } | undefined>(undefined)
  const [fixTooltipPosition, setFixTooltipPosition] = useState<number[]>([])

  const hasSelection = selectionIndexes?.length === 2

  useEffect(() => {
    if (!selectionIndexes) {
      setFixTooltipPosition([])
    }
  }, [selectionIndexes])

  const onClickChart: CategoricalChartFunc = (event) => {
    if (!event) return
    if (typeof event.activeTooltipIndex === 'number' && onClick) {
      onClick(event.activeTooltipIndex)
    }
    if (fixTooltipPosition.length < 2 && event.activeCoordinate?.x) {
      setFixTooltipPosition([...fixTooltipPosition, event.activeCoordinate.x])
    } else {
      setFixTooltipPosition([])
      setTooltipPosition(event.activeCoordinate)
    }
    setRefAreaRight(undefined)
  }

  const selectionPercentages = useMemo(() => {
    if (selectionIndexes?.length === 2) {
      const max = elevationData[elevationData.length - 1].distance
      const start = elevationData[selectionIndexes[0]].distance
      const end = elevationData[selectionIndexes[1]].distance
      return [(start / max) * 100, (end / max) * 100]
    }
    return []
  }, [elevationData, selectionIndexes])

  const onHoverChart: CategoricalChartFunc = (event) => {
    if (hasSelection) {
      return
    }
    if (selectionIndexes) {
      setRefAreaRight(Number(event.activeLabel))
    }
    setTooltipPosition(event.activeCoordinate)
    if (onHover && event.activeTooltipIndex !== undefined) {
      onHover(event.activeTooltipIndex)
    }
  }

  const onHoverOutChart = () => {
    setRefAreaRight(undefined)
    setTooltipPosition(undefined)
  }

  useEffect(() => {
    if (!tooltipPosition && onHoverOut) {
      onHoverOut()
    }
  }, [tooltipPosition, onHoverOut])

  return (
    <Box height={height} className={styles['chart-container']}>
      <ResponsiveContainer>
        <ComposedChart
          data={elevationData}
          margin={{
            top: 24,
            left: 64,
            right: 64,
            bottom: -8,
          }}
          onClick={interactive ? onClickChart : undefined}
          onMouseMove={interactive ? onHoverChart : undefined}
          onMouseLeave={interactive ? onHoverOutChart : undefined}
        >
          <Area
            yAxisId="elevation"
            type="monotone"
            dataKey="elevation"
            fill={colors.neutral.secondary}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
            opacity={1}
          />
          <CartesianGrid stroke={colors.neutral.quartery} vertical={false} />
          <XAxis
            allowDataOverflow
            dataKey="distance"
            type="number"
            tickLine={false}
            stroke={colors.neutral.quartery}
            padding={{ left: 4, right: 4 }}
            tick={<XAxisTick />}
            tickFormatter={xAxisFormatter}
            axisLine={false}
            domain={[0, 'dataMax']}
            tickCount={5}
          />
          <YAxis
            allowDataOverflow
            type="number"
            dataKey="elevation"
            yAxisId="elevation"
            tickLine={false}
            mirror
            axisLine={false}
            tick={<YAxisTick />}
            tickFormatter={yAxisFormatter}
            domain={([dataMin, dataMax]) => {
              const absMax = roundToNearest(dataMax * 1.5, 100)
              return [0, absMax]
            }}
          />
          {/* hovered section background */}
          {selectionIndexes && refAreaRight !== undefined && refAreaRight >= 0 ? (
            <ReferenceArea
              yAxisId="elevation"
              x1={elevationData[selectionIndexes[0]].distance}
              x2={refAreaRight}
              fill={colors.actionColor.primary}
              fillOpacity={0.2}
            />
          ) : null}
          {/* selected section background */}
          {hasSelection && (
            <ReferenceArea
              yAxisId="elevation"
              x1={elevationData[selectionIndexes[0]].distance}
              x2={elevationData[selectionIndexes[1]].distance}
              fill={colors.actionColor.primary}
              fillOpacity={0.2}
            />
          )}
          {/* default tooltip */}
          {!hasSelection && interactive && (
            <Tooltip
              content={<CustomTooltip />}
              labelFormatter={tooltipLabelFormatter}
              cursor={{ stroke: colors.onNeutral.tertiary, strokeWidth: 1 }}
              position={{
                x: tooltipPosition?.x ?? 0,
                y: 28,
              }}
              isAnimationActive={false}
            />
          )}
          <Line
            yAxisId="elevation"
            type="monotone"
            dataKey="elevation"
            strokeWidth={6}
            strokeLinecap="round"
            stroke={interactive ? 'url(#lineColorBackground)' : colors.polylineComp.background}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
          {interactive && (
            <defs>
              <linearGradient id="lineColorBackground" x1="0%" y1="0" x2="100%" y2="0">
                {/* default line background color */}
                <stop offset={'0%'} stopColor={colors.polylineComp.background} />
                {/* selected section line background color */}
                {selectionPercentages.length === 2 && (
                  <>
                    <stop
                      offset={`${selectionPercentages[0]}%`}
                      stopColor={colors.polylineComp.background}
                    />
                    <stop
                      offset={`${selectionPercentages[0]}%`}
                      stopColor={colors.polylineComp.alternate.background}
                    />
                    <stop
                      offset={`${selectionPercentages[1]}%`}
                      stopColor={colors.polylineComp.alternate.background}
                    />
                    <stop
                      offset={`${selectionPercentages[1]}%`}
                      stopColor={colors.polylineComp.background}
                    />
                  </>
                )}
              </linearGradient>
            </defs>
          )}
          <Line
            yAxisId="elevation"
            type="monotone"
            dataKey="elevation"
            strokeWidth={3}
            strokeLinecap="round"
            stroke={interactive ? 'url(#lineColor)' : colors.polylineComp.foreground}
            dot={false}
            activeDot={!hasSelection && interactive ? <RouteDot /> : false}
            isAnimationActive={false}
          />
          {interactive && (
            <defs>
              <linearGradient id="lineColor" x1="0%" y1="0" x2="100%" y2="0">
                {/* default line color */}
                <stop offset={'0%'} stopColor={colors.polylineComp.foreground} />
                {/* selected section line color */}
                {selectionPercentages.length === 2 && (
                  <>
                    <stop
                      offset={`${selectionPercentages[0]}%`}
                      stopColor={colors.polylineComp.foreground}
                    />
                    <stop
                      offset={`${selectionPercentages[0]}%`}
                      stopColor={colors.polylineComp.alternate.foreground}
                    />
                    <stop
                      offset={`${selectionPercentages[1]}%`}
                      stopColor={colors.polylineComp.alternate.foreground}
                    />
                    <stop
                      offset={`${selectionPercentages[1]}%`}
                      stopColor={colors.polylineComp.foreground}
                    />
                  </>
                )}
              </linearGradient>
            </defs>
          )}
          {/* selected point line and marker */}
          {selectionIndexes && (
            <>
              <ReferenceLine
                yAxisId="elevation"
                x={elevationData[selectionIndexes[0]].distance}
                stroke={colors.onNeutral.tertiary}
              />
              <ReferenceDot
                yAxisId="elevation"
                x={elevationData[selectionIndexes[0]].distance}
                y={elevationData[selectionIndexes[0]].elevation}
                shape={<RouteDot />}
              />
            </>
          )}
          {/* selected section tooltip, line and marker */}
          {hasSelection && (
            <>
              <Tooltip
                content={<SectionTooltip ascent={ascent} descent={descent} />}
                cursor={false}
                position={{
                  x: Math.abs((fixTooltipPosition[0] + fixTooltipPosition[1]) / 2),
                  y: 28,
                }}
                wrapperStyle={{
                  visibility: 'visible',
                }}
                isAnimationActive={false}
              />
              <ReferenceLine
                yAxisId="elevation"
                x={elevationData[selectionIndexes[1]].distance}
                stroke={colors.onNeutral.tertiary}
              />
              <ReferenceDot
                yAxisId="elevation"
                x={elevationData[selectionIndexes[1]].distance}
                y={elevationData[selectionIndexes[1]].elevation}
                shape={<RouteDot />}
              />
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}
