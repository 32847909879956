import { useRef, useState } from 'react'
import { useUser } from 'web-app/feature-user'
import { UserMenu } from './user-menu'
import { PremiumLink } from './links'
import { UserMenuToggle } from './user-menu-toggle'

import styles from './user-navigation.module.css'

export function UserNavigation() {
  const [user, isUserLoaded] = useUser()

  const navigationRef = useRef(null)

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  return (
    <>
      <nav ref={navigationRef} className={styles['root']}>
        {isUserLoaded && !user?.isPremium && <PremiumLink primary />}
        <UserMenuToggle isOpen={isMenuOpen} onToggle={setIsMenuOpen} />
      </nav>
      <UserMenu
        anchorEl={navigationRef.current}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => { setIsMenuOpen(false) }}
        user={(isUserLoaded && user) || undefined}
      />
    </>
  )
}
