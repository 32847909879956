import { useMemo } from 'react'
import { LineString, Position } from 'geojson'
import { useElevationCurveContext } from '../context'
import { MapMarkerPoint } from 'shared/ui-map'

interface ElevationMapMarkerPointProps {
  geometry?: LineString
}

export const ElevationMapMarkerPoint = ({
  geometry,
}: ElevationMapMarkerPointProps) => {
  const { elevationPointIndex } = useElevationCurveContext()

  const elevationPoint = useMemo<Position | null>(() => {
    if (
      geometry &&
      elevationPointIndex !== undefined &&
      geometry.coordinates.length > elevationPointIndex &&
      geometry.coordinates[elevationPointIndex]
    ) {
      return geometry.coordinates[elevationPointIndex]
    }
    return null
  }, [elevationPointIndex, geometry])

  return elevationPoint && <MapMarkerPoint longitude={elevationPoint[0]} latitude={elevationPoint[1]} />
}
