import { useLocale } from 'shared/util-intl'
import { BasePage } from './base-page'
import { DetailedMap } from '../components/detailed-map'

interface DetailedMapPageProps {
  mapKey: string
  pageNumber: number
}

export const DetailedMapPage = ({ mapKey, pageNumber }: DetailedMapPageProps) => {
  const { intl } = useLocale()

  return (
    <BasePage
      number={pageNumber}
      title={intl.formatMessage({
        id: 'route_print_detailed_map_page_title',
        defaultMessage: 'Detailed map',
      })}
    >
      <DetailedMap mapKey={mapKey} />
    </BasePage>
  )
}
