import { ElevationCurveMapFeatures } from 'shared/feature-elevation-curve'
import { useFinalGeometry, useRoutePlannerState } from '../state'
import { ROUTE_ID } from './settings'

export const RoutePlannerElevationCurveMapFeatures = () => {
  const finalGeometry = useFinalGeometry()
  const { distance } = useRoutePlannerState()

  return finalGeometry && distance ? (
    <ElevationCurveMapFeatures mapRouteId={ROUTE_ID} geometry={finalGeometry} distance={distance} />
  ) : null
}
