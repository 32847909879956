import { Feature, FeatureCollection } from 'geojson'
import { useCallback, useMemo, useState } from 'react'
import { Layer, Source, useMap, MapLayerMouseEvent, Point } from 'react-map-gl/maplibre'
import { LngLat, lngLatToPosition2d } from 'shared/util-geo'
import { useMapImage } from './use-map-image'
import { useMapClick, useMapMouseMove } from './hooks'

import defaultMarkerImg from './img/route-marker-default.png'
import mtbMarkerImg from './img/route-marker-mtb.png'
import roadBikeMarkerImg from './img/route-marker-road-bike.png'

type RouteStart = {
  routeId: number
  routeType: 'default' | 'road-bike' | 'mtb'
  position: LngLat
}

interface DiscoverRouteMarkersProps {
  id: string
  mapId: string
  routeStarts: RouteStart[]
  onClick: (routeId: number) => void
  onClickOutside: () => void
}

export const DiscoverRouteMarkers = ({
  id,
  mapId,
  routeStarts,
  onClick,
  onClickOutside,
}: DiscoverRouteMarkersProps) => {
  const { [mapId]: map } = useMap()

  const [activeRouteId, setActiveRouteId] = useState<number | null>(null)

  useMapImage(defaultMarkerImg, 'route-marker-default')
  useMapImage(mtbMarkerImg, 'route-marker-mtb')
  useMapImage(roadBikeMarkerImg, 'route-marker-road-bike')

  const layerId = useMemo(() => `${id}-layer`, [id])

  const sourceData = useMemo<FeatureCollection>(() => ({
    type: 'FeatureCollection',
    features: routeStarts.map(({ routeId, routeType, position }): Feature => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: lngLatToPosition2d(position),
      },
      properties: {
        routeId,
        icon: `route-marker-${routeType}`,
        active: routeId === activeRouteId,
      },
    })),
  }), [activeRouteId, routeStarts])

  const findClickableFeature = useCallback((point: Point): Feature | null => {
    if (!map) return null
    const features = map.getMap().queryRenderedFeatures(point)
    for (const feature of features) {
      if (feature.layer.id === layerId) {
        map.getCanvas().style.cursor = 'pointer'
        setActiveRouteId(feature.properties['routeId'])
        return feature
      }
    }
    return null
  }, [layerId, map])

  const handleMouseMove = useCallback((event: MapLayerMouseEvent) => {
    if (!map) return
    const feature = findClickableFeature(event.point)
    if (feature) {
      map.getCanvas().style.cursor = 'pointer'
      setActiveRouteId(feature.properties && feature.properties['routeId'])
    } else {
      map.getCanvas().style.cursor = ''
      setActiveRouteId(null)
    }
  }, [findClickableFeature, map])
  useMapMouseMove(mapId, handleMouseMove)

  const handleMapClick = useCallback((event: MapLayerMouseEvent) => {
    const feature = findClickableFeature(event.point)
    const routeId = feature?.properties && feature.properties['routeId']
    if (routeId) {
      onClick(routeId)
    } else {
      onClickOutside()
    }
  }, [findClickableFeature, onClick, onClickOutside])
  useMapClick(mapId, handleMapClick)

  return (
    <Source
      id={`${id}-source`}
      type='geojson'
      data={sourceData}
    >
      <Layer
        id={layerId}
        type='symbol'
        layout={{
          'icon-image': ['get', 'icon'],
          'icon-anchor': 'bottom',
          'icon-offset': [0, 15],
          'icon-size': ['case', ['boolean', ['get', 'active'], false], 0.55, 0.5],
        }}
      />
    </Source>
  )
}
