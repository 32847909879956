import { ReactNode } from 'react'
import { Link, MinimalFooter as MinimalFooterComponent } from 'shared/ui-components'
import { getHomeUrl, getImprintUrl } from 'shared/util-navigation'
import { useSessionInfo } from './hooks'
import { useLocale } from 'shared/util-intl'
import { useDispatch } from 'react-redux'
import { cookieBannerShown } from 'web-app/feature-cookie-consent'

export interface MinimalFooterProps {
  secondaryItems?: ReactNode[]
  colored?: boolean
}

export const MinimalFooter = ({ secondaryItems, colored }: MinimalFooterProps) => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()

  const handlePrivacyControlsClick = () => {
    dispatch(cookieBannerShown())
  }

  return (
    <MinimalFooterComponent
      homeHref={getHomeUrl(sessionInfo)}
      items={[
        <Link small secondary onColor={colored} href={getImprintUrl(sessionInfo)}>
          {intl.formatMessage({
            id: 'map_view_footer_link_imprint',
            defaultMessage: 'Imprint',
          })}
        </Link>,
        <Link button small secondary onColor={colored} onClick={handlePrivacyControlsClick}>
          {intl.formatMessage({
            id: 'map_view_footer_link_cookie_banner',
            defaultMessage: 'Privacy controls',
          })}
        </Link>,
      ]}
      secondaryItems={secondaryItems}
      colored={colored}
    />
  )
}
