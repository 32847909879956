import clsx from 'clsx'
import MapMarkerEmptySvg from './svgs/map-marker-empty.svg'
import { CommonMapMarker, CommonMapMarkerProps } from './shared/common-map-marker'

import styles from './map-marker-via.module.scss'

interface MapMarkerViaProps extends Omit<CommonMapMarkerProps, 'svg'> {
  label: string
  selected?: boolean
}

/**
 * Map marker for the route via points.
 */
export const MapMarkerVia = ({
  label,
  selected,
  ...markerProps
}: MapMarkerViaProps) => (
  <CommonMapMarker
    {...markerProps}
    svg={MapMarkerEmptySvg}
    size={selected ? 'large' : 'small'}
  >
    <div className={clsx(styles['label'], { [styles['label-large']]: selected })}>
      {label}
    </div>
  </CommonMapMarker>
)

export default MapMarkerVia
