import { RoutePoiEntity } from 'shared/data-access-core'
import { MapMarkerAccommodation, MapMarkerPhoto, MapMarkerPoi, MapMarkerRepair, MapMarkerWater } from 'shared/ui-map'
import { useRoutePois } from '../route-pois-context'
import { MarkerProps } from 'react-map-gl/maplibre'

interface RoutePoiMapMarkerProps {
  routePoi: RoutePoiEntity
  onSelect?: (routePoi: RoutePoiEntity) => void
  interactive?: boolean
}

export const RoutePoiMapMarker = ({
  routePoi,
  onSelect,
  interactive = true,
}: RoutePoiMapMarkerProps) => {
  const { selectedRoutePoi, onRoutePoiSelectionChange } = useRoutePois()
  const isSelected = routePoi.id === selectedRoutePoi?.id

  const props: MarkerProps = {
    longitude: routePoi.lng,
    latitude: routePoi.lat,
    onClick: !isSelected && interactive ? (e) => {
      e.originalEvent.stopPropagation()
      onRoutePoiSelectionChange(routePoi.id)
      if (onSelect) {
        onSelect(routePoi)
      }
    } : undefined,
  }

  const size = isSelected ? 'large' : 'default'

  switch (routePoi.type) {
    case 'water': return <MapMarkerWater {...props} size={size} />
    case 'repair': return <MapMarkerRepair {...props} size={size} />
    case 'hotel': return <MapMarkerAccommodation {...props} size={size} />
    case 'photo': return <MapMarkerPhoto {...props} size={size} />
  }
  return <MapMarkerPoi {...props} size={size} />
}
