import { createSelector } from 'reselect'
import { StateWithRouteDetailsSlice, ROUTE_DETAILS_SLICE_KEY, EditingField } from './types'
import { RouteCollectionEntity } from 'shared/data-access-core'

export const routeDetailsSliceSelector = (state: StateWithRouteDetailsSlice) => state[ROUTE_DETAILS_SLICE_KEY]

export const routeCollectionsSelector: (state: StateWithRouteDetailsSlice) => Record<number, RouteCollectionEntity> =
  createSelector(routeDetailsSliceSelector, (slice) => slice.routeCollections)

export const openImageIndexSelector: (state: StateWithRouteDetailsSlice) => number | null = createSelector(
  routeDetailsSliceSelector,
  (slice) => slice.openImageIndex,
)

export const editingFieldSelector: (state: StateWithRouteDetailsSlice) => EditingField | null = createSelector(
  routeDetailsSliceSelector,
  (slice) => slice.editingField,
)
