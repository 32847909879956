import { Button, ButtonGroup, Dialog, MainNavigation } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { WEB_APP_PATH_ROUTE_DETAILS } from 'shared/util-navigation'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reset, useRoutePrintState } from './state'
import { useState } from 'react'

interface RoutePrintMainNavigationProps {
  routeId: number
}

export const RoutePrintMainNavigation = ({ routeId }: RoutePrintMainNavigationProps) => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const navigate = useNavigate()
  const { detailedMapViewports, detailedMapImages } = useRoutePrintState()

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)

  const cancel = () => {
    dispatch(reset())
    navigate(WEB_APP_PATH_ROUTE_DETAILS.replace(':routeId', routeId.toString()))
  }

  const handleCancel = () => {
    if (Object.keys(detailedMapViewports).length || Object.keys(detailedMapImages).length) {
      setIsCancelDialogOpen(true)
    } else {
      cancel()
    }
  }

  const handleCancelConfirm = () => {
    setIsCancelDialogOpen(false)
    cancel()
  }

  return (
    <>
      <MainNavigation
        title={intl.formatMessage({
          id: 'route_print_title',
          defaultMessage: 'Route print',
        })}
        onCancel={handleCancel}
      />
      <Dialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        title={intl.formatMessage({
          id: 'route_print_cancel_dialog_title',
          defaultMessage: 'Do you really want to quit?',
        })}
        text={intl.formatMessage({
          id: 'route_print_cancel_dialog_text',
          defaultMessage:
            'You will lose the changes you made so far. If you would like to print it later, you can already save it as PDF. Just click "Print or Save" and select PDF in the browser dialog.',
        })}
        buttons={
          <ButtonGroup stack>
            <Button variant="secondary" onClick={() => setIsCancelDialogOpen(false)}>
              {intl.formatMessage({
                id: 'route_print_cancel_dialog_cancel',
                defaultMessage: 'Cancel',
              })}
            </Button>
            <Button variant="danger-primary" onClick={handleCancelConfirm}>
              {intl.formatMessage({
                id: 'route_print_cancel_dialog_confirm',
                defaultMessage: 'Dismiss changes',
              })}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  )
}
