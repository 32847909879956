import React from 'react'
import clsx from 'clsx'
import { useLocale } from 'shared/util-intl'
import ImageIcon from '@mui/icons-material/Image'
import { Skeleton } from '@mui/material'

import styles from './images-grid.module.css'

export type OneToFourImages = [string] | [string, string] | [string, string, string] | [string, string, string, string]

interface ImagesGridProps {
  images: OneToFourImages
  numMoreImages: number
  onImageClick?: (index: number) => void
}

export const ImagesGrid = ({ images, numMoreImages, onImageClick }: ImagesGridProps) => {
  const { intl } = useLocale()

  const handleItemClick = (index: number) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onImageClick) {
      e.preventDefault()
      onImageClick(index)
    }
  }

  return (
    <div className={clsx(styles['container'], { [styles['container-full']]: images.length > 3 })}>
      {images.map((src, i) => {
        const alt = intl.formatMessage(
          {
            id: 'route_images_alt_text',
            defaultMessage: 'Route image {number}',
          },
          {
            number: i + 1,
          },
        )
        const img = <img src={src} alt={alt} className={styles['image']} />
        return (
          <a key={i} className={styles['image-item']} href={src} onClick={handleItemClick(i)}>
            {i === 3 && numMoreImages ? (
              <div className={styles['dimmed-item']}>
                {img}
                <div className={styles['overlay-label']}>
                  +{numMoreImages} <ImageIcon />
                </div>
              </div>
            ) : (
              img
            )}
          </a>
        )
      })}
    </div>
  )
}

export const ImagesGridSkeleton = () => (
  <div className={clsx(styles['container'], styles['container-full'])}>
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
    <Skeleton variant="rectangular" height="100%" className={styles['image-item']} />
  </div>
)
