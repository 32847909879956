import { fetchRoute } from 'web-app/feature-route'
import { HomeSliceDispatch, StateWithHomeSlice } from './types'
import { routeAddedToFavorites, routeRemovedFromFavorites, routeSelected } from './state'
import { StateWithUserSlice, USER_SLICE_KEY, favoriteRoutesCountUpdated } from 'web-app/feature-user'
import { homeSliceSelector } from './selectors'
import { addRouteToFavorites, removeRouteFromFavorites } from 'shared/data-access-core'

export function selectRoute(routeId: number) {
  return (dispatch: HomeSliceDispatch) => {
    dispatch(fetchRoute(routeId))
    dispatch(routeSelected({ routeId }))
  }
}

export function toggleFavoriteRoute(routeId: number) {
  return async (dispatch: HomeSliceDispatch, getState: () => StateWithHomeSlice & StateWithUserSlice) => {
    const state = getState()
    const { routes } = homeSliceSelector(state)
    const route = routes[routeId]
    const { user } = state[USER_SLICE_KEY]
    if (!route || !user) return
    if (route.isFavorite) {
      dispatch(routeRemovedFromFavorites({ routeId }))
      const result = await removeRouteFromFavorites(route.id)
      if (result.success) {
        dispatch(favoriteRoutesCountUpdated(user.favoriteRoutesCount - 1))
      } else {
        dispatch(routeAddedToFavorites({ routeId }))
      }
    } else {
      dispatch(routeAddedToFavorites({ routeId }))
      const result = await addRouteToFavorites(route.id)
      if (result.success) {
        dispatch(favoriteRoutesCountUpdated(user.favoriteRoutesCount + 1))
      } else {
        dispatch(routeRemovedFromFavorites({ routeId }))
      }
    }
  }
}
