import { MapViewport } from 'shared/ui-map'

/**
 * ID of the main Web App map which is based on global state.
 */
export const MAP_ID = 'bm-map'

/**
 * Default (Vienna) location to be used for map initialization before a better location can be determined.
 */
export const DEFAULT_VIEWPORT: MapViewport = {
  center: [16.37208, 48.20849],
  zoom: 12,
  bearing: 0,
  pitch: 0,
}
