import { Reducer, Action } from '@reduxjs/toolkit'

export type StateWithHistory<S> = {
  past: Partial<S>[]
  present: S
  future: Partial<S>[]
}

export type UndoableOptions<S> = {
  initialState: S
  actions: {
    undo: Action
    redo: Action
  }
  whitelist?: (keyof S)[] // props to keep in past/future history
  includedActions?: string[] // for which actions to update history
  maxHistory?: number
  allowUnchangedHistoryEntry?: boolean
}

/**
 * Simpler version of [redux-undo](https://www.npmjs.com/package/redux-undo) npm package,
 * with option to keep only specific state props in undo/redo history by using `whitelist` prop.
 * Based on [Implementing Undo History](https://redux.js.org/usage/implementing-undo-history) Redux docs.
 */
export default function undoable<S extends object, A extends Action>(
  reducer: Reducer<S, A>,
  { initialState, actions, whitelist, includedActions, maxHistory, allowUnchangedHistoryEntry }: UndoableOptions<S>
) {
  return (
    state: StateWithHistory<S> = {
      past: [],
      present: initialState,
      future: [],
    },
    action: A
  ): StateWithHistory<S> => {
    const { past, present, future } = state

    const presentForHistory = whitelist ? whitelistedStateProps(present, whitelist) : present

    if (action.type === actions.undo.type) {
      const previous = past[past.length - 1]
      const newPast = past.slice(0, past.length - 1)
      return {
        past: newPast,
        present: {
          ...present,
          ...previous,
        },
        future: [presentForHistory, ...future],
      }
    }

    if (action.type === actions.redo.type) {
      const next = future[0]
      const newFuture = future.slice(1)
      return {
        past: [...past, presentForHistory],
        present: {
          ...present,
          ...next,
        },
        future: newFuture,
      }
    }

    // New state after changes from main reducers
    const newPresent = reducer(present, action)
    if (!allowUnchangedHistoryEntry && present === newPresent) {
      return state
    }

    // Update past history only for specific actions or all actions if not defined
    if (!includedActions || includedActions.includes(action.type)) {
      const isHistoryOverflow = maxHistory && maxHistory <= past.length
      const pastSliced = past.slice(isHistoryOverflow ? 1 : 0)

      return {
        past: [...pastSliced, presentForHistory],
        present: newPresent,
        future: [],
      }
    }

    return {
      past,
      present: newPresent,
      future,
    }
  }
}

/**
 * Return only state props defined in whitelist array.
 */
function whitelistedStateProps<S extends object, K extends keyof S>(state: S, whitelist: K[]): Pick<S, K> {
  const filteredProps = {} as Pick<S, K>

  for (const prop of whitelist) {
    if (prop in state) {
      filteredProps[prop] = state[prop]
    }
  }

  return filteredProps
}
