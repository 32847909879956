import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import {
  routeImportSliceSelector,
  getActiveGeometry,
  getInactiveGeometry,
  getSelectedRouteGeometry,
  getSelectedRouteDistance,
} from './selectors'

export const useRouteImportState = () => useSelector(routeImportSliceSelector)

export const useRouteImportRouteData = () =>
  useSelector(createSelector(routeImportSliceSelector, (slice) => slice.routeData))

export const useIsAlreadyUploaded = () =>
  useSelector(
    createSelector(
      routeImportSliceSelector,
      (slice) =>
        slice.step === 'upload' && slice.fromStep !== 'upload' && slice.routeFileName && !slice.isUploadInProgress,
    ),
  )

export const useActiveGeometry = () =>
  useSelector(createSelector(routeImportSliceSelector, (slice) => getActiveGeometry(slice)))

export const useInactiveGeometry = () =>
  useSelector(createSelector(routeImportSliceSelector, (slice) => getInactiveGeometry(slice)))

export const useSelectedRouteGeometry = () =>
  useSelector(createSelector(routeImportSliceSelector, (slice) => getSelectedRouteGeometry(slice)))

export const useSelectedRouteDistance = () =>
  useSelector(createSelector(routeImportSliceSelector, (slice) => getSelectedRouteDistance(slice)))
