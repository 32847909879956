import React, { useState } from 'react'
import clsx from 'clsx'
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded'
import _uniqueId from 'lodash/uniqueId'
import { ToolButton } from '../ToolButton'
import { useMessages } from '../MessagesProvider'

import styles from './Input.module.scss'

interface CommonProps {
  value: string
  name: string
  label: string
  hideLabel?: boolean
  textarea?: boolean
  onReset?: () => void
  isRequired?: boolean
  error?: string
  icon?: React.ReactNode
  resize?: boolean
}

export interface InputProps extends CommonProps, Omit<React.HTMLAttributes<HTMLInputElement>, 'onReset'> {
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export interface TextAreaProps extends CommonProps, Omit<React.HTMLAttributes<HTMLTextAreaElement>, 'onReset'> {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
}

export const Input: React.FC<CommonProps & React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>> = (props) => {
  const {
    name,
    label,
    hideLabel,
    textarea,
    placeholder,
    onReset,
    className,
    isRequired,
    error,
    icon,
    resize,
    ...rest
  } = props

  const { resetLabel } = useMessages()
  const [id] = useState(_uniqueId(name + '-'))

  const inputProps = {
    name,
    id,
    placeholder: placeholder || ' ',
    className: clsx(styles['input'], {
      [styles['textarea']]: textarea,
      [styles['resize']]: textarea && resize,
      [styles['has-error']]: error,
    }),
    ...rest,
  }

  // TODO WEB-1098: Refactor into separate components
  const inputTag = (textarea
    ? <textarea {...(inputProps as Omit<TextAreaProps, 'label'>)} />
    : <input {...(inputProps as Omit<InputProps, 'label'>)} />
  )

  return (
    <div className={clsx(styles['root'], className, { [styles['with-clear']]: onReset && !textarea, [styles['with-icon']]: icon })}>
      <label className={clsx(styles['label'], { [styles['invisible-label']]: hideLabel })} htmlFor={id}>
        {label}
        {isRequired && <span className={styles['label-required']}>*</span>}
      </label>
      <div className={styles['input-wrapper']}>
        {icon && !textarea && (
          <div className={styles['icon-holder']}>
            {icon}
          </div>
        )}
        {inputTag}
        {onReset && !textarea &&
          <div className={styles['clear-button']}>
            <ToolButton
              variant='ghost-primary'
              onMouseDown={(e) => {
                // Allow onClick to run (when this button is hidden in CSS)
                e.preventDefault()
              }}
              onClick={(e) => {
                // Do not submit any form
                e.preventDefault()
                onReset()
              }}
              icon={<DisabledByDefaultRoundedIcon />}
              ariaLabel={resetLabel}
            />
          </div>
        }
      </div>

      {error && <div className={styles['error']}>{error}</div>}
    </div>
  )
}

export default Input
