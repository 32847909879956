import { useDispatch } from 'react-redux'
import { RoutingMode, routingModeChanged, useRoutePlannerState } from '../../state'
import { useLocale } from 'shared/util-intl'
import { Typography } from '@mui/material'
import { RoutingProfileSelect } from './routing-profile-select'
import { RoutingLevelSelect } from './routing-level-select'
import { ApplyRoutingSwitch } from './apply-routing-switch'
import { WebAppContent } from 'web-app/ui-layout'
import { InlineTabs } from 'shared/ui-components'
import { FreehandRoutingIllustration } from './freehand-routing-illustration'

import styles from './routing-options.module.css'

export const RoutingOptions = () => {
  const dispatch = useDispatch()
  const { intl } = useLocale()
  const { routingMode, routingProfile } = useRoutePlannerState()

  const routingTabOptions = [
    {
      value: 'routing',
      label: intl.formatMessage({
        id: 'route_planner_routing_tab_label_routing',
        defaultMessage: 'Routing',
      }),
    },
    {
      value: 'freehand',
      label: intl.formatMessage({
        id: 'route_planner_routing_tab_label_freehand',
        defaultMessage: 'Freehand',
      }),
    },
  ] as { value: RoutingMode; label: string }[]

  const freehandExplanation = intl.formatMessage({
    id: 'route_planner_freehand_mode_explanation',
    defaultMessage: 'Draw straight segments on the map, useful for off-road terrain where routing is impossible.',
  })

  return (
    <WebAppContent>
      <div className={styles['container']}>
        <InlineTabs
          options={routingTabOptions}
          value={routingMode}
          onSelect={(mode) => dispatch(routingModeChanged(mode))}
        />
        {routingMode === 'routing' && (
          <>
            <RoutingProfileSelect />
            {routingProfile === 'cycling_paths' && <RoutingLevelSelect />}
            <ApplyRoutingSwitch />
          </>
        )}
        {routingMode === 'freehand' && (
          <>
            <div className={styles['freehand-illustration']}>
              <FreehandRoutingIllustration alt={freehandExplanation} />
            </div>
            <Typography paragraph variant="body2" color="textSecondary">
              {freehandExplanation}
            </Typography>
          </>
        )}
      </div>
    </WebAppContent>
  )
}
