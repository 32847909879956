import { useRoutePlannerState, useSelectedWaypoint } from '../state'
import { RoutePlannerMapRoute } from './route'
import { RoutePlannerElevationCurveMapFeatures } from './route-planner-elevation-curve-map-features'
import { PopupCreate, PopupEdit } from './map-popup'
import { MapMarkerLocation } from 'shared/ui-map'
import { useMapInteraction } from './use-map-interaction'

export const MapFeatures = () => {
  const { selectedLocation } = useRoutePlannerState()
  const selectedWaypointState = useSelectedWaypoint()

  useMapInteraction()

  return (
    <>
      <RoutePlannerMapRoute />
      {selectedLocation && <MapMarkerLocation longitude={selectedLocation.lng} latitude={selectedLocation.lat} />}
      <RoutePlannerElevationCurveMapFeatures />
      {selectedLocation ? (
        <PopupCreate selectedLocation={selectedLocation} />
      ) : selectedWaypointState ? (
        <PopupEdit selectedWaypointState={selectedWaypointState} />
      ) : null}
    </>
  )
}
