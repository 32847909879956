import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded'
import LocalSeeRoundedIcon from '@mui/icons-material/LocalSeeRounded'
import BuildRoundedIcon from '@mui/icons-material/BuildRounded'
import WaterDropRoundedIcon from '@mui/icons-material/WaterDropRounded'
import HotelRoundedIcon from '@mui/icons-material/HotelRounded'
import { Button, ButtonGroup, Input, Select, useMessages } from 'shared/ui-components'
import { useTypeLabels } from '../use-type-labels'
import { ChangeEvent, MouseEvent, ReactNode, useMemo } from 'react'
import { ROUTE_POI_TYPES, RoutePoiType } from 'shared/data-access-core'
import { useLocale } from 'shared/util-intl'
import { RoutePoiFormImage } from './route-poi-form-image'
import { useRoutePois } from '../route-pois-context'

import styles from './route-poi-form.module.css'

const ROUTE_POI_TYPE_ICONS: Record<RoutePoiType, ReactNode> = {
  sight: <StarRateRoundedIcon />,
  photo: <LocalSeeRoundedIcon />,
  repair: <BuildRoundedIcon />,
  water: <WaterDropRoundedIcon />,
  hotel: <HotelRoundedIcon />,
}

export type RoutePoiFormData = {
  type: RoutePoiType
  description: string
  image?: File
  currentImage?: string
}

interface RoutePoiFormProps {
  formData: RoutePoiFormData
  onChange: (formData: RoutePoiFormData) => void
  onSubmit: () => void
  onImageFileError: (message: string) => void
  loading?: boolean
}

export const RoutePoiForm = ({ formData, onChange, onSubmit, onImageFileError, loading }: RoutePoiFormProps) => {
  const { intl } = useLocale()
  const typeLabels = useTypeLabels()
  const { cancelLabel, saveLabel } = useMessages()
  const { onCancel } = useRoutePois()

  const options = useMemo(
    () =>
      ROUTE_POI_TYPES.map((type) => ({
        value: type,
        label: typeLabels[type],
        icon: ROUTE_POI_TYPE_ICONS[type],
      })),
    [typeLabels],
  )

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <form className={styles['form']}>
      <Select
        name="type"
        value={formData.type}
        onSelect={(type) => onChange({ ...formData, type })}
        options={options}
      />
      <Input
        name="description"
        value={formData.description}
        textarea
        label={intl.formatMessage({
          id: 'route_poi_form_description_label',
          defaultMessage: 'Description',
        })}
        placeholder={intl.formatMessage({
          id: 'route_poi_form_description_placeholder',
          defaultMessage: 'Start writing a short description...',
        })}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          onChange({ ...formData, description: e.target.value })
        }}
        data-testid="save-route-description"
      />
      <RoutePoiFormImage
        currentImage={formData.currentImage}
        value={formData.image}
        onChange={(image) => onChange({ ...formData, image, currentImage: undefined })}
        onRemove={() => onChange({ ...formData, image: undefined, currentImage: undefined })}
        onFileError={onImageFileError}
      />
      <ButtonGroup>
        <Button variant="secondary" onClick={onCancel} disabled={loading}>
          {cancelLabel}
        </Button>
        <Button type="submit" onClick={handleSubmit} disabled={loading}>
          {saveLabel}
        </Button>
      </ButtonGroup>
    </form>
  )
}
