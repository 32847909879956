import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EditingField, ROUTE_DETAILS_SLICE_KEY, RouteDetailsState } from './types'
import { LngLat } from 'shared/ui-map'
import { RouteCollectionEntity } from 'shared/data-access-core'

export const initialState: RouteDetailsState = {
  openImageIndex: null,
  routeCollections: {},
  isEmbedConfiguratorOpen: false,
  editingField: null,
  selectedLocation: null,
}

const slice = createSlice({
  name: ROUTE_DETAILS_SLICE_KEY,
  initialState,
  reducers: {
    imageOpened(state, action: PayloadAction<{ index: number }>) {
      state.openImageIndex = action.payload.index
      state.isEmbedConfiguratorOpen = false
    },
    lightboxClosed(state) {
      state.openImageIndex = null
    },
    embedConfiguratorOpened(state) {
      state.isEmbedConfiguratorOpen = true
      state.openImageIndex = null
    },
    embedConfiguratorClosed(state) {
      state.isEmbedConfiguratorOpen = false
    },
    routeCollectionsFetched(state, action: PayloadAction<RouteCollectionEntity[]>) {
      for (const collection of action.payload) {
        state.routeCollections[collection.id] = collection
      }
    },
    routeCollectionCreated(state, action: PayloadAction<RouteCollectionEntity>) {
      state.routeCollections[action.payload.id] = action.payload
    },
    editingStarted(state, action: PayloadAction<EditingField>) {
      state.editingField = action.payload
    },
    editingDone(state) {
      state.editingField = null
    },
    locationSelected(state, action: PayloadAction<LngLat>) {
      state.selectedLocation = action.payload
    },
    mapPopupClosed(state) {
      state.selectedLocation = null
    },
    routeAddedToCollection(state, action: PayloadAction<number>) {
      state.routeCollections[action.payload].routesCount++
    },
    routeRemovedFromCollection(state, action: PayloadAction<number>) {
      state.routeCollections[action.payload].routesCount--
    },
    reset() {
      return initialState
    },
  },
})

export const {
  imageOpened,
  lightboxClosed,
  embedConfiguratorOpened,
  embedConfiguratorClosed,
  routeCollectionsFetched,
  routeCollectionCreated,
  editingStarted,
  editingDone,
  locationSelected,
  mapPopupClosed,
  routeAddedToCollection,
  routeRemovedFromCollection,
  reset,
} = slice.actions

export const routeDetailsReducer = slice.reducer
