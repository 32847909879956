import { useEffect, useState } from 'react'

/**
 * Detects when user doesn't have internet connection and returns current state.
 */
export function useOfflineState(): [boolean] {
  const [isOffline, setIsOffline] = useState(false)

  useEffect(() => {
    const handleOffline = () => {
      if (!isOffline) {
        setIsOffline(true)
      }
    }
    window.addEventListener('offline', handleOffline)
    return () => window.removeEventListener('offline', handleOffline)
  }, [isOffline])

  useEffect(() => {
    const handleOnline = () => {
      if (isOffline) {
        setIsOffline(false)
      }
    }
    window.addEventListener('online', handleOnline)
    return () => window.removeEventListener('online', handleOnline)
  }, [isOffline])

  return [isOffline]
}
