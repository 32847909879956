import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteSliceDispatch, fetchRoute, useIsOwnRoute } from 'web-app/feature-route'
import { ImagesGrid, ImagesGridSkeleton, OneToFourImages } from './images-grid'
import { editingDone, imageOpened, useIsEditingFlags } from '../../state'
import { EditContentButton } from '../editable-content'
import { RouteImagesUploader } from './route-images-uploader'
import { RouteEntity } from 'shared/data-access-core'

import styles from './route-images.module.css'

interface RouteImagesProps {
  route?: RouteEntity
}

export const RouteImages = ({ route }: RouteImagesProps) => {
  const name = 'images'
  const dispatch = useDispatch() as RouteSliceDispatch
  const isOwnRoute = useIsOwnRoute()
  const { isEditing, isEditingAnotherField } = useIsEditingFlags(name)

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)

  const hasImages = !!route?.images.length

  const handleImagesUpdated = async () => {
    if (!route) return
    setIsRefreshing(true)
    try {
      await dispatch(fetchRoute(route.id)).unwrap()
      dispatch(editingDone())
      setIsRefreshing(false)
    } catch {
      window.location.reload()
    }
  }

  return (
    <div className={styles['container']}>
      {isRefreshing || !route ? (
        <ImagesGridSkeleton />
      ) : (
        <>
          {hasImages && !isEditing && (
            <ImagesGrid
              images={route.images.slice(0, 4) as OneToFourImages}
              numMoreImages={route.images.length - 4}
              onImageClick={(index) => dispatch(imageOpened({ index }))}
            />
          )}
          {isOwnRoute && (isEditing || !hasImages) && <RouteImagesUploader onImagesUpdated={handleImagesUpdated} />}
          {isOwnRoute && !isEditing && !isEditingAnotherField && hasImages && (
            <div className={styles['edit-button-holder']}>
              <EditContentButton name={name} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
