import { ROUTING_PROFILE_CYCLING_PATHS } from 'shared/data-access-core'
import { SelectRow } from 'shared/ui-components'
import { useMemo } from 'react'
import { RoutePlannerSliceDispatch, changeRoutingLevel, useRoutePlannerState } from '../../state'
import { useLocale } from 'shared/util-intl'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'

export const RoutingLevelSelect = () => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { routingLevel } = useRoutePlannerState()

  const levelLabels = useMemo(
    () => ({
      low: intl.formatMessage({
        id: 'routing_profile_cycling_paths_level_low',
        defaultMessage: 'Low',
      }),
      medium: intl.formatMessage({
        id: 'routing_profile_cycling_paths_level_medium',
        defaultMessage: 'Medium',
      }),
      high: intl.formatMessage({
        id: 'routing_profile_cycling_paths_level_high',
        defaultMessage: 'High',
      }),
    }),
    [intl],
  )

  const levelDescriptions = useMemo(
    () => ({
      low: intl.formatMessage({
        id: 'routing_profile_cycling_paths_level_description_low',
        defaultMessage: 'Cycling lanes are prioritised. Could cause minor detours.',
      }),
      medium: intl.formatMessage({
        id: 'routing_profile_cycling_paths_level_description_medium',
        defaultMessage: 'Cycling lanes are prioritised over other roads as long as the detour is manageable.',
      }),
      high: intl.formatMessage({
        id: 'routing_profile_cycling_paths_level_description_high',
        defaultMessage: 'Force cycling lanes if they exist. Could cause major detours.',
      }),
    }),
    [intl],
  )

  return (
    <div>
      <SelectRow
        options={ROUTING_PROFILE_CYCLING_PATHS.map((level) => ({
          value: level,
          label: levelLabels[level],
        }))}
        value={routingLevel}
        onSelect={(value) => dispatch(changeRoutingLevel(value))}
      />
      <Typography paragraph variant="body2" mt="0.25rem" color="textSecondary">
        {levelDescriptions[routingLevel]}
      </Typography>
    </div>
  )
}
