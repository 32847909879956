import { useState } from 'react'
import Directions from '@mui/icons-material/Directions'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { Button, ButtonGroup } from 'shared/ui-components'
import { LocationMapPopup, SelectedMapLocation, useMapLocationSelection } from 'web-app/feature-map'
import { useLocale } from 'shared/util-intl'
import { GeocoderLocation, reverseGeocode } from 'shared/data-access-geocoding'
import { MapMarkerLocation } from 'shared/ui-map'
import { areLngLatsEqual } from 'shared/util-geo'
import { useNavigate } from 'react-router-dom'
import { WEB_APP_PATH_ROUTE_PLANNER } from 'shared/util-navigation'
import { HomeSliceDispatch, routeSelectionCleared } from '../state'
import { useDispatch } from 'react-redux'

export const MapLocationSelection = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch() as HomeSliceDispatch
  const { intl, language } = useLocale()

  const [selectedLocation, setSelectedLocation] = useState<SelectedMapLocation | null>(null)

  useMapLocationSelection(
    async (location) => {
      dispatch(routeSelectionCleared())
      setSelectedLocation(location)
      if (!location.address) {
        const position = location.position
        const res = await reverseGeocode(position, language)
        setSelectedLocation((selectedLocation) => {
          if (selectedLocation && areLngLatsEqual(selectedLocation.position, position)) {
            return {
              ...selectedLocation,
              address: res.success ? res.data.address : intl.formatMessage({
                id: 'reverse_geocoding_unknown_address',
                defaultMessage: 'Unknown address',
              }),
            }
          }
          return selectedLocation
        })
      }
    },
    () => setSelectedLocation(null),
    !selectedLocation
  )

  return selectedLocation && (
    <>
      <MapMarkerLocation longitude={selectedLocation.position.lng} latitude={selectedLocation.position.lat} />
      <LocationMapPopup
        longitude={selectedLocation.position.lng}
        latitude={selectedLocation.position.lat}
        geocoded={selectedLocation.address ? (selectedLocation as GeocoderLocation) : undefined}
      >
        <ButtonGroup>
          <Button
            icon={<Directions />}
            onClick={() => {
              const { position, address, poiName } = selectedLocation
              navigate(WEB_APP_PATH_ROUTE_PLANNER, {
                state: {
                  destination: {
                    ...position,
                    address,
                    poiName,
                  },
                },
              })
            }}
          >
            {intl.formatMessage({
              id: 'planner_entry_action_destination',
              defaultMessage: 'Set destination',
            })}
          </Button>
          <Button
            variant='secondary'
            icon={<AddCircleRoundedIcon />}
            onClick={() => {
              const { position, address, poiName } = selectedLocation
              navigate(WEB_APP_PATH_ROUTE_PLANNER, {
                state: {
                  origin: {
                    ...position,
                    address,
                    poiName,
                  },
                },
              })
            }}
          >
            {intl.formatMessage({
              id: 'planner_entry_action_start',
              defaultMessage: 'Start here',
            })}
          </Button>
        </ButtonGroup>
      </LocationMapPopup>
    </>
  )
}
