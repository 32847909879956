import { MapProvider } from 'react-map-gl/maplibre'
import React, { Suspense, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Map, { MapProps, VisibleMapPadding } from 'shared/ui-map'
import { viewportChanged, useMapViewport, useMapState, globalHeatmapSupportDetected, boundsChanged } from './state'
import { MAP_ID } from './settings'
import { WebAppControls, WebAppFooter, WebAppMedia, useLayoutMediaQueries, useLayoutRefs } from 'web-app/ui-layout'
import { GeolocateControl } from './controls/geolocate-control'
import { MapStyleControl } from './controls/map-style-control'
import { NavigationControl } from './controls/navigation-control'
import { MapAttributionsFooter } from './map-attributions-footer'
import { LngLatBoundsArray } from 'shared/util-geo'

export interface WebAppMapProps {
  children?: React.ReactNode
  padding?: VisibleMapPadding
  boundsToFit?: LngLatBoundsArray | null
  onFitBounds?: () => void
  onClick?: MapProps['onClick']
}

/**
 * Renders a stateful map as Web App media, related controls and a footer with attributions. This contains Web App
 * layout components, so it needs to be inside a `WebAppLayout`.
 */
export const WebAppMap = (props: WebAppMapProps) => {
  const dispatch = useDispatch()
  const { isLargeViewport } = useLayoutMediaQueries()
  const { bottomSheetRef } = useLayoutRefs()
  const { mapStyle, isGlobalHeatmapEnabled, isGlobalHeatmapSupported } = useMapState()

  const bottomSheetHeight = bottomSheetRef.current?.height || 0
  const mapPadding = useMemo(() => {
    return isLargeViewport ? {
      top: 160,
      right: 118,
      bottom: 170,
      left: 454,
    } : {
      top: 56, // start marker + 1rem
      right: 86, // 1rem + controls + 1rem + 1/2 start marker
      bottom: bottomSheetHeight + 16, // standard sheet height (open or closed) + 1rem
      left: 30, // 1rem + 1/2 start marker
    }
  }, [bottomSheetHeight, isLargeViewport])

  return (
    <MapProvider>
      <WebAppMedia>
        <Suspense>
          <Map
            {...props}
            id={MAP_ID}
            mapStyle={mapStyle}
            viewport={useMapViewport()}
            onViewportChanged={(viewport) => dispatch(viewportChanged(viewport))}
            onBoundsChange={(bounds) => dispatch(boundsChanged(bounds))}
            padding={mapPadding}
            isGlobalHeatmapEnabled={isGlobalHeatmapSupported && isGlobalHeatmapEnabled}
            onGlobalHeatmapSupportDetection={(isSupported) => {
              if (isGlobalHeatmapSupported !== isSupported) {
                dispatch(globalHeatmapSupportDetected(isSupported))
              }
            }}
          />
        </Suspense>
      </WebAppMedia>
      <WebAppControls>
        <NavigationControl />
        <GeolocateControl />
        <MapStyleControl small={!isLargeViewport} />
      </WebAppControls>
      <WebAppFooter>
        <MapAttributionsFooter />
      </WebAppFooter>
    </MapProvider>
  )
}
