import { useLocale } from 'shared/util-intl'
import { NavigationLinkProps } from './types'
import { Button, GPXUploadIcon } from 'shared/ui-components'
import { WEB_APP_IMPORT } from 'shared/util-navigation'
import { useInternalLink } from '../use-internal-link'

export const RouteImportLink = ({ primary, compact }: NavigationLinkProps) => {
  const getInternalLinkProps = useInternalLink()
  const { intl } = useLocale()

  return (
    <Button
      block
      variant={primary ? 'primary' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      icon={<GPXUploadIcon />}
      {...getInternalLinkProps(WEB_APP_IMPORT)}
      disabled={window.location.href.includes(WEB_APP_IMPORT)}
    >
      {intl.formatMessage({
        id: 'route_import_link_label',
        defaultMessage: 'Import a route',
      })}
    </Button>
  )
}
