import { getNearestPoint, routingApi } from 'shared/data-access-core'
import {
  controlPointsSelector,
  hasRouteDistanceSelector,
  routePlannerSliceSelector,
  waypointsSelector,
} from '../selectors'
import {
  freehandCalculated,
  freehandSegmentsCalculated,
  routeDataOutdated,
  routingFailure,
  routingRequest,
  routingSuccess,
  segmentRoutingSuccess,
} from '../state'
import { RoutePlannerSliceDispatch, StateWithRoutePlannerSlice } from '../types'
import { INTL_SLICE_KEY, StateWithIntlSlice } from 'shared/util-intl'
import { LngLat, LngLatElevation } from 'shared/util-geo'

/**
 * Make a routing request for the whole route based on current waypointe.
 */
export function calculateRoute() {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithIntlSlice,
  ) => {
    const state = getState()
    const { isFullRoute, waypoints } = waypointsSelector(state)
    const controlPoints = controlPointsSelector(state)

    if (isFullRoute && hasRouteDistanceSelector(state)) {
      const { routingMode, routingProfile, routingLevel } = routePlannerSliceSelector(state)

      if (routingMode === 'routing') {
        const language = state[INTL_SLICE_KEY].language
        dispatch(routingRequest())
        const res = await routingApi.getRoute(controlPoints || waypoints, language, routingProfile, routingLevel)
        if (res.success) {
          dispatch(
            routingSuccess({
              geometry: res.data.geometry,
              distanceInM: res.data.distance,
              durationInS: res.data.time / 1000,
              surfacesAlongTheRoute: res.data.surfacesAlongTheRoute,
              wayTypesAlongTheRoute: res.data.wayTypesAlongTheRoute,
              bikeNetworkAlongTheRoute: res.data.bikeNetworkAlongTheRoute,
              snappedWaypoints: res.data.snappedWaypoints,
            }),
          )
        } else {
          dispatch(routingFailure(res.errors))
        }
      } else if (routingMode === 'freehand') {
        dispatch(
          freehandCalculated({
            controlPoints: await getPointsWithElevation(controlPoints || waypoints),
          }),
        )
      }
    } else if (routePlannerSliceSelector(getState()).geometry) {
      dispatch(routeDataOutdated())
    }
  }
}

/**
 * Calculate new segments between given locations and insert them at a given segment index, overwriting
 * a given number of existing segments.
 */
export function calculateSegments(requestWaypoints: LngLat[], segmentIndex: number, numSegments: number) {
  return async (
    dispatch: RoutePlannerSliceDispatch,
    getState: () => StateWithRoutePlannerSlice & StateWithIntlSlice,
  ) => {
    if (requestWaypoints.length < 2) return
    const state = getState()
    const { routingMode, routingProfile, routingLevel } = routePlannerSliceSelector(state)

    if (routingMode === 'routing') {
      const language = state[INTL_SLICE_KEY].language
      dispatch(routingRequest())
      const res = await routingApi.getRoute(requestWaypoints, language, routingProfile, routingLevel)
      if (res.success) {
        const { geometry, distance, time, snappedWaypoints } = res.data
        dispatch(
          segmentRoutingSuccess({
            geometry,
            distanceInM: distance,
            durationInS: time / 1000,
            surfacesAlongTheRoute: res.data.surfacesAlongTheRoute,
            wayTypesAlongTheRoute: res.data.wayTypesAlongTheRoute,
            bikeNetworkAlongTheRoute: res.data.bikeNetworkAlongTheRoute,
            segmentIndex,
            numSegments,
            snappedWaypoints,
          }),
        )
      } else {
        dispatch(routingFailure(res.errors))
      }
    } else if (routingMode === 'freehand') {
      dispatch(
        freehandSegmentsCalculated({
          controlPoints: await getPointsWithElevation(requestWaypoints),
          segmentIndex,
          numSegments,
        }),
      )
    }
  }
}

/**
 * Make parallel nearest point requests for each point without elevation, then return a list where all points
 * have elevation defined.
 */
async function getPointsWithElevation(points: LngLat[]): Promise<LngLatElevation[]> {
  return Promise.all(
    points.map(async (point): Promise<LngLatElevation> => {
      if (point.elevation) {
        return point as LngLatElevation
      } else {
        try {
          const res = await getNearestPoint(point)
          if (res.success) {
            return { ...point, elevation: res.data.point.elevation }
          }
        } catch {}
        return { ...point, elevation: 0 }
      }
    }),
  )
}
