import { useEffect, useRef } from 'react'
import { colors } from 'shared/ui-design-system'
import { useRoute } from 'web-app/feature-route'
import { EmbedOptions } from './embed-options-form'
import { getRouteDetailUrl, getRouteWidgetUrl } from 'shared/util-navigation'
import { useSessionInfo } from 'web-app/feature-navigation'
import { useLocale } from 'shared/util-intl'

import styles from './embed-preview.module.scss'

interface EmbedPreviewProps {
  options: EmbedOptions
  onHtmlChange: (html: string) => void
}

export const EmbedPreview = ({ options, onHtmlChange }: EmbedPreviewProps) => {
  const { intl } = useLocale()
  const route = useRoute()
  const sessionInfo = useSessionInfo()

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const html = wrapperRef.current?.innerHTML
    onHtmlChange(html ? html.replace(/&amp;/g, '&') : '')
  })

  return route ? (
    <div className={styles['container']}>
      <div className={styles['wrapper']} ref={wrapperRef}>
        <iframe
          title={route.title}
          src={getRouteWidgetUrl({
            routeId: route.id,
            unitPreference: sessionInfo.unitPreference,
            extended: options.extended,
            distanceMarkers: options.distanceMarkers,
          })}
          width={options.width}
          height={options.height}
          style={{ border: 'none' }}
        />
        <div
          style={{
            marginLeft: '16px',
            height: '16px',
            fontFamily: 'sans-serif',
            fontSize: '11px',
          }}
        >
          <a
            href={getRouteDetailUrl({
              routeId: route.id,
              ...sessionInfo,
            })}
            style={{ color: colors.actionColor.onNeutral.primary }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(
              {
                id: 'embed_preview_route',
                defaultMessage: 'Cycle route {routeId}',
              },
              {
                routeId: route.id,
              },
            )}
          </a>
          {' - '}
          {intl.formatMessage(
            {
              id: 'embed_preview_via',
              defaultMessage: 'via {websiteLink}',
            },
            {
              websiteLink: (
                <a
                  href={process.env['NX_PUBLIC_WEBSITE_URL']}
                  style={{ color: colors.actionColor.onNeutral.primary }}
                  target="_blank"
                  rel="noreferrer"
                >
                  bikemap.net
                </a>
              ),
            },
          )}
        </div>
      </div>
    </div>
  ) : null
}
