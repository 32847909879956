import { RoutePoiMapMarker } from './map/route-poi-map-marker'
import { useRoutePois } from './route-pois-context'
import { useMap } from 'react-map-gl/maplibre'
import { useEffect } from 'react'
import { RoutePoiEntity } from 'shared/data-access-core'
import { RoutePoiMapMarkerEditing } from './map/route-poi-map-marker-editing'

interface RoutePoisMapFeaturesProps {
  mapId: string
  onSelect?: (routePoi: RoutePoiEntity) => void
  interactive?: boolean
}

/**
 * Shows route POIs on a map.
 */
export const RoutePoisMapFeatures = ({
  mapId,
  onSelect,
  interactive = true,
}: RoutePoisMapFeaturesProps) => {
  const maps = useMap()
  const map = maps[mapId]

  const { routePois, selectedRoutePoi, editingPosition, onRoutePoiSelectionChange } = useRoutePois()

  useEffect(() => {
    const handleMapClick = () => {
      onRoutePoiSelectionChange(null)
    }
    if (map) {
      map.on('click', handleMapClick)
    }
    return () => {
      if (map) {
        map.off('click', handleMapClick)
      }
    }
  }, [map, onRoutePoiSelectionChange])

  return (
    <>
      {routePois.sort((a, b) => b.lat - a.lat).map((routePoi) => {
        return !editingPosition || routePoi.id !== selectedRoutePoi?.id ? (
          <RoutePoiMapMarker key={routePoi.id} routePoi={routePoi} onSelect={onSelect} interactive={interactive} />
        ) : null
      })}
      {editingPosition && <RoutePoiMapMarkerEditing position={editingPosition} />}
    </>
  )
}
