import { useRouteState } from 'web-app/feature-route'
import { ViewNotFound } from 'web-app/utils-error-handling'
import { RoutePlannerBase } from './base'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RoutePlannerSliceDispatch, initFromRoute } from '../state'

interface RoutePlannerEditProps {
  routeId: number
}

export const RoutePlannerEdit = ({
  routeId,
}: RoutePlannerEditProps) => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { isRouteUnavailable } = useRouteState()

  useEffect(() => {
    const abortController = new AbortController
    dispatch(initFromRoute(routeId, abortController.signal))
    return () => abortController.abort()
  }, [dispatch, routeId])

  return isRouteUnavailable
    ? <ViewNotFound />
    : <RoutePlannerBase />
}
