import { useLocale, useTitle } from 'shared/util-intl'
import RouteImport from 'web-app/view-route-import'

const RouteImportView = () => {
  const { intl } = useLocale()
  useTitle(
    intl.formatMessage({
      id: 'route_import_document_title',
      defaultMessage: 'Import your cycling track - Bikemap',
    }),
  )

  return <RouteImport />
}

export default RouteImportView
