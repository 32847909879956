import { Switch } from 'shared/ui-components'
import { useDispatch } from 'react-redux'
import {
  RoutePlannerSliceDispatch,
  applyRoutingProfileToWholeRoute,
  routingAppliedToChanges,
  useRoutePlannerState,
} from '../../state'
import { useLocale } from 'shared/util-intl'
import { Typography } from '@mui/material'

export const ApplyRoutingSwitch = () => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { isWholeRouteRouted } = useRoutePlannerState()

  return (
    <>
      <Switch
        contained
        label={intl.formatMessage({
          id: 'route_planner_routing_apply_to_whole_route',
          defaultMessage: 'Apply to entire route',
        })}
        checked={isWholeRouteRouted}
        onChange={(checked) => dispatch(checked ? applyRoutingProfileToWholeRoute() : routingAppliedToChanges())}
      />
      <Typography paragraph variant="body2" color="textSecondary">
        {isWholeRouteRouted
          ? intl.formatMessage({
            id: 'route_planner_apply_to_whole_route_disable_explanation',
            defaultMessage:
                'Disable this to prevent the recalculation of the entire route when selecting a different routing option. Only newly added or edited segments will have the routing option applied.',
          })
          : intl.formatMessage({
            id: 'route_planner_apply_to_whole_route_enable_explanation',
            defaultMessage: 'Enable this to recalculate the entire route based on the selected routing option.',
          })}
      </Typography>
    </>
  )
}
