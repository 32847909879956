import React from 'react'
import { Toaster } from 'sonner'

/**
 * Placeholder where all notifications will be rendered.
 */
export function NotificationsContainer() {
  return (
    <Toaster
      position="top-right"
      offset="1rem"
      style={{ '--width': '27.5rem' } as React.CSSProperties}
      toastOptions={{
        style: { right: 0 },
      }}
    />
  )
}
