import { languageUpdated, useLocale } from 'shared/util-intl'
import { useDispatch } from 'react-redux'
import { LanguageSelectRow } from 'shared/ui-navigation'

export const WebAppLanguageSelect = () => {
  const dispatch = useDispatch()
  const { language } = useLocale()

  return <LanguageSelectRow language={language} onSelect={(locale) => dispatch(languageUpdated(locale))} />
}
