import FavoriteIcon from '@mui/icons-material/Favorite'
import { RouteCollectionItem } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useUserState } from 'web-app/feature-user'

export const FavoritesItem = () => {
  const { intl } = useLocale()
  const { user } = useUserState()

  return (
    <RouteCollectionItem
      title={intl.formatMessage({
        id: 'favorites_item_title',
        defaultMessage: 'Favorites',
      })}
      info={intl.formatMessage(
        {
          id: 'favorites_item_info',
          defaultMessage: '{routesCount} saved routes',
        },
        {
          routesCount: user?.favoriteRoutesCount ?? 0,
        },
      )}
      icon={<FavoriteIcon />}
    />
  )
}
