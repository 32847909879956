import { PremiumBadgeIcon } from 'shared/ui-components'

import styles from './premium-modal-header.module.scss'

interface PremiumModalHeaderProps {
  image: {
    png: string
    webp: string
    avif: string
    alt: string
  }
  badge?: string
}

export const PremiumModalHeader = ({
  image,
  badge,
}: PremiumModalHeaderProps) => (
  <header className={styles['container']}>
    <picture>
      <source srcSet={image.avif} type="image/avif" />
      <source srcSet={image.webp} type="image/webp" />
      <source srcSet={image.png} type="image/png" />
      <img src={image.png} alt={image.alt} width="380" height="256" />
    </picture>
    {badge && <div className={styles['badge']}>{badge}</div>}
    <PremiumBadgeIcon className={styles['premium-badge']} />
  </header>
)
