import { useLocale } from 'shared/util-intl'
import ExploreIcon from '@mui/icons-material/Explore'
import { NavigationLinkProps } from './types'
import { Button } from 'shared/ui-components'
import { getSearchUrl } from 'shared/util-navigation'
import { useSessionInfo } from '../hooks'

export const DiscoverLink = ({ primary, compact }: NavigationLinkProps) => {
  const { intl } = useLocale()
  const sessionInfo = useSessionInfo()

  return (
    <Button
      block
      variant={primary ? 'primary' : 'secondary'}
      size={compact ? 'medium' : 'default'}
      href={getSearchUrl(sessionInfo)}
      icon={<ExploreIcon />}
    >
      {intl.formatMessage({
        id: 'discover_link_label',
        defaultMessage: 'Discover routes',
      })}
    </Button>
  )
}
