import { Typography } from '@mui/material'
import { ButtonGroup } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { MyRoutesLink, RouteImportLink, RoutePlannerIllustratedLink } from 'web-app/feature-navigation'
import { useUser } from 'web-app/feature-user'
import { WebAppContent } from 'web-app/ui-layout'

export const QuickAccessSection = () => {
  const { intl } = useLocale()
  const [user, isUserLoaded] = useUser()

  return (
    <WebAppContent>
      <Typography variant="h2" marginBottom='1rem'>
        {intl.formatMessage({
          id: 'home_quick_actions_subtitle',
          defaultMessage: 'What would you like to do?',
        })}
      </Typography>
      <ButtonGroup>
        <RoutePlannerIllustratedLink />
        <RouteImportLink />
        {isUserLoaded && user && <MyRoutesLink user={user} />}
      </ButtonGroup>
    </WebAppContent>
  )
}
