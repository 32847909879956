import { useDispatch } from 'react-redux'
import { languageUpdated, AvailableLocale } from 'shared/util-intl'
import { CookieConsent, CookieConsentSettings } from 'shared/feature-cookie-consent'
import { cookieBannerHidden, settingsChanged } from './state'
import { useCookieConsentState } from './selectors'

interface WebAppCookieConsentProps {
  unitPreference: 'metric' | 'imperial'
}

export function WebAppCookieConsent({ unitPreference }: WebAppCookieConsentProps) {
  const dispatch = useDispatch()
  const { settings, hidden } = useCookieConsentState()

  const onLanguageSelect = (lang: AvailableLocale) => {
    dispatch(languageUpdated(lang))
  }

  const onSave = (settings: CookieConsentSettings): void => {
    dispatch(settingsChanged(settings))
    dispatch(cookieBannerHidden())
  }

  return (
    <CookieConsent
      unitPreference={unitPreference}
      settings={settings}
      hidden={hidden}
      onSave={onSave}
      onLanguageSelect={onLanguageSelect}
    />
  )
}
