import { has, isEmpty } from 'lodash'
import { addApiHeaders, formatDateToISO, postToCoreApi, deleteFromCoreApi } from '../../network'
import { API_PATH_ROUTE, API_PATH_ROUTES, API_PATH_ROUTE_UPLOAD_IMAGE } from '../../config'
import { RouteCreateRequestBody, RouteFormOld } from './types'
import { ApiServiceResult } from '../shared/types'
import { ApiService } from '../shared/api-service'
import { getFailureResult, getSuccessResult } from '../shared/helpers'
import { lngLatToPosition2d } from 'shared/util-geo'

type CreateRouteErrors = {
  unexpectedError?: true
  unexpectedResponse?: true
}

type UploadImageErrors = {
  unexpectedError?: true
  unexpectedResponse?: true
}

type DeleteErrors = {
  unexpectedError?: true
}

export class RoutesApiService extends ApiService {

  /**
   * Create a route.
   * @link https://development.bikemap.net/api/swagger-ui/#/routes/routes_create
   * @returns ID of the created route
   */
  create = async (route: RouteFormOld): ApiServiceResult<string, CreateRouteErrors> => {
    try {
      const body: RouteCreateRequestBody = {
        title: route.title || null,
        description: route.description || null,
        ground: route.surfaces && !isEmpty(route.surfaces) ? route.surfaces : [0],
        category: route.bikeTypes && !isEmpty(route.bikeTypes) ? route.bikeTypes : [0],
        points: {
          ...route.points,
          type: 'MultiLineString',
        },
        distance: route.distance || 0,
        is_private: route.isPrivate,
        planned: true,
        created: formatDateToISO(new Date()),
        app_type: route.appType || null,
        app_version: route.appVersion || null,
      }

      if (route.waypoints) {
        body.waypoints = {
          type: 'MultiPoint',
          coordinates: route.waypoints.map(lngLatToPosition2d),
        }
      }

      if (route.duration) {
        body.duration = route.duration
      }

      const res = await postToCoreApi(API_PATH_ROUTES, {
        headers: await addApiHeaders(),
        body,
        type: 'json',
      })

      if (has(res, 'id') && res.id) {
        return getSuccessResult(res.id)
      }

      this.logError('Could not create route', null, { route, res })
      return getFailureResult({ unexpectedError: true })
    } catch (e) {
      this.logError('Invalid route response', e, { route })
      return getFailureResult({ unexpectedResponse: true })
    }
  }

  /**
   * Upload and save route image.
   * @link https://development.bikemap.net/api/swagger-ui/#/routes/routes_upload_image_create
   */
  uploadImage = async (routeId: string, image: File): ApiServiceResult<File, UploadImageErrors> => {
    try {
      const res = await postToCoreApi(API_PATH_ROUTE_UPLOAD_IMAGE, {
        params: { routeId },
        body: { image },
        headers: await addApiHeaders(),
      })

      if (has(res, 'status') && res.status === 'OK') {
        return getSuccessResult(image)
      }

      this.logError('Could not upload route image', null, { routeId, image, res })
      return getFailureResult({ unexpectedError: true })
    } catch (e) {
      this.logError('Invalid upload image response', e, { routeId, image })
      return getFailureResult({ unexpectedResponse: true })
    }
  }

  delete = async (routeId: number): ApiServiceResult<number, DeleteErrors> => {
    try {
      await deleteFromCoreApi(API_PATH_ROUTE, {
        headers: await addApiHeaders(),
        params: { routeId },
      })
      return getSuccessResult(routeId)
    } catch (e) {
      this.logError('Could not delete route', e, { routeId })
      return getFailureResult({ unexpectedError: true })
    }
  }

}
