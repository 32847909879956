import { LANGUAGE_URL_PARAM, UNIT_PREFERENCE_URL_PARAM, addTrackingParameters } from './params'
import { WEB_APP_PATH_ROUTE_DETAILS } from './paths'
import { BaseUrlArgs } from './types'

const WEB_APP_BASE_URL = process.env['NX_PUBLIC_WEB_APP_URL']

export function getWebAppParams({
  language,
  unitPreference,
  cookieConsentStatistics,
  trackingParameters,
}: BaseUrlArgs): string {
  const params = new URLSearchParams({
    [LANGUAGE_URL_PARAM]: language,
    [UNIT_PREFERENCE_URL_PARAM]: unitPreference,
  })

  if (cookieConsentStatistics !== null) {
    params.set('cookieConsentStatistics', cookieConsentStatistics ? '1' : '0')
  }

  if (trackingParameters) {
    addTrackingParameters(trackingParameters, params)
  }

  return params.toString()
}

export function getRouteDetailsUrl(routeId: number): string {
  return WEB_APP_BASE_URL + WEB_APP_PATH_ROUTE_DETAILS.replace(':routeId', routeId.toString())
}
