import { useMemo } from 'react'
import { RouteBikeTypes } from 'shared/data-access-core'
import { useLocale } from 'shared/util-intl'

type BikeTypeLabels = {
  1: string
  2: string
  3: string
}

export const useBikeTypeLabels = (): BikeTypeLabels => {
  const { intl } = useLocale()

  const labels = useMemo<BikeTypeLabels>(
    () => ({
      1: intl.formatMessage({
        id: 'route_bike_type_road_bike',
        defaultMessage: 'Road bike',
      }),
      2: intl.formatMessage({
        id: 'route_bike_type_mtb',
        defaultMessage: 'MTB',
      }),
      3: intl.formatMessage({
        id: 'route_bike_type_city_bike',
        defaultMessage: 'City bike',
      }),
    }),
    [intl],
  )

  return labels
}

export const useBikeTypes = (bikeTypes: RouteBikeTypes): string[] => {
  const labels = useBikeTypeLabels()
  return useMemo(() => bikeTypes.map(key => labels[key]), [bikeTypes, labels])
}
