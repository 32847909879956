import React from 'react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import styles from './IconVia.module.scss'

interface IconViaProps {
  label: string
}

export const IconVia = ({ label }: IconViaProps) => (
  <div className={styles['root']}>
    <RadioButtonUncheckedIcon />
    <div className={styles['label']}>{label}</div>
  </div>
)

export default IconVia
