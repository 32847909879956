import { useDispatch } from 'react-redux'
import {
  RoutePlannerSliceDispatch,
  drawRoute,
  locationSelected,
  selectLocation,
  selectionCanceled,
  useRoutePlannerState,
} from '../state'
import { MapLayerMouseEvent, useMap } from 'react-map-gl/maplibre'
import { MAP_ID, useMapLocationSelection } from 'web-app/feature-map'
import { useElevationCurveContext } from 'shared/feature-elevation-curve'
import { useCallback, useMemo } from 'react'
import { useLayoutMediaQueries } from 'web-app/ui-layout'

export const useMapInteraction = () => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { [MAP_ID]: map } = useMap()
  const { selectionIndexes, onSelectionIndexesChange } = useElevationCurveContext()
  const { selectedLocation, selectedWaypoint } = useRoutePlannerState()
  const { isLargeViewport } = useLayoutMediaQueries()

  const isLocationSelectionActive = useMemo<boolean>(() => !(
    selectedLocation || selectedWaypoint !== null
  ), [selectedLocation, selectedWaypoint])

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const currentMap = map?.getMap()

      if (selectionIndexes) {
        onSelectionIndexesChange()
      }

      if (!currentMap || selectedLocation || selectedWaypoint !== null) {
        dispatch(selectionCanceled())
      } else if (isLargeViewport) {
        dispatch(drawRoute(event.lngLat))
      }
    },
    [
      map,
      selectionIndexes,
      selectedLocation,
      selectedWaypoint,
      isLargeViewport,
      onSelectionIndexesChange,
      dispatch,
    ],
  )

  useMapLocationSelection(
    ({ position: { lng, lat }, address, poiName }) => {
      if (selectionIndexes) {
        onSelectionIndexesChange()
      }
      if (address) {
        dispatch(
          locationSelected({
            lng,
            lat,
            poiName,
            address,
          }),
        )
      } else {
        dispatch(selectLocation({ lng, lat }, poiName))
      }
    },
    handleMapClick,
    isLocationSelectionActive
  )
}
