import { API_PATH_DEEPLINK } from '../config'
import { addApiHeaders, getFromCoreApi } from '../network'
import { ApiResult, createFailureResult, createSuccessResult } from 'shared/util-network'

type AdditionalParams = {
  fallbackUrl?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
}

type RequestQueryParams = {
  url: string
  ofl?: string
  efr?: 1
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
}

/**
 * Get a dynamic link (mobile app deep link > platform app store > web page) for the given URL.
 */
export async function getDynamicLink(url: string, additionalParams: AdditionalParams = {}): ApiResult<string> {
  try {
    const queryParams: RequestQueryParams = {
      url,
      efr: 1,
    }

    const { fallbackUrl, utmSource, utmMedium, utmCampaign } = additionalParams
    if (fallbackUrl) {
      queryParams.ofl = fallbackUrl
    }
    if (utmSource && utmMedium && utmCampaign) {
      queryParams.utm_source = utmSource
      queryParams.utm_medium = utmMedium
      queryParams.utm_campaign = utmCampaign
    }

    const res = await getFromCoreApi(API_PATH_DEEPLINK, {
      headers: await addApiHeaders(),
      queryParams,
    })
    return createSuccessResult(res.deep_link)
  } catch (e) {
    return createFailureResult({ unexpectedError: true }, { url })
  }
}
