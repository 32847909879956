import { createSelector } from 'reselect'
import { RouteImportState } from './types'
import { hasGeometry, hasMatching, hasSimplify } from './selectors'

/**
 * Find next step based on current state.
 */
export const getNextStep: (state: RouteImportState) => RouteImportState['step'] = createSelector(
  [
    (state) => state.step,
    (state) => state.isMatchingSelected,
    (state) => hasGeometry(state),
    (state) => hasMatching(state),
    (state) => state.isSimplifiedRouteMissing,
  ],
  (step, isMatchingSelected, hasGeometryData, hasMatchingData, isSimplifiedRouteMissing) => {
    if (step === 'upload') {
      if (hasMatchingData) {
        return 'matching'
      } else {
        return 'simplify'
      }
    }

    if (step === 'matching') {
      // If map-matching is selected or simplified route not found we can skip simplify step
      if ((isMatchingSelected && hasMatchingData) || isSimplifiedRouteMissing) {
        return 'save'
      }
      if (!isMatchingSelected) {
        return 'simplify'
      }
    }

    if (step === 'simplify') {
      if (hasGeometryData) {
        return 'save'
      } else {
        // Just reset back to first step since we don't have any useful route data
        return 'upload'
      }
    }

    // Stay on same step as a fallback, but this shouldn't really happen
    return step
  },
)

/**
 * Find previous step based on current state.
 */
export const getPrevStep: (state: RouteImportState) => RouteImportState['step'] = createSelector(
  [
    (state) => state.step,
    (state) => state.isMatchingSelected,
    (state) => hasMatching(state),
    (state) => hasSimplify(state),
  ],
  (step, isMatchingSelected, hasMatchingData, hasSimplifyData) => {
    if (step === 'matching') {
      return 'upload'
    }

    if (step === 'simplify') {
      if (hasMatchingData) {
        return 'matching'
      } else {
        return 'upload'
      }
    }

    if (step === 'save') {
      if (!isMatchingSelected && hasSimplifyData) {
        return 'simplify'
      }
      if (hasMatchingData) {
        return 'matching'
      }
      // If both map-matching and simplification failed
      if (!hasMatchingData && !hasSimplifyData) {
        return 'upload'
      }
    }

    // Stay on same step as a fallback, but this shouldn't really happen
    return step
  },
)
