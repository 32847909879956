import { useState } from 'react'
import { useDispatch } from 'react-redux'
import UndoRounded from '@mui/icons-material/UndoRounded'
import RedoRounded from '@mui/icons-material/RedoRounded'
import { MainNavigation, ToolButton } from 'shared/ui-components'
import { useLocale } from 'shared/util-intl'
import { useUndoRedo, useWaypoints, useHasRouteBeenChanged, RoutePlannerSliceDispatch, redo, undo } from '../state'
import { RoutePlannerCancelDialog } from './cancel-dialog'
import { useRoutePlannerCancel } from './use-route-planner-cancel'

export const RoutePlannerMainNavigation = () => {
  const dispatch = useDispatch() as RoutePlannerSliceDispatch
  const { intl } = useLocale()
  const { canUndo, canRedo } = useUndoRedo()
  const { isFullRoute } = useWaypoints()
  const hasRouteBeenChanged = useHasRouteBeenChanged()
  const onCancel = useRoutePlannerCancel()

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)

  return (
    <>
      <MainNavigation
        title={intl.formatMessage({
          id: 'route_planner_title',
          defaultMessage: 'Route planner',
        })}
        actions={
          <>
            <ToolButton
              variant="ghost-primary"
              ariaLabel={intl.formatMessage({
                id: 'route_planner_undo_label',
                defaultMessage: 'Undo',
              })}
              disabled={!canUndo}
              icon={<UndoRounded />}
              onClick={() => {
                dispatch(undo())
              }}
            />
            <ToolButton
              variant="ghost-primary"
              ariaLabel={intl.formatMessage({
                id: 'route_planner_redo_label',
                defaultMessage: 'Redo',
              })}
              disabled={!canRedo}
              icon={<RedoRounded />}
              onClick={() => {
                dispatch(redo())
              }}
            />
          </>
        }
        onCancel={() => {
          if (isFullRoute && hasRouteBeenChanged) {
            setIsCancelDialogOpen(true)
          } else {
            onCancel()
          }
        }}
      />
      <RoutePlannerCancelDialog
        open={isCancelDialogOpen}
        onClose={() => {
          setIsCancelDialogOpen(false)
        }}
      />
    </>
  )
}
