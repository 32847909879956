import { BaseUrlArgs, getLoginUrl } from 'shared/util-navigation'
import { useLocale } from 'shared/util-intl'
import { useCookieConsentStatistics } from 'web-app/feature-cookie-consent'
import { useUser, useUserState } from 'web-app/feature-user'
import { useCallback } from 'react'

/**
 * Provides all session info to be passed on to the Website, collected from different state slices.
 */
export function useSessionInfo(): BaseUrlArgs {
  const { language } = useLocale()
  const { unitPreference } = useUserState()
  const cookieConsentStatistics = useCookieConsentStatistics()

  return { language, unitPreference, cookieConsentStatistics }
}

/**
 * Offer functionality based on authentication state, including redirect to signup / login.
 */
export function useAuthenticationWall() {
  const sessionInfo = useSessionInfo()
  const [user, isUserLoaded] = useUser()

  /**
   * Run a function only if the user is authenticated. If the user is definitely anonymous,
   * redirect to signup / login.
   */
  const doForAuthenticatedUser = useCallback(
    (callback: () => void) => {
      if (isUserLoaded) {
        if (!user) {
          window.location.href = getLoginUrl({
            webAppPath: window.location.pathname,
            ...sessionInfo,
          })
        } else {
          callback()
        }
      }
    },
    [isUserLoaded, sessionInfo, user],
  )

  return { doForAuthenticatedUser, isUserLoaded }
}
