import { RoutePreviewEntity, UserPreviewEntity } from 'shared/data-access-core'
import { RouteTile, RouteTileProps } from 'shared/ui-components'
import { useMemo } from 'react'
import { getRouteDetailsUrl } from 'shared/util-navigation'
import { useRouteMessages } from './use-route-messages'
import { useRouteStatsFormatters } from './use-route-stats-formatters'
import { useBikeTypes } from './use-bike-types'
import { useSurfaces } from './use-surfaces'

type OmittedProps =
  'title' |
  'distance' |
  'ascent' |
  'descent' |
  'location' |
  'favoriteCount' |
  'tags' |
  'image' |
  'staticMap' |
  'isFavorite' |
  'avatarProps' |
  'href' |
  'messages'

export interface RouteEntityTileProps extends Omit<RouteTileProps, OmittedProps> {
  route: RoutePreviewEntity
  creator?: UserPreviewEntity
  imperial?: boolean
  href?: string
}

/**
 * Renders a route tile based on a given route entity (and its optional creator entity).
 * Useful when entities are already available or entity state is managed on higher level.
 */
export const RouteEntityTile = ({
  route,
  creator,
  imperial,
  href,
  ...routeTileProps
} : RouteEntityTileProps) => {
  const { distanceLabel, ascentLabel, descentLabel, locationLabel } = useRouteMessages()
  const { formatRouteDistance, formatRouteElevation } = useRouteStatsFormatters(!!imperial)
  const bikeTypes = useBikeTypes(route.bikeTypes)
  const surfaces = useSurfaces(route.surfaces)

  const tags = useMemo<string[]>(() => bikeTypes.concat(surfaces), [bikeTypes, surfaces])

  return (
    <RouteTile
      title={route.title}
      distance={formatRouteDistance(route.distance)}
      ascent={formatRouteElevation(route.ascent)}
      descent={formatRouteElevation(route.descent)}
      location={route.location}
      favoriteCount={route.favoriteCount}
      tags={tags}
      image={route.images.length ? route.images[0] : undefined}
      staticMap={route.staticMap}
      isFavorite={route.isFavorite}
      avatarProps={creator && {
        name: creator.name,
        image: creator.avatar,
        isPremium: creator.isPremium,
      }}
      href={href ?? getRouteDetailsUrl(route.id)}
      messages={{ distanceLabel, ascentLabel, descentLabel, locationLabel }}
      {...routeTileProps}
    />
  )
}
